import React, { useState } from 'react';
import styles from '../index.less';
import { Button, Form, Input, InputNumber, Row, Skeleton, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  UpdateLocaCallConfigInput,
  getLocacallConfig,
  updateLocacallConfig,
} from '../service';
const Locacall = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [form] = Form.useForm();
  const getLocacallRequest = useRequest(getLocacallConfig, {
    onSuccess(res) {
      form.setFieldsValue(res);
    },
  });
  const updateLocacallRequest = useRequest(updateLocacallConfig, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      setIsDisabled(true);
      getLocacallRequest.refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const onFinish = (val: UpdateLocaCallConfigInput) => {
    const payload = Object.entries(val).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Number(value),
      }),
      {},
    );

    updateLocacallRequest.run(payload);
  };
  return (
    <div>
      <div className={styles.setting}>
        <h1 className={styles.titleSetting}>LocaCall</h1>
        <Button
          icon={<EditOutlined />}
          ghost
          type="primary"
          className={styles.editBtn}
          onClick={() => {
            setIsDisabled(!isDisabled);
            if (!isDisabled) {
              getLocacallRequest.refresh();
            }
          }}
        ></Button>
        {getLocacallRequest.loading ? (
          <Skeleton />
        ) : (
          <Form
            disabled={isDisabled}
            className={styles.formItem}
            onFinish={onFinish}
            name="basic"
            labelCol={{ span: 4 }}
            form={form}
            labelWrap={true}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item
              label="Hệ số quy đổi hoạt động"
              name="activity_coefficient"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Hệ số phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Thời gian cuộc gọi"
              name="require_meet_time"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Thời gian phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt gọi mặc định/ngày"
              name="default_call_count"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Lượt gọi phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt nhận mặc định/ngày"
              name="default_receive_count"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Lượt nhận phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt gọi cộng cho membership/ngày"
              name="membership_call_count"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Lượt gọi phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt gọi nhận cho membership/ngày"
              name="membership_receive_count"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Lượt gọi phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt gọi cộng cho giới thiệu thành công"
              name="invite_success_call_count"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Lượt gọi phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia cho 1 cuộc gọi"
              name="maximum_locabonus"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số LB phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia (giờ thấp điểm)"
              name="sleep_time_maximum_locabonus"
              rules={[
                { required: true, message: 'Không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số LB phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            {!isDisabled && (
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Row>
                  <Button
                    className={styles.submitBtn}
                    danger
                    onClick={() => {
                      setIsDisabled(true);
                      if (!isDisabled) {
                        getLocacallRequest.refresh();
                      }
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    className={styles.submitBtn}
                    type="primary"
                    htmlType="submit"
                    loading={updateLocacallRequest.loading}
                  >
                    Lưu
                  </Button>
                </Row>
              </Form.Item>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};

export default Locacall;
