import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Tabs,
  Select,
  message,
  Row,
  Breadcrumb,
} from 'antd';
import { Link, history } from 'umi';
import SelectNFT from '@/components/SelectNFT';
import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import Stall from '../Components/Stall';
import BillBoards from '../Components/BillBoards';
import {
  requestCreateLocaBuilding,
  useGetBuildingTemplate,
  ICreateLocaBuilding,
} from '../service';
import { useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';
import UploadImage from '@/components/UploadImage';
import { extname } from 'path';
import { LeftOutlined } from '@ant-design/icons';

export default () => {
  const [form] = Form.useForm();
  const { buildingTemplate } = useGetBuildingTemplate();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const onCreateLocaBuilding = useRequest(
    (payload: ICreateLocaBuilding) => {
      return requestCreateLocaBuilding(payload);
    },
    {
      manual: true,
      onSuccess: () => {
        setLoading(false);
        message.success('Thành công');
        history.push('/loca-building');
      },
      onError: (err: any) => {
        setLoading(false);
        message.error(err?.data?.message);
      },
    },
  );
  const onFinish = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    onCreateLocaBuilding.run({
      ...values,
      ...{
        image: values.image.file
          ? `${values.image?.[0]?.response?.url}${values.image?.[0]?.response?.path}`
          : values.image,
      },
    });
  };
  const onCancel = () => {
    history.push('/loca-building');
  };
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/loca-building" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Tạo building
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Divider orientation="left">Thông tin chung</Divider>
        <div className={styles.formInfoGeneral}>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={styles.formSearch}
            layout="vertical"
            initialValues={{
              status: true,
            }}
          >
            <Form.Item name="lat_nft" hidden></Form.Item>
            <Form.Item name="long_nft" hidden></Form.Item>
            <Form.Item name="user_owner_nft" hidden></Form.Item>
            <Form.Item name="address_nft" hidden></Form.Item>
            <Form.Item
              name="name"
              label="Tên toà nhà"
              rules={[
                { required: true, message: 'Vui lòng nhập tên toà nhà!' },
              ]}
            >
              <Input />
            </Form.Item>
            <SelectNFT
              fromName="nft_id"
              className={styles.locationNFT}
              onChange={(data: any) => {
                if (!!data) {
                  const { label, value, key } = data;
                  let coordinates: any;
                  if (!!key) {
                    coordinates = key.split(',');
                  }
                  const newAddres: any = {
                    id_nft: value,
                    address: label,
                    latitude: Number(coordinates[0]),
                    longitude: Number(coordinates[1]),
                    user_owner_nft: coordinates[2],
                  };
                  form.setFieldValue('nft_id', newAddres.id_nft);
                  form.setFieldValue('lat_nft', newAddres.latitude);
                  form.setFieldValue('long_nft', newAddres.longitude);
                  form.setFieldValue(
                    'user_owner_nft',
                    newAddres.user_owner_nft,
                  );
                  form.setFieldValue('address_nft', newAddres.address);
                }
              }}
              allowClear={true}
              loadOnMount={true}
              // {...propsInput}
            />
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.nft_id !== curValues.nft_id
              }
            >
              {({ getFieldValue }) => {
                const lat_nft = getFieldValue('lat_nft');
                const long_nft = getFieldValue('long_nft');
                const user_owner_nft = getFieldValue('user_owner_nft');

                return (
                  <div className={styles.infoNFT}>
                    <p>lat: {lat_nft}</p>
                    <p>long: {long_nft}</p>
                    <p>Chủ sở hữu: {user_owner_nft}</p>
                  </div>
                );
              }}
            </Form.Item>

            <Form.Item
              name="building_template_id"
              label="Toà nhà"
              className={styles.locationNFT}
            >
              <Select
                placeholder="Search to Select"
                onChange={(value) => {
                  const image = form.getFieldValue('image');
                  const template = buildingTemplate?.find(
                    (item: any) => item.id === Number(value),
                  );
                  if (!image && !!template) {
                    form.setFieldValue('image', template.image_primary);
                    setFileList([
                      {
                        uid: '-1',
                        name: extname(template.image_primary),
                        status: 'done',
                        url: template.image_primary,
                      },
                    ]);
                  }
                }}
                options={buildingTemplate?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
              />
            </Form.Item>
            <UploadImage
              label="Ảnh tòa nhà"
              fileList={fileList}
              setFileList={setFileList}
              name="image"
            />
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_template_id !==
                curValues.building_template_id
              }
            >
              {({ getFieldValue }) => {
                const building = getFieldValue('building_template_id');
                const template = buildingTemplate?.find(
                  (item: any) => item.id === Number(building),
                );
                if (!building) return;
                return (
                  <div className={styles.preview}>
                    <p>Preview Toà nhà</p>
                    <iframe
                      src={`https://nft.locamos.com/${template?.vr_oid}`}
                      title="preview"
                      width="100%"
                      height="600"
                    />
                  </div>
                );
              }}
            </Form.Item>
            <Space size={15}>
              <p>Trạng thái</p>
              <Form.Item
                name="status"
                className={styles.status}
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Space>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_template_id !==
                curValues.building_template_id
              }
            >
              {({ getFieldValue }) => {
                const building = getFieldValue('building_template_id');
                const template = buildingTemplate?.find(
                  (item: any) => item.id === Number(building),
                );
                if (!building) return;
                return (
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Gian hàng" key="1">
                      <Stall shop={template.shopLocation} isEdit={false} />
                    </TabPane>
                    <TabPane tab="Biển quảng cáo" key="2">
                      <BillBoards
                        banner={template.bannerLocation}
                        isEdit={false}
                      />
                    </TabPane>
                  </Tabs>
                );
              }}
            </Form.Item>
            <Row justify="center">
              <Button
                className="btn-submit"
                type="primary"
                ghost
                danger
                onClick={onCancel}
              >
                Huỷ
              </Button>
              <Button
                className="btn-submit"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Tạo
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
