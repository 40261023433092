import React from 'react';
import ReactEcharts from 'echarts-for-react';
interface IPropsChart {
  data: any[];
}
const RangeAgeChart = ({ data }: IPropsChart) => {
  const options = {
    title: {
      text: 'Biểu đồ theo độ tuổi người nhặt quà',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: 'category',
      name: 'Độ tuổi',
      width: '60px',
      data: data?.map((item) => item.key),
    },
    yAxis: {
      type: 'value',
      name: 'Số người nhặt',
    },
    series: [
      {
        barWidth: '50%',
        data: data?.map((item) => item.count),
        type: 'bar',
      },
    ],
  };
  return (
    <div>
      <ReactEcharts
        option={options}
        style={{ height: '400px', maxWidth: '1600px' }}
      ></ReactEcharts>
    </div>
  );
};

export default RangeAgeChart;
