import React, { useEffect, useState } from 'react';
import styles from './index.less';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Switch,
  message,
} from 'antd';
import { history, useParams } from 'umi';
import UploadImage from '@/components/UploadImage';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { detailEventGift, updateEventGift } from '../service';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
const DetailEvent = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const params = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);
  const { id } = params;
  const detailEvent = useRequest(detailEventGift, {
    manual: true,
    onSuccess: (res) => {
      form.setFieldsValue({
        ...res.data,
        start_time: moment(res.data.start_time),
        end_time: moment(res.data.end_time),
      });
      if (res.data?.image) {
        setFileList([
          {
            uid: '-1',
            name: res.data.name,
            status: 'done',
            url: res.data?.image,
          },
        ]);
      }
    },
  });
  const updateEvent = useRequest(updateEventGift, {
    manual: true,
    onSuccess: (res) => {
      message.success('Sửa thành công');
      setIsEdit(false);
      detailEvent.run(+id);
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const onFinish = (value: any) => {
    const payload = {
      ...value,
      end_time: moment(value.end_time).format('YYYY-MM-DD HH:mm:ss'),
      start_time: moment(value.start_time).format('YYYY-MM-DD HH:mm:ss'),
      status: value.status ?? false,
      image: value.image?.[0]?.response
        ? value.image?.[0]?.response?.url + value.image?.[0]?.response?.path
        : detailEvent.data?.data?.image,
    };
    updateEvent.run(+id, payload);
  };

  useEffect(() => {
    detailEvent.run(+id);
  }, [id]);
  return (
    <div className={styles.createBuilding}>
      <Space
        onClick={() => history.push('/gift-events')}
        className={styles.titlePage}
        size={5}
        align="center"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="left"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
        </svg>
        Chi tiết sự kiện
      </Space>
      <div className={styles.titleSub}>Thông tin sự kiện</div>
      <div className={styles.backgroundColor}>
        <Row
          justify="space-between"
          align="middle"
          style={{
            marginBottom: '20px',
          }}
        >
          <div>
            ID sự kiện: <b>{detailEvent.data?.data?.ref_id}</b>
          </div>

          <Button
            type="primary"
            icon={<EditOutlined />}
            ghost
            title="Sửa"
            onClick={() => setIsEdit(true)}
          >
            Sửa thông tin
          </Button>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          style={{
            marginBottom: '20px',
          }}
        >
          <div>
            Người tạo: <b>{detailEvent.data?.data?.user?.email}</b>
          </div>
        </Row>

        <Form
          onFinish={onFinish}
          form={form}
          disabled={!isEdit}
          autoComplete="off"
          className={styles.formSearch}
          layout="vertical"
        >
          <Row justify="space-between">
            <Col span={11} lg={11} sm={11} xs={24}>
              <Form.Item
                label="Tên sự kiện"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập',
                  },
                ]}
              >
                <Input placeholder="Nhập tên sự kiện" />
              </Form.Item>
              <Form.Item label="Địa điểm tổ chức" name="organization_location">
                <Input placeholder="Nhập địa điểm tổ chức" />
              </Form.Item>

              <Form.Item label="Đơn vị tổ chức" name="organizational_units">
                <Input placeholder="Nhập đơn vị tổ chức" />
              </Form.Item>
              <UploadImage
                fileList={fileList}
                propsUpload={{
                  disabled: !isEdit,
                }}
                setFileList={setFileList}
                label="Ảnh sự kiện"
                name="image"
                required={false}
              />
            </Col>

            <Col span={11} lg={11} sm={11} xs={24}>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Thời gian bắt đầu"
                    name="start_time"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn thời gian bắt đầu',
                      },
                    ]}
                  >
                    <DatePicker
                      format="HH:mm DD/MM/YYYY"
                      showTime
                      disabledDate={(currentDate) => {
                        if (!!form.getFieldValue('end_time')) {
                          const end_time = dayjs(
                            form.getFieldValue('end_time'),
                          );
                          return (
                            currentDate < dayjs() || currentDate > end_time
                          );
                        }
                        return currentDate < dayjs();
                      }}
                      // onChange={(date, dateString) => {
                      //   setData('time_end', dayjs(dateString).format());
                      // }}
                      style={{
                        width: '100%',
                      }}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Thời gian kết thúc"
                    name="end_time"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn thời gian kết thúc',
                      },
                    ]}
                  >
                    <DatePicker
                      format="HH:mm DD/MM/YYYY"
                      showTime
                      disabledDate={(currentDate) => {
                        if (!!form.getFieldValue('start_time')) {
                          const start_time = dayjs(
                            form.getFieldValue('start_time'),
                          );
                          return (
                            currentDate < dayjs() || currentDate < start_time
                          );
                        }
                        return currentDate < dayjs();
                      }}
                      style={{
                        width: '100%',
                      }}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Ghi chú" name="note">
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
              <Form.Item
                name="status"
                valuePropName="checked"
                label="Trạng thái"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          {isEdit && (
            <Row justify="center" className={styles.submitGroup}>
              <Button
                className="btn-submit"
                danger
                onClick={() => {
                  detailEvent.run(+id);
                  setIsEdit(false);
                }}
              >
                Huỷ
              </Button>
              <Button
                className="btn-submit"
                type="primary"
                htmlType="submit"
                loading={updateEvent.loading}
              >
                Lưu
              </Button>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default DetailEvent;
