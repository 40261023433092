import React, { useState } from 'react';
import styles from '../index.less';

import PartnerVoucher from './PartnerVoucher';
import { Tabs } from 'antd';
import LocamosVoucher from './LocamosVoucher';
const EnterpriseVoucher = () => {
  const [tab, setTab] = useState<'enterprise' | 'partner'>('enterprise');

  const renderTab = () => {
    switch (tab) {
      case 'enterprise':
        return <LocamosVoucher />;
      case 'partner':
        return <PartnerVoucher />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className={styles.searchContainer}>
        <Tabs
          onChange={(key: any) => {
            setTab(key);
          }}
        >
          <Tabs.TabPane tab="Doanh nghiệp" key="enterprise"></Tabs.TabPane>
          <Tabs.TabPane tab="Đối tác" key="partner"></Tabs.TabPane>
        </Tabs>
      </div>
      {renderTab()}
    </>
  );
};

export default EnterpriseVoucher;
