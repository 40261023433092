import React from 'react';
import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  message,
  Spin,
  Breadcrumb,
  Row,
} from 'antd';
import { Link, history, useParams } from 'umi';

import styles from './index.less';
import ModalStall from '../ModalStall';
import ModalBillboards from '../ModalBillboards';
import ModalGift from '../ModalGift';
import { useDebounceFn, useRequest } from 'ahooks';
import axios from 'axios';
import {
  ICreateBuildingTemplate,
  requestUpdate,
  useGetDetailBuildingTemplate,
} from '../service';
import { LeftOutlined } from '@ant-design/icons';

export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { buildingTemplateDetail, loadDetail } = useGetDetailBuildingTemplate(
    param?.id,
  );
  const getDataVR = useRequest(
    (id: string) => {
      return axios.get(`https://lum-api.locamos.com/vr360/${id}/json-view`);
    },
    {
      manual: true,
      onSuccess: (res: any) => {
        if (res?.data.status !== 200) {
          form.setFields([
            {
              name: 'vr_oid',
              errors: ['Không thấy VR tour'],
            },
          ]);
        }
        if (res?.data?.status === 200) {
          form.setFields([
            {
              name: 'vr_oid',
              errors: [],
            },
          ]);
          const shopLocation = res?.data.data?.shopLocation?.position;
          const imagePrimary = res?.data.data?.image_primary;
          const countShop = res?.data.data?.shopLocation?.count;
          const formatShop = shopLocation?.map((item: any) => {
            return {
              ref_id: Number(item.hotspot_id),
              name: item.hotspot_name,
              scene: '1',
            };
          });
          form.setFieldValue('shopLocation', formatShop);
          form.setFieldValue('count_shop', countShop);
          form.setFieldValue('image_primary', imagePrimary);
        }
      },
    },
  );
  React.useEffect(() => {
    form.setFieldsValue(buildingTemplateDetail);
    const vr_oid = buildingTemplateDetail?.vr_oid;
    vr_oid && getDataVR.run(vr_oid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDetail]);

  const onUpdate = useRequest(
    (payload: ICreateBuildingTemplate) => {
      return requestUpdate(payload, param?.id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        history.push('/building-template');
      },
      onError: (err: any) => {
        message.error(err.data.message);
      },
    },
  );
  const onFinish = (data: ICreateBuildingTemplate) => {
    onUpdate.run(data);
  };

  if (loadDetail) {
    return (
      <div className={styles.createBuilding}>
        <Space
          onClick={() => history.push('/building-template')}
          className={styles.titlePage}
          size={5}
          align="center"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="left"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
          </svg>
          Cập nhật mẫu tòa nhà
        </Space>
        <div className={styles.loading}>
          <Spin />
        </div>
      </div>
    );
  }
  const onCancel = () => {
    history.push('/building-template');
  };
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/building-template" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Cập nhật mẫu tòa nhà
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Form
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          className={styles.formSearch}
          layout="vertical"
          initialValues={{
            shopLocation: [],
            bannerLocation: [],
            giftLocation: [],
            count_shop: 0,
            count_banner: 0,
            count_gift: 0,
          }}
        >
          <Form.Item hidden name="shopLocation"></Form.Item>
          <Form.Item hidden name="bannerLocation"></Form.Item>
          <Form.Item hidden name="giftLocation"></Form.Item>
          <Form.Item hidden name="count_shop"></Form.Item>
          <Form.Item hidden name="count_banner"></Form.Item>
          <Form.Item hidden name="count_gift"></Form.Item>
          <Form.Item hidden name="image_primary"></Form.Item>
          <Form.Item
            name="name"
            label="Tên mẫu toà nhà"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên mẫu tòa nhà',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider orientation="left">VR Tour</Divider>
          <Form.Item
            name="vr_oid"
            label="ID VR tour"
            className={styles.locationNFT}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập ID VR tour',
              },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.vr_oid !== curValues.vr_oid
            }
          >
            {({ getFieldValue }) => {
              const vr_oid = getFieldValue('vr_oid');
              if (!vr_oid || getDataVR?.data?.data.status !== 200) return;
              if (vr_oid && getDataVR?.data?.data.status === 200)
                return (
                  <>
                    <div className={styles.preview}>
                      <p>Preview Toà nhà</p>
                      <iframe
                        src={`https://nft.locamos.com/${vr_oid}`}
                        title="preview"
                        width="100%"
                        height="600"
                      />
                    </div>
                    <Form.Item label="Số lượng gian hàng">
                      <div className={styles.itemDisabled}>
                        <Input
                          disabled
                          value="Số lượng gian hàng trên VR Tour"
                        />
                        <ModalStall
                          position={
                            getDataVR?.data?.data?.data?.shopLocation?.position
                          }
                        >
                          Chi tiết
                        </ModalStall>
                      </div>
                    </Form.Item>
                    <Form.Item label="Số lượng biển quảng cáo">
                      <div className={styles.itemDisabled}>
                        <Input
                          disabled
                          value="Số lượng biển quảng cáo trên VR Tour"
                        />
                        <ModalBillboards
                          position={
                            getDataVR?.data?.data?.data?.bannerLocation
                              ?.position
                          }
                        >
                          Chi tiết
                        </ModalBillboards>
                      </div>
                    </Form.Item>
                    <Form.Item label="Số lượng vị trí thả quà">
                      <div className={styles.itemDisabled}>
                        <Input disabled value="Số lượng quà thả trên VR Tour" />
                        <ModalGift>Chi tiết</ModalGift>
                      </div>
                    </Form.Item>
                  </>
                );
            }}
          </Form.Item>

          <Divider orientation="left">Thông tin mẫu toà nhà</Divider>
          <Form.Item
            name="rating"
            label="Số sao"
            className={styles.locationNFT}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (Number(value) > 5) {
                    return Promise.reject(
                      new Error('Số sao không được lớn hơn 5'),
                    );
                  }
                  if (Number(value) === 0) {
                    return Promise.reject(
                      new Error('Số sao không được bằng 0'),
                    );
                  }
                  if (value == undefined) {
                    return Promise.reject(new Error('Vui lòng nhập số sao'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            normalize={(value) => {
              const isNumber = /^\d+$/.test(value);
              if (isNumber) {
                return value;
              } else {
                return null;
              }
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Chi phí xây dựng"
            className={styles.locationNFT}
            normalize={(value) => {
              const isNumber = /^\d+$/.test(value);
              if (isNumber) {
                return value;
              } else {
                return null;
              }
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const isNumber = /^\d+$/.test(value);
                  if (value == undefined || value == '') {
                    return Promise.reject(
                      new Error('Vui lòng nhập chi phí xây dựng'),
                    );
                  } else if (Number(value) === 0) {
                    return Promise.reject(
                      new Error('Chi phí xây dựng không được bằng 0'),
                    );
                  } else if (!isNumber) {
                    return Promise.reject(
                      new Error('Chi phí xây dựng chỉ nhập số'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Space align="center" size={15} className={styles.status}>
            Trạng thái
            <Form.Item name="status" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Space>
          <Row justify="center">
            <Button
              className="btn-submit"
              type="primary"
              ghost
              danger
              onClick={onCancel}
            >
              Huỷ
            </Button>
            <Button type="primary" className="btn-submit" htmlType="submit">
              Lưu
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};
