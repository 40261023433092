import { useAntdTable } from 'ahooks';
import { Button, Row, message, Form, DatePicker } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { getPointLocaBonus } from '../service';
import { WithdrawDialog } from './Dialog/WithdrawDialog';
import { formatNumber } from '@/utils/apis/common';
import moment from 'moment';
import { ENVIRONMENTS } from '@/utils/constant';
import { DownloadOutlined } from '@ant-design/icons';
import LimitTransferDialog from './Dialog/LimitTransferDialog';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
  type: string;
  totalLB: number;
  status: string;
  amount: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'STT',
    width: 100,
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
  },
  {
    title: 'Thời gian',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Nội dung',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Số locabonus',
    dataIndex: 'amount',
    key: 'amount',
    render(value, record) {
      if (
        record?.type === 'TRANSFER' ||
        record?.type === 'TRANSFER_TO_WEB' ||
        record.type === 'USER_FEE' ||
        record.type === 'WITHDRAW' ||
        record?.type === 'BUY_EVOUCHER_URBOX' ||
        record?.type === 'BUY_VOUCHER'
      )
        return `- ${formatNumber(Number(record.amount))}`;
      else return `+ ${formatNumber(Number(record.amount))}`;
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render(value, record) {
      switch (record?.status) {
        case 'WAITING_FOR_RECEIVER':
          return 'Chờ người nhận xác nhận';
        case 'WAITING_FOR_REQUEST_USER':
          return 'Chờ xác nhận';
        case 'DISPUTE':
          return 'Tranh chấp';
        case 'REFUND':
          return 'Hoàn điểm';
        case 'SUCCESS':
          return 'Thành công';
        default:
          return 'Thành công';
      }
    },
  },
];

const { RangePicker } = DatePicker;
const PointLocaBonus = ({
  transferLimit,
  refreshInfo,
}: {
  transferLimit: any;
  refreshInfo: () => void;
}) => {
  const inititalTransferLimit = useMemo(() => {
    const key = Object.keys(transferLimit).reverse()[0];
    if (key) {
      const value = key ? transferLimit[key] : '';
      return {
        receiver_id: key,
        limit: value,
      };
    }
    return null;
  }, [transferLimit]);
  const { formatMessage } = useIntl();
  const params = useParams<{ id: string }>();
  const { id } = params;
  const [isOpenModalWithdraw, setIsOpenModalWithDraw] = useState(false);
  const [isOpenModalTL, setIsOpenModalTL] = useState(false);
  const [form] = Form.useForm();
  const { tableProps, data, refresh, search } = useAntdTable(
    getPointLocaBonus,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10, id: Number(id) }],
      // defaultParams: [{ current: 1, pageSize: 10 }, +id],
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );
  const onClose = () => {
    setIsOpenModalWithDraw(false);
  };
  const onCloseTLDialog = () => {
    setIsOpenModalTL(false);
  };

  const { submit } = search;
  const infoUser = JSON.parse(
    window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    ) as string,
  );

  const adminId = infoUser?.admin_profile?.admin_id;
  const queryExport = () => {
    const date = form.getFieldValue('toDate');
    let query = `/user/export-user-transaction-data?userId=${id}&adminId=${adminId}`;
    if (date) {
      const fromDate = Array.isArray(date)
        ? moment(date[0]).format('YYYY-MM-DD')
        : '';
      const toDate = Array.isArray(date)
        ? moment(date[1]).format('YYYY-MM-DD')
        : '';
      query += `?start_time=${fromDate}&end_time=${toDate}`;
    }
    return query;
  };
  const searchForm = (
    <div
      className={styles.searchContainer}
      style={{
        marginLeft: '15px',
      }}
    >
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Button
        icon={<DownloadOutlined />}
        className={styles.btnOption}
        type="primary"
        ghost
        href={ENVIRONMENTS.API_URL + queryExport()}
        onClick={() => {
          console.log(ENVIRONMENTS.API_URL + queryExport());
        }}
      >
        {formatMessage({ id: 'general_export_excel' })}
      </Button>
      <Button
        type="primary"
        ghost
        onClick={() => setIsOpenModalWithDraw(true)}
        className={styles.btnOption}
      >
        {formatMessage({ id: 'withdraw' })}
      </Button>
      <Button
        type="primary"
        ghost
        onClick={() => setIsOpenModalTL(true)}
        className={styles.btnOption}
      >
        Set hạn mức chuyển LB
      </Button>
    </div>
  );

  return (
    <div className={styles.mainInfoUser}>
      <Row
        justify="space-between"
        align="top"
        style={{
          marginBottom: '20px',
        }}
      >
        <Row>{searchForm}</Row>
        <div>
          Tổng LocaBonus: <b>{formatNumber(Number(data?.totalLB))}</b>
        </div>
      </Row>
      <Table
        //@ts-ignore
        {...tableProps}
        columns={columns}
        rowKey={(record) => record.key}
        scroll={{ x: 1000 }}
        // {...tableProps}
      />
      {isOpenModalWithdraw && (
        <WithdrawDialog
          open={isOpenModalWithdraw}
          onClose={onClose}
          refresh={refresh}
          totalLB={data?.totalLB}
        />
      )}
      {isOpenModalTL && (
        <LimitTransferDialog
          refresh={refreshInfo}
          inititalTransferLimit={inititalTransferLimit}
          userId={+id}
          isOpen={isOpenModalTL}
          onCancel={onCloseTLDialog}
        />
      )}
    </div>
  );
};

export default PointLocaBonus;
