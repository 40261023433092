import { API_PATH, privateRequest, request } from '@/utils/apis';
import { KycApprover, StatusKyc } from '@/utils/enum';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
}

export interface EditKYC {
  full_name: string;
  address: string;
  identity_id: string;
  date_of_issue: string;
  place_of_issue: string;
  date_of_birth: string;
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName?: string;
    toDate: string[];
    status?: string;
    approvedBy?: KycApprover;
    is_liveness: boolean;
  },
): Promise<Result> => {
  if (!formData.fullName) {
    formData.fullName = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `
      mutation {
        get_list_request_kyc(input: {
          date_created_from:"${fromDate}",
          date_created_to:"${toDate}",
          page_index:${current ? current - 1 : 0},
          page_size:${pageSize || 10},
          search_text:"${formData.fullName}",
          ${formData.status ? `status: ${formData.status},` : ''}
          ${formData.approvedBy ? `approvedBy: ${formData.approvedBy},` : ''}
					${
            formData.is_liveness !== undefined
              ? `is_liveness: ${formData.is_liveness}`
              : ''
          }
        }) {
          total
          data {
            id
            userId
            request_at
            full_name
            phone
            email
            type_identity
            verification_status
            identity_backside
            identity_front
            verify_image
            date_of_birth
            date_of_issue
            place_of_issue
            identity_id
            address
            matchRate
						liveness_url
          }
        }
      }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.get_list_request_kyc?.data?.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.get_list_request_kyc?.total,
      list: data,
    };
  });
};

export const handleKycService = (
  userId: number | string,
  isAccept: boolean,
  reason?: string,
) => {
  const data = {
    query: `
      mutation {
        admin_handle_kyc_request(handle: { userId: ${userId}, status: ${isAccept}, ${
      reason ? `reason: "${reason}"` : ''
    } }) {
          message
        }
      }    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const editKycUser = (payload: EditKYC, userId: number) => {
  const data = {
    query: `mutation {
      admin_edit_user_kyc(input: {full_name: "${payload?.full_name}", address: "${payload?.address}", identity_id: "${payload?.identity_id}", date_of_issue: "${payload?.date_of_issue}", place_of_issue: "${payload?.place_of_issue}", date_of_birth:"${payload?.date_of_birth}", user_id: ${userId}}) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const handleRefuseKyc = (input: { userId: number; reason: string }) => {
  const { userId, reason } = input;
  const data = {
    query: `mutation {
      admin_refuse_kyc_request(handle: { userId: ${userId}, reason: "${reason}" }) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getDataBlacklist = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    search: string;
    toDate: string[];
  },
) => {
  if (!formData.search) {
    formData.search = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).toISOString()
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).toISOString()
    : '';
  let q = `page=${current}&limit=${pageSize}`;
  if (formData.search) {
    q += `&textSearch=${formData.search}`;
  }
  if (fromDate && toDate) {
    q += `&fromDate=${fromDate}&toDate=${toDate}`;
  }
  return privateRequest(
    request.get,
    API_PATH.LIST_BLACKLIST_FACE + '?' + q,
  ).then((res) => {
    const data = res?.data?.map((e: any, index: any) => ({
      ...e,
      stt: (current ? current - 1 : 0) * pageSize + (index + 1),
    }));
    return {
      total: res?.meta?.total_records,
      list: data,
    };
  });
};

export const deleteBlacklistFace = (id: number) => {
  return privateRequest(request.delete, API_PATH.EDIT_BLACKLIST_FACE(id));
};
