import { useAntdTable } from 'ahooks';
import React from 'react';
import { getDataCallHistory } from '../service';
import { useParams } from 'umi';
import { Form, Input, Row, Table, DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import styles from '../index.less';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
const CallHistory = () => {
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { tableProps, search, data } = useAntdTable(getDataCallHistory, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        user_id: +params.id,
      },
      {},
    ],
    form,
  });
  const { submit } = search;
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'time',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'ID địa điểm',
      dataIndex: 'location_id',
    },
    {
      title: 'NFT địa điểm',
      dataIndex: 'location_name',
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
      render: (value) => (
        <>
          {value === 'caller'
            ? 'Người gọi'
            : value === 'receiver'
            ? 'Người nhận'
            : null}
        </>
      ),
    },
    {
      title: 'ID user gọi',
      dataIndex: 'partner_id',
    },
    {
      title: 'Thời gian cuộc gọi',
      dataIndex: 'call_time',
    },
    {
      title: 'Số Locabonus',
      dataIndex: 'locabonus',
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Row>
        <Form form={form} className={styles.searchForm}>
          <Form.Item name="searchValue" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm ID user"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="toDate" className={styles.searchItem}>
            {/* @ts-ignore */}
            <RangePicker
              format={'DD/MM/YYYY'}
              onChange={submit}
              picker="date"
              className="w-100"
              placeholder={['Từ ngày', 'Đến ngày']}
            />
          </Form.Item>
        </Form>
      </Row>
      <div>
        <p>
          Lượt gọi còn lại
          <b>{` ${data?.total_call_count} `}</b>
          lượt
        </p>
        <p>
          Lượt nhận còn lại
          <b>{` ${data?.total_receive_count} `}</b>
          lượt
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <div className={styles.mainInfoUser}>
        {searchForm}
        <Table columns={columns} {...tableProps} scroll={{ x: 1000 }} />
      </div>
    </div>
  );
};

export default CallHistory;
