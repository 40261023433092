import { useAntdTable } from 'ahooks';
import React from 'react';
import { getListBrand } from '../service';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Tag,
  Row,
  Tooltip,
  Switch,
  Skeleton,
  Table,
  message,
} from 'antd';
import styles from '../index.less';
import { STATUS_KYC } from '@/utils/constant';
import { useIntl, useRequest } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { updateStatusStore } from '@/pages/BusinessInfo/service';
const { RangePicker } = DatePicker;
const ListBrand = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search, loading, error, refresh } = useAntdTable(
    getListBrand,
    {
      form,
    },
  );
  const { submit } = search;
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ACCEPT':
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case 'PENDING':
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case 'REFUSE':
        return (
          <>
            <Tag color="red" icon={<CloseOutlined />}>
              Từ chối xác thực
            </Tag>{' '}
          </>
        );
      case 'NOT_REQUEST':
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
      default:
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
    }
  };
  const onSwitchStatus = (id: any, status: any) => {
    switchRequest.run(id);
  };
  const switchRequest = useRequest(updateStatusStore, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
        refresh();
      } else {
        message.success('Đã cập nhật trạng thái Doanh nghiệp');
        refresh();
      }
    },
    formatResult: (res) => res,
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên, mail, SĐT"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái xác thực"
          >
            {STATUS_KYC?.map((item, i) => (
              <Select.Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Pháp nhân kinh doanh',
      dataIndex: 'type_of_enterprise',
      render: (value) => {
        switch (value) {
          case 'INDIVIDUAL':
            return <>Cá nhân</>;
          case 'BUSINESS_HOUSEHOLD':
            return <>Hộ kinh doanh</>;
          case 'COMPANY':
            return <>Công ty</>;
        }
      },
    },
    {
      title: 'Địa chỉ kinh doanh',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Mã số thuế',
      dataIndex: 'tax_code',
      key: 'tax_code',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'representative_name',
      key: 'representative_name',
    },
    {
      title: 'Số CCCD/CMND',
      dataIndex: 'identity_number',
      key: 'identity_number',
    },
    {
      title: 'Số lần yêu cầu',
      dataIndex: 'scan_count',
      key: 'scan_count',
    },
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'requested_at',
      render: (value, record) => (
        <>{value ? dayjs(value).format('HH:mm DD/MM/YYYY') : ''}</>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'verify_status',
      key: 'verify_status',
      render: (_: any, record: any) => (
        <>{renderStatus(record?.verify_status)}</>
      ),
    },
    {
      title: 'Người duyệt',
      dataIndex: ['verified_by', 'full_name'],
      key: 'verified_by',
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Xem">
              <a href={`/business/brand/${record?.id}`}>
                <EyeOutlined className="color-purple" />
              </a>
            </Tooltip>
            <Tooltip title="Trạng thái hoạt động">
              <Switch
                loading={switchRequest.loading}
                defaultChecked={record.status}
                onClick={(e: any) => {
                  onSwitchStatus(record.enterprise_id, e);
                }}
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            /* @ts-ignore */
            {...tableProps}
            columns={columns}
            locale={{
              emptyText: formatMessage({ id: 'const_column_empty' }),
            }}
            rowKey={'enterprise_id'}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
    </div>
  );
};

export default ListBrand;
