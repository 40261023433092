import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export const getUserTransactionHistory = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchValue?: string;
    toDate: string[];
    type?: string;
    amount: string;
  },
) => {
  if (!formData.searchValue) {
    formData.searchValue = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `mutation {
        get_user_transaction_history(input: {search_value: "${
          formData.searchValue
        }", start_time: "${fromDate}", end_time: "${toDate}", page_index: ${
      current ? current - 1 : 0
    }, page_size: ${pageSize}, ${
      formData.type ? `transaction_type: "${formData.type}",` : ''
    } ${
      formData.amount === 'less'
        ? `end_amount: 1000`
        : formData.amount === 'more'
        ? `start_amount: 1000`
        : ''
    }  }) {
          total
          data {
            created_at
            transaction_id
            user_id
            type
            amount
            description
          }
        }
      }`,
  };

  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.get_user_transaction_history?.data?.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.get_user_transaction_history?.total,
      list: data,
    };
  });
};
