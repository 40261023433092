import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Typography,
  UploadFile,
  message,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { BUSINESS_TYPE } from '@/utils/constant';
import { Link, useIntl, useParams, useRequest } from 'umi';
import { useTranslate } from '@/utils/hooks/useTranslate';
import MapReaflat from '@/components/MapReaflat';
import { paternPhone } from '@/utils/patern';

import {
  IUpdateStore,
  approveStoreService,
  updateStoreService,
} from '../service';
import UploadImage from '@/components/UploadImage';
import { getListOrgs } from '@/pages/UsersInfo/service';
import QRCode from 'react-qr-code';
const StoreInfo = ({
  detailStore,
  infoAccount,
}: {
  detailStore: any;
  infoAccount: any;
}) => {
  const { formatMessage } = useIntl();
  const { t } = useTranslate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const params = useParams<{ id: string }>();
  const requestOrgs = useRequest(getListOrgs);
  const updateStore = useRequest(updateStoreService, {
    manual: true,
    onSuccess: (res) => {
      setIsEdit(false);
      detailStore.run(+params?.id);
      message.success('Cập nhật thông tin thành công');
    },
    onError: (err: any) => {
      console.log(err.data);
      message.error(err.data?.messsage || 'Có lỗi xảy ra');
    },
  });

  const approveStore = useRequest(approveStoreService, {
    manual: true,
    onSuccess: (res) => {
      setIsEdit(false);
      detailStore.run(+params?.id);
      message.success('Thành công');
    },
    onError: (err: any) => {
      console.log(err.data);
      message.error('Có lỗi xảy ra');
    },
  });

  const handleApproveStore = () => {
    return Modal.confirm({
      icon: null,
      title: 'Phê duyệt cửa hàng',
      content: 'Bạn có chắc chắn muốn duyệt cửa hàng này?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk: () => {
        approveStore.run(+params?.id, {
          confirm: true,
        });
      },
      okButtonProps: {
        loading: approveStore.loading,
      },
    });
  };
  const [formRefuse] = Form.useForm();
  const handleRefuseStore = () => {
    const onSubmitRefuse = (val: any) => {
      approveStore.run(+params?.id, {
        confirm: false,
        reason: val.reason,
      });
    };
    return Modal.confirm({
      icon: null,
      title: 'Từ chối duyệt cửa hàng',
      content: (
        <>
          <Form
            form={formRefuse}
            onFinish={onSubmitRefuse}
            id="formRefuse"
            layout="vertical"
          >
            <Form.Item
              label="Lý do từ chối"
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Nhập lý do',
                },
              ]}
            >
              <Input.TextArea placeholder="Nhập lý do từ chối" />
            </Form.Item>
          </Form>
        </>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: approveStore.loading,
      },
      onCancel() {
        formRefuse.resetFields();
      },
      onOk: () => {
        if (!formRefuse.getFieldValue('reason')) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    });
  };

  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );
    form.setFieldValue('province_id', province?.id);

    // setPickerPosition({
    //   lat: pos?.lat,
    //   lng: pos?.long,
    //   customName: pos?.name,
    // });

    form.setFieldsValue({
      address: pos?.name,
      lat: pos?.lat,
      long: pos?.long,
    });
  };
  const onSubmit = (val: any) => {
    // let username = detailStore.data?.phone ?? detailStore.data?.email;
    const payload: IUpdateStore = {
      ...val,
      logo: Array.isArray(val.logo) ? val.logo?.[0]?.response.path : val.logo,
    };
    if (!!val?.confirm_password) {
      delete val.enter_password;
      delete payload?.enter_password;
      payload.confirm_password = val.confirm_password;
    }
    // if (!!username && val.username !== username) {
    //   if (username.includes('@')) {
    //     payload.email = val.username;
    //   } else {
    //     payload.phone = val.username;
    //   }
    // }
    delete payload['username'];

    updateStore.run(+params?.id, payload);
  };
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ACCEPT':
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case 'PENDING':
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case 'REFUSE':
        return (
          <>
            <Tag color="red" icon={<CloseOutlined />}>
              Từ chối xác thực
            </Tag>{' '}
            Lý do: {detailStore.data?.refuse_reson}
          </>
        );
      case 'NOT_REQUEST':
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
      default:
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
    }
  };

  useEffect(() => {
    if (!!infoAccount?.logo) {
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: infoAccount?.logo,
        },
      ]);
    }
    form.setFieldsValue({
      ...infoAccount,
      username: infoAccount?.phone ?? infoAccount?.email,
    });
  }, [infoAccount]);
  return (
    <div className={styles.wrapper}>
      {detailStore.loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          disabled={!isEdit}
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          labelWrap
          onFinish={onSubmit}
          scrollToFirstError={true}
        >
          <Row
            gutter={20}
            justify="space-between"
            className={styles.wrapperFlex}
          >
            <Col span={18} xxl={18} xl={18} lg={24} sm={24} xs={24}>
              <Row justify="space-between">
                <Space align="start">
                  <Typography.Title className={styles.titleTab} level={4}>
                    Thông tin cửa hàng
                  </Typography.Title>
                  <Button
                    disabled={false}
                    icon={<EditOutlined />}
                    ghost
                    type="primary"
                    onClick={() => setIsEdit(true)}
                  ></Button>
                </Space>
                {detailStore.data?.verify_status === 'PENDING' && (
                  <Space align="start">
                    <Button
                      type="primary"
                      disabled={false}
                      onClick={handleApproveStore}
                    >
                      Phê duyệt
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      danger
                      disabled={false}
                      onClick={handleRefuseStore}
                    >
                      Từ chối
                    </Button>
                  </Space>
                )}
              </Row>
              <Form.Item label="Trạng thái">
                {renderStatus(detailStore.data?.verify_status)}
              </Form.Item>
              <Form.Item label="Tên thương hiệu/cửa hàng" name="name">
                <Input
                  placeholder={formatMessage({ id: 'brand_name' })}
                ></Input>
              </Form.Item>
              <Form.Item label="Tên đăng nhập" name="username">
                <Input
                  placeholder="Nhập email hoặc SĐT đăng nhập"
                  disabled
                ></Input>
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'email' })}
                name="display_email"
                rules={[
                  {
                    type: 'email',
                    message: t('error.email', {
                      field: t('email'),
                    }),
                  },
                ]}
              >
                <Input placeholder={formatMessage({ id: 'email' })} />
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'phone_number' })}
                name="display_phone"
                rules={[
                  {
                    pattern: paternPhone,
                    message: t('error.patern', {
                      field: t('phone_number'),
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'phone_number' })}
                ></Input>
              </Form.Item>
              <Form.Item
                name="confirm_password"
                label={t('password')}
                rules={[
                  {
                    min: 8,
                    max: 99,
                    message: t('error.password', {
                      field: t('password'),
                    }),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('password')}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="enter_password"
                label={t('enter_password')}
                dependencies={['confirm_password']}
                hasFeedback
                rules={[
                  // {
                  //   required: form.getFieldValue('confirm_password') ? true : false,
                  //   message: t('error.require', {
                  //     field: t('confirm_password'),
                  //   }),
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !!getFieldValue('confirm_password')) {
                        return Promise.reject(
                          new Error(
                            t('error.require', {
                              field: t('enter_password'),
                            }),
                          ),
                        );
                      }
                      if (getFieldValue('confirm_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('password_no_match')));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('enter_password')} />
              </Form.Item>
              <Form.Item
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn địa điểm trên bản đồ',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Khu vực" name="province_id">
                <Select
                  placeholder="Chọn khu vực"
                  options={requestOrgs.data?.get_all_orgs?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                style={{
                  textAlign: 'center',
                }}
              >
                <MapReaflat
                  setPos={onSelectPos}
                  listPosition={[]}
                  loading={false}
                  disable={false}
                  mapItemDetail={detailStore.data}
                />

                <Row
                  gutter={16}
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Col span={12} className={styles.dialogFormItem}>
                    <Form.Item
                      label="Lat"
                      name="lat"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn',
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.dialogFormItem}>
                    <Form.Item
                      label="Lng"
                      name="long"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn',
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'business_type' })}
                name="business_type"
              >
                <Select
                  placeholder={t('business_type_input')}
                  options={BUSINESS_TYPE.map((item) => ({
                    label: t(item.name),
                    value: item.value,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Giới thiệu cửa hàng/thương hiệu"
              >
                <Input.TextArea rows={2} placeholder="Nhập giới thiệu" />
              </Form.Item>
            </Col>
            <Col span={4} xxl={4} xl={4} lg={24} sm={24} xs={24}>
              <div className={styles.wrapperCol2}>
                <div className={styles.avatar}>
                  {!!detailStore.data?.logo && !isEdit ? (
                    <Avatar src={detailStore.data?.logo} size={200} />
                  ) : (
                    <UploadImage
                      name="logo"
                      propsUpload={{
                        disabled: !isEdit,
                      }}
                      isAvatar={true}
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  )}
                </div>
                <Card className={styles.qrCode}>
                  <QRCode value={detailStore.data?.qr_data} size={150} />
                  <p
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    QR Doanh nghiệp
                  </p>
                </Card>
              </div>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              margin: '20px 0',
            }}
          >
            <Button
              type="primary"
              ghost
              danger
              className="btn-submit"
              onClick={() => {
                detailStore.run(+params?.id);
                setIsEdit(false);
              }}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              loading={updateStore.loading}
            >
              Lưu
            </Button>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default StoreInfo;
