import { DatePicker, Form, Input } from 'antd';
import React from 'react';
import { useIntl } from 'umi';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import { getTableDataHistory } from '../service';
import Table, { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
const ActionHistory = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { tableProps, search, loading } = useAntdTable(getTableDataHistory, {
    form,
  });

  const columns: ColumnsType<any> = [
    {
      title: 'stt',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'updated_at',
      align: 'right',
      render: (value, record, index) => (
        <>{dayjs(record?.updated_at).format('HH:mm DD/MM/YYYY')}</>
      ),
    },
    {
      title: 'Người tác động',
      dataIndex: ['user', 'admin_profile', 'full_name'],
    },
    {
      title: 'Nội dung',
      dataIndex: 'change_log',
    },
  ];

  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_action' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="date">
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div className={styles.tableComponent}>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default ActionHistory;
