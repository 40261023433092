import { useRequest } from 'ahooks';
import { Form, Modal, Select, message } from 'antd';
import React from 'react';
import { requestUpdateShop, useGetShopNoSelected } from '../../service';
interface IProps {
  children: any;
  building_id?: number;
  id: number;
  refresh?: () => void;
  name?: string;
}
const ModalEdit = (props: IProps) => {
  const [form] = Form.useForm();
  const { children, building_id = 1, id, refresh, name } = props;
  const { shop } = useGetShopNoSelected();
  const [visible, setVisible] = React.useState(false);
  const onVisible = () => {
    form.setFieldValue('building_entities_id', null);
    setVisible(!visible);
  };
  const onUpdateShop = useRequest(
    (data: any) => requestUpdateShop(id, data, building_id),
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        onVisible();
        refresh && refresh();
        // console.log('thanh cong');
      },
      onError: () => {
        // console.log('that bai');
        message.error('Thất bại');
      },
    },
  );

  const onOk = () => {
    const value = form.getFieldValue('building_entities_id');
    onUpdateShop.run(value);
  };
  return (
    <>
      <span onClick={onVisible}>{children}</span>
      <Modal
        title={name}
        open={visible}
        onOk={onOk}
        onCancel={onVisible}
        centered
      >
        <Form form={form}>
          <Form.Item name="building_entities_id">
            <Select
              options={shop?.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ModalEdit;
