import React, { useState } from 'react';
import { Actions } from 'ahooks/lib/useToggle';
import {
  AutoComplete,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from 'antd';
import { useRequest } from 'ahooks';
import {
  getAllTypePartnerVoucher,
  releasePartnerVoucherType,
} from '../service';
interface ReleaseVoucherModalProps {
  isOpenModal: boolean;
  setIsOpenModal: Actions<boolean>;
  refresh: () => void;
}
const ReleasePartnerVoucherModal = ({
  isOpenModal,
  setIsOpenModal,
  refresh,
}: ReleaseVoucherModalProps) => {
  const requestSearchVoucher = useRequest(getAllTypePartnerVoucher, {
    debounceWait: 1000,
    onSuccess: (res) => {
      setAtOptions(() =>
        res.data?.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        })),
      );
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const releaseVoucher = useRequest(releasePartnerVoucherType, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      if (res.status === 400) {
        message.error('Có lỗi xảy ra');
      } else {
        message.success('Phát hành voucher thành công');
        handleClose();
        refresh();
      }
    },
    onError(err: any) {
      message.error('Có lỗi xảy ra');
    },
  });
  const [form] = Form.useForm();
  const handleClose = () => {
    setIsOpenModal.toggle();
    form.resetFields();
  };
  const [atOptions, setAtOptions] = useState<any[]>([]);
  const [selectedVoucher, setSelectedVoucher] = useState<any>();
  const handleSelectVoucher = (value: string) => {
    const option = atOptions.find((item) => item.value === value);
    if (!!option) {
      setSelectedVoucher(option?.id);
    }
  };
  const onSubmit = (value: any) => {
    releaseVoucher.run({
      ...value,
    });
  };
  return (
    <Modal
      open={isOpenModal}
      footer={null}
      onCancel={handleClose}
      title="Phát hành voucher"
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Loại voucher"
          name="type_id"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại voucher',
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            onClear={() => setSelectedVoucher(null)}
            onSearch={(val) => requestSearchVoucher.run(val)}
            placeholder="Chọn voucher"
            filterOption={false}
            options={atOptions.map((item: any, i: number) => ({
              ...item,
              key: `${item?.value}_${i}`,
            }))}
            notFoundContent={
              requestSearchVoucher.loading ? <Spin /> : <>Trống</>
            }
            onSelect={(data) => handleSelectVoucher(data)}
          />
        </Form.Item>

        <Form.Item
          initialValue={100}
          label="Số lượng voucher phát hành"
          name="amount"
          normalize={(value) => {
            const isNumber = /^\d+$/.test(value);
            if (isNumber) {
              return value;
            } else {
              return null;
            }
          }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (Number(value) === 0) {
                  return Promise.reject(
                    new Error('Số lượng phát hành không được bằng 0'),
                  );
                }
                if (!value) {
                  return Promise.reject(new Error('Vui lòng nhập'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            style={{
              width: '50%',
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            placeholder="Nhập số lượng"
          />
        </Form.Item>

        <Row justify="center">
          <Button
            style={{
              margin: '10px',
            }}
            onClick={handleClose}
          >
            Hủy
          </Button>
          <Button
            style={{
              margin: '10px',
            }}
            type="primary"
            htmlType="submit"
            loading={releaseVoucher.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReleasePartnerVoucherModal;
