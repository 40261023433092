import React from 'react';
import { Form, Input, Row, Table, Tooltip } from 'antd';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import { statisticUseVoucher } from '../service';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'umi';

const StatisticVoucherEvent = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { tableProps, search } = useAntdTable(statisticUseVoucher, {
    form,
  });
  const { submit } = search;
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
    },
    {
      title: 'ID',
      dataIndex: 'user_id',
      width: '150px',
    },
    {
      title: 'Họ Và Tên',
      dataIndex: 'full_name',
    },
    {
      title: 'Số điên thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Tổng số lần đã áp dụng',
      dataIndex: 'apply_count',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Hành động',
      width: '15%',
      align: 'center',
      render: (value, record) => (
        <>
          <Row justify="space-evenly">
            <Tooltip title="Xem">
              <EyeOutlined
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(`/discount_event/report/${record?.user_id}`)
                }
                className="purple-color"
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên, ID, SĐT"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(item) => Math.random() * item.created_at}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default StatisticVoucherEvent;
