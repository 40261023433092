import AliasStatistic from '@/pages/BusinessInfo/Components/AliasStatistic';
import BillConfig from '@/pages/BusinessInfo/Components/BillConfig';
import EnterpriseCustomer from '@/pages/BusinessInfo/Components/EnterpriseCustomer';
import LocaBonus from '@/pages/BusinessInfo/Components/LocaBonus';
import { LocabonusHistory } from '@/pages/BusinessInfo/Components/LocabonusHistory';
import VoucherConfig from '@/pages/BusinessInfo/Components/VoucherConfig';
import VoucherImport from '@/pages/BusinessInfo/Components/VoucherImport';
import React from 'react';
import { Link, useParams, useRequest } from 'umi';
import { getDetailStore } from '../service';
import { Card, Tabs } from 'antd';
import styles from '../index.less';
import { LeftOutlined } from '@ant-design/icons';
import StoreInfo from './StoreInfo';
const DetailStore = () => {
  const [activeTabs, setActiveTabs] = React.useState(1);
  const params = useParams<{ id: string }>();
  const [infoAccount, setInfoAccount] = React.useState<any>(null);
  const detailStore = useRequest(getDetailStore, {
    defaultParams: [Number(params.id)],
    onSuccess: (res) => {
      setInfoAccount({
        ...res,
        username: res?.phone ?? res?.email,
      });
    },
  });
  const renderTab = () => {
    switch (activeTabs) {
      case 1:
        return (
          <>
            <StoreInfo detailStore={detailStore} infoAccount={infoAccount} />
          </>
        );

      case 2:
        return (
          <>
            <LocaBonus />
          </>
        );
      case 3:
        return (
          <>
            <LocabonusHistory />
          </>
        );
      case 4:
        return (
          <>
            <EnterpriseCustomer />
          </>
        );
      case 5:
        return (
          <>
            <BillConfig
              enterpriseId={+params.id}
              bill_template={infoAccount?.bill_template || ''}
              qr_transfer={infoAccount?.qr_transfer || ''}
            />
          </>
        );
      case 6:
        return (
          <>
            <VoucherConfig enterpriseId={+params.id} />
          </>
        );
      case 7:
        return (
          <>
            <AliasStatistic enterprise_id={+params.id} />
          </>
        );
      case 8:
        return (
          <>
            <VoucherImport partner_id={infoAccount?.partner_id} />
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      <div>
        <Link to="/business" className={styles.previousLink}>
          <LeftOutlined />
          Danh sách cửa hàng
        </Link>
      </div>
      <div className="primary-container">
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveTabs(parseInt(key));
          }}
          className="tab-on-card"
        >
          <Tabs.TabPane tab="Thông tin cửa hàng" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử lượt tặng" key="2"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử tặng Locabonus" key="3"></Tabs.TabPane>
          <Tabs.TabPane tab="Danh sách khách hàng" key="4"></Tabs.TabPane>
          <Tabs.TabPane tab="Cài đặt hóa đơn" key="5"></Tabs.TabPane>
          {infoAccount?.register_release_voucher && (
            <Tabs.TabPane tab="Cài đặt voucher" key="6"></Tabs.TabPane>
          )}
          {infoAccount?.register_release_voucher && (
            <Tabs.TabPane
              tab="Thống kê sử dụng mã Alias"
              key="7"
            ></Tabs.TabPane>
          )}
          {!!infoAccount?.partner_id && (
            <Tabs.TabPane tab="Voucher import" key="8"></Tabs.TabPane>
          )}
        </Tabs>
        <Card className="card-after-tab" size="small">
          {renderTab()}
        </Card>
      </div>
    </>
  );
};

export default DetailStore;
