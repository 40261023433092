import { useGetAccessBanner } from '@/pages/BillBoards/service';
import { Form, DatePicker, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { useParams } from 'umi';

interface DataType {
  key: string;
  id: string;
  day: string;
  count: string;
}
const { RangePicker } = DatePicker;
export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { access, run } = useGetAccessBanner(param?.id);
  React.useEffect(() => {
    run();
  }, []);
  const total = access?.data?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.count,
    0,
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Thời gian',
      dataIndex: 'day',
      key: 'day',
      render(value, record, index) {
        return <>{moment(record?.day).format('DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Số lượt truy cập',
      dataIndex: 'count',
      key: 'count',
    },
  ];
  const onChangeForm = () => {
    const date = form.getFieldValue('date');
    const newData = {
      from_date: moment(date?.[0]).format('YYYY-MM-DD'),
      to_date: moment(date?.[1]).format('YYYY-MM-DD'),
    };
    run(newData);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Form onValuesChange={onChangeForm} form={form}>
          <Form.Item name="date">
            <RangePicker
              disabledDate={(current: any) => {
                return current && current > moment().endOf('day');
              }}
            />
          </Form.Item>
        </Form>
        <p>Tổng lượt truy cập: {0}</p>
      </Row>
      <Table
        columns={columns}
        dataSource={access?.data}
        // {...tableProps}
      />
    </>
  );
};
