import React, { useState } from 'react';
import styles from '../index.less';
import type { ColumnsType } from 'antd/lib/table';
import { Button, Form, message, Skeleton, Table, Tag } from 'antd';
import { useAntdTable, useToggle } from 'ahooks';
import { useRequest } from 'umi';
import { currentVersion, getTableData } from '../service';
import { useIntl } from 'umi';
import Dialog from './Dialog';
import dayjs from 'dayjs';
interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: boolean;
}
const LocaMeetVersion = () => {
  const refreshData = () => {
    refresh();
  };
  const [form] = Form.useForm();
  const [openDialog, setOpenDialog] = useToggle(false);
  const [version, setVersion] = useState({
    ios: '',
    android: '',
  });

  const handleVersion = () => {
    setOpenDialog.set(true);
  };

  useRequest(currentVersion, {
    onSuccess: (res: any) => {
      if (res?.currentVersion) {
        setVersion({
          ios: res?.currentVersion.versionIos,
          android: res?.currentVersion.versionAndroid,
        });
      }
    },
  });
  const { formatMessage } = useIntl();
  const { tableProps, refresh, error, loading } = useAntdTable(getTableData, {
    form,
    onError: (error: any) => {
      message.error(
        error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
      );
    },
  });
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: '5%',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'Phiên bản',
      width: 100,
      dataIndex: 'version',
      key: 'version',
      align: 'right',
    },
    {
      width: 150,
      title: 'Dòng máy',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Trạng thái update',
      width: 170,
      dataIndex: 'status',
      render: (value: boolean, record: any) => (
        <Tag color={value ? 'red' : 'rgba(0,0,0,0.3)'}>{`${
          value ? 'Bắt buộc cập nhật' : 'Không bắt buộc cập nhật'
        }`}</Tag>
      ),
      key: 'status',
    },
    {
      width: 180,
      title: 'Thời gian cập nhật',
      align: 'right',
      dataIndex: 'created_at',
      render: (value: any) => <>{dayjs(value).format('HH:MM:ss DD/MM/YYYY')}</>,
      key: 'created_at',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const versionForm = (
    <div className={styles.searchContainer}>
      <div className={styles.breadcrumb}>
        <div className={styles.versionWrap}>
          Phiên bản IOS hiện tại:{' '}
          <span className={styles.version}>{version.ios}</span>
        </div>
        <div className={styles.versionWrap}>
          Phiên bản Android hiện tại:{' '}
          <span className={styles.version}>{version.android}</span>
        </div>
      </div>
      <Button
        onClick={() => {
          handleVersion();
        }}
      >
        Cập nhật version
      </Button>
    </div>
  );
  return (
    <div className={styles.LocaMeetVersion}>
      {versionForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
      {openDialog && (
        <Dialog
          app="LocaMeet"
          open={openDialog}
          refreshData={refreshData}
          setOpen={(b) => {
            setOpenDialog.set(b);
            // refresh();
          }}
        />
      )}
    </div>
  );
};

export default LocaMeetVersion;
