import React, { useState } from 'react';
import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Select,
  Upload,
  message,
  Breadcrumb,
  Row,
} from 'antd';
import { Link, history, useParams } from 'umi';

import styles from './index.less';
import type { UploadProps } from 'antd/es/upload/interface';
import { LeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  ICreateBanner,
  getDetailBanner,
  requestUpdateBanner,
  useBanner,
  useBuilding,
} from '../service';
import { ENVIRONMENTS } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
import axios from 'axios';
import UploadImage from '@/components/UploadImage';

// const { Option } = Select;
export enum ClickAction {
  IFRAME = 1,
  POPUP = 2,
  NO = 3,
}
export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { banner, getBanner, loadingBanner } = useBanner();
  const [fileList, setFileList] = useState<any[]>([]);
  const detailBanner = useRequest(getDetailBanner, {
    defaultParams: [param.id],
    onSuccess(res) {
      form.setFieldsValue({
        ...res,
        building_id: res?.building?.id,
      });
      getBanner(res?.building.id);
      if (res?.image) {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: res.image,
          },
        ]);
      }
    },
  });
  const { building } = useBuilding();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const onUpdateBanner = useRequest(
    (payload: ICreateBanner) => requestUpdateBanner(payload, param?.id),
    {
      manual: true,
      onSuccess: () => {
        message.success('thành công');
        history.push('/billboards');
      },
      onError: (err: any) => {
        message.error(err?.data.message);
      },
    },
  );
  const onFinish = (values: any) => {
    const data = {
      ...values,
      image: values.image?.[0]?.response?.url
        ? `${values.image?.[0]?.response?.url}${values.image?.[0]?.response?.path}`
        : values.image,
    };
    // console.log(data);

    onUpdateBanner.run(data);
  };

  const onChangeAvatar = useRequest(
    (value: any) => {
      return axios.post(ENVIRONMENTS.API_URL + API_PATH.UPLOAD, value);
    },
    {
      manual: true,
      onSuccess: (res: any) => {
        setLoading(false);
        setImageUrl(`${res.data.url}${res.data.path}`);
        // const urlImage = `${res.data.url}${res.data.path}`;
        // form.setFieldValue('image', urlImage);
      },
    },
  );

  const handleChange: UploadProps['onChange'] = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const formData = new FormData();
      formData.append('file', info.file.originFileObj);
      onChangeAvatar.run(formData);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/billboards" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Cập nhật biển quảng cáo
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Divider orientation="left">Thông tin chung</Divider>
        <div className={styles.formInfoGeneral}>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={styles.formSearch}
            layout="vertical"
            // initialValues={{
            //   action: ClickAction.IFRAME,
            //   status: true,
            // }}
          >
            <Form.Item name="name" label="Tên biển quảng cáo">
              <Input />
            </Form.Item>
            <Form.Item
              name="building_id"
              label="Toà nhà"
              className={styles.locationNFT}
            >
              <Select
                placeholder="Lựa chọn toà nhà"
                options={building?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                onChange={(data) => getBanner(data)}
              />
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building = getFieldValue('building_id');
                const isDisabled = building && !loadingBanner ? false : true;
                return (
                  <Form.Item
                    name="building_entities_id"
                    label="Vị trí biển quảng cáo"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (building && !value) {
                            return Promise.reject(
                              new Error('Vui lòng chọn vị trí biển quảng cáo'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Search to Select"
                      options={banner?.map((banner: any) => {
                        return {
                          value: banner.id,
                          label: banner.name,
                        };
                      })}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            {/* <Upload
              name="image"
              listType="picture-card"
              className={styles.uploadAvatar}
              showUploadList={false}
              onChange={handleChange}
              accept=".png,.jpg,.jpeg"
              // onPreview={onPreview}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload> */}
            <UploadImage
              label="Ảnh banner"
              fileList={fileList}
              setFileList={setFileList}
              name="image"
            />

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building_id = getFieldValue('building_id');
                const template = building?.find(
                  (item: any) => item.id === building_id,
                );

                if (!building_id) return;
                return (
                  <div className={styles.preview}>
                    <p>Preview vị trí biển quảng cáo</p>
                    <iframe
                      src={
                        template
                          ? `https://nft.locamos.com/${template?.vr_oid}`
                          : `https://nft.locamos.com/${detailBanner.data?.vr_oid}`
                      }
                      title="preview"
                      width="100%"
                      height="600"
                    />
                  </div>
                );
              }}
            </Form.Item>
            <Divider orientation="left">Tuỳ chọn tương tác</Divider>
            <Form.Item name="click_action" label="Click action">
              <Select
                options={[
                  {
                    value: ClickAction.IFRAME,
                    label: 'Embed Iframe',
                  },
                  {
                    value: ClickAction.POPUP,
                    label: 'Popup nội dung',
                  },
                  {
                    value: ClickAction.NO,
                    label: 'Không',
                  },
                ]}
              />
            </Form.Item>

            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const action = getFieldValue('click_action');
                if (action === ClickAction.IFRAME) {
                  return (
                    <Form.Item name="content" label="URL hiển thị">
                      <Input placeholder="URL được mở khi ấn vào quảng cáo" />
                    </Form.Item>
                  );
                }
                if (action === ClickAction.POPUP) {
                  return (
                    <Form.Item name="content" label="Nội dung hiển thị">
                      <Input placeholder="Nhập nội dung" />
                    </Form.Item>
                  );
                }
                return '';
              }}
            </Form.Item>
            <Space size={15}>
              <p>Trạng thái</p>
              <Form.Item
                name="status"
                className={styles.status}
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Space>

            <Row justify="center">
              <Button
                className="btn-submit"
                type="primary"
                ghost
                danger
                onClick={() => {
                  history.push('/billboards');
                }}
              >
                Huỷ
              </Button>
              <Button
                className="btn-submit"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Lưu
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
