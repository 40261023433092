import React, { useEffect } from 'react';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
  message,
} from 'antd';
import styles from '../index.less';
import { Actions } from 'ahooks/lib/useToggle';
import { useIntl, useRequest } from 'umi';
import { DetailReportService, handleReportService } from '../service';
import { StatusReportVoucher } from '@/utils/enum';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { ENVIRONMENTS } from '@/utils/constant';
import dayjs from 'dayjs';
interface IDetailBadReport {
  isOpen: boolean;
  setIsOpen: Actions<boolean>;
  id: string;
  refresh: () => void;
}
const DetailBadReport = ({
  isOpen,
  setIsOpen,
  id,
  refresh,
}: IDetailBadReport) => {
  const [form] = Form.useForm();
  const detailReport = useRequest(DetailReportService, {
    manual: true,
    onSuccess(res) {
      form.setFieldsValue({
        ...res,
        updated_at: dayjs(res.updated_at).format('HH:mm DD/MM/YYYY'),
      });
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });
  useEffect(() => {
    detailReport.run(id);
  }, [id]);
  const handleClose = () => {
    setIsOpen.set(false);
    form.resetFields();
  };
  const renderStatusReport = (status: StatusReportVoucher) => {
    if (detailReport?.data?.images) {
    }
    return (
      <>
        {status === StatusReportVoucher.REFUSE ? (
          <Tag color="red" icon={<CloseOutlined />}>
            Đã từ chối
          </Tag>
        ) : status === StatusReportVoucher.APPROVE ? (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã duyệt
          </Tag>
        ) : status === StatusReportVoucher.PENDING ? (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Chờ xử lý
          </Tag>
        ) : (
          <></>
        )}
      </>
    );
  };
  const renderImage = (images: any) => {
    return (
      <>
        {Object.keys(images)?.map((item: string) => {
          const image_url = ENVIRONMENTS.BUCKET_NAME + '/' + images[item];
          return (
            <Image
              style={{
                width: '80px',
                height: '80px',
                marginRight: '15px',
              }}
              src={image_url}
            />
          );
        })}
      </>
    );
  };
  const { formatMessage } = useIntl();
  const handleReportVoucher = useRequest(handleReportService, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
      refresh();
      handleClose();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const [formRefuse] = Form.useForm();
  const handleRefuseReport = (id: string) => {
    const onSubmitReason = (value: any) => {
      handleReportVoucher.run({
        id,
        status: StatusReportVoucher.REFUSE,
        reason: value.reason,
      });
    };
    return Modal.confirm({
      icon: null,
      title: 'Lý do từ chối yêu cầu báo xấu',
      content: (
        <Form
          id="formRefuse"
          layout="vertical"
          form={formRefuse}
          onFinish={onSubmitReason}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: handleReportVoucher.loading,
      },
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onCancel() {
        formRefuse.resetFields();
      },
      onOk() {
        if (formRefuse.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const handleApproveReport = (id: string) => {
    return Modal.confirm({
      icon: null,
      title: 'Phê duyệt báo xấu voucher',
      content: `Bạn có chắc chắn muốn phê duyệt báo xấu voucher này không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        loading: handleReportVoucher.loading,
      },
      onOk() {
        handleReportVoucher.run({
          id: id,
          status: StatusReportVoucher.APPROVE,
        });
      },
    });
  };
  return (
    <Modal
      width={600}
      title="Chi tiết báo xấu"
      open={isOpen}
      onCancel={handleClose}
      footer={null}
    >
      {detailReport.loading ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <Form
          form={form}
          wrapperCol={{
            span: 16,
          }}
          labelCol={{
            span: 5,
          }}
          labelWrap
          labelAlign="left"
          onFinish={(val) => console.log(val)}
        >
          <div>
            <Typography.Title level={5}>Người báo xấu</Typography.Title>
            <Form.Item label="ID" name={['user', 'id']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item
              label="Tên user"
              name={['user', 'user_profile', 'full_name']}
            >
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Email" name={['user', 'email']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Số điện thoại" name={['user', 'phone']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Typography.Title level={5}>
              Doanh nghiệp bị báo xấu
            </Typography.Title>
            <Form.Item label="ID" name={['enterprise', 'id']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Tên DN" name={['enterprise', 'name']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Email" name={['enterprise', 'email']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Số điện thoại" name={['enterprise', 'phone']}>
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Typography.Title level={5}>Nội dung báo xấu</Typography.Title>
            <Form.Item label="Thời gian" name="updated_at">
              <Input
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Nội dung báo xấu" name="content">
              <Input.TextArea
                rows={2}
                readOnly
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label="Trạng thái" name="status">
              {renderStatusReport(detailReport.data?.status)}
            </Form.Item>
            {detailReport.data?.reason && (
              <Form.Item label="Lý do từ chối" name="reason">
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
            )}
          </div>
          <Typography.Title level={5}>Ảnh minh chứng</Typography.Title>
          <div>
            {detailReport?.data?.images &&
              renderImage(detailReport?.data?.images)}
          </div>
          {detailReport.data?.status === StatusReportVoucher.PENDING && (
            <Row
              justify="center"
              style={{
                marginTop: '15px',
              }}
            >
              <Button
                className={styles.submitButton}
                danger
                onClick={() => handleRefuseReport(id)}
              >
                Từ chối
              </Button>
              <Button
                className={styles.submitButton}
                type="primary"
                onClick={() => handleApproveReport(id)}
              >
                Phê duyệt
              </Button>
            </Row>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default DetailBadReport;
