import { API_PATH, privateRequest, request } from '@/utils/apis';
import { useRequest } from 'ahooks';

interface Item {
  id: number;
  name: string;
  building_name: string;
  cost_by_month: string;
  cost_by_total: string;
  created_at: string;
}

interface Result {
  total: number;
  list: Item[];
}

export interface ICreateShop {
  name: string;
  building_id: number;
  building_entities_id: number;
  unit_name: string;
  address: string;
  representative: string;
  phone: string;
  email: string;
  status: boolean;
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  form: Object,
): Promise<Result> => {
  let query = `page_index=${current}&page_size=${pageSize}`;
  Object.entries(form).forEach(([key, value]) => {
    if ((value || value === 0) && key !== 'order') {
      query += `&${key}=${value}`;
    }
  });
  return privateRequest(request.get, API_PATH.SHOP + '?' + query).then(
    (res) => {
      return {
        total: res.total,
        list: res.data,
      };
    },
  );
};

// get detail building
export const requesBuilding = (id: number) => {
  return privateRequest(request.get, API_PATH.BUILDING + `/${id}`);
};
// get template building
export const useGetBuildingTemplate = () => {
  const query = `page_index=0&page_size=99`;
  const { data } = useRequest(() => {
    return privateRequest(
      request.get,
      API_PATH.BUILDING_TEMPLATES + '?' + query,
    );
  });
  return {
    buildingTemplate: data,
  };
};

// get list building

export const useBuilding = () => {
  const { data, loading } = useRequest(() => {
    return privateRequest(
      request.get,
      API_PATH.BUILDING + '?status=true&page_size=999&page_index=1',
    );
  });
  return {
    building: data?.data,
    loadData: loading,
  };
};

// get position shop

export const useShop = () => {
  const { data, run, loading } = useRequest(
    (id: number) => {
      return privateRequest(
        request.get,
        API_PATH.BUILDING +
          `/${id}/shop?status=true&page_size=999&page_index=0`,
      );
    },
    {
      manual: true,
    },
  );
  return {
    shop: data?.data,
    getShop: run,
    loadingShop: loading,
  };
};

// create shop
export const requestCreateShop = (payload: ICreateShop) => {
  return privateRequest(request.post, API_PATH.SHOP, {
    data: payload,
  });
};
// get detail shop
export const useGetDetailShop = (id: number) => {
  const { data, loading } = useRequest(() => {
    return privateRequest(request.get, API_PATH.SHOP + `/${id}`);
  });
  return {
    shopDetail: data,
    loadingShopDetail: loading,
  };
};

// change status
export const requestChangeStatus = (status: boolean, id: number) => {
  return privateRequest(request.put, API_PATH.SHOP + `/${id}`, {
    data: {
      status,
    },
  });
};

// update shop
export const requestUpdateShop = (id: number, data: ICreateShop) => {
  return privateRequest(request.put, API_PATH.SHOP + `/${id}`, {
    data,
  });
};

// get access
export const useGetAccessShop = (id: number) => {
  const { data, run } = useRequest(
    (date?: any) => {
      let query = `page_index=1&page_size=10`;
      date &&
        Object.entries(date).forEach(([key, value]) => {
          if (value) {
            query += `&${key}=${value}`;
          }
        });

      return privateRequest(
        request.get,
        API_PATH.SHOP + `/${id}/access-times?${query}`,
      );
    },
    {
      manual: true,
    },
  );
  return {
    access: data,
    run,
  };
};

export const getListEnterprise = (search?: string) => {
  const data = {
    query: `mutation {
			admin_get_list_enterprise(input: { page_index: 0, page_size: 30, filter: "${
        search ?? ''
      }" }) {
				data {
					enterprise_id
					name
					email
					phone
					created_at
					status
					address
					owner_name
				}
				total
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
