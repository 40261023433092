import React from 'react';
import styles from './index.less';
import { Tabs } from 'antd';
import { useHistory, useLocation } from 'umi';
import StatisticVoucherEvent from './Component/StatisticVoucherEvent';
import ListDiscountEvent from './Component/ListDiscountEvent';

export const TYPE_EVENT = {
  DISCOUNT_EVENT: '/discount_event',
  DISCOUNT_EVENT_REPORT: '/discount_event/report',
};

const DiscountEvent = () => {
  const location = useLocation();
  const history = useHistory();

  const renderTab = () => {
    switch (location.pathname) {
      case TYPE_EVENT.DISCOUNT_EVENT:
        return <ListDiscountEvent />;
      case TYPE_EVENT.DISCOUNT_EVENT_REPORT:
        return <StatisticVoucherEvent />;

      default:
        break;
    }
  };
  return (
    <div>
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane
          tab="Quản lý chương trình khuyến mãi"
          key="/discount_event"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Thống kê voucher đã sử dụng"
          key="/discount_event/report"
        ></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </div>
  );
};

export default DiscountEvent;
