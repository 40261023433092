import type { ColumnsType } from 'antd/lib/table';
import Table from 'antd/lib/table';
import { EyeOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useRequest } from 'umi';
import style from '../index.less';
import { findSimilarFace, similarFaceBlacklist } from '../service';
import { Image } from 'antd';
interface DataType {
  id: number;
  name: string;
  note: string;
  confidence: string;
  image: string;
}

const BlacklistFace = ({ image }: { image: string }) => {
  const blacklistFace = useRequest(similarFaceBlacklist, {
    manual: true,
    formatResult: (res) => res,
  });
  useEffect(() => {
    blacklistFace.run(image);
  }, [image]);
  const cols: ColumnsType<DataType> = [
    {
      title: 'Id User',
      dataIndex: 'name',
    },
    {
      title: 'Tỷ lệ giống',
      dataIndex: 'confidence',
      render: (_, record) => (
        <>{`${(Number(record?.confidence) * 100).toFixed(2)} %`}</>
      ),
    },
    {
      title: 'Ảnh',
      dataIndex: 'image',
      render: (_, record) => (
        <>
          <Image
            src={record.image}
            alt="blacklist"
            style={{
              width: '50px',
              height: '50px',
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div className={style.SameFace}>
      <div className={style.TitleSameFace}>
        Danh sách khuôn mặt black list tương đồng
      </div>
      <Table
        columns={cols}
        dataSource={blacklistFace.data}
        loading={blacklistFace.loading}
        rowKey={(item) => item.id}
      />
    </div>
  );
};

export default BlacklistFace;
