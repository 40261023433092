import {
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'umi';
import styles from '../index.less';
import { DeleteOutlined, LeftOutlined, EditOutlined } from '@ant-design/icons';
import { paternUrl } from '@/utils/patern';
import { ColumnsType } from 'antd/lib/table';
import { useAntdTable, useRequest } from 'ahooks';
import {
  UserGroup,
  createNotification,
  getDetailNotification,
  getListGroupUser,
  updateNotification,
} from '../service';
import SelectGroupUserModal from './SelectGroupUserModal';
import moment from 'moment';
import dayjs from 'dayjs';

const DetailNotification = () => {
  const [optionUser, setOptionUser] = useState<'all' | 'custom'>('all');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [groupUser, setGroupUser] = useState<UserGroup[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const history = useHistory();
  const onDeleteGroup = (id: string) => {
    setGroupUser(groupUser.filter((item) => item.id !== id));
  };
  const updateNoti = useRequest(updateNotification, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      message.success('Chỉnh sửa thông báo thành công');
      history.push('/notification');
    },
    onError: (err) => {
      console.log(err);
      message.error('Có lỗi xảy ra');
    },
  });
  const detailNoti = useRequest(getDetailNotification, {
    defaultParams: [Number(params.id)],
    onSuccess(res) {
      console.log(res);
      form.setFieldsValue({
        ...res,
        sending_time: moment(res.sending_time),
      });
      if (res.user_groups && res.user_groups.length > 0) {
        setOptionUser('custom');
        setGroupUser(res.user_groups);
      } else {
        setOptionUser('all');
      }
    },
  });
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nhóm user',
      dataIndex: 'name',
    },
    {
      title: 'Hành động',
      align: 'center',
      // width: '10%',
      dataIndex: 'id',
      render: (value, record) => (
        <>
          {detailNoti.data?.status !== 'SEND' && (
            <Button
              icon={<DeleteOutlined />}
              type="primary"
              danger
              ghost
              title="Xoá"
              disabled={!isEdit}
              onClick={() => onDeleteGroup(record?.id)}
            ></Button>
          )}
        </>
      ),
    },
  ];

  const onSubmit = (val: any) => {
    updateNoti.run(
      {
        ...val,
        user_groups: groupUser,
      },
      Number(params.id),
    );
  };
  const disabledHours = () => {
    const hours = [];
    const currentHour = moment().hour();

    for (let i = currentHour - 1; i > 0; i--) {
      hours.push(i);
    }

    return hours;
  };

  const disabledMinutes = (selectedHour: any) => {
    const minutes = [];
    const currentMinute = moment().minute();
    if (selectedHour === moment().hour()) {
      for (let i = currentMinute - 1; i > 0; i--) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/notification" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>Chi tiết thông báo</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className={styles.createNotiWrapper}>
        <Row align="middle" style={{ marginBottom: 10 }}>
          <div
            style={{
              marginRight: 10,
            }}
          >
            Trạng thái:{' '}
          </div>
          {detailNoti.data?.status === 'SEND' ? (
            <Tag color="green">Đã gửi</Tag>
          ) : (
            <Tag color="gray">Chờ gửi</Tag>
          )}
        </Row>

        {detailNoti.data?.status !== 'SEND' && (
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => setIsEdit(true)}
            title="Sửa thông báo"
          ></Button>
        )}
        <Form
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 20 }}
          disabled={!isEdit}
          labelAlign="left"
          layout="vertical"
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            label="Thời gian gửi"
            name="sending_time"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn',
              },
            ]}
          >
            <DatePicker
              showTime
              format={'DD/MM/YYYY HH:mm'}
              disabledDate={(currentDate) =>
                currentDate < dayjs().add(-1, 'day')
              }
              disabledTime={() => ({
                disabledHours: disabledHours,
                disabledMinutes: disabledMinutes,
              })}
            />
          </Form.Item>
          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tiêu đề thông báo" />
          </Form.Item>
          <Form.Item
            label="Nội dung"
            name="body"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input.TextArea placeholder="Nhập nội dung thông báo" rows={3} />
          </Form.Item>
          <Form.Item
            label="Link điều hướng"
            name="link"
            rules={[
              {
                required: false,
              },
              {
                pattern: paternUrl,
                message: 'Không đúng định dạng',
              },
            ]}
          >
            <Input placeholder="Nhập link" />
          </Form.Item>
          <Form.Item label="Nhóm user">
            <Select
              onChange={(val) => {
                setOptionUser(val);
              }}
              value={optionUser}
            >
              <Select.Option value="all">Tất cả</Select.Option>
              <Select.Option value="custom">Tuỳ chọn</Select.Option>
            </Select>
          </Form.Item>
          {optionUser === 'custom' && (
            <div>
              <Button
                type="primary"
                ghost
                style={{
                  margin: '15px 0',
                }}
                onClick={() => setIsOpenModal(true)}
              >
                Thêm nhóm user nhận thông báo
              </Button>
              <Table columns={columns} dataSource={groupUser} rowKey="id" />
            </div>
          )}
          {detailNoti.data?.status !== 'SEND' && (
            <Row
              justify="center"
              style={{
                marginTop: '20px',
              }}
            >
              <Button
                type="primary"
                ghost
                danger
                className="btn-submit"
                onClick={() => {
                  setIsEdit(false);
                  detailNoti.run(+params.id);
                }}
              >
                Huỷ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                loading={updateNoti.loading}
              >
                Lưu
              </Button>
            </Row>
          )}
        </Form>
        {isOpenModal && (
          <SelectGroupUserModal
            isOpen={isOpenModal}
            selectedGroup={groupUser}
            setGroupUser={setGroupUser}
            onClose={() => setIsOpenModal(false)}
          />
        )}
      </Card>
    </div>
  );
};

export default DetailNotification;
