import React from 'react';
import { Breadcrumb, Tabs } from 'antd';

import styles from './index.less';
import { useState } from 'react';
import { useIntl } from 'umi';
import KycManageMent from './KycManagement';
import BlacklistFace from './BlacklistFace';

export default function KYC() {
  const [activeTabs, setActiveTabs] = useState(1);
  const { formatMessage } = useIntl();
  const renderTab = () => {
    switch (activeTabs) {
      case 1: {
        return <KycManageMent />;
      }
      case 2: {
        return <BlacklistFace />;
      }
    }
  };
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => {
          setActiveTabs(parseInt(key));
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="Yêu cầu KYC" key="1"></Tabs.TabPane>
        {/* <Tabs.TabPane tab="Tỷ lệ khớp khuôn mặt" key="2"></Tabs.TabPane> */}
        <Tabs.TabPane tab="Blacklist khuôn mặt" key="2"></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </>
  );
}
