import { Breadcrumb, Button, Form, Input, message, Skeleton, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import FaceMatching from './Component/FaceMatching';
import Locameet from './Component/Locameet';

import styles from './index.less';
import BlacklistDomain from './Component/BlacklistDomain';
import Locacall from './Component/Locacall';
import BannerConfig from './Component/Banner';
import { history, useLocation } from 'umi';
type BannerRoute = {
  HOME: '/';
  MEET: '/meet';
  statistic: '/statistic';
};

const Setting: React.FC = () => {
  const [activeTabs, setActiveTabs] = useState(1);
  const location = useLocation();
  const renderTab = () => {
    switch (location.pathname) {
      case '/setting/locameet':
        return <Locameet />;
      // return <FaceMatching />;
      case '/setting/blacklist':
        return <BlacklistDomain />;
      case '/setting/banner':
      case '/setting/banner/meet':
      case '/setting/banner/statistic':
        return <BannerConfig />;
      case '/setting/locacall':
        return <Locacall />;
      default:
        return <></>;
    }
  };
  return (
    <>
      {/* <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Cài đặt hệ thống</Breadcrumb.Item>
      </Breadcrumb> */}
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          // setActiveTabs(parseInt(key));
          history.push(key);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="LocaMeet" key="/setting/locameet"></Tabs.TabPane>
        {/* <Tabs.TabPane tab="Tỷ lệ khớp khuôn mặt" key="2"></Tabs.TabPane> */}
        <Tabs.TabPane
          tab="Domain email blacklist"
          key="/setting/blacklist"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Banner App"
          key={`/setting/banner${
            location.pathname.includes('/banner/')
              ? `/${location.pathname.split('/').reverse()[0]}`
              : ''
          }`}
        ></Tabs.TabPane>
        <Tabs.TabPane tab="Cuộc gọi" key="/setting/locacall"></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </>
  );
};

export default Setting;
