import { API_PATH, privateRequest, request } from '@/utils/apis';
import { formatTime } from '@/utils/formatTime';
import moment from 'moment';

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search: string;
    toDate: string[];
    status?: string;
  },
): Promise<any> => {
  if (formData.search === undefined) {
    formData.search = '';
  }
  if (formData.status === undefined) {
    delete formData['status'];
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `mutation {
      get_enterprise_customer_request(input: { page_index: ${
        current - 1
      }, page_size: ${pageSize}, search: "${
      formData.search
    }", end_time: "${toDate}", start_time: "${fromDate}", 
    ${formData.status ? `status: ${formData.status}` : ''}
  }) {
			total
			requests {
				request_id
				request_at
				accrual_spending
				user_name
				enterprise_name
				bill_path
				rating
				spending
				status
			}
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_enterprise_customer_request?.requests?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current - 1) * pageSize + (index + 1),
        }),
      );
      return {
        list: result,
        total: res?.data?.get_enterprise_customer_request?.total,
      };
    },
  );
};
