import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export const getListLiveness = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search: string;
    toDate: string[];
    status: string;
  },
) => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `{
		list_liveness_profile(input: { 
			page_index: ${current}, 
			page_limit: ${pageSize}, 
			${!!formData.status ? `status: ${formData.status},` : ''} 
			${!!formData.search ? `search_text: "${formData.search}",` : ''} 
			${!!fromDate ? `start_date: "${fromDate}",` : ''}
			${!!toDate ? `end_date: "${toDate}"` : ''}
		}) {
			total
			data {
				liveness_at
				user_id
				full_name
				status
			}
		}
	}`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (response) => {
      const data = response?.data?.list_liveness_profile?.data?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current - 1) * pageSize + (index + 1),
        }),
      );
      return {
        total: response?.data?.list_liveness_profile?.total,
        list: data,
      };
    },
  );
};
