import { useAntdTable } from 'ahooks';
import React from 'react';
import { listImportVoucherPartner } from '../service';
import { ColumnsType } from 'antd/lib/table';
import { Table, Tabs } from 'antd';
import { formatNumber } from '@/utils/apis/common';
const VoucherImportType = {
  ONLINE: 'false',
  OFFLINE: 'true',
};
const VoucherImport = ({ partner_id }: { partner_id?: number }) => {
  const { tableProps, run } = useAntdTable(listImportVoucherPartner, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        partner_id,
        is_offline: 'true',
      },
    ],
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Mệnh giá',
      dataIndex: 'value',
      render(value) {
        return <>{formatNumber(value)}</>;
      },
    },
    {
      title: 'Số lượng chưa dùng',
      dataIndex: 'quantity',
    },
  ];
  return (
    <div>
      <Tabs
        defaultActiveKey={VoucherImportType.OFFLINE}
        onChange={(key) =>
          run({
            current: 1,
            pageSize: 10,
            partner_id,
            is_offline: key,
          })
        }
      >
        <Tabs.TabPane
          tab="Offline"
          key={VoucherImportType.OFFLINE}
          tabKey={VoucherImportType.OFFLINE}
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Online"
          key={VoucherImportType.ONLINE}
          tabKey={VoucherImportType.ONLINE}
        ></Tabs.TabPane>
      </Tabs>
      <Table
        {...tableProps}
        scroll={{ x: 1000 }}
        columns={columns}
        rowKey={(item) => item.id}
      />
    </div>
  );
};

export default VoucherImport;
