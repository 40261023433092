import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { useHistory, useIntl, useRequest } from 'umi';
import Dialog from './Components/Dialog';
import styles from './index.less';
import { getTableData, switchRole } from './service';

const { Option } = Select;

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  stt: number;
  groupname: string;
  dateEdit: string;
}
export const Decentralization = () => {
  const [openDialog, setOpenDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const [form] = Form.useForm();
  const history = useHistory();

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      defaultPageSize: 10,
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const handleEditRole = (id: string) => {
    history.push('/decentralization_edit/' + id);
  };
  const handleViewRole = (id: string) => {
    history.push('/decentralization_info/' + id);
  };

  const handleNewAdmin = () => {
    history.push('/decentralization_new');
  };
  const DeleteRole = (isRole: number | string) => {
    setIdSelected(isRole);
    setOpenDialog.set(true);
  };

  //////switchRole
  const switchRoleRequest = useRequest(switchRole, {
    manual: true,
    onSuccess(res: any) {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Nhóm quyền đang được sử dụng');
        }
      } else {
        message.success('Chuyển trạng thái thành công');
        refresh();
      }

      search.submit();
    },
    // onError() {
    //   message.error('Nhóm quyền đang được sử dụng');
    //   search.submit();
    // },
    formatResult: (res) => res,
  });

  const handleSwitchRole = (idRole: any) => {
    switchRoleRequest.run(idRole);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_group_name',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'const_column_date_editend',
      dataIndex: 'date_modified',
      align: 'right',
      key: 'date_modified',
      render(_: any, record: any) {
        return moment(record.date_modified).format('HH:mm:ss DD/MM/YYYY');
      },
    },
    {
      title: formatMessage({ id: 'const_column_status' }),
      key: 'status',
      render: (_: any, record: any) => (
        <>
          {record?.is_active ? (
            <Tag color="green">Đã kích hoạt</Tag>
          ) : (
            <Tag color="gray">Chưa kích hoạt</Tag>
          )}
        </>
      ),
    },
    {
      title: 'const_column_action',
      dataIndex: 'custom',
      width: '15%',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.activeButton}>
            <Tooltip title={formatMessage({ id: 'general_edit' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleEditRole(record.id)}
              >
                <EditOutlined className="primary-color" />
              </div>
            </Tooltip>

            <Tooltip title={formatMessage({ id: 'general_tooltip_delete' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => DeleteRole(record.id)}
              >
                <DeleteOutlined className="red-color" />
              </div>
            </Tooltip>
            <Tooltip title={formatMessage({ id: 'general_view_infomation' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewRole(record.id)}
              >
                <EyeOutlined className="purple-color" />
              </div>
            </Tooltip>
            <Tooltip title={formatMessage({ id: 'const_column_status' })}>
              <Switch
                defaultChecked={record.is_active}
                onClick={() => handleSwitchRole(record.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="search_text" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({
              id: 'form_search_name_decentralization',
            })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      <Button onClick={() => handleNewAdmin()}>
        {formatMessage({ id: 'general_add' })}
      </Button>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_group_decentralization' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )}
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          refresh={refresh}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={idSelected}
        />
      )}
    </>
  );
};
