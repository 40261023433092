import React from 'react';
import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Select,
  Upload,
  message,
} from 'antd';
import { history, useParams } from 'umi';

import styles from './index.less';
import { useRequest } from 'ahooks';

import {
  getDetailBanner,
  useBanner,
  useBuilding,
} from '@/pages/BillBoards/service';

// const { Option } = Select;
export enum ClickAction {
  IFRAME = 1,
  POPUP = 2,
  NO = 3,
}
export default () => {
  const [form] = Form.useForm();
  const param = useParams<{ id: string }>();
  const [imageUrl, setImageUrl] = React.useState<string>();

  const detailBanner = useRequest(getDetailBanner, {
    defaultParams: [param.id],
    onSuccess(res) {
      form.setFieldsValue({
        ...res,
        building_entities_id: res?.building_entities?.name,
      });
      setImageUrl(res?.image);
    },
  });
  // React.useEffect(() => {
  //   form.setFieldsValue(detailBanner);
  //   form.setFieldValue(
  //     'building_entities_id',
  //     detailBanner?.building_entities.name,
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadingDetail]);
  const { building } = useBuilding();
  const { banner, getBanner } = useBanner();

  return (
    <div className={styles.createBuilding}>
      <Divider orientation="left">Thông tin chung</Divider>
      <div className={styles.formInfoGeneral}>
        <Form
          form={form}
          autoComplete="off"
          className={styles.formSearch}
          layout="vertical"
          // initialValues={{
          //   action: ClickAction.IFRAME,
          //   status: true,
          // }}
          disabled
        >
          <Form.Item name="name" label="Tên biển quảng cáo">
            <Input />
          </Form.Item>
          <Form.Item
            name="building_id"
            label="Toà nhà"
            className={styles.locationNFT}
          >
            <Select
              placeholder="Lựa chọn toà nhà"
              options={building?.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={(data) => getBanner(data)}
            />
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.location !== curValues.location
            }
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="building_entities_id"
                  label="Vị trí biển quảng cáo"
                >
                  <Select
                    placeholder="Search to Select"
                    options={banner?.map((banner: any) => {
                      return {
                        value: banner.id,
                        label: banner.name,
                      };
                    })}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Upload
            name="image"
            listType="picture-card"
            className={styles.uploadAvatar}
            showUploadList={false}
            accept=".png,.jpg,.jpeg"
            // onPreview={onPreview}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              ''
            )}
          </Upload>
          <div className={styles.preview}>
            <p>Preview vị trí biển quảng cáo</p>
            <iframe
              src={`https://nft.locamos.com/${detailBanner.data?.vr_oid}`}
              title="preview"
              width="100%"
              height="600"
            />
          </div>
          <Divider orientation="left">Tuỳ chọn tương tác</Divider>
          <Form.Item name="click_action" label="Click action">
            <Select
              options={[
                {
                  value: ClickAction.IFRAME,
                  label: 'Embed Iframe',
                },
                {
                  value: ClickAction.POPUP,
                  label: 'Popup nội dung',
                },
                {
                  value: ClickAction.NO,
                  label: 'Không',
                },
              ]}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const action = getFieldValue('click_action');
              if (action === ClickAction.IFRAME) {
                return (
                  <Form.Item name="content" label="URL hiển thị">
                    <Input placeholder="URL được mở khi ấn vào quảng cáo" />
                  </Form.Item>
                );
              }
              if (action === ClickAction.POPUP) {
                return (
                  <Form.Item name="content" label="Nội dung hiển thị">
                    <Input placeholder="Nhập nội dung" />
                  </Form.Item>
                );
              }
              return '';
            }}
          </Form.Item>
          <Space size={15}>
            <p>Trạng thái</p>
            <Form.Item
              name="status"
              className={styles.status}
              valuePropName="checked"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Space>
        </Form>
      </div>
    </div>
  );
};
