import { STATUS_DESPUTE } from '@/utils/constant';
import { StatusDespute } from '@/utils/enum';
import { EyeOutlined, RedoOutlined, RollbackOutlined } from '@ant-design/icons';

import { useAntdTable, useRequest, useToggle } from 'ahooks';
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import { useIntl, history, useHistory } from 'umi';
import styles from '../index.less';
import { adminHandleDispute, getDisputeData } from '../service';

const { Option } = Select;

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const dateFormat = 'DD/MM/YYYY';
const DisputeLocabonus = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getDisputeData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const requestDispute = useRequest(adminHandleDispute, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        message.success('Thành công');
      }
    },
  });

  const handleDispute = (idUser: number | string, status: string) => {
    requestDispute.run(idUser, status);
  };
  const handleAcceptUser = (idUser: number | string, status: any) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn xác nhận chuyển điểm không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleDispute(idUser, status);
        refresh();
      },
    });
  };
  const handletRefuseUser = (idUser: number | string, status: any) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn hoàn lại điểm không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleDispute(idUser, status);
        refresh();
      },
    });
  };

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_dispute_time',
      dataIndex: 'disputed_at',
      align: 'right',
      key: 'disputed_at',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.disputed_at
              ? moment(record.disputed_at).format('HH:mm:ss DD/MM/YYYY')
              : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_code',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: 'const_column_dispute_people',
      dataIndex: 'request_user_name',
      key: 'request_user_name',
    },
    {
      title: 'const_column_dispute_recipient',
      dataIndex: 'receiver_name',
      key: 'receiver_name',
    },
    {
      title: 'const_column_local',
      dataIndex: 'amount',
      align: 'right',
      render: (value: any) => (
        <strong className="primary-color">{value}</strong>
      ),
      key: 'amount',
    },
    {
      title: 'const_column_status',
      dataIndex: 'dispute_handled',
      key: 'dispute_handled',
      render: (value: any, record: any, index: number) => {
        const status = STATUS_DESPUTE.find(
          (item) => item.value === record.dispute_handled,
        );
        return (
          <>
            <Tag
              color={
                record?.dispute_handled === StatusDespute.DISPUTE
                  ? 'warning'
                  : record?.dispute_handled === StatusDespute.REFUND
                  ? 'purple'
                  : 'green'
              }
            >
              {status?.name}
            </Tag>
          </>
        );
      },
    },
    {
      title: 'const_column_action',
      dataIndex: 'custom',
      align: 'center',
      width: '10%',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.activeButton}>
            {record.dispute_handled === StatusDespute.DISPUTE && (
              <>
                <Tooltip title="Hoàn điểm">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handletRefuseUser(
                        record.transfer_id,
                        StatusDespute.REFUND,
                      )
                    }
                  >
                    <RollbackOutlined className="red-color" />
                  </div>
                </Tooltip>
                <Tooltip title="Chuyển điểm">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAcceptUser(
                        record.transfer_id,
                        StatusDespute.TRANSFER,
                      )
                    }
                  >
                    <RedoOutlined className="primary-color" />
                  </div>
                </Tooltip>
              </>
            )}

            <Tooltip title="Xem">
              <a
                style={{ cursor: 'pointer' }}
                href={`/dispute_detail/${record?.transfer_id}`}
              >
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        className={styles.searchForm}
        initialValues={{
          type: StatusDespute.DISPUTE,
        }}
        layout="inline"
      >
        <Form.Item name="value" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo người chuyển/nhận, ID giao dịch"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="type" className={styles.searchItem}>
          <Select onChange={submit} allowClear placeholder="Loại giao dịch">
            {STATUS_DESPUTE.map((item, i) => (
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            // locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            rowKey={(item) => item?.transfer_id}
          />
        )}
      </div>
    </>
  );
};

export default DisputeLocabonus;
