import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import { BusinessType, EnterpriseBizPerDay } from '@/utils/enum';
import moment from 'moment';

export enum TYPE_ENTERPRISE {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS_HOUSEHOLD = 'BUSINESS_HOUSEHOLD',
  COMPANY = 'COMPANY',
}
interface Result {
  total: number;
  list: any[];
}
export interface IUpdateStore {
  username?: string;
  name?: string;
  email?: string;
  phone?: string;
  display_phone?: string;
  address?: string;
  lat?: number;
  long?: number;
  province_id?: string;
  business_type?: BusinessType;
  ref_code?: string;
  logo?: string;
  description?: string;
  confirm_password?: string;
  enter_password?: string;
}
export interface IUpdateBrand {
  brand_name: string;
  email: string;
  phone: string;
  owner_name: string;
  address: string;
  identity_front: string;
  identity_backside: string;
  license_image: string;
  tax_code: string;
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    name: string;
    toDate: string[];
    verify_status?: string;
    biz_per_day: EnterpriseBizPerDay;
  },
): Promise<Result> => {
  if (formData.name === undefined) {
    formData.name = '';
  }
  if (formData.verify_status === undefined) {
    delete formData['verify_status'];
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `mutation {
      admin_get_list_enterprise(input: { page_index: ${current}, page_size: ${pageSize}, filter: "${
      formData.name
    }", end_time: "${toDate}", start_time: "${fromDate}", 
    ${formData.verify_status ? `verify_status: ${formData.verify_status}` : ''}
		${
      formData.biz_per_day === EnterpriseBizPerDay.GT_30
        ? `biz_per_day_gt: 30,
			biz_per_day_lt: 9999
			`
        : formData.biz_per_day === EnterpriseBizPerDay.LT_30
        ? `biz_per_day_gt: 0,
			biz_per_day_lt: 30`
        : ''
    }
  }) {
        data {
          enterprise_id
          name
          email
          phone
          created_at
          status
          ref_username
          verify_status
					requestCount
        }
        total
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.admin_get_list_enterprise?.data?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current - 1) * pageSize + (index + 1),
          name: e.name,
          create_at: formatTime(e.create_at),
        }),
      );
      return {
        list: result,
        total: res?.data?.admin_get_list_enterprise?.total,
      };
    },
  );
};

export const deleteBusiness = (id: any) => {
  const query = `
mutation {
  delete_enterprise(id: ${id}) {
    message
  }
}


  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const getListLinkAccount = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    search: string;
    toDate: string[];
    status: string;
  },
) => {
  const params: any = {
    page_index: current,
    page_size: pageSize,
  };
  if (!!formData.search) {
    params.filter = formData.search;
  }
  if (Array.isArray(formData.toDate)) {
    params.start_time = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.end_time = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  if (!!formData.status) {
    params.status = formData.status;
  }
  return privateRequest(request.get, API_PATH.LIST_LINK_ACCOUNT, {
    params,
  }).then((response) => {
    return {
      list: response.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: response?.total,
    };
  });
};

export const unlinkAccountEnterprise = (id: number, reason: string) => {
  return privateRequest(request.put, API_PATH.UNLINK_ACCOUNT + '/' + `${id}`, {
    data: { reason },
  });
};

export const getListStore = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search?: string;
    toDate?: string[];
    status?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.search) {
    params.search = formData.search;
  }
  if (!!Array.isArray(formData.toDate)) {
    params.start_time_range = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.end_time_range = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  if (!!formData.status) {
    params.verify_status = formData.status;
  }
  return privateRequest(request.get, API_PATH.LIST_BRAND, { params }).then(
    (response) => {
      return {
        list: response.data?.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + (index + 1),
        })),
        total: response?.data?.total,
      };
    },
  );
};

export const getListBrand = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search?: string;
    toDate?: string[];
    status?: string;
  },
) => {
  const params: any = {
    filter_by: 'brand',
    page: current,
    limit: pageSize,
  };
  if (!!formData.search) {
    params.search = formData.search;
  }
  if (!!Array.isArray(formData.toDate)) {
    params.start_time_range = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.end_time_range = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  if (!!formData.status) {
    params.verify_status = formData.status;
  }
  return privateRequest(request.get, API_PATH.LIST_BRAND, { params }).then(
    (response) => {
      return {
        list: response.data?.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + (index + 1),
        })),
        total: response?.data?.total,
      };
    },
  );
};

export const getDetailStore = (id: number) => {
  return privateRequest(request.get, API_PATH.DETAIL_STORE + '/' + `${id}`);
};

export const getDetailBrand = (id: number) => {
  return privateRequest(request.get, API_PATH.BRAND + '/' + `${id}`);
};

export const updateStoreService = (id: number, input: IUpdateStore) => {
  return privateRequest(request.put, API_PATH.DETAIL_STORE + '/' + `${id}`, {
    data: input,
  });
};

export const updateDetailBrand = (id: number, input: IUpdateBrand) => {
  return privateRequest(request.put, API_PATH.BRAND + '/' + id, {
    data: input,
  });
};

export const approveStoreService = (
  id: number,
  input: {
    confirm: boolean;
    reason?: string;
  },
) => {
  return privateRequest(request.put, API_PATH.APPROVE_STORE + '/' + `${id}`, {
    data: input,
  });
};

export const approveBrandService = (
  id: number,
  input: {
    confirm: boolean;
    reason?: string;
  },
) => {
  return privateRequest(request.put, API_PATH.APPROVE_BRAND + '/' + `${id}`, {
    data: input,
  });
};
