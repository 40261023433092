import React, { useEffect } from 'react';
import {
  Form,
  Space,
  Button,
  message,
  Row,
  // DatePicker,
} from 'antd';
import { history, useLocation } from 'umi';

import styles from './index.less';
import { useRequest } from 'ahooks';
import {
  ICreateGift,
  releaseVoucherAsGift,
  requestCreateGift,
  TypeGift,
} from '../service';
import FormGift from '../Components/FormGift';
import { ENVIRONMENTS } from '@/utils/constant';
import { checkURL } from '@/utils/apis/common';

export default () => {
  const [form] = Form.useForm();
  const [dataForm, setDataForm] = React.useState<ICreateGift>();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [fileListCrop, setfileListCrop] = React.useState<any[]>([]);
  const location: any = useLocation();
  const getDataForm = (name: string, value: any) => {
    setDataForm((prev: any) => ({ ...prev, [name]: value }));
  };
  const onFinish = (val: any) => {
    const payload = {
      ...val,
      image: ENVIRONMENTS.BUCKET_NAME + '/' + val?.image?.[0]?.response?.path,
      url_cropping_image: val?.url_cropping_image?.[0]?.response?.url,
      puzzle_gifts: val?.puzzle_gifts?.map((item: any) => ({
        ...item,
        image: item.image?.[0]?.response?.url
          ? `${item.image?.[0]?.response?.url}${item.image?.[0]?.response?.path}`
          : item.image,
      })),
      user_receive_gift: dataForm?.set_all_user
        ? []
        : dataForm?.user_receive_gift,
      status: val.status ? 'ACTIVE' : 'NOT_ACTIVE',
      set_all_user: dataForm?.set_all_user,
      voucher: {
        ...dataForm?.voucher,
        quantity: +val?.voucher?.quantity,
      },
    };
    const payloadGiftVoucherLocamos = {
      ...val,
      limit: +val.limit,
      image: ENVIRONMENTS.BUCKET_NAME + '/' + val?.image?.[0]?.response?.path,
      puzzle_gifts: val?.puzzle_gifts?.map((item: any) => ({
        ...item,
        image:
          ENVIRONMENTS.BUCKET_NAME + '/' + item?.image?.[0]?.response?.path,
      })),
      user_receive_gift: dataForm?.set_all_user
        ? []
        : dataForm?.user_receive_gift,
      status: val.status ? 'ACTIVE' : 'NOT_ACTIVE',
      set_all_user: dataForm?.set_all_user,
    };
    if (!!dataForm?.enterprise_create) {
      payload.enterprise_create = {
        business_id: dataForm?.enterprise_create.enterprise_id,
        business_name: dataForm?.enterprise_create.name,
        business_address: dataForm?.enterprise_create?.address,
        business_mobile: dataForm?.enterprise_create?.phone,
        business_image: dataForm?.enterprise_create?.enterprise_image,
        business_longitude: dataForm?.enterprise_create?.long,
        business_latitude: dataForm?.enterprise_create?.lat,
      };
    }
    if (dataForm?.type === TypeGift.GIFT_VOUCHER_LOCAMOS) {
      releaseVoucherGift.run(payloadGiftVoucherLocamos);
    } else {
      // console.log(payload);
      onCreateGift.run(payload);
    }
  };
  const releaseVoucherGift = useRequest(releaseVoucherAsGift, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
      history.push('/gift-building');
    },
    onError: (err: any) => {
      message.error('Lỗi hệ thống');
    },
  });

  const onCreateGift = useRequest(
    (payload: ICreateGift) => {
      return requestCreateGift(payload);
    },
    {
      manual: true,
      onSuccess: (res) => {
        if (!res.data && !!res?.errors) {
          message.error(res?.errors?.[0]?.message);
        } else if (res?.data?.enterprise_create_gift?.statusCode === 400) {
          message.error(res?.data?.enterprise_create_gift?.message);
        } else {
          message.success('Thành công');
          if (location.query?.is_enterprise === 'true') {
            history.push('/gift-building?is_enterprise=true');
          } else {
            history.push('/gift-building');
          }
        }
      },
      onError: (err: any) => {
        message.error('Lỗi hệ thống');
      },
    },
  );
  useEffect(() => {
    let defaultData = {
      type: TypeGift.GIFT_LOCABONUS,
      set_all_user: true,
      address_type: 'OTHER',
      limit_time: 60,
      status: true,
      qr_require: false,
    };
    setDataForm((prev: any) => ({ ...prev, ...defaultData }));
  }, []);
  return (
    <div className={styles.createBuilding}>
      <Space
        onClick={() => history.push('/gift-building')}
        className={styles.titlePage}
        size={5}
        align="center"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="left"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
        </svg>
        Tạo quà tặng
      </Space>
      <div className={styles.formInfoGeneral}>
        <Form
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          className={styles.formSearch}
          scrollToFirstError={true}
          layout="vertical"
          initialValues={{
            type: TypeGift.GIFT_LOCABONUS,
            set_all_user: true,
            address_type: 'OTHER',
            limit_time: 60,
            isEdit: true,
            isEditPuzzle: true,
            status: true,
            qr_require: true,
          }}
        >
          <FormGift
            fileList={fileList}
            fileListCrop={fileListCrop}
            setFileList={setFileList}
            setfileListCrop={setfileListCrop}
            getData={dataForm}
            setData={getDataForm}
            form={form}
            UserDetail={dataForm}
            onChange={setDataForm}
          />
          <Row justify="center" className={styles.submitGroup}>
            <Button
              className="btn-submit"
              danger
              onClick={() => history.push('/gift-building')}
            >
              Huỷ
            </Button>
            <Button
              className="btn-submit"
              type="primary"
              htmlType="submit"
              loading={onCreateGift.loading}
            >
              Lưu
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};
