import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Tabs, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useIntl, useLocation, useParams, useRequest } from 'umi';
import KycInfo from './Components/KycInfo';
import PersonalInfo from './Components/PersonalInfo';
import PickupHistory from './Components/PickupHistory';
import PointLocaBonus from './Components/PointLocaBonus';
import SystemTree from './Components/SystemTree';
import UpdateHistory from './Components/UpdateHistory';
import styles from './index.less';
import { getListNation, getListOrgs, getOneUser } from './service';
import MeetHistory from './Components/MeetHistory';
import DeviceLogin from './Components/DeviceLogin';
import CallHistory from './Components/CallHistory';
import LivenessProfile from './Components/LivenessProfile';

interface InfoAccount {
  userId: string;
  email: string;
  full_name: string;
  phone: string;
  gender: string;
  status: string;
  date_of_birth?: any;
  address: string;
  nation: string;
  organization: string;
  date_created: any;
  organizationId: any;
  nationId: any;
  apple_id: string;
  google_id: string;
  twitter_id: string;
  facebook_id: string;
  is_blocked_transfer: boolean;
  meet_count_default: number;
  meet_count_membership_weekly: number;
  meet_count_bonus: number;
  membership_expired: string;
  is_membership: boolean;
  checkpoint_reason: string;
  auth_phone: string;
  warning_biz_count: number;
  url_liveness: string;
  limit_transfer: Object;
}
interface HistoryUpdate {
  stt?: any;
  dayUpdate: string;
  infoUpdate: string;
  byUpdate: string;
}
const Users = () => {
  const params = useParams<{ id: string }>();
  const location: any = useLocation();
  const [activeTabs, setActiveTabs] = React.useState(1);
  const [kycInfo, setKycInfo] = useState<any>(null);
  const [infoAccount, setInfoAccount] = useState<InfoAccount | null>(null);
  const [userChildren, setUserChildren] = useState<any>([]);
  const [orgs, setOrgs] = useState<any>(null);
  const [nation, setNation] = useState<any>(null);
  useEffect(() => {
    if (location?.query?.search) {
      setActiveTabs(5);
    }
  }, [location]);
  const { formatMessage } = useIntl();

  const getDataUser = useRequest(getOneUser, {
    // manual: true,
    defaultParams: [params?.id],
    onSuccess(res: any) {
      if (res) {
        const dataRes: InfoAccount = {
          auth_phone: res?.auth_phone,
          full_name: res.user_profile?.full_name,
          phone: res.phone,
          email: res.email,
          status: res.status,
          userId: res.id,
          gender: res.user_profile?.gender,
          date_of_birth: res.user_profile?.date_of_birth
            ? moment(res.user_profile?.date_of_birth)
            : '',
          address: res.user_profile?.address,
          date_created: res.user_profile?.date_created
            ? moment(res.user_profile?.date_created).format('DD/MM/YYYY')
            : '',
          nation: res.user_profile?.nation?.id,
          organization: res.user_profile?.organization?.id,
          nationId: res.user_profile?.nation_id,
          organizationId: res.user_profile?.organization_id,
          apple_id: res.apple_id,
          google_id: res.google_id,
          twitter_id: res.twitter_id,
          facebook_id: res.facebook_id,
          is_blocked_transfer: res?.is_blocked_transfer,
          meet_count_default: res?.meet_count_default,
          meet_count_bonus: res?.meet_count_bonus,
          meet_count_membership_weekly: res?.meet_count_membership_weekly,
          is_membership: res?.is_membership,
          membership_expired: res?.membership_expired,
          checkpoint_reason: res?.checkpoint_reason,
          warning_biz_count: res?.warning_biz_count,
          url_liveness: res?.user_profile?.url_liveness,
          limit_transfer: res?.limit_transfer,
        };
        setInfoAccount(dataRes);
        setKycInfo(res.user_identity);
        setUserChildren(res.children || []);
        requestOrgs.run();
        requestNation.run();
      }
    },
    onError(err: any) {
      if (err?.data) {
        if (err?.data?.statusCode === 403) message.error('Không có quyền');
        else {
          message.error('Có lỗi xảy ra!');
        }
      }
    },
  });
  const requestOrgs = useRequest(getListOrgs, {
    manual: true,
    onSuccess: (data: any) => {
      setOrgs(data);
    },
  });
  const requestNation = useRequest(getListNation, {
    manual: true,
    onSuccess: (data: any) => {
      setNation(data);
    },
  });

  // useEffect(() => {
  //   if (params?.id) {
  //     getDataUser.run(params?.id);
  //     history.run(params?.id);
  //     requestOrgs.run();
  //     requestNation.run();
  //   } else {
  //     setInfoAccount(null);
  //   }
  //   if (location?.query?.search) {
  //     setActiveTabs(5);
  //   }
  // }, [params.id, location]);

  const renderTab = () => {
    switch (activeTabs) {
      case 1:
        return (
          <PersonalInfo
            infoAccount={infoAccount}
            getDataUser={getDataUser}
            orgs={orgs}
            nation={nation}
          />
        );
      case 2:
        return <UpdateHistory />;
      case 3:
        return <PickupHistory />;
      case 4:
        return <SystemTree />;
      case 5:
        return <KycInfo getDataUser={getDataUser} />;
      case 6:
        return (
          <PointLocaBonus
            refreshInfo={getDataUser.refresh}
            transferLimit={infoAccount?.limit_transfer}
          />
        );
      case 7:
        return (
          <MeetHistory
            totalMeetCount={
              infoAccount
                ? infoAccount.meet_count_default +
                  infoAccount.meet_count_membership_weekly +
                  infoAccount.meet_count_bonus
                : 0
            }
          />
        );
      case 8:
        return <CallHistory />;
      case 9:
        return <DeviceLogin />;
      case 10:
        return <LivenessProfile userId={+params?.id} />;
      default:
        break;
    }
  };
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/user-management" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {formatMessage({ id: 'navigation_user_info' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs
          defaultActiveKey={location?.query?.search ? '5' : '1'}
          onChange={(key) => {
            setActiveTabs(parseInt(key));
          }}
          className="tab-on-card"
        >
          <Tabs.TabPane tab="Thông tin cá nhân" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử cập nhật" key="2"></Tabs.TabPane>
          {/* <Tabs.TabPane tab="Lịch sử nhặt vật phẩm" key="3"></Tabs.TabPane> */}
          <Tabs.TabPane tab="Thống kê cây hệ thống" key="4"></Tabs.TabPane>
          <Tabs.TabPane tab="Thông tin KYC" key="5"></Tabs.TabPane>
          <Tabs.TabPane tab="Điểm LocaBonus" key="6"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử meet" key="7"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử gọi" key="8"></Tabs.TabPane>
          <Tabs.TabPane tab="Thiết bị đăng nhập" key="9"></Tabs.TabPane>
          {infoAccount?.url_liveness && (
            <Tabs.TabPane tab="Ảnh liveness" key="10"></Tabs.TabPane>
          )}
        </Tabs>
        <Card className="card-after-tab" size="small">
          {renderTab()}
        </Card>
      </div>
    </>
  );
};

export default Users;
