import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  TRANSFER_GIFT_STATUS,
  TRANSFER_VOUCHER_TYPE,
  getTransferStatistics,
} from '../service';
import { useAntdTable } from 'ahooks';
import dayjs from 'dayjs';
import { OrderedListOutlined } from '@ant-design/icons';
const TransferStatistic = () => {
  const [transferType, setTransferType] = useState(
    TRANSFER_VOUCHER_TYPE.LOCAMOS,
  );
  const [isOpenListCode, setIsOpenListCode] = useState(false);
  const [listCodeTransfer, setListCodeTransfer] = useState([]);
  const renderStatusVoucher = (status: string) => {
    switch (status) {
      case TRANSFER_GIFT_STATUS.SUCCESS:
        return <Tag color="green">Thành công</Tag>;
      case TRANSFER_GIFT_STATUS.DISPUTE:
        return <Tag color="gray">Tranh chấp</Tag>;
      case TRANSFER_GIFT_STATUS.HANDLE_BACK:
        return <Tag color="purple">Hoàn voucher</Tag>;
      case TRANSFER_GIFT_STATUS.REQUEST:
        return <Tag color="gray">Tạo yêu cầu chuyển</Tag>;
      case TRANSFER_GIFT_STATUS.RECEIVER_CONFIRM:
        return <Tag color="green">Người nhận xác nhận</Tag>;
      case TRANSFER_GIFT_STATUS.RECEIVER_REJECT:
        return <Tag color="red">Người nhận từ chối</Tag>;
      case TRANSFER_GIFT_STATUS.TRANSFER_FAILED:
        return <Tag color="red">Thất bại</Tag>;
      case TRANSFER_GIFT_STATUS.HANDLE_TRANSFER:
        return <Tag color="green">Chuyển voucher</Tag>;

      default:
        return <Tag color="gray">未知</Tag>;
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '5%',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Người chuyển voucher',
      dataIndex:
        transferType === TRANSFER_VOUCHER_TYPE.LOCAMOS
          ? 'request_user'
          : 'user',
    },
    transferType === TRANSFER_VOUCHER_TYPE.LOCAMOS
      ? {
          title: 'Người nhận voucher',
          dataIndex: 'receiver',
        }
      : {},
    {
      title: 'Tên voucher',
      dataIndex: 'voucher',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => (
        <>
          {transferType === TRANSFER_VOUCHER_TYPE.LOCAMART ? (
            value ? (
              <Tag color="green">Thành công</Tag>
            ) : (
              <Tag color="red">Thất bại</Tag>
            )
          ) : (
            renderStatusVoucher(value)
          )}
        </>
      ),
    },
    transferType === TRANSFER_VOUCHER_TYPE.LOCAMART
      ? {
          title: 'Mã voucher',
          dataIndex: 'code',
          render: (value) => (
            <>
              <Tooltip title="Xem tất cả mã voucher">
                <OrderedListOutlined
                  className="purple-color"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setListCodeTransfer(
                      value?.map((item: string) => ({
                        code: item,
                      })),
                    );
                    setIsOpenListCode(true);
                  }}
                />
              </Tooltip>
            </>
          ),
        }
      : {},
  ];
  const columnsCode: ColumnsType<any> = [
    {
      title: 'STT',
      render: (_, rc, i) => <>{i + 1}</>,
    },
    {
      title: 'Mã voucher',
      dataIndex: 'code',
    },
  ];
  const [form] = Form.useForm();
  const { tableProps, run, search } = useAntdTable(getTransferStatistics, {
    manual: true,
    defaultParams: [
      {
        pageSize: 10,
        current: 1,
        type: transferType,
      },
      {},
    ],
    form,
  });
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm ID/tên user"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );

  useEffect(() => {
    run(
      {
        pageSize: 10,
        current: 1,
        type: transferType,
      },
      {},
    );
  }, [transferType]);
  return (
    <div>
      <Tabs
        style={{
          padding: '0 24px',
        }}
        defaultActiveKey={transferType}
        onChange={(key: any) => {
          setTransferType(key);
        }}
      >
        <Tabs.TabPane
          tab="Locamos"
          key={TRANSFER_VOUCHER_TYPE.LOCAMOS}
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Locamart"
          key={TRANSFER_VOUCHER_TYPE.LOCAMART}
        ></Tabs.TabPane>
      </Tabs>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
      {isOpenListCode && (
        <Modal
          footer={null}
          onCancel={() => setIsOpenListCode(false)}
          open={isOpenListCode}
          title="Danh sách mã voucher"
        >
          <Table columns={columnsCode} dataSource={listCodeTransfer} />
        </Modal>
      )}
    </div>
  );
};

export default TransferStatistic;
