import React, { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Tooltip, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'umi';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import { IGroupUserData, getListGroupUser } from '../service';
import AddGroupUserModal from './AddGroupUserModal';
import EditGroupUserModal from './EditGroupUserModal';
const GroupUser = () => {
  const [form] = Form.useForm();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroupUserData>();
  const history = useHistory();
  const { tableProps, search, refresh } = useAntdTable(getListGroupUser, {
    form,
  });
  const { submit } = search;
  const onSelectGroup = (group: IGroupUserData) => {
    setSelectedGroup(group);
    setIsOpenUpdateModal(true);
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'name',
    },
    {
      title: 'Số lượng user',
      dataIndex: 'totalUser',
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '10%',
      dataIndex: 'id',
      render: (value, record) => (
        <>
          <Tooltip title="Xem">
            <EyeOutlined
              onClick={() => onSelectGroup(record)}
              className="color-purple"
              style={{
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder={'Tìm kiếm theo tên nhóm user'}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      <Row align="middle" justify="end">
        <Button onClick={() => setIsOpenAddModal(true)}>Tạo nhóm user</Button>
      </Row>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table {...tableProps} columns={columns} rowKey={(item) => item.id} />
      </div>
      {isOpenAddModal && (
        <AddGroupUserModal
          refresh={refresh}
          isOpen={isOpenAddModal}
          setIsOpen={setIsOpenAddModal}
        />
      )}
      {isOpenUpdateModal && (
        <EditGroupUserModal
          refresh={refresh}
          isOpen={isOpenUpdateModal}
          setIsOpen={setIsOpenUpdateModal}
          dataGroup={selectedGroup}
        />
      )}
    </div>
  );
};

export default GroupUser;
