import MapReaflat from '@/components/MapReaflat';
import SelectKiot from '@/components/SelectKiotViet';
import SelectNFT from '@/components/SelectNFT';
import { API_PATH } from '@/utils/apis';
import {
  formatInputNumber,
  isLatitude,
  isLongitude,
} from '@/utils/apis/common';
import {
  AVERAGE_INVOICE,
  BUSINESSS_SCALE,
  BUSINESS_TYPE,
  ENTERPRISE_TYPE,
  ENVIRONMENTS,
} from '@/utils/constant';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { paternPhone, paternUrl, patternInt } from '@/utils/patern';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useLoadScript } from '@react-google-maps/api';
import { useToggle } from 'ahooks';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  Upload,
  UploadFile,
  message,
  Table,
  Tooltip,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import { useHistory, useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import {
  UpdateEnterpriseInput,
  WithdrawVoucherEnterprise,
  adminVerifyEnterprise,
  updateBranchPartner,
  updateBusiness,
  BranchPartner as IBranch,
} from '../service';
import AddInviter from './Dialog/AddInviter';
import { ColumnsType } from 'antd/lib/table';
import { EnterpriseType } from '@/utils/enum';
import { getListOrgs } from '@/pages/UsersInfo/service';
const { Option } = Select;
const BranchPartner = ({
  isOpen,
  setIsOpen,
  data,
  enterprise_id,
  updateBranches,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: any[];
  enterprise_id: number;
  updateBranches: (id: number, input: IBranch[]) => Promise<any>;
}) => {
  const handleDeleteBranch = (i: number) => {
    data.splice(i, 1);

    return Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa chi nhánh không?',
      okText: 'Xác nhận',
      okType: 'primary',
      cancelText: 'Huỷ',
      onOk: () => {
        updateBranches(enterprise_id, data);
      },
    });
  };
  const [formAddBranch] = Form.useForm();
  const handleAddBranch = () => {
    const onSubmitBranch = (value: any) => {
      data.push({
        ...value,
      });
      formAddBranch.resetFields();
      updateBranches(enterprise_id, data);
    };
    Modal.confirm({
      icon: null,
      title: 'Thêm chi nhánh',
      width: 500,
      content: (
        <Form
          id="formAddBranch"
          layout="horizontal"
          form={formAddBranch}
          onFinish={onSubmitBranch}
          labelCol={{
            span: 6,
          }}
          labelWrap
          labelAlign="left"
        >
          <Form.Item
            label="Tên chi nhánh"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên chi nhánh" />
          </Form.Item>
          <Form.Item
            label="Địa chỉ"
            name="address"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập địa chỉ chi nhánh" />
          </Form.Item>
          <Form.Item
            label="Lat"
            name="lat"
            rules={[
              {
                validator(_, value) {
                  if (!!value) {
                    if (!isLatitude(value)) {
                      return Promise.reject(
                        new Error('Vui lòng nhập đúng định dạng Latitude'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject('Vui lòng nhập');
                },
              },
            ]}
          >
            <InputNumber
              style={{
                width: '100%',
              }}
              placeholder="Lat"
              controls={false}
            />
          </Form.Item>
          <Form.Item
            label="Long"
            name="long"
            rules={[
              {
                validator(_, value) {
                  if (!!value) {
                    if (!isLongitude(value)) {
                      return Promise.reject(
                        new Error('Vui lòng nhập đúng định dạng Longitude'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject('Vui lòng nhập');
                },
              },
            ]}
          >
            <InputNumber
              style={{
                width: '100%',
              }}
              placeholder="Long"
              controls={false}
            />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formAddBranch',
      },
      okText: 'Lưu',
      cancelText: 'Huỷ',
      onCancel() {
        formAddBranch.resetFields();
      },
      onOk() {
        const value = formAddBranch.getFieldsValue();
        if (!isLatitude(value.lat) || !isLongitude(value.long)) {
          return Promise.reject();
        }
        if (!Object.keys(value).every((key) => value[key])) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (_, record, i) => {
        return (
          <Tooltip title="Xoá">
            <DeleteOutlined
              className="red-color"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleDeleteBranch(i)}
            />
          </Tooltip>
        );
      },
    },
  ];
  return (
    <Modal
      width={600}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      title="Danh sách chi nhánh"
    >
      <Row
        justify="end"
        style={{
          marginBottom: '10px',
        }}
      >
        <Button type="primary" onClick={() => handleAddBranch()}>
          Thêm chi nhánh
        </Button>
      </Row>
      <Table columns={columns} dataSource={data} rowKey="stt" />
    </Modal>
  );
};

const PersonalInfo = ({ infoAccount, refresh }: any) => {
  ///MAP

  const [pickerPosition, setPickerPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  } | null>(null);
  const [fileList, setFileList] = useState<{
    enterprise_image: UploadFile[];
    license_image: UploadFile[];
    identity_backside: UploadFile[];
    identity_front: UploadFile[];
    passport_image: UploadFile[];
    bill_template: UploadFile[];
    qr_transfer: UploadFile[];
    logo: UploadFile[];
  }>({
    enterprise_image: [],
    license_image: [],
    identity_backside: [],
    identity_front: [],
    passport_image: [],
    bill_template: [],
    qr_transfer: [],
    logo: [],
  });
  const [enterpriseType, setEnterpriseType] = useState<EnterpriseType>(
    EnterpriseType.NORMAL,
  );
  const [selectedNFT, setSelectedNFT] = useState<{
    value: number;
    label: string;
  }>();
  const [selectedKiot, setSelectedKiot] = useState<{
    value: number;
    label: string;
  }>();
  const [identityType, setIdentityType] = useState(1);
  const [isRegisterVoucher, setIsRegisterVoucher] = useState<boolean>(false);
  // const [isImportVoucher, setIsImportVoucher] = useState<boolean>(false);
  const [isOpenBranchView, setOpenBranchView] = useState<boolean>(false);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const requestOrgs = useRequest(getListOrgs);
  const fillData = () => {
    if (!infoAccount) return;
    setEnterpriseType(infoAccount?.type);
    if (!!infoAccount && !!infoAccount?.lat && !!infoAccount?.lng) {
      setPickerPosition({
        customName: infoAccount?.address,
        lat: infoAccount?.lat,
        lng: infoAccount?.lng,
      });
    }

    if (infoAccount.logo) {
      setFileList({
        ...fileList,
        logo: [
          {
            uid: '-10',
            name: 'logo.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.logo}`,
          },
        ],
      });
    }

    if (!!infoAccount && !!infoAccount.bill_template) {
      if (infoAccount?.passport_image) {
        setIdentityType(2);
        setFileList((state) => ({
          ...state,
          passport_image: [
            {
              uid: '-5',
              name: 'image4.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.passport_image
              }`,
            },
          ],
        }));
      } else {
        setFileList((state) => ({
          ...state,
          identity_backside: [
            {
              uid: '-3',
              name: 'image2.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.identity_backside
              }`,
            },
          ],
          identity_front: [
            {
              uid: '-4',
              name: 'image3.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.identity_front
              }`,
            },
          ],
        }));
      }
      setFileList((state) => ({
        ...state,
        enterprise_image: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${
              ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.enterprise_image
            }`,
          },
        ],
        license_image: [
          {
            uid: '-2',
            name: 'image1.png',
            status: 'done',
            url: `${
              ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.license_image
            }`,
          },
        ],

        bill_template: [
          {
            uid: '-6',
            name: 'image5.png',
            status: 'done',
            url: `${
              ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.bill_template
            }`,
          },
        ],
      }));
    } else if (
      !!infoAccount &&
      !!infoAccount.enterprise_image &&
      !infoAccount.bill_template
    ) {
      if (infoAccount?.passport_image) {
        setIdentityType(2);
        setFileList((state) => ({
          ...state,
          passport_image: [
            {
              uid: '-5',
              name: 'image4.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.passport_image
              }`,
            },
          ],
        }));
      } else {
        setFileList((state) => ({
          ...state,
          identity_backside: [
            {
              uid: '-3',
              name: 'image2.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.identity_backside
              }`,
            },
          ],
          identity_front: [
            {
              uid: '-4',
              name: 'image3.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.identity_front
              }`,
            },
          ],
        }));
      }
      setFileList((state) => ({
        ...state,
        enterprise_image: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${
              ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.enterprise_image
            }`,
          },
        ],
        license_image: [
          {
            uid: '-2',
            name: 'image1.png',
            status: 'done',
            url: `${
              ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.license_image
            }`,
          },
        ],
      }));
    }
    if (!!infoAccount && !!infoAccount.qr_transfer) {
      setFileList((state) => ({
        ...state,
        qr_transfer: [
          {
            uid: '-3',
            name: 'image2.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + infoAccount?.qr_transfer}`,
          },
        ],
      }));
    }
    if (infoAccount?.is_locacafe) {
      setEnterpriseType(EnterpriseType.LOCACAFE);
      form.setFieldsValue({
        link_location: infoAccount?.link_location_name,
        kiotviet_id: infoAccount?.kiotviet_name,
      });
    }
    if (!!infoAccount && !!infoAccount.register_release_voucher) {
      setIsRegisterVoucher(true);
    }
    // if (infoAccount.partner_id) {
    // form.setFieldValue('import_partner', true);
    //   setIsImportVoucher(true);
    // }
  };

  React.useEffect(() => {
    fillData();
  }, [infoAccount]);

  const loadScript = useLoadScript({
    googleMapsApiKey: ENVIRONMENTS.DEFAULT_API_MAP as string,
    libraries: ['drawing', 'places'],
  });

  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );
    if (editable) {
      form.setFieldValue('province_id', province?.id);
    }
    setPickerPosition({
      lat: pos?.lat,
      lng: pos?.long,
      customName: pos?.name,
    });
  };

  const params = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const [editable, setEditable] = useToggle(false);
  const { t } = useTranslate();

  const updateRequest = useRequest(updateBusiness, {
    manual: true,
    onSuccess(data) {
      if (data?.errors) {
        if (data?.errors[0]?.message === 'account identity already exist') {
          message.error('Email/Số điện thoại đã tồn tại');
        } else if (
          data?.errors[0]?.extensions?.response?.message?.[0] ===
          'phone must be a valid phone number'
        ) {
          message.error('Số điện thoại không đúng định dạng');
        } else if (data?.errors[0]?.extensions?.exception?.code === 'P2002') {
          if (
            data?.errors[0]?.extensions?.exception?.meta?.target?.[0] ===
            'kiotviet_id'
          ) {
            message.error(
              'Điểm KiotViet đã được chọn. Vui lòng chọn KiotViet khác',
            );
          } else {
            message.error('NFT đã được chọn. Vui lòng chọn NFT địa điểm khác');
          }
        } else {
          message.error('Không có quyền');
        }
      } else {
        // history.push('/business');
        message.success(t('message_user_success'));

        refresh();
        setEditable.set(false);
      }
    },
    formatResult: (res) => res,
  });

  const updateBranches = useRequest(updateBranchPartner, {
    manual: true,
    onSuccess(result) {
      if (result?.errors) {
        message.error('Chi nhánh không hợp lệ');
      } else {
        message.success(t('message_user_success'));
      }
      refresh();
    },
    formatResult: (res) => res,
  });

  const withDrawVoucher = useRequest(WithdrawVoucherEnterprise, {
    manual: true,
    onSuccess(res) {
      message.success('Thu hồi voucher thành công');
      refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });

  const verifyEnterprise = useRequest(adminVerifyEnterprise, {
    manual: true,
    onSuccess(data) {
      if (data?.errors) {
        if (data?.errors[0]?.message) {
          message.error('Có lỗi xảy ra');
        } else {
          message.error('Không có quyền');
        }
      } else {
        // history.push('/business');
        message.success('Thành công');
        refresh();
      }
    },
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setPreviewImage(src);
    setPreviewOpen(true);
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);
  };

  const handleAcceptVerify = () => {
    Modal.confirm({
      icon: null,
      title: 'Phê duyệt tài khoản doanh nghiệp',
      content: `Bạn có chắc chắn muốn phê duyệt tài khoản doanh nghiệp này không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        verifyEnterprise.run({ status: true, enterprise_id: +params.id });
      },
    });
  };

  const handleWithdrawVoucher = () => {
    Modal.confirm({
      icon: null,
      title: 'Thu hồi voucher',
      content: `Bạn có chắc chắn muốn chuyển các voucher đang thả quà sang kho voucher? Hành động này sẽ không thể hoàn tác?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        withDrawVoucher.run(infoAccount?.enterprise_id);
      },
    });
  };

  const [formRefuse] = Form.useForm();
  const handleRefuseVerify = () => {
    const onSubmitCheckpoint = (value: any) => {
      verifyEnterprise.run({
        status: false,
        reason: value.reason,
        enterprise_id: +params.id,
      });
    };
    Modal.confirm({
      icon: null,
      title: 'Lý do từ chối xác thực tài khoản doanh nghiệp',
      content: (
        <Form
          id="formRefuse"
          layout="vertical"
          form={formRefuse}
          onFinish={onSubmitCheckpoint}
          scrollToFirstError={true}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
      },
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onCancel() {
        formRefuse.resetFields();
      },
      onOk() {
        if (formRefuse.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const onFinish = (values: any) => {
    delete values['enter_password'];
    const payload: UpdateEnterpriseInput = {
      ...values,
      description: values?.description?.includes(`\n`)
        ? `""${values.description?.replace(/"/g, '\\"')}""`
        : `${
            !!values.description ? values.description?.replace(/"/g, '\\"') : ''
          }`,
      enterprise_id: +params.id,
      address: pickerPosition?.customName ?? '',
      lat: pickerPosition?.lat,
      long: pickerPosition?.lng,
      logo: values.logo?.[0]?.response?.path ?? values.logo,
      link_location: values?.link_location?.value ?? infoAccount?.link_location,
      link_location_name: selectedNFT?.label,
      kiotviet_id: values?.kiotviet_id?.value ?? infoAccount?.kiotviet_id,
      kiotviet_name: selectedKiot?.label,
      enterprise_image:
        values.enterprise_image?.[0]?.response?.path ??
        infoAccount?.enterprise_image,
      license_image:
        values.license_image?.[0]?.response?.path ?? infoAccount?.license_image,
      identity_front:
        values.identity_front?.[0]?.response?.path ??
        infoAccount?.identity_front,
      identity_backside:
        values.identity_backside?.[0]?.response?.path ??
        infoAccount?.identity_backside,
      passport_image:
        values.passport_image?.[0]?.response?.path ??
        infoAccount?.passport_image,
      bill_template:
        values.bill_template?.[0]?.response?.path ?? infoAccount?.bill_template,
      qr_transfer:
        values.qr_transfer?.[0]?.response?.path ?? infoAccount?.qr_transfer,
    };
    if (!!values?.new_password) {
      payload.new_password = values?.new_password;
    }
    updateRequest.run(payload);
  };
  const [openModal, setOpenModal] = useState(false);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return loadScript.isLoaded ? (
    <>
      <Row justify="end">
        {infoAccount?.verify_status === 'PENDING' && (
          <>
            <Button
              type="primary"
              className={styles.btnEditEnterprise}
              onClick={() => handleAcceptVerify()}
            >
              Phê duyệt
            </Button>
            <Button
              danger
              className={styles.btnEditEnterprise}
              onClick={() => handleRefuseVerify()}
            >
              Từ chối
            </Button>
          </>
        )}
        {!infoAccount?.status && infoAccount?.register_release_voucher && (
          <Button
            type="primary"
            ghost
            danger
            className={styles.btnEditEnterprise}
            onClick={() => handleWithdrawVoucher()}
            disabled={!infoAccount?.can_withdraw}
          >
            Thu hồi voucher
          </Button>
        )}
        <Button
          className={styles.btnEditEnterprise}
          type="primary"
          ghost
          onClick={() => setOpenModal(true)}
        >
          {t('general_add_invite_code')}
        </Button>
        <Button
          className={styles.btnEditEnterprise}
          type="text"
          onClick={() => setEditable.set(true)}
          icon={<EditOutlined style={{ fontSize: 24 }} />}
        ></Button>
      </Row>
      {params.id && !infoAccount ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          className={styles.mainInfoUser}
          initialValues={infoAccount}
          autoComplete="off"
          onFinish={onFinish}
          labelWrap
          labelAlign="left"
          labelCol={{
            span: 5,
          }}
          scrollToFirstError={true}
        >
          <Form.Item label="Trạng thái">
            {infoAccount?.verified ? (
              <Tag color="green" icon={<CheckOutlined />}>
                Đã xác thực
              </Tag>
            ) : infoAccount?.verify_status === 'PENDING' ? (
              <Tag color="warning" icon={<ClockCircleOutlined />}>
                Đang chờ xác thực
              </Tag>
            ) : (
              <>
                <Tag color="red" icon={<CloseOutlined />}>
                  Từ chối xác thực
                </Tag>{' '}
              </>
            )}
          </Form.Item>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="name"
                label={t('const_column_business_name')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('const_column_business_name'),
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={t('const_column_business_name')}
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="phone"
                label="SĐT"
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: 'Số điện thoại',
                    }),
                  },
                  {
                    pattern: paternPhone,
                    message: t('error.patern', {
                      field: t('phone_number'),
                    }),
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="email"
                label={t('email')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('email'),
                    }),
                  },
                  {
                    type: 'email',
                    message: t('error.email', {
                      field: t('email'),
                    }),
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="new_password"
                label={t('password')}
                rules={[
                  // {
                  //   required: true,
                  //   message: t('error.require', {
                  //     field: t('password'),
                  //   }),
                  // },
                  {
                    min: 8,
                    max: 99,
                    message: t('error.password', {
                      field: t('password'),
                    }),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('password')}
                  autoComplete="off"
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="enter_password"
                label={t('enter_password')}
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                    required: form.getFieldValue('new_password') ? true : false,
                    message: t('error.require', {
                      field: t('new_password'),
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('password_no_match')));
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={t('enter_password')}
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="status"
            label={t('status')}
            style={{
              textAlign: 'start',
            }}
          >
            <Switch disabled={!editable} defaultChecked={infoAccount.status} />
          </Form.Item>

          <Row
            justify="space-between"
            style={{
              margin: '15px 0',
            }}
          >
            <h2>Thông tin doanh nghiệp</h2>
          </Row>

          <Form.Item name="tax_code" label={t('tax_code')}>
            <Input disabled={!editable} placeholder={t('tax_code_input')} />
          </Form.Item>
          <Form.Item name="brand_name" label={t('brand_name')}>
            <Input disabled={!editable} placeholder={t('brand_name')} />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'business_type' })}
            name="business_type"
          >
            <Select
              disabled={!editable}
              placeholder={t('business_type_input')}
              options={BUSINESS_TYPE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item label={formatMessage({ id: 'scale' })} name="scale">
            <Select
              disabled={!editable}
              placeholder={t('scale_input')}
              options={BUSINESSS_SCALE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'average_invoice' })}
            name="average_invoice"
          >
            <Select
              disabled={!editable}
              placeholder={t('average_invoice_input')}
              options={AVERAGE_INVOICE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'limit_to_give_lb' })}
            name="limit_to_give_lb"
            // rules={[
            //   {
            //     required: true,
            //     message: t('required'),
            //   },
            // ]}
          >
            <InputNumber
              disabled={!editable}
              controls={false}
              style={{
                width: '100%',
              }}
              formatter={formatInputNumber}
              addonAfter="VND"
              min={1}
            />
          </Form.Item>
          <Form.Item name="abaha_id" label={t('abaha_id')}>
            <Input disabled={!editable} placeholder={t('abaha_id')} />
          </Form.Item>
          <div
            style={{
              margin: '20px 0',
            }}
          >
            <Form.Item>
              <Checkbox disabled={!editable} value={true} checked={true}>
                Đăng ký làm địa điểm Locabiz
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_create_gift" valuePropName="checked">
              <Checkbox
                disabled={!editable}
                defaultChecked={false}
                checked={false}
              >
                Đăng ký thả quà
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="register_pickup_gift_location"
              valuePropName="checked"
            >
              <Checkbox
                disabled={!editable}
                defaultChecked={false}
                checked={false}
              >
                Đăng ký địa điểm nhận quà
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_release_voucher" valuePropName="checked">
              <Checkbox
                disabled={!editable}
                defaultChecked={false}
                checked={false}
                onChange={(e) => setIsRegisterVoucher(e.target.checked)}
              >
                Đăng ký phát hành voucher
              </Checkbox>
            </Form.Item>
            {/* <Form.Item name="import_partner" valuePropName="checked">
              <Checkbox
                disabled={!editable}
                defaultChecked={false}
                checked={false}
                onChange={(e) => setIsImportVoucher(e.target.checked)}
              >
                Import voucher đối tác
              </Checkbox>
            </Form.Item> */}
            <Form.Item name="type" label="Loại Biz">
              <Select
                disabled={!editable}
                options={ENTERPRISE_TYPE.map((item) => ({
                  label: t(item.name),
                  value: item.value,
                }))}
                onChange={(val) => setEnterpriseType(val)}
              />
            </Form.Item>
          </div>
          {enterpriseType !== EnterpriseType.NORMAL && (
            <>
              {enterpriseType === EnterpriseType.LOCACAFE && (
                <SelectNFT
                  propsInput={{ disabled: !editable }}
                  fromName="link_location"
                  onChange={(res: any) => setSelectedNFT(res)}
                  allowClear={true}
                  loadOnMount={true}
                />
              )}
              <SelectKiot
                type={enterpriseType}
                propsInput={{ disabled: !editable }}
                formItemName="kiotviet_id"
                onChange={(res: any) => setSelectedKiot(res)}
                allowClear={true}
              />
            </>
          )}
          <Form.Item
            className="uploadItem"
            name="logo"
            label="Logo"
            getValueFromEvent={(e) => normFile(e)}
          >
            <Upload
              disabled={!editable}
              accept=".png,.jpg,.jpeg"
              action={
                ENVIRONMENTS.API_URL +
                `${
                  ENVIRONMENTS.DEV_ENV ? API_PATH.UPLOAD_DEV : API_PATH.UPLOAD
                }`
              }
              onPreview={onPreview}
              fileList={fileList.logo}
              listType="picture-card"
              onChange={(val: any) => {
                setFileList((state: any) => ({
                  ...state,
                  logo: val.fileList,
                }));
              }}
              onRemove={() => {
                setFileList((state: any) => ({
                  ...state,
                  logo: [],
                }));
              }}
            >
              {fileList.logo.length < 1 && 'Tải lên'}
            </Upload>
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả ngắn"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={2} disabled={!editable} />
          </Form.Item>
          {!!infoAccount?.partner_id && (
            <>
              <Form.Item
                name="website_url"
                label="Link website"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: paternUrl,
                    message: 'Không đúng định dạng URL',
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
              <Form.Item
                name="online_url"
                label="Link mua hàng online"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Danh sách chi nhánh"
                className={styles.branches}
              >
                <Button
                  type="text"
                  disabled={!editable}
                  onClick={() => setOpenBranchView(true)}
                  className="primary-color"
                >
                  Xem chi tiết
                </Button>
              </Form.Item>
              <Form.Item
                name="can_use_qr_alias"
                label="Sử dụng voucher partner"
                style={{
                  textAlign: 'start',
                }}
              >
                <Switch
                  disabled={!editable}
                  defaultChecked={infoAccount.can_use_qr_alias}
                />
              </Form.Item>
            </>
          )}

          {/* {isRegisterVoucher && (
            <Form.Item
              name="is_not_use_voucher"
              label="Báo xấu voucher"
              valuePropName="checked"
            >
              <Switch
                disabled={!editable}
                defaultChecked={infoAccount.is_not_use_voucher}
              />
            </Form.Item>
          )} */}
          <Row>
            <Col xs={24}>
              <Form.Item
                // name="address"
                label={t('address')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('address'),
                    }),
                  },
                ]}
              >
                {/* @ts-ignore */}
                <Input
                  placeholder={t('address')}
                  value={pickerPosition?.customName}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Khu vực" name="province_id">
            <Select
              disabled={!editable}
              placeholder="Chọn khu vực"
              options={requestOrgs.data?.get_all_orgs?.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
          <Row
          // style={{
          //   textAlign: 'center',
          // }}
          >
            <Col xs={24}>
              <Form.Item>
                <MapReaflat
                  setPos={onSelectPos}
                  listPosition={[]}
                  loading={false}
                  mapItemDetail={infoAccount}
                />
                {pickerPosition ? (
                  <Row
                    gutter={16}
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    <Col span={12} className={styles.dialogFormItem}>
                      <Form.Item label="Lat">
                        <Input value={pickerPosition.lat} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={styles.dialogFormItem}>
                      <Form.Item label="Long">
                        <Input value={pickerPosition.lng} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Form.Item>
            </Col>
          </Row>

          <div className={styles.lisenceImage}>
            <Row align="bottom">
              <Col flex="1 0">
                <Form.Item
                  className="uploadItem"
                  name="enterprise_image"
                  getValueFromEvent={(e) => normFile(e)}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={!editable}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.enterprise_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: [],
                      }));
                    }}
                  >
                    {fileList.enterprise_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh chụp cửa hàng</p>
              </Col>
              <Col flex="1 0">
                <Form.Item
                  className="uploadItem"
                  name="license_image"
                  getValueFromEvent={(e) => normFile(e)}
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={!editable}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.license_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: [],
                      }));
                    }}
                  >
                    {fileList.license_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh đăng ký kinh doanh</p>
              </Col>
            </Row>

            <Row align="bottom">
              <Col flex="1 0">
                <Form.Item
                  className="uploadItem"
                  name="bill_template"
                  getValueFromEvent={(e) => normFile(e)}
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: infoAccount?.bill_template ? true : false,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={!editable}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.bill_template}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: [],
                      }));
                    }}
                  >
                    {fileList.bill_template.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh hóa đơn</p>
              </Col>
              <Col flex="1 0">
                <Form.Item
                  className="uploadItem"
                  name="qr_transfer"
                  getValueFromEvent={(e) => normFile(e)}
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: infoAccount?.qr_transfer ? true : false,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={!editable}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.qr_transfer}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: [],
                      }));
                    }}
                  >
                    {fileList.qr_transfer.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh QR chuyển khoản</p>
              </Col>
            </Row>
          </div>

          <h2 className={styles.subtitle}>Thông tin ngân hàng</h2>
          <Form.Item name="bank_account_name" label={t('bank_account_name')}>
            <Input
              disabled={!editable}
              placeholder={t('bank_account_name_input')}
            />
          </Form.Item>
          <Form.Item name="bank" label={t('bank')}>
            <Input disabled={!editable} placeholder={t('bank')} />
          </Form.Item>
          <Form.Item
            name="bank_account_number"
            label={t('bank_account_number')}
          >
            <Input
              disabled={!editable}
              placeholder={t('bank_account_number_input')}
            />
          </Form.Item>
          <h2 className={styles.subtitle}>Thông tin chủ doanh nghiệp</h2>
          <Form.Item name="owner_name" label={t('owner_name')}>
            <Input disabled={!editable} placeholder={t('owner_name')} />
          </Form.Item>

          <div className={styles.identityUpload}>
            <Row
              align="middle"
              className={styles.identityType}
              style={{
                margin: '15px 0',
              }}
            >
              <span className={styles.identityTypeLabel}>
                Giấy tờ của chủ quán:
              </span>
              <Select
                style={{
                  marginLeft: '18px',
                }}
                disabled={!editable}
                className={styles.selectIdentityType}
                onChange={(val) => setIdentityType(val)}
                value={identityType}
                options={[
                  {
                    label: 'CCCD/CMND',
                    value: 1,
                  },
                  {
                    label: 'Hộ chiếu',
                    value: 2,
                  },
                ]}
              />
            </Row>
            {identityType === 1 ? (
              <Row
                style={{
                  textAlign: 'center',
                }}
              >
                <Col flex="1 0">
                  <Form.Item
                    className="uploadItem"
                    name="identity_front"
                    getValueFromEvent={(e) => normFile(e)}
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      disabled={!editable}
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOAD
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_front}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: [],
                        }));
                      }}
                    >
                      {fileList.identity_front.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt trước</p>
                </Col>
                <Col flex="1 0">
                  <Form.Item
                    className="uploadItem"
                    name="identity_backside"
                    getValueFromEvent={(e) => normFile(e)}
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      disabled={!editable}
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOAD
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_backside}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: [],
                        }));
                      }}
                    >
                      {fileList.identity_backside.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt sau</p>
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Form.Item
                  className="uploadItem"
                  name="passport_image"
                  getValueFromEvent={(e) => normFile(e)}
                  rules={[
                    {
                      required: identityType === 2 ? true : false,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={!editable}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.passport_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: [],
                      }));
                    }}
                  >
                    {fileList.passport_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Hộ chiếu
                </p>
              </Row>
            )}
          </div>

          {editable && (
            <Row
              align="middle"
              justify="center"
              className={styles.submitButtonGroup}
            >
              <Button
                danger
                onClick={() => {
                  history.push('/business');
                }}
                className="btn-submit"
              >
                {t('general_cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                loading={updateRequest.loading}
              >
                {t('general_save')}
              </Button>
            </Row>
          )}
        </Form>
      )}
      {previewOpen && (
        <Modal
          open={previewOpen}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
      {isOpenBranchView && (
        <BranchPartner
          enterprise_id={+params.id}
          updateBranches={updateBranches.run}
          isOpen={isOpenBranchView}
          setIsOpen={setOpenBranchView}
          data={infoAccount?.branches?.map((item: any, i: number) => ({
            ...item,
            stt: i + 1,
          }))}
        />
      )}
      <AddInviter
        open={openModal}
        refresh={refresh}
        infoAccount={infoAccount}
        setOpen={setOpenModal}
      />
    </>
  ) : null;
};

export default PersonalInfo;
