import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

interface ConfirmInput {
  request_id: number;
  confirm: boolean;
  note?: string;
}
export const getListTransferVoucherWeb = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    textSearch: string;
    toDate: string[];
    status: string;
  },
) => {
  const params: any = {
    pageSize: pageSize,
    pageIndex: current,
  };
  if (!!formData.textSearch) {
    params.searchValue = formData.textSearch;
  }
  if (Array.isArray(formData.toDate)) {
    params.startTime = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.endTime = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  if (!!formData.status) {
    params.status = formData.status;
  }
  return privateRequest(request.get, API_PATH.GET_LIST_TRANSFER_WEB, {
    params,
  }).then((response) => {
    console.log(response);

    return {
      list: response.data?.map((item: any, index: number) => {
        return {
          ...item,
          stt: (current - 1) * pageSize + (index + 1),
        };
      }),
      total: response.total,
    };
  });
};

export const confirmTransferRequest = (input: ConfirmInput) => {
  return privateRequest(request.put, API_PATH.APRROVER_TRANSFER_WEB, {
    data: input,
  });
};
