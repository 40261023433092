import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Upload,
  UploadProps,
  message,
} from 'antd';
import React, { useState } from 'react';
import { history } from 'umi';
import styles from '../../index.less';
import { ENVIRONMENTS, ImageExt, VideoExt } from '@/utils/constant';
import { API_PATH, getAccessToken } from '@/utils/apis';
import { useRequest } from 'ahooks';
import { createBannerMeet } from '../../service';
import { validateNumber } from '@/utils/patern';
const CreateBannerMeet = () => {
  const [form] = Form.useForm();
  const onFinish = (val: any) => {
    createBanner.run(val);
  };
  const [isUploading, setIsUploading] = useState(false);
  const onChange: UploadProps['onChange'] = ({ file }) => {
    setIsUploading(true);

    if (file.response) {
      form.setFieldValue('image', file.response.path);
      setIsUploading(false);
    }
  };
  const token = getAccessToken();
  const createBanner = useRequest(createBannerMeet, {
    manual: true,
    onSuccess(res) {
      message.success('Tạo banner thành công');
      history.push('/setting/banner/meet');
    },
    onError(err: any) {
      message.error(err.data?.message);
    },
  });
  return (
    <div className={styles.bannerWrapper}>
      <Space className={styles.breadcrumb}>
        <LeftOutlined
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            history.goBack();
          }}
        />
        Danh sách banner
      </Space>
      <h1 className={styles.titleSetting}>Tạo banner quảng cáo</h1>
      <Row justify="space-between">
        <Col span={18} lg={18} sm={24} xs={24}>
          <Form
            className={styles.formItem}
            onFinish={onFinish}
            name="basic"
            labelCol={{ span: 6 }}
            form={form}
            labelWrap={true}
            labelAlign="left"
            wrapperCol={{ span: 18 }}
            autoComplete="off"
          >
            <Row>
              <Col span={16} lg={16} sm={16} xs={24}>
                <Form.Item label="Title" name="title">
                  <Input placeholder="Nhập title của banner" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} lg={16} sm={16} xs={24}>
                <Form.Item
                  label="Ảnh/Video"
                  name="image"
                  rules={[
                    { required: true, message: 'Vui lòng tải ảnh/video' },
                    // {
                    //   message: 'Không đúng định dạng URL',
                    //   validator: (_, value) => {
                    // var res = value.match(
                    //   /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                    // );
                    // if (res == null && value) {
                    //   return Promise.reject();
                    // } else {
                    //   return Promise.resolve();
                    // }
                    //   },
                    // },
                  ]}
                >
                  <Input.TextArea
                    rows={2}
                    readOnly
                    placeholder="Upload ảnh/video banner"
                  />
                </Form.Item>
              </Col>
              <Col span={6} lg={6} sm={6} xs={12}>
                <Form.Item
                  rules={[
                    ({ getFieldValue }) => ({
                      // eslint-disable-next-line @typescript-eslint/space-before-function-paren
                      async validator(_, value) {
                        if (value?.[0]?.error) {
                          return Promise.reject('Tải ảnh thất bại');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Upload
                    name="media"
                    accept={ImageExt.concat(VideoExt).join(',')}
                    action={ENVIRONMENTS.API_URL + API_PATH.UPLOAD_MEDIA}
                    onChange={onChange}
                    headers={{
                      Authorization: `Bearer ${token}`,
                    }}
                    maxCount={1}
                    // disabled={isDisabled}
                    // showUploadList={false}
                    onRemove={() => {
                      form.setFieldValue('image', '');
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        margin: '0 30px',
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} lg={16} sm={16} xs={24}>
                <Form.Item
                  name="redirect_url"
                  label="Link điều hướng"
                  rules={[
                    // { required: true, message: 'Vui lòng nhập Url' },
                    {
                      message: 'Không đúng định dạng URL',
                      validator: (_, value) => {
                        var res = value.match(
                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                        );
                        if (res == null && value) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Nhập link điều hướng" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} lg={16} sm={16} xs={24}>
                <Form.Item
                  name="order"
                  label="Thứ tự"
                  rules={[
                    {
                      validator(_, value) {
                        if (!!value) {
                          if (!validateNumber(value)) {
                            return Promise.reject('Vui lòng nhập số');
                          }
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số thứ tự ưu tiên"
                    style={{
                      width: '50%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} lg={16} sm={16} xs={24}>
                <Form.Item
                  label="Hiển thị"
                  name="display"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
              <Row>
                <Button
                  className={styles.submitBtn}
                  danger
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Hủy
                </Button>
                <Button
                  className={styles.submitBtn}
                  type="primary"
                  htmlType="submit"
                  disabled={isUploading}
                  loading={createBanner.loading}
                >
                  Lưu
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBannerMeet;
