import { API_PATH, privateRequest, request } from '@/utils/apis';

export const getTableData = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    name: string;
  },
) => {
  const data = {
    query: `{
      get_all_companies(input: { page_size: ${pageSize}, page_index: ${
      current - 1
    }, name: "${formData.name ? formData?.name : ''}" }) {
        total
        data {
          id
          name
          count_user
          created_at
          updated_at
        }
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res) => {
      const result = res?.data?.get_all_companies?.data?.map(
        (item: any, index: number) => {
          return {
            ...item,
            stt: (current - 1) * pageSize + (index + 1),
          };
        },
      );
      return {
        list: result,
        total: res?.data?.get_all_companies?.total,
      };
    },
  );
};
