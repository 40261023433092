import React from 'react';
import { Form, Input, Divider, Switch, Space, Select } from 'antd';

import styles from './index.less';
import { useParams } from 'umi';
import { useGetDetailShop, useShop } from '@/pages/StallList/service';
import { normalizeNumber } from '@/utils/normalize';
import { useBuilding } from '@/pages/BillBoards/service';

export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { shopDetail, loadingShopDetail } = useGetDetailShop(param?.id);
  const { building } = useBuilding();
  const { shop } = useShop();
  React.useEffect(() => {
    form.setFieldsValue(shopDetail);
    form.setFieldValue('building_id', shopDetail?.building.name);
    form.setFieldValue(
      'building_entities_id',
      shopDetail?.building_entities.name,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingShopDetail]);
  return (
    <>
      <Divider orientation="left">Thông tin chung</Divider>
      <div className={styles.formInfoGeneral}>
        <Form
          form={form}
          autoComplete="off"
          className={styles.formSearch}
          layout="vertical"
          disabled
        >
          <Form.Item
            name="name"
            label="Tên gian hàng"
            rules={[{ required: true, message: 'Vui lòng nhập tên gian hàng' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="building_id"
            label="Toà nhà"
            className={styles.locationNFT}
          >
            <Select
              placeholder="Lựa chọn toà nhà"
              optionFilterProp="children"
              options={building?.map((building: any) => {
                return {
                  label: `${building.name}`,
                  value: `${building.id}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item name="building_entities_id" label="Vị trí gian hàng">
            <Select
              disabled={true}
              placeholder="Lựa chọn vị trí gian hàng"
              options={shop?.map((shop: any) => {
                return {
                  value: shop.id,
                  label: shop.name,
                };
              })}
            />
          </Form.Item>
          <Form.Item name="vr_oid" label="ID VR tour">
            <Input />
          </Form.Item>
          <div className={styles.preview}>
            <p>Preview gian hàng</p>
            <iframe
              src={`https://nft.locamos.com/${shopDetail?.vr_oid}`}
              title="preview"
              width="100%"
              height="600"
            />
          </div>
          <Divider orientation="left">Thông tin chủ gian hàng</Divider>
          <Form.Item name="unit_name" label="Tên đơn vị">
            <Input placeholder="tên công ty, cửa hàng, đơn vị,..." />
          </Form.Item>
          <Form.Item name="address" label="Địa chỉ">
            <Input placeholder="Địa chỉ" />
          </Form.Item>
          <Form.Item name="representative" label="Người đại diện">
            <Input placeholder="Người đại diện" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Số điện thoại"
            normalize={(value, prevValue) => normalizeNumber(value, prevValue)}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const regexPhoneNumber = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
                  const phone = getFieldValue('phone');
                  if (!phone.match(regexPhoneNumber)) {
                    return Promise.reject(
                      new Error('Vui lòng nhập đúng định dạng số điện thoại!'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Số điện thoại" />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input placeholder="Email" />
          </Form.Item>
          <Space size={15}>
            <p>Trạng thái</p>
            <Form.Item
              name="status"
              className={styles.status}
              valuePropName="checked"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Space>
        </Form>
      </div>
    </>
  );
};
