import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Row, Select, Slider, message } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  CreateGroupUser,
  IGroupUserData,
  createGroupUser,
  listProviceUser,
  updateGroupUser,
} from '../service';
import styles from '../index.less';
import { EditOutlined } from '@ant-design/icons';
interface IModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  dataGroup?: IGroupUserData;
}
const EditGroupUserModal = ({
  isOpen,
  setIsOpen,
  refresh,
  dataGroup,
}: IModalProps) => {
  const listProvince = useRequest(listProviceUser);
  const [form] = Form.useForm();
  const handleClose = () => {
    form.resetFields();
    setIsOpen(false);
  };
  const onFinish = (val: any) => {
    const payload: IGroupUserData = {
      id: dataGroup?.id ?? '0',
      name: val.name,
      age_range_min: val.age[0],
      age_range_max: val.age[1],
      gender: val.gender,
      provinces:
        val?.provinces === 'all'
          ? []
          : Array.isArray(val.provinces)
          ? val.provinces
          : [val.provinces],
    };
    updateGroup.run(payload);
  };
  const updateGroup = useRequest(updateGroupUser, {
    manual: true,
    onSuccess(res) {
      message.success('Sửa nhóm user thành công');
      refresh();
      handleClose();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
      console.log(err);
    },
  });
  const [modeSelect, setModeSelect] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      width={600}
      title="Chi tiết nhóm user"
    >
      <Button
        icon={<EditOutlined />}
        type="primary"
        onClick={() => setIsEdit(true)}
        title="Sửa nhóm user"
      ></Button>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        disabled={!isEdit}
        initialValues={{
          ...dataGroup,
          age: [dataGroup?.age_range_min, dataGroup?.age_range_max],
          provinces:
            dataGroup?.provinces?.length === 0 ? 'all' : dataGroup?.provinces,
        }}
      >
        <Form.Item
          name="name"
          label="Tên nhóm user"
          rules={[{ required: true, message: 'Vui lòng nhập' }]}
        >
          <Input placeholder="Nhập tên nhóm user" />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Giới tính"
          rules={[{ required: true, message: 'Vui lòng chọn' }]}
        >
          <Select>
            <Select.Option value="ALL">Tất cả</Select.Option>
            <Select.Option value="MALE">Nam</Select.Option>
            <Select.Option value="FEMALE">Nữ</Select.Option>
            {/* <Select.Option value="OTHER">Giới tính khác</Select.Option> */}
          </Select>
        </Form.Item>
        <Form.Item label="Độ tuổi" name="age">
          <Slider
            range
            tooltip={{
              open: true,
              placement: 'left',
            }}
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item label="Thành phố" name="provinces">
          <Select
            placeholder="Chọn thành phố"
            mode={dataGroup?.provinces?.length === 0 ? modeSelect : 'multiple'}
            onChange={(val) => {
              if (val === 'all' || val?.includes('all')) {
                setModeSelect('');
                form.setFieldValue('provinces', 'all');
              } else {
                setModeSelect('multiple');
              }
            }}
          >
            <Select.Option key={-1} value={'all'}>
              Tất cả
            </Select.Option>
            {listProvince.data?.map((item: any, i: number) => (
              <Select.Option key={i} value={item?.province}>
                {item?.province}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row justify="center" className={styles.addGroupButton}>
          <Button
            danger
            type="primary"
            ghost
            onClick={handleClose}
            className={styles.addButton}
          >
            Huỷ
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className={styles.addButton}
            loading={updateGroup.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditGroupUserModal;
