import { Breadcrumb, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useIntl, useLocation } from 'umi';
import ActionHistory from './Components/ActionHistory';
import AdminManage from './Components/AdminManage';
import styles from './index.less';
import { Decentralization } from '../Decentralization';

const AdminComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const renderTab = () => {
    switch (location.pathname) {
      case '/admin': {
        return <AdminManage />;
      }
      case '/admin-history': {
        return <ActionHistory />;
      }
      case '/decentralization': {
        return <Decentralization />;
      }
      default:
        return null;
    }
  };
  return (
    <>
      {/* <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_admin' })}
        </Breadcrumb.Item>
      </Breadcrumb> */}
      <Tabs
        onChange={(key) => history.push(key)}
        defaultActiveKey={location.pathname}
        className={styles.tabs}
      >
        <Tabs.TabPane key="/admin" tab="Quản lý tài khoản Admin"></Tabs.TabPane>
        <Tabs.TabPane
          key="/admin-history"
          tab="Lịch sử tác động"
        ></Tabs.TabPane>
        <Tabs.TabPane
          key="/decentralization"
          tab="Quản lý nhóm phân quyền"
        ></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </>
  );
};

export default AdminComponent;
