import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Tabs } from 'antd';
import React from 'react';
import { Link, useIntl, useParams } from 'umi';
import GiftInfomation from './Components/GiftInfomation';
import PickupGiftAccount from './Components/PickupGiftAccount';
import styles from './index.less';

export default () => {
  const params = useParams();
  const [activeTabs, setActiveTabs] = React.useState(1);
  const { formatMessage } = useIntl();
  const renderTab = () => {
    switch (activeTabs) {
      case 1:
        return (
          <>
            <GiftInfomation />
          </>
        );
      case 2:
        return (
          <>
            <PickupGiftAccount />
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/gifts" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {formatMessage({ id: 'navigation_gifts_infomation' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveTabs(parseInt(key));
          }}
          className="tab-on-card"
        >
          <Tabs.TabPane tab="Thông tin quà tặng" key="1"></Tabs.TabPane>
          <Tabs.TabPane
            tab="Tài khoản đã nhận mảnh ghép"
            key="2"
          ></Tabs.TabPane>
        </Tabs>
        <Card className="card-after-tab" size="small">
          {renderTab()}
        </Card>
      </div>
    </>
  );
};
