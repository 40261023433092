import { API_PATH, privateRequest, request } from '@/utils/apis';
import { SearchVoucherParam } from '../GiftBuilding/service';
import moment from 'moment';
export enum StatusVoucherUrbox {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}
export const GetListVoucherUrbox = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: { textSearch: string },
) => {
  return privateRequest(request.get, API_PATH.LIST_VOUCHER_URBOX, {
    params: {
      textSearch: formData.textSearch,
      page: current,
      limit: pageSize,
    },
  }).then((res) => {
    return {
      list: res?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      })),
      total: res?.total,
    };
  });
};

export const ActiveVoucherUrvox = (id: number, status: StatusVoucherUrbox) => {
  return privateRequest(request.put, API_PATH.UPDATE_VOUCHER_URBOX(id), {
    data: {
      status: status,
    },
  });
};

export const GetReleasedVoucherUrbox = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    textSearch: string;
    toDate: string[];
  },
) => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  let params: any = {
    page: current,
    limit: pageSize,
    textSearch: formData.textSearch,
  };
  if (!!formData && !!toDate) {
    params['start_date'] = fromDate;
    params['end_date'] = toDate;
  }
  return privateRequest(request.get, API_PATH.RELEASED_VOUCHER_URBOX, {
    params,
  }).then((res) => {
    return {
      list: res?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: res?.total,
    };
  });
};

export const GetHistoryVoucherUrbox = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    textSearch: string;
    toDate: string[];
  },
) => {
  let params: any = {
    page: current,
    limit: pageSize,
    textSearch: formData.textSearch,
  };
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!formData && !!toDate) {
    params['start_date'] = fromDate;
    params['end_date'] = toDate;
  }
  return privateRequest(request.get, API_PATH.HISTORY_VOUCHER_URBOX, {
    params,
  }).then((res) => {
    return {
      list: res?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: res?.total,
    };
  });
};

export const ReleaseVoucherUrbox = (input: {
  id: string;
  title: string;
  quantity: number;
}) => {
  return privateRequest(request.post, API_PATH.RELEASED_VOUCHER_URBOX, {
    data: input,
  });
};

export const searchAllVoucher = (params: SearchVoucherParam) => {
  return privateRequest(request.get, API_PATH.SEARCH_ALL_VOUCHER_URBOX, {
    params,
  });
};
