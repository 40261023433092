import { useAntdTable, useToggle } from 'ahooks';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Skeleton,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { getLocaBonus } from '../service';
import Dialog from './Dialog/Dialog';
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
interface InfoLoca {
  create_at: string;
  description: string;
  point: number;
}

const LocaBonus = () => {
  const formatDate = 'DD/MM/YYYY';
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [idSelected, setIdSelected] = useState<number | string | null>(null);
  const [openDialog, setOpenDialog] = useToggle(false);

  const [point, setPoint] = useState(0);
  const [canSelect, setCanSelect] = useState<any[]>([]);
  const param = useParams<{ id: string }>();
  const handleDialog = () => {
    setOpenDialog.set(true);
  };

  const getData = () => {
    const { id } = param;
    if (id) {
      refresh();
    }
  };

  const { tableProps, search, refresh, error, loading, params } = useAntdTable(
    getLocaBonus,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, { id: param.id }],
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
      onSuccess(res: any, params) {
        const point = res?.turns;
        const pakageCanSelect = res?.package_can_select;
        setCanSelect(pakageCanSelect);
        setPoint(point);
      },
    },
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_update_time',
      align: 'right',
      dataIndex: 'create_at',
      key: 'create_at',
      render(_: any, record: any) {
        return moment(record.created_at).format('HH:mm:ss DD/MM/YYYY');
      },
    },
    {
      title: 'const_column_update_info',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'business_member',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'busness_point',
      align: 'right',
      dataIndex: 'turn',
      key: 'turn',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Button onClick={() => handleDialog()}>
        {formatMessage({ id: 'general_loca' })}
      </Button>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <DatePicker.RangePicker
            format={formatDate}
            onChange={search.submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item>
          <Typography>
            Lượt tặng còn lại:
            <strong className="primary-color">{point}</strong>
          </Typography>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      {param.id && point === null ? (
        <Skeleton active />
      ) : (
        <div className={styles.LocaBonusContainer}>
          {searchForm}
          <Table
            {...tableProps}
            columns={columns}
            rowKey={(item) => item.key}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
      {openDialog && (
        <Dialog
          getData={getData}
          open={openDialog}
          canSelect={canSelect}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={point}
        />
      )}
    </>
  );
};

export default LocaBonus;
