import { Form, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import { getListKiotBranches, getListKiotBranchesV2 } from './service';
import { EnterpriseType } from '@/utils/enum';
interface Iprops {
  formItemName: string;
  className?: string;
  onChange: any;
  allowClear?: boolean;
  propsInput?: any;
  type: EnterpriseType;
}
const { Option } = Select;
const SelectKiot = (props: Iprops) => {
  const { formItemName, className, onChange, allowClear, propsInput, type } =
    props;
  const { data, loading, run } = useRequest(getListKiotBranches, {
    manual: true,
    debounceWait: 1000,
  });
  const {
    data: dataLocamart,
    loading: loadingLocamart,
    run: runLocamart,
  } = useRequest(getListKiotBranchesV2, {
    manual: true,
    debounceWait: 1000,
  });
  const onSearch = (val: string) => {
    if (type === EnterpriseType.LOCACAFE) {
      run(val);
    } else {
      runLocamart(val);
    }
  };
  useEffect(() => {
    if (type === EnterpriseType.LOCACAFE) {
      run();
    } else {
      runLocamart();
    }
  }, [type]);
  return (
    <Form.Item
      name={formItemName}
      label="Liên kết cửa hàng KiotViet"
      className={className}
    >
      <Select
        allowClear={allowClear}
        style={{ width: '100%' }}
        placeholder="Chọn chi nhánh KiotViet"
        showSearch
        labelInValue={true}
        className={className}
        notFoundContent={
          loading || loadingLocamart ? (
            <Spin size="small" />
          ) : (
            'Không có dữ liệu'
          )
        }
        filterOption={false}
        onChange={onChange}
        onSearch={onSearch}
        {...propsInput}
      >
        {type === EnterpriseType.LOCACAFE
          ? data?.map((item: any, i: number) => (
              <Select.Option key={`${i}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))
          : dataLocamart?.data?.branches?.map((item: any, i: number) => (
              <Select.Option key={`${i}`} value={item.id}>
                {item.branchName}
              </Select.Option>
            ))}
      </Select>
    </Form.Item>
  );
};

export default SelectKiot;
