import React, { useState } from 'react';
import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Table,
  DatePicker,
  Row,
  Tooltip,
  Tag,
  Modal,
  message,
} from 'antd';
import { useIntl } from 'umi';
import styles from './index.less';
import { useAntdTable, useRequest } from 'ahooks';
import { confirmTransferRequest, getListTransferVoucherWeb } from './service';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { CheckOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { TransferVoucherWeb } from '@/utils/enum';
import { STATUS_TRANSFER_VOUCHER_WEB } from '@/utils/constant';
import DetailTransferVoucherModal from './Components/DetailTransferVoucherModal';
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const TransferDVoucherWeb = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [selectedRecord, setSelectdRecord] = useState<any>();
  const [isOpenModalDetail, setIsModalDetail] = useState(false);
  const { tableProps, search, refresh } = useAntdTable(
    getListTransferVoucherWeb,
    {
      form,
    },
  );
  const confirmTransfer = useRequest(confirmTransferRequest, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      refresh();
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const { submit } = search;
  const renderStatus = (status: TransferVoucherWeb) => {
    switch (status) {
      case TransferVoucherWeb.CANCELED:
        return <Tag color="grey">Không thành công</Tag>;
      case TransferVoucherWeb.REQUESTED:
        return <Tag color="warning">Đang xử lý</Tag>;
      case TransferVoucherWeb.REFUSED:
        return <Tag color="red">Từ chối</Tag>;
      case TransferVoucherWeb.CONFIRMED:
        return <Tag color="green">Hoàn thành</Tag>;
      case TransferVoucherWeb.CALLBACK_ERROR:
        return <Tag color="red">Lỗi đồng bộ</Tag>;
      default:
        break;
    }
  };
  const handleApproveTransfer = (id: number) => {
    return Modal.confirm({
      title: 'Xác nhận yêu cầu chuyển voucher',
      content: 'Bạn có chắc chắn muốn xác nhận yêu cầu chuyển voucher?',
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      okButtonProps: {
        type: 'primary',
      },
      icon: null,
      onOk: () => {
        confirmTransfer.run({
          request_id: id,
          confirm: true,
        });
      },
    });
  };
  const [formRefuse] = Form.useForm();
  const handleRefuseTransfer = (id: number) => {
    const onSubmitRefuse = (val: any) => {
      confirmTransfer.run({
        request_id: id,
        confirm: false,
        note: val.reason,
      });
    };
    return Modal.confirm({
      title: 'Xác nhận từ chối chuyển voucher',
      icon: null,
      content: (
        <>
          <p>Bạn có chắc chắn muốn từ chối yêu cầu chuyển voucher?</p>
          <Form
            layout="vertical"
            onFinish={onSubmitRefuse}
            form={formRefuse}
            id="formRefuse"
          >
            <Form.Item
              label="Lý do"
              name="reason"
              rules={[{ required: true, message: 'Nhập lý do' }]}
            >
              <Input.TextArea rows={4} placeholder="Nhập lý do từ chối" />
            </Form.Item>
          </Form>
        </>
      ),
      okText: 'Xác nhận',
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: confirmTransfer.loading,
      },
      onCancel: () => {
        formRefuse.resetFields();
      },
    });
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo Id người chuyển/người nhận"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          initialValue={null}
        >
          <Select onChange={submit} allowClear placeholder="Trạng thái">
            {STATUS_TRANSFER_VOUCHER_WEB.map((item, i) => (
              <Select.Option value={item.value} key={i}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Thời gian phát sinh',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'ID người chuyển (User web)',
      dataIndex: 'sender_id',
    },
    {
      title: 'Người chuyển',
      dataIndex: 'email',
    },
    {
      title: 'ID người nhận (User app)',
      dataIndex: 'receiver_id',
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'receiver_name',
    },
    {
      title: 'Loại Voucher',
      dataIndex: 'voucher_type',
    },
    {
      title: 'Số lượng chuyển',
      dataIndex: 'quantity',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render(value) {
        return renderStatus(value);
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <Row justify="space-evenly">
          <Tooltip title="Xem">
            <EyeOutlined
              onClick={() => {
                setSelectdRecord(record);
                setIsModalDetail(true);
              }}
              className="purple-color"
              style={{
                cursor: 'pointer',
              }}
            />
          </Tooltip>
          {record?.status === TransferVoucherWeb.REQUESTED && (
            <>
              <Tooltip title="Duyệt">
                <CheckOutlined
                  onClick={() => handleApproveTransfer(record?.id)}
                  className="green-color"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
              <Tooltip title="Từ chối">
                <CloseOutlined
                  onClick={() => handleRefuseTransfer(record?.id)}
                  className="red-color"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </>
          )}
        </Row>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'transfer_voucher_web' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
      {isOpenModalDetail && (
        <DetailTransferVoucherModal
          isOpen={isOpenModalDetail}
          data={selectedRecord}
          setIsOpen={setIsModalDetail}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default TransferDVoucherWeb;
