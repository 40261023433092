import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';

interface Result {
  total: number;
  list: any[];
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: any,
): Promise<Result> => {
  const data = {
    query: `mutation {
      get_list_role(input: { page_size: ${pageSize}, page_index: ${
      current - 1
    }, search_text: "${formData.search_text || ''}" }) {
        total
        data {
          id
          role_name
          is_active
          date_modified
        }
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_list_role.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.get_list_role?.total,
        list: result,
      };
    },
  );
};

export const switchRole = (idRole: any) => {
  const query = `
    mutation {
      switch_status(id: "${idRole}")
}
  
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const deleteRole = (idRole: any) => {
  const query = `
  mutation {
    delete_role(id: "${idRole}")
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
