import { API_PATH, privateRequest, request } from '@/utils/apis';
import { useRequest } from 'ahooks';

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}

interface Result {
  total: number;
  list: Item[];
}
export interface ICreateBanner {
  name: string;
  building_id: number;
  building_entities_id: number;
  image: string;
  click_action: number;
  content: string;
  status: boolean;
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  form: Object,
): Promise<Result> => {
  let query = `page_index=${current - 1}&page_size=${pageSize}`;
  Object.entries(form).forEach(([key, value]) => {
    if ((value || value === 0) && key !== 'order') {
      query += `&${key}=${value}`;
    }
  });
  return privateRequest(
    request.get,
    API_PATH.BUILDING_BANNER + '?' + query,
  ).then((res) => {
    return {
      total: res.total,
      list: res.data,
    };
  });
};

// get all toà nhà

export const useBuilding = () => {
  const { data, loading } = useRequest(() => {
    return privateRequest(
      request.get,
      API_PATH.BUILDING + '?status=true&page_size=999&page_index=1',
    );
  });
  return {
    building: data?.data,
    loadData: loading,
  };
};

// get position banner

export const useBanner = () => {
  const { data, run, loading } = useRequest(
    (id: number) => {
      return privateRequest(
        request.get,
        API_PATH.BUILDING +
          `/${id}/banner?status=true&page_size=999&page_index=0`,
      );
    },
    {
      manual: true,
    },
  );
  return {
    banner: data?.data,
    getBanner: run,
    loadingBanner: loading,
  };
};

export const requestCreateBanner = (payload: ICreateBanner) => {
  return privateRequest(request.post, API_PATH.BUILDING_BANNER, {
    data: payload,
  });
};

export const requestChangeStatus = (id: number, status: boolean) => {
  return privateRequest(request.put, API_PATH.BUILDING_BANNER + `/${id}`, {
    data: {
      status,
    },
  });
};

//get detail

export const getDetailBanner = (id: string) => {
  return privateRequest(request.get, API_PATH.BUILDING_BANNER + `/${id}`);

  // return {
  //   detailBanner: data,
  //   loadingDetail: loading,
  // };
};
// get access
export const useGetAccessBanner = (id: number) => {
  const { data, run } = useRequest(
    (date?: any) => {
      let query = `page_index=1&page_size=10`;
      date &&
        Object.entries(date).forEach(([key, value]) => {
          if (value) {
            query += `&${key}=${value}`;
          }
        });
      return privateRequest(
        request.get,
        API_PATH.BUILDING_BANNER + `/${id}/access-times?${query}`,
      );
    },
    {
      manual: true,
    },
  );
  return {
    access: data,
    run,
  };
};

//update banner
export const requestUpdateBanner = (data: ICreateBanner, id: number) => {
  return privateRequest(request.put, API_PATH.BUILDING_BANNER + `/${id}`, {
    data,
  });
};
