import { useAntdTable } from 'ahooks';
import React from 'react';
import { statisticUseAlias } from '../service';
import { Breadcrumb, DatePicker, Form, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import styles from './index.module.less';
import dayjs from 'dayjs';
interface DataType {
  stt: number;
}
const AliasStatistic = ({ enterprise_id }: { enterprise_id: number }) => {
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(statisticUseAlias, {
    form,
    defaultParams: [
      {
        pageSize: 10,
        current: 1,
        enterprise_id,
      },
      {},
    ],
  });
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'used_time',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'user_name',
    },
    {
      title: 'Mã Alias',
      dataIndex: 'alias_code',
    },
    {
      title: 'Giá trị voucher',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} layout="inline" className={styles.searchForm}>
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            onSearch={submit}
            placeholder="Tìm kiếm userId, mã alias"
            allowClear
          />
        </Form.Item>
        <Form.Item name="date" className={styles.searchItem}>
          <DatePicker.RangePicker format="DD/MM/YYYY" onChange={submit} />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Thống kê sử dụng mã Alias</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table columns={columns} {...tableProps} rowKey={(item) => item.stt} />
      </div>
    </div>
  );
};

export default AliasStatistic;
