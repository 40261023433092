import {
  Form,
  Input,
  Switch,
  Space,
  Button,
  Tabs,
  Select,
  message,
  Spin,
  Breadcrumb,
  Row,
} from 'antd';
import { Link, history, useParams } from 'umi';
import SelectNFT from '@/components/SelectNFT';
import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import Stall from '../Components/Stall';
import BillBoards from '../Components/BillBoards';
import {
  useGetBuildingTemplate,
  useGetNFT,
  ICreateLocaBuilding,
  requesBuilding,
  requestUpdateLocaBuilding,
} from '../service';
import { useRequest } from 'ahooks';
import UploadImage from '@/components/UploadImage';
import { useState } from 'react';
import { extname } from 'path';
import { LeftOutlined } from '@ant-design/icons';

export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const [fileList, setFileList] = useState<any[]>([]);
  const { buildingTemplate } = useGetBuildingTemplate();
  const [activeTab, setActiveTab] = useState('1');
  const onUpdateLocaBuilding = useRequest(
    (payload: ICreateLocaBuilding) => {
      return requestUpdateLocaBuilding(param?.id, payload);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        history.push('/loca-building');
      },
      onError: (err: any) => {
        message.error(err?.data.message);
      },
    },
  );
  const { data, loading, refresh } = useRequest(
    () => requesBuilding(param.id),
    {
      onSuccess: (res) => {
        setFileList([
          {
            uid: '-1',
            name: extname(res.image),
            status: 'done',
            url: res.image,
          },
        ]);
        form.setFieldsValue(res);
      },
    },
  );

  const onFinish = () => {
    const values = form.getFieldsValue();
    onUpdateLocaBuilding.run({
      ...data,
      ...values,
      ...{
        image: values.image.file
          ? `${values.image?.[0]?.response?.url}${values.image?.[0]?.response?.path}`
          : values.image,
      },
    });
  };
  const onCancel = () => {
    history.push('/loca-building');
  };
  if (loading) {
    return (
      <div className={styles.createBuilding}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>
            <Link to="/loca-building" className={styles.previousLink}>
              <LeftOutlined />
            </Link>
            Chi tiết building
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.loading}>
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/loca-building" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Chi tiết building
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          <TabPane tab="Thông tin chung" key="1">
            <div className={styles.formInfoGeneral}>
              <Form
                onFinish={onFinish}
                form={form}
                autoComplete="off"
                className={styles.formSearch}
                layout="vertical"
                initialValues={{
                  status: true,
                }}
              >
                <Form.Item name="nft_id" hidden></Form.Item>
                <Form.Item name="lat_nft" hidden></Form.Item>
                <Form.Item name="long_nft" hidden></Form.Item>
                <Form.Item name="user_owner_nft" hidden></Form.Item>
                <Form.Item name="name" label="Tên toà nhà">
                  <Input />
                </Form.Item>
                <SelectNFT
                  fromName="address_nft"
                  className={styles.locationNFT}
                  onChange={(data: any) => {
                    if (!!data) {
                      const { label, value, key } = data;
                      let coordinates: any;
                      if (!!key) {
                        coordinates = key.split(',');
                      }
                      const newAddres: any = {
                        id_nft: value,
                        address: label,
                        latitude: Number(coordinates[0]),
                        longitude: Number(coordinates[1]),
                        user_owner_nft: coordinates[2],
                      };
                      form.setFieldValue('nft_id', newAddres.id_nft);
                      form.setFieldValue('lat_nft', newAddres.latitude);
                      form.setFieldValue('long_nft', newAddres.longitude);
                      form.setFieldValue(
                        'user_owner_nft',
                        newAddres.user_owner_nft,
                      );
                      form.setFieldValue('address_nft', newAddres.address);
                    }
                  }}
                  allowClear={true}
                  loadOnMount={true}
                  // {...propsInput}
                />
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.address_nft !== curValues.address_nft
                  }
                >
                  {({ getFieldValue }) => {
                    const lat_nft = getFieldValue('lat_nft');
                    const long_nft = getFieldValue('long_nft');
                    const user_owner_nft = getFieldValue('user_owner_nft');

                    return (
                      <div className={styles.infoNFT}>
                        <p>lat: {lat_nft}</p>
                        <p>long: {long_nft}</p>
                        <p>Chủ sở hữu: {user_owner_nft}</p>
                      </div>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  name="building_template_id"
                  label="Toà nhà"
                  className={styles.locationNFT}
                >
                  <Select
                    placeholder="Search to Select"
                    options={buildingTemplate?.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  />
                </Form.Item>
                <UploadImage
                  label="Ảnh tòa nhà"
                  fileList={fileList}
                  setFileList={setFileList}
                  name="image"
                />
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.building_template_id !==
                    curValues.building_template_id
                  }
                >
                  {({ getFieldValue }) => {
                    const building = getFieldValue('building_template_id');
                    const template = buildingTemplate?.find(
                      (item: any) => item.id === Number(building),
                    );
                    if (!building) return;
                    return (
                      <div className={styles.preview}>
                        <p>Preview Toà nhà</p>
                        <iframe
                          src={`https://nft.locamos.com/${template?.vr_oid}`}
                          title="preview"
                          width="100%"
                          height="600"
                        />
                      </div>
                    );
                  }}
                </Form.Item>
                <Space size={15}>
                  <p>Trạng thái</p>
                  <Form.Item
                    name="status"
                    className={styles.status}
                    valuePropName="checked"
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Space>
                <Row justify="center">
                  <Button
                    className="btn-submit"
                    type="primary"
                    ghost
                    danger
                    onClick={onCancel}
                  >
                    Huỷ
                  </Button>
                  <Button
                    className="btn-submit"
                    type="primary"
                    htmlType="submit"
                  >
                    Lưu
                  </Button>
                </Row>
              </Form>
            </div>
          </TabPane>
          <TabPane tab="Gian hàng" key="2">
            <Stall
              shop={data?.shopLocation}
              isEdit={true}
              building_id={data?.id}
              refresh={refresh}
              detail={true}
            />
          </TabPane>
          <TabPane tab="Biển quảng cáo" key="3">
            <BillBoards
              banner={data?.bannerLocation}
              building_id={data?.id}
              isEdit={true}
              refresh={refresh}
              detail={true}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
