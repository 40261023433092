import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export interface UpdateBannerConfigInput {
  image?: string;
  redirect_url?: string;
  display?: boolean;
  order?: number;
}

export interface UpdateLocaCallConfigInput {
  activity_coefficient?: number;
  default_call_count?: number;
  default_receive_count?: number;
  membership_call_count?: number;
  membership_receive_count?: number;
  invite_success_call_count?: number;
  require_meet_time?: number;
  maximum_locabonus?: number;
  sleep_time_maximum_locabonus?: number;
}

export const setUpLocameet = (data: any) => {
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getLocameet = () => {
  const data = {
    query: `
mutation {
  get_info_setup_loca_meet {
    meet_time
    nums_of_scan
    landing_page_meet_count_bonus
    meet_count_default
    locabonus_limit_per_meet
    meet_count_membership
    locabonus_limit_per_meet_night
    locabonus_limit_user_to_earn
		display_ads
		meet_range_start
		meet_range_end
		display_ads
		liveness_profile
    register_social_network
		locacafe_limit
		voucher_invite_bonus
		voucher_register_bonus
  }
}
    `,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getAllBlackListDomain = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchValue: string;
  },
) => {
  if (!formData.searchValue) {
    formData.searchValue = '';
  }
  const data = {
    query: `mutation {
      get_all_blacklist_email(input: { page_size: ${pageSize}, page_index: ${
      current - 1
    }, search_value: "${formData.searchValue}" }) {
        list {
          id
          email
          total
        }
        total
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.get_all_blacklist_email?.list?.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.get_all_blacklist_email?.total,
      list: data,
    };
  });
};

export const deleteBlacklistDomain = (id: number) => {
  const data = {
    query: `mutation {
      delete_blacklist_email(id: ${id}) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const addBlacklistDomain = (email: string) => {
  const data = {
    query: `mutation {
      create_blacklist_email(email: "${email}") {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getBannerConfig = () => {
  return privateRequest(request.get, API_PATH.BANNER_CONFIG);
};

export const updateBannerConfig = (input: UpdateBannerConfigInput) => {
  return privateRequest(request.post, API_PATH.BANNER_CONFIG, { data: input });
};

export const getLocacallConfig = () => {
  return privateRequest(request.get, API_PATH.LOCACALL_CONFIG);
};

export const updateLocacallConfig = (input: UpdateLocaCallConfigInput) => {
  return privateRequest(request.post, API_PATH.LOCACALL_CONFIG, {
    data: input,
  });
};

export const getListBannerMeet = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchText: string;
    toDate: string[];
  },
) => {
  const params: any = {
    page_size: pageSize,
    page_index: current,
  };
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!fromDate && !!toDate) {
    params['start_time'] = fromDate;
    params['end_time'] = toDate;
  }
  if (!!formData.searchText) {
    params['search_value'] = formData.searchText;
  }
  return privateRequest(request.get, API_PATH.BANNER_MEET, { params }).then(
    (res) => {
      return {
        list: res.banners?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + (index + 1),
        })),
        total: res.total,
        data: {
          random_banner: res.random_banner,
        },
      };
    },
  );
};

export const createBannerMeet = (input: UpdateBannerConfigInput) => {
  return privateRequest(request.post, API_PATH.BANNER_MEET, {
    data: input,
  });
};

export const getDetailBannerMeet = (id: number) => {
  return privateRequest(request.get, API_PATH.DETAIL_BANNER_MEET(id));
};

export const updateBannerMeet = (
  id: number,
  input: UpdateBannerConfigInput,
) => {
  return privateRequest(request.put, API_PATH.DETAIL_BANNER_MEET(id), {
    data: input,
  });
};

export const updateMeetBannerConfig = (display: boolean) => {
  return privateRequest(request.post, API_PATH.RANDOM_DISPLAY_BANNER, {
    data: {
      random_banner: display,
    },
  });
};
