import { useAntdTable } from 'ahooks';
import React from 'react';
import { getListReceivedGift } from '../service';
import { Form, Input, DatePicker, Breadcrumb } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import styles from '../index.less';
import { Link, useIntl, useParams } from 'umi';
import { LeftOutlined } from '@ant-design/icons';
const ListReceivedGift = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const params = useParams<{ id: string }>();
  const { tableProps, search, data } = useAntdTable(getListReceivedGift, {
    form,
    defaultParams: [
      {
        pageSize: 10,
        current: 1,
        id: +params?.id,
      },
      {},
    ],
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '10%',
    },
    {
      title: 'Thời gian giặt quà',
      dataIndex: 'time_pick_up',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'UserId',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên user',
      dataIndex: 'full_name',
    },
    data?.list?.[0]?.status_receive
      ? {
          title: 'Trạng thái nhận quà',
          dataIndex: 'status_receive',
        }
      : {},
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        autoComplete="off"
        className={styles.searchForm}
        layout="inline"
      >
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo id/tên user"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
            format={['DD/MM/YYYY', 'DD/MM/YY']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/gift-building" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Danh sách User đã nhặt quà <b>{data?.gift_info?.name}</b>
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          rowKey="user_id"
          columns={columns}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
          {...tableProps}
        />
      </div>
    </div>
  );
};

export default ListReceivedGift;
