import React, { useState } from 'react';
import { Breadcrumb, Form, Tabs } from 'antd';
import styles from './index.less';
import { useHistory, useLocation } from 'umi';
import EnterpriseVoucher from './pages/EnterpriseVoucher';
import TypeVoucher from './pages/TypeVoucher';
import HistoryReleaseVoucher from './pages/HistoryReleaseVoucher';
import BadReportVoucher from './pages/BadReportVoucher';
import { ENVIRONMENTS } from '@/utils/constant';
import { PERMISSIONS } from '@/utils/enum';
import { useAuth } from '@/utils/hooks/useAuth';
import TransferStatistic from './pages/TransferStatistic';
const VoucherEnterprise = () => {
  const location = useLocation();
  const history = useHistory();
  const { getAdminLocalStore } = useAuth();
  const admin: any = getAdminLocalStore();
  const renderTab = () => {
    switch (location.pathname) {
      case '/voucher':
        return <EnterpriseVoucher />;
      case '/voucher/released':
        return <TypeVoucher />;
      case '/voucher/history':
        return <HistoryReleaseVoucher />;
      case '/voucher/bad_reports':
        return <BadReportVoucher />;
      case '/voucher/transfer_statistics':
        return <TransferStatistic />;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý voucher Locamos</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        {admin?.role?.permissions?.release_voucher_management_level !==
          PERMISSIONS.NONE && (
          <>
            <Tabs.TabPane tab="Thống kê voucher" key="/voucher"></Tabs.TabPane>
            <Tabs.TabPane
              tab="Lịch sử phát hành Voucher"
              key="/voucher/history"
            ></Tabs.TabPane>
          </>
        )}
        {admin?.role?.permissions?.voucher_type_management_level !==
          PERMISSIONS.NONE && (
          <Tabs.TabPane
            tab="Loại voucher"
            key="/voucher/released"
          ></Tabs.TabPane>
        )}

        <Tabs.TabPane
          tab="Báo xấu voucher"
          key="/voucher/bad_reports"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Chuyển voucher"
          key="/voucher/transfer_statistics"
        ></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </div>
  );
};

export default VoucherEnterprise;
