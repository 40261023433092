import React, { useEffect, useState } from 'react';
import {
  Form,
  Image,
  Typography,
  Table,
  Row,
  Button,
  Space,
  Modal,
  Input,
} from 'antd';
import { useRequest } from 'umi';
import { useAntdTable } from 'ahooks';
import { getOrderDetailService } from '../service';
import { ColumnsType } from 'antd/lib/table';
import { ENVIRONMENTS, blankImageUrl } from '@/utils/constant';
import { formatNumber } from '@/utils/apis/common';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from '../index.less';
import classNames from 'classnames';
const { Text } = Typography;

interface DetailOrderProps {
  orderCode: string;
}
interface DataTypeFormReject {
  reason: string;
}

const DetailOrder: React.FC<DetailOrderProps> = ({ orderCode }) => {
  const { tableProps, data } = useAntdTable(getOrderDetailService, {
    defaultParams: [
      {
        current: 1,
        pageSize: 4,
        idOrder: orderCode,
      },
    ],
  });
  const [displayIncome, setDisplayIncome] = useState(true);
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '5%',
      key: 'stt',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      render: (_: any, record: any) => (
        <>
          <Image
            src={
              ENVIRONMENTS.BUCKET_NAME + '/' + record?.product?.images[0]?.link
            }
            alt="product"
            fallback={blankImageUrl}
            width={80}
            height={80}
          />
        </>
      ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      render: (_: any, record: any) => (
        <>
          <p>{_}</p>
          <p
            style={{
              color: 'gray',
            }}
          >
            {record?.bundle_prop_order?.map((item: any, i: number) => (
              <>
                {i === record?.bundle_prop_order?.length - 1
                  ? `${item?.bundle_prop_name}`
                  : `${item?.bundle_prop_name}, `}
              </>
            ))}
          </p>
        </>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'product_quantity',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price_per_product',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total_price',
      render: (value: any, record: any) => (
        <>
          {record?.total_price !==
            record?.price_per_product * record?.product_quantity &&
          record?.orders_discount_event?.length > 0 ? (
            <div>
              <p className={styles.originPrice}>
                {formatNumber(
                  record?.price_per_product * record?.product_quantity,
                )}
                đ
              </p>
              <p>{formatNumber(record?.total_price)}đ</p>
            </div>
          ) : (
            <>{formatNumber(value)}đ</>
          )}
        </>
      ),
    },
  ];

  const handleApproveOrder = () => {
    return Modal.confirm({
      title: 'Xác nhận duyệt đơn hàng',
      content: `Bạn có chắc muốn xác nhận đơn hàng ${orderCode}`,
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      okButtonProps: {
        className: 'btn-submit',
      },
      cancelButtonProps: {
        className: 'btn-submit',
      },
      icon: null,
    });
  };
  const [formRejectOrder] = Form.useForm();
  const handleRejectOrder = () => {
    const onFinish = (val: any) => {
      console.log(val);
      formRejectOrder.resetFields();
    };
    return Modal.confirm({
      icon: null,
      title: 'Xác nhận từ chối đơn hàng',
      content: (
        <>
          <Form onFinish={onFinish} form={formRejectOrder} id="formRejectOrder">
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập lý do từ chối!',
                },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Form>
        </>
      ),
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      okButtonProps: {
        className: 'btn-submit',
        htmlType: 'submit',
        form: 'formRejectOrder',
      },
      cancelButtonProps: {
        className: 'btn-submit',
      },

      onCancel() {
        formRejectOrder.resetFields();
      },
      onOk() {
        if (formRejectOrder.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
      // icon: null,
    });
  };
  return (
    <div>
      <Table
        {...tableProps}
        columns={columns}
        scroll={{
          x: 1000,
        }}
      />
      <Row
        justify="end"
        style={{
          margin: '15px 0',
        }}
      >
        <Button type="link" onClick={() => setDisplayIncome(!displayIncome)}>
          {!displayIncome ? (
            <Space>
              <span>Hiển thị chi tiết doanh thu</span>
              <DownOutlined />
            </Space>
          ) : (
            <Space>
              <span>Ẩn chi tiết doanh thu</span>
              <UpOutlined />
            </Space>
          )}
        </Button>
      </Row>
      {displayIncome && (
        <div className={styles.incomeDetail}>
          <Row justify="space-between" className={styles.incomeItem}>
            <div>Tổng cộng </div>
            <div>{formatNumber(data?.data?.total_origin)} VND</div>
          </Row>
          <Row justify="space-between" className={styles.incomeItem}>
            <div>Giảm giá sản phẩm </div>
            <div>
              {data?.data?.total_discount_product > 0
                ? `-${formatNumber(data?.data?.total_discount_product)}`
                : data?.data?.total_discount_product}{' '}
              VND
            </div>
          </Row>
          <Row
            justify="space-between"
            className={classNames(styles.incomeItem, {
              [styles.incomeItemVoucher]: true,
            })}
          >
            <div>Tổng số Voucher áp dụng cho sản phẩm </div>
            <div>{data?.data?.total_product_voucher} D-LocaVouches</div>
          </Row>
          <Row justify="space-between" className={styles.incomeItem}>
            <div>Giảm giá đơn hàng </div>
            <div>
              {data?.data?.total_discount_bill > 0
                ? `-${formatNumber(data?.data?.total_discount_bill)}`
                : data?.data?.total_discount_bill}
              VND
            </div>
          </Row>
          <Row
            justify="space-between"
            className={classNames(styles.incomeItem, {
              [styles.incomeItemVoucher]: true,
            })}
          >
            <div>Tổng số Voucher áp dụng cho đơn hàng</div>
            <div>{data?.data?.total_bill_voucher} D-LocaVouches</div>
          </Row>
          <Row justify="space-between" className={styles.incomeItem}>
            <div>Tổng giá trị đơn hàng </div>
            <div>
              {data?.data?.last_price > 0
                ? formatNumber(data?.data?.last_price)
                : data?.data?.last_price}
              VND
            </div>
          </Row>
        </div>
      )}
      <Row justify="end">
        <Button
          type="primary"
          className="btn-submit"
          onClick={handleApproveOrder}
        >
          Xác nhận
        </Button>
        <Button
          type="primary"
          danger
          className="btn-submit"
          onClick={handleRejectOrder}
        >
          Từ chối
        </Button>
      </Row>
    </div>
  );
};

export default DetailOrder;
