import { message, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useIntl, useRequest } from 'umi';
import { deleteRole } from '../service';
const { Option } = Select;

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit: any;
  refresh: () => void;
}

interface IUser {
  address?: string;
  avatar?: any;
  createdAt?: string;
  dateOfBirth?: string;
  email?: string;
  fullName?: string;
  gender?: string;
  id?: string | number;
  identificationCode?: string;
  isActive?: true;
  phone?: string;
  points?: number;
  referralCode?: string;
  roles?: Array<any>;
  status?: string;
  updatedAt?: string;
}

const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  refresh,
  ...rest
}) => {
  const id = itemEdit;
  const { formatMessage } = useIntl();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    formatMessage({ id: 'message_delete_decentralization' }),
  );

  const deleteRoleRequest = useRequest(deleteRole, {
    manual: true,
    onSuccess(data: any) {
      if (data?.errors) {
        if (data?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Nhóm quyền đang được sử dụng');
        }
      } else {
        message.success('Xoá nhóm quyền thành công');
        refresh();
      }
      setOpen(false);
    },
    formatResult: (res) => res,
  });

  const handleOk = async () => {
    await deleteRoleRequest.run(id);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
