import React from 'react';
import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import styles from './index.less';
interface Props {
  children: any;
  position: any;
}
interface DataType {
  key: string;
  id: string;
  name_location: number;
  name_stall: string;
}
export default (props: Props) => {
  const { children, position } = props;
  const [visible, setVisible] = React.useState(false);
  const onVisible = () => {
    setVisible(!visible);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Tên vị trí',
      dataIndex: 'hotspot_id',
      key: 'hotspot_id',
    },
    {
      title: 'Tên biển quảng cáo',
      dataIndex: 'hotspot_name',
      key: 'hotspot_name',
    },
  ];
  return (
    <>
      <span onClick={() => onVisible()} className={styles.text}>
        {children}
      </span>
      <Modal
        title="Danh sách biển quảng cáo"
        open={visible}
        onOk={onVisible}
        onCancel={onVisible}
        centered
        footer={null}
      >
        <Table
          rowKey={'hotspot_id'}
          columns={columns}
          dataSource={position}
          pagination={{ pageSize: 5 }}
          // {...tableProps}
        />
      </Modal>
    </>
  );
};
