import { API_PATH, privateRequest, request } from '@/utils/apis';

interface Result {
  total: number;
  list: any[];
}
export const getKycDetail = (id: any) => {
  const data = {
    query: `
    {
      get_profile(id: ${id}) {
        userId
        request_at
        full_name
        phone
        email
        type_identity
        verification_status
        identity_backside
        identity_front
        verify_image
        date_of_birth
        date_of_issue
        place_of_issue
        identity_id
        address
        reason
        matchRate
        reason_not_auto_kyc
				liveness_url
				is_liveness
      }
    }    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const findSimilarFace = (kyc_id: number) => {
  const data = {
    query: `mutation {
      findUserSimilarByUserId(userId: ${kyc_id}) {
        userId
        fullName
        confidence
				img
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const addFaceToBlacklist = (input: {
  image: string;
  name?: string;
  note?: string;
}) => {
  try {
    return privateRequest(request.post, API_PATH.LIST_BLACKLIST_FACE, {
      data: input,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const similarFaceBlacklist = (image: string) => {
  return privateRequest(
    request.get,
    API_PATH.LIST_BLACKLIST_FACE_SIMILAR + `?image=${image}`,
  );
};

export const listFaceSimilar = (id: number) => {
  const data = {
    query: `{
			list_face(user_id: ${id}) {
				face_id
				url
				type
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

interface IRemoveFace {
  face_id: string;
  user_id: string;
  reason: string;
  url: string;
}

export const removeFaceSimilar = (input: IRemoveFace) => {
  const data = {
    query: `mutation {
			remove_face(${Object.entries(input)
        .map(([key, val]) => `${key}: "${val}"`)
        .join(',')}) {
				message
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
