import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';

interface Result {
  total: number;
  list: any[];
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  const data = {
    query: `
    {
      version(input: { page: ${current ? current - 1 : 0}, size:  ${
      pageSize || 10
    },search_text:"" }) {
        total
        data {
          version
          created_at
          status
          type
        }
      }
    } 
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.version.data.map((e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }));
      return {
        total: res?.data?.version?.total,
        list: result,
      };
    },
  );
};

export const currentVersion = () => {
  const query = `
  {
    currentVersion{
      versionIos
      versionAndroid
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
export const currentVersionLocaBiz = () => {
  const query = `{
    locaBizCurrentVersion {
      versionIos
      statusIos
      versionAndroid
      statusAndroid
    }
  }`;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const getTableDataLocabiz = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  const data = {
    query: `{
      locaBizVersion(input: { page: ${
        current ? current - 1 : 0
      }, size: ${pageSize} }) {
        total
        data {
          version
          status
          created_at
          type
        }
      }
    }
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.locaBizVersion.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
          created_at: formatTime(e.created_at),
          status: e.status ? 'Bắt buộc cập nhật' : 'Không bắt buộc cập nhật',
        }),
      );
      return {
        total: res?.data?.locaBizVersion?.total,
        list: result,
      };
    },
  );
};

export const createVersion = (data: any) => {
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};
