import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useIntl, useParams } from 'umi';
import styles from '../index.less';
import { getDataMeetHistory } from '../service';
import { Form, Image, DatePicker, Row, Button } from 'antd';
import { useAntdTable } from 'ahooks';
import { DownloadOutlined } from '@ant-design/icons';
import { ENVIRONMENTS, blankImageUrl } from '@/utils/constant';
const { RangePicker } = DatePicker;
interface DataType {
  created_at: string;
  meet_percentage: number;
  loca_bonus: number;
  image: string;
  location_name: string;
  connect_user: {
    user_profile: {
      full_name: string;
    };
  };
}

const MeetHistory = ({ totalMeetCount }: { totalMeetCount?: number }) => {
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search } = useAntdTable(getDataMeetHistory, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        id: +params.id,
      },
      {
        toDate: null,
      },
    ],
    form,
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'index',
      align: 'center',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      key: 'created_at',
      render(_: any, record: DataType) {
        return moment(record.created_at).format('HH:mm:ss DD/MM/YYYY');
      },
    },
    {
      title: 'NFT địa điểm',
      dataIndex: 'location_name',
      key: 'location_name',
    },
    {
      title: 'ID địa điểm',
      dataIndex: 'location_id',
      key: 'location_id',
    },
    {
      title: 'ID user meet',
      dataIndex: 'connect_user_id',
      key: 'connect_user_id',
    },
    {
      title: 'Nội dung',
      key: 'connect_user',
      render: (_, record: DataType) => (
        <>{`Kết nối với ${record.connect_user.user_profile.full_name} thành công`}</>
      ),
    },
    {
      title: 'Chất lượng cuộc gặp',
      key: 'meet_percentage',
      render: (_, record: DataType) => <>{`${record.meet_percentage}%`}</>,
    },
    {
      title: 'Số Locabonus',
      dataIndex: 'loca_bonus',
      key: 'loca_bonus',
    },
    {
      title: 'Ảnh Meet',
      key: 'image',
      render: (_, record: DataType) => (
        <>
          <Image
            src={record.image ?? blankImageUrl}
            alt="image_meet"
            style={{
              width: '50px',
              height: '50px',
            }}
            fallback={blankImageUrl}
          />
        </>
      ),
    },
  ];

  const { submit } = search;
  const infoUser = JSON.parse(
    window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    ) as string,
  );

  const adminId = infoUser?.admin_profile?.admin_id;
  const queryExport = () => {
    const date = form.getFieldValue('toDate');
    let query = `/user/export-meet/${adminId}?userId=${params.id}`;
    if (date) {
      const fromDate = Array.isArray(date)
        ? moment(date[0]).format('YYYY-MM-DD')
        : '';
      const toDate = Array.isArray(date)
        ? moment(date[1]).format('YYYY-MM-DD')
        : '';
      query += `&start_time=${fromDate}&end_time=${toDate}`;
    }
    return query;
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Row>
        <Form form={form} className={styles.searchForm}>
          <Form.Item name="toDate" className={styles.searchItem}>
            {/* @ts-ignore */}
            <RangePicker
              format={'DD/MM/YYYY'}
              onChange={submit}
              picker="date"
              className="w-100"
              placeholder={['Từ ngày', 'Đến ngày']}
            />
          </Form.Item>
        </Form>
        <Button
          icon={<DownloadOutlined />}
          href={ENVIRONMENTS.API_URL + queryExport()}
          type="primary"
          ghost
        >
          Xuất Excel
        </Button>
      </Row>
      <div>
        Còn lại
        <b>{` ${totalMeetCount} `}</b>
        lượt meet
      </div>
    </div>
  );
  return (
    <>
      <div className={styles.mainInfoUser}>
        {searchForm}
        <Table
          columns={columns}
          {...tableProps}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
        />
      </div>
    </>
  );
};

export default MeetHistory;
