import React from 'react';
import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import styles from './index.less';
interface IShopLocation {
  hotspot_id: string;
  hotspot_name: string;
}
interface Props {
  children: any;
  position: IShopLocation[];
}
interface DataType {
  key: string;
  hotspot_id: string;
  hotspot_name: string;
}
export default (props: Props) => {
  const { children, position } = props;
  const [visible, setVisible] = React.useState(false);
  const onVisible = () => {
    setVisible(!visible);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'hotspot_id',
      key: 'hotspot_id',
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'hotspot_name',
      key: 'hotspot_name',
    },
  ];
  return (
    <>
      <span onClick={() => onVisible()} className={styles.text}>
        {children}
      </span>
      <Modal
        title="Danh sách gian hàng"
        open={visible}
        onOk={onVisible}
        onCancel={onVisible}
        centered
        footer={null}
      >
        <Table
          rowKey={'hotspot_id'}
          columns={columns}
          dataSource={position}
          pagination={{ pageSize: 5 }}
        />
      </Modal>
    </>
  );
};
