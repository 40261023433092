import { OPTION_STATUS_ACTIVE, ROLE_TYPE } from '@/utils/constant';
import { PERMISSIONS, StatusAccount } from '@/utils/enum';
import { EyeOutlined, LeftOutlined } from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Row,
  Tree,
  message,
  Skeleton,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  useIntl,
  useHistory,
  Link,
  useRequest,
  useLocation,
  useParams,
} from 'umi';
import styles from './index.less';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { createDataRole, editDataRole, getRole } from './service';
import Dialog from './Components/Dialog';
import { TREE_DATA_ROLE } from '@/utils/constant';

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}

export default () => {
  const { t } = useTranslate();

  const [treeDataSubmit, setTreeDataSubmit] = useState<any>([]);

  const [openDialog, setOpenDialog] = useToggle(false);
  const location = useLocation();
  const params: any = useParams();
  const [form] = Form.useForm();
  const { push } = useHistory();

  const [editable, setEditable] = useState<boolean>(true);
  const [defaultCheckedRole, setDefaultCheckedRole] = useState<any[]>([]);
  const [data, setData] = useState(null);

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setTreeDataSubmit(info.checkedNodes);
  };

  const getRoleRequest = useRequest(getRole, {
    manual: true,
    onSuccess(res: any) {
      if (res?.find_role) {
        form.setFieldValue('role_name', res?.find_role?.role_name);
        const listRoleCheck = [];
        for (let role in res?.find_role.permissions) {
          if (role === 'id') continue;
          if (res?.find_role.permissions[role] === PERMISSIONS.FULL) {
            listRoleCheck.push(`${role}_${PERMISSIONS.FULL}`);
            listRoleCheck.push(`${role}_${PERMISSIONS.READ}`);
            listRoleCheck.push(`${role}_${PERMISSIONS.UPDATE}`);
            listRoleCheck.push(`${role}_${PERMISSIONS.CREATED}`);
            listRoleCheck.push(`${role}_${PERMISSIONS.APPROVED}`);
          }
          listRoleCheck.push(`${role}_${res?.find_role.permissions[role]}`);
          // if (res?.find_role.permissions[role] === PERMISSIONS.READ) {
          //   listRoleCheck.push(`${role}_${PERMISSIONS.READ}`);
          // }
        }
        setDefaultCheckedRole(listRoleCheck);
        setData(res?.find_role);
      }
    },
  });

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (params.id) {
      getRoleRequest.run(params.id);
      if (location.pathname.includes('info')) {
        setEditable(false);
      }
    }
  }, [location]);

  const createRoleRequest = useRequest(createDataRole, {
    manual: true,
    onSuccess(data: any) {
      if (!data) {
        message.error('Không có quyền');
      } else {
        message.success(t('message_success'));
        push('/decentralization');
      }
    },
  });
  const editRoleRequest = useRequest(editDataRole, {
    manual: true,
    onSuccess(data: any) {
      if (!data) {
        message.error('Không có quyền');
      } else {
        message.success(t('message_success'));
        push('/decentralization');
      }
    },
  });

  const handleCancel = () => {
    setOpenDialog.set(true);
  };

  const onFinish = (value: any) => {
    const dataSubmit: {
      is_active: boolean;
      permissions: any[];
      role_name: string;
    } = {
      is_active: true,
      role_name: value.role_name,
      permissions: [],
    };
    const newArray: any[] = [];
    const treeData = treeDataSubmit
      .slice()
      .filter((item: any) => {
        return !item.children;
      })
      .map((item: any) => {
        const role_value = item.key?.substring(item.key?.lastIndexOf('_') + 1);
        const role_key = item.key?.substring(0, item.key?.lastIndexOf('_'));
        return {
          key: role_key,
          value: role_value,
        };
      });

    for (let i = 0; i < treeData.length; i++) {
      let keyTree = treeData[i].key;
      let check = false;
      for (let j = 0; j < treeData.length; j++) {
        if (i === j) continue;
        if (keyTree === treeData[j].key) check = true;
      }
      if (check) {
        newArray.push({
          key: keyTree,
          value: PERMISSIONS.FULL,
        });
      } else {
        newArray.push({
          key: keyTree,
          value: treeData[i].value,
        });
      }
    }

    dataSubmit.permissions = newArray.filter((item, index) => {
      const findIndex = newArray.findIndex((itemFind) => {
        return itemFind.key === item.key;
      });
      return index === findIndex;
    });

    if (params.id) {
      editRoleRequest.run({
        id: params.id,
        ...dataSubmit,
      });
      return;
    }
    createRoleRequest.run(dataSubmit);
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Link to="/decentralization" className={styles.previousEditLink}>
          <LeftOutlined />
          <Breadcrumb.Item>
            {location.pathname.includes('decentralization_new') ? (
              <>{formatMessage({ id: 'create_group_decentralization' })}</>
            ) : (
              <>Chi tiết nhóm quyền</>
            )}
          </Breadcrumb.Item>
        </Link>
      </Breadcrumb>
      <div className={styles.tableComponent}>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <Col md={12} sm={24}>
              <Form.Item
                label={formatMessage({ id: 'const_column_group_name' })}
                className={styles.searchItem}
                name={'role_name'}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('const_column_group_name'),
                    }),
                  },
                ]}
              >
                <Input placeholder="nhập tên nhóm quyền" disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.rowTree}>
            <Col span={24}>
              {(() => {
                if (!params.id) {
                  return (
                    <Tree
                      checkable
                      className={styles.tree}
                      onCheck={onCheck}
                      treeData={TREE_DATA_ROLE}
                      defaultExpandAll
                      defaultCheckedKeys={defaultCheckedRole}
                    />
                  );
                }
                if (!getRoleRequest.loading && !!data) {
                  return (
                    <Tree
                      checkable
                      disabled={!editable}
                      className={styles.tree}
                      onCheck={onCheck}
                      treeData={TREE_DATA_ROLE}
                      defaultExpandAll
                      defaultCheckedKeys={defaultCheckedRole}
                    />
                  );
                }
              })()}
            </Col>
          </Row>
          {editable && (
            <Row
              align="middle"
              justify="center"
              className={styles.submitButtonGroups}
            >
              <Col>
                <Button
                  danger
                  className={styles.addButton}
                  onClick={handleCancel}
                >
                  {t('general_cancel')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.addButton}
                  loading={
                    params.id
                      ? createRoleRequest.loading
                      : editRoleRequest.loading
                  }
                >
                  {t('general_save')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
        {openDialog && (
          <Dialog
            open={openDialog}
            setOpen={(b) => {
              setOpenDialog.set(b);
            }}
          />
        )}
      </div>
    </>
  );
};
