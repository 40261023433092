import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import { DatePicker, Form, Input, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAntdTable } from 'ahooks';
import {
  GetStatisticEnterpriseVoucher,
  HistoryReleasedVoucher,
  getReleaseHistoryPartnerVoucher,
} from '../service';
import { useParams } from 'umi';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
const HistoryReleaseVoucher = () => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState<'enterprise' | 'partner'>('enterprise');

  const {
    tableProps: dataLcmVoucher,
    search,
    data,
    run: runTableEntersprise,
  } = useAntdTable(HistoryReleasedVoucher, {
    form,
    manual: true,
  });

  const {
    tableProps: dataPartnerVoucher,
    search: searchPartner,
    run: runTablePartner,
  } = useAntdTable(getReleaseHistoryPartnerVoucher, {
    form,
    manual: true,
  });
  const { submit } = tab === 'enterprise' ? search : searchPartner;
  const columns1: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Tên doanh nghiệp',
      dataIndex: ['enterprise', 'name'],
    },
    {
      title: 'Loại voucher',
      dataIndex: ['type', 'name'],
    },
    {
      title: 'Tổng voucher',
      dataIndex: 'amount',
    },
    {
      title: 'Số voucher thả quà',
      dataIndex: 'gift_amount',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Số voucher vào kho ưu đãi',
      dataIndex: 'inventory_amount',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Người phát hành',
      dataIndex: ['release_by', 'admin_profile', 'full_name'],
    },
  ];

  const columns2: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Loại voucher',
      dataIndex: 'name',
    },
    {
      title: 'Số voucher',
      align: 'right',
      dataIndex: 'amount',
    },

    {
      title: 'Người phát hành',
      dataIndex: 'release_by',
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo ID/tên DN"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  const HistoryLocamosVoucher = (
    <>
      <div className={styles.searchContainer}>
        <div>
          <span>Tổng số voucher: </span>
          <b>{formatNumber(data?.statistic?.amount)}</b>
        </div>
        <div>
          <span>Voucher thả quà: </span>
          <b>{formatNumber(data?.statistic?.gift_amount)}</b>
        </div>
        <div>
          <span>Kho voucher: </span>
          <b>{formatNumber(data?.statistic?.inventory_amount)}</b>
        </div>
      </div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...dataLcmVoucher}
          columns={columns1}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </>
  );
  const HistoryPartnerVoucher = (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...dataPartnerVoucher}
          columns={columns2}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </>
  );
  const renderTab = () => {
    switch (tab) {
      case 'enterprise':
        return HistoryLocamosVoucher;
      case 'partner':
        return HistoryPartnerVoucher;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (tab === 'enterprise') {
      runTableEntersprise({ current: 1, pageSize: 10 }, {});
    } else {
      runTablePartner({ current: 1, pageSize: 10 }, {});
    }
  }, [tab]);

  return (
    <div>
      <div className={styles.searchContainer}>
        <Tabs
          onChange={(key: any) => {
            setTab(key);
            form.resetFields();
          }}
        >
          <Tabs.TabPane tab="Doanh nghiệp" key="enterprise"></Tabs.TabPane>
          <Tabs.TabPane tab="Đối tác" key="partner"></Tabs.TabPane>
        </Tabs>
      </div>
      {renderTab()}
    </div>
  );
};

export default HistoryReleaseVoucher;
