import { API_PATH, privateRequest, request } from '@/utils/apis';
import { useRequest } from 'ahooks';

export interface ITemplateBuilding {
  cost: number;
  count_banner: number;
  count_gift: null;
  count_shop: number;
  created_at: string;
  created_by: string;
  id: number;
  image_primary: string;
  is_deleted: boolean;
  name: string;
  rating: number;
  status: boolean;
  vr_oid: string;
}

export interface ICreateBuildingTemplate {
  name: string;
  vr_oid: string;
  count_shop: number;
  count_banner: number;
  count_gift: number;
  rating: number;
  cost: number;
  status: boolean;
  image_primary: string;
  shopLocation: any;
  bannerLocation: any;
  giftLocation: any;
}

// get all template building
export const useGetBuildingTemplate = () => {
  const { data, loading, run } = useRequest(
    (form?: any, current?: number) => {
      let query = `page_index=${current ? current : 0}&page_size=8`;

      form &&
        Object.entries(form).forEach(([key, value]) => {
          if (value || value === 0) {
            query += `&${key}=${value}`;
          }
        });
      return privateRequest(
        request.get,
        API_PATH.BUILDING_TEMPLATES + '?' + query,
      );
    },
    {
      manual: true,
    },
  );
  return {
    buildingTemplate: data?.data,
    total: data?.total,
    run,
    loading,
  };
};

export const requestCreateBuildingTemplate = (
  data: ICreateBuildingTemplate,
) => {
  return privateRequest(request.post, API_PATH.BUILDING_TEMPLATES, {
    data,
  });
};

export const useGetDetailBuildingTemplate = (id: number) => {
  const { data, loading } = useRequest(() => {
    return privateRequest(request.get, API_PATH.BUILDING_TEMPLATES + `/${id}`);
  });
  return {
    buildingTemplateDetail: data,
    loadDetail: loading,
  };
};
export const requestUpdate = (data: ICreateBuildingTemplate, id: number) => {
  return privateRequest(request.put, API_PATH.BUILDING_TEMPLATES + `/${id}`, {
    data,
  });
};
