// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/root/workspace/locameet-cms/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "exact": true,
    "path": "/login",
    "component": require('@/layouts/AuthLayout/index').default,
    "title": "Login",
    "wrappers": [require('@/layouts/Wrapper').default],
    "routes": [
      {
        "exact": true,
        "path": "/login",
        "component": require('@/pages/SignIn/index').default
      }
    ]
  },
  {
    "exact": false,
    "path": "/",
    "component": require('@/layouts/MainLayout').default,
    "wrappers": [require('@/layouts/Wrapper').default],
    "routes": [
      {
        "exact": true,
        "path": "/admin",
        "component": require('@/pages/Admin/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/admin-history",
        "component": require('@/pages/Admin/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/decentralization",
        "component": require('@/pages/Admin/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/admin-detail/:id",
        "component": require('@/pages/AdminEditAccount/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/admin_new_account/",
        "component": require('@/pages/AdminNewAccount/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/admin_edit_account/:id",
        "component": require('@/pages/AdminEditAccount/index').default,
        "title": "navigation_admin",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/kyc",
        "component": require('@/pages/Kyc/index').default,
        "title": "navigation_user_kyc",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/kyc-detail/:id",
        "component": require('@/pages/KycInfo/index').default,
        "title": "navigation_user_kyc",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/user-management",
        "component": require('@/pages/Users/index').default,
        "title": "navigation_user",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/user-management/:id",
        "component": require('@/pages/UsersInfo/index').default,
        "title": "navigation_user_info",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gifts",
        "component": require('@/pages/Gifts/index').default,
        "title": "navigation_gifts",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gifts-infomation/:id",
        "component": require('@/pages/GiftInfomation/index').default,
        "title": "navigation_gifts_infomation",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/new-gift",
        "component": require('@/pages/GiftsNew/index').default,
        "title": "navigation_gifts",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/edit-gift/:id",
        "component": require('@/pages/GiftsNew/index').default,
        "title": "navigation_gifts",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gifts-infomation/:id",
        "component": require('@/pages/GiftInfomation/index').default,
        "title": "navigation_gifts_infomation",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/list_gift",
        "component": require('@/pages/GiftLists/index').default,
        "title": "navigation_gifts",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/notification",
        "component": require('@/pages/Notification/index').default,
        "title": "navigation_notification",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/notification/:id",
        "component": require('@/pages/Notification/NotificationUser/DetailNotification').default,
        "title": "navigation_notification",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-notification",
        "component": require('@/pages/Notification/NotificationUser/CreateNotification').default,
        "title": "navigation_notification",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/group_user",
        "component": require('@/pages/Notification/index').default,
        "title": "navigation_notification",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/decentralization",
        "component": require('@/pages/Decentralization/index').default,
        "title": "navigation_group_decentralization",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/decentralization_new",
        "component": require('@/pages/DecentralizationInfo/index').default,
        "title": "navigation_group_decentralization",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/decentralization_edit/:id",
        "component": require('@/pages/DecentralizationInfo/index').default,
        "title": "navigation_group_decentralization",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/decentralization_info/:id",
        "component": require('@/pages/DecentralizationInfo/index').default,
        "title": "navigation_group_decentralization",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/store",
        "component": require('@/pages/Business/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/brand",
        "component": require('@/pages/Business/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/link-account",
        "component": require('@/pages/Business/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business",
        "component": require('@/pages/Business/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/store/:id",
        "component": require('@/pages/Business/Components/DetailStore').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/brand",
        "component": require('@/pages/Business/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business/brand/:id",
        "component": require('@/pages/Business/Components/DetailBrand').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business_new",
        "component": require('@/pages/BusinessNewAccount/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/business_detail/:id",
        "component": require('@/pages/BusinessInfo/index').default,
        "title": "navigation_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/setting/*",
        "component": require('@/pages/Setting/index').default,
        "title": "setting",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/setting/banner/*",
        "component": require('@/pages/Setting/index').default,
        "title": "setting",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-banner-meet",
        "component": require('@/pages/Setting/Component/Banner/CreateBannerMeet').default,
        "title": "setting",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/detail-banner-meet/:id",
        "component": require('@/pages/Setting/Component/Banner/DetailBannerMeet').default,
        "title": "setting",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/total_Locabonus",
        "component": require('@/pages/TotalLocabonus/index').default,
        "title": "total_Locabonus",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/handle_dispute",
        "component": require('@/pages/DisputeHandle/index').default,
        "title": "handle_dispute",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/handle_dispute_gift",
        "component": require('@/pages/DisputeHandle/index').default,
        "title": "handle_dispute",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/handle_dispute_voucher",
        "component": require('@/pages/DisputeHandle/index').default,
        "title": "handle_dispute",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/dispute_detail/:id",
        "component": require('@/pages/DisputeInfo/DisputeLocabonus/index').default,
        "title": "handle_dispute",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/dispute_gift_detail/:id",
        "component": require('@/pages/DisputeInfo/DisputeGift/index').default,
        "title": "handle_dispute",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/dispute_voucher_detail/:id",
        "component": require('@/pages/DisputeInfo/DisputeVoucher/index').default,
        "title": "handle_dispute",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/company",
        "component": require('@/pages/Company/index').default,
        "title": "company",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/company/:id",
        "component": require('@/pages/CompanyInfo/index').default,
        "title": "company",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/version",
        "component": require('@/pages/Version/index').default,
        "title": "Version",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/locabonus_release",
        "component": require('@/pages/LocabonusRelease/index').default,
        "title": "LocabonusRelease",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/locabonus_transfer",
        "component": require('@/pages/LocabonusTransfer/index').default,
        "title": "LocabonusTransfer",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/blacklist_device",
        "component": require('@/pages/BlacklistDevice/index').default,
        "title": "BlacklistDevice",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/building-template",
        "component": require('@/pages/BuildingTemplate/index').default,
        "title": "BuildingTemplate",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-template",
        "component": require('@/pages/BuildingTemplate/CreateTemplate/index').default,
        "title": "CreateTemplate",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/building-template/:id",
        "component": require('@/pages/BuildingTemplate/DetailTemplate/index').default,
        "title": "DetailTemplate",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/loca-building",
        "component": require('@/pages/LocaBuilding/index').default,
        "title": "LocaBuilding",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/loca-building/:id",
        "component": require('@/pages/LocaBuilding/DetailBuilding/index').default,
        "title": "LocaBuilding",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/update-loca-building/:id",
        "component": require('@/pages/LocaBuilding/UpdateBuilding/index').default,
        "title": "LocaBuilding",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-building",
        "component": require('@/pages/LocaBuilding/CreateBuilding/index').default,
        "title": "CreateLocaBuilding",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/stall",
        "component": require('@/pages/StallList/index').default,
        "title": "StallList",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-stall",
        "component": require('@/pages/StallList/CreateStall/index').default,
        "title": "CreateStall",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/stall/:id",
        "component": require('@/pages/StallList/DetailStall/index').default,
        "title": "stall",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/update-stall/:id",
        "component": require('@/pages/StallList/UpdateStall/index').default,
        "title": "stall",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/billboards",
        "component": require('@/pages/BillBoards/index').default,
        "title": "BillBoards",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-billboards",
        "component": require('@/pages/BillBoards/CreateBillBoards/index').default,
        "title": "CreateBillBoards",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/billboards/:id",
        "component": require('@/pages/BillBoards/DetailBillBoards/index').default,
        "title": "DetailBillBoards",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/update-billboards/:id",
        "component": require('@/pages/BillBoards/UpdateBillBoards/index').default,
        "title": "UpdateBillBoards",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-building",
        "component": require('@/pages/GiftBuilding/index').default,
        "title": "Quản lý quà tặng",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-events",
        "component": require('@/pages/GiftBuilding/index').default,
        "title": "Quản lý sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-statistics",
        "component": require('@/pages/GiftBuilding/index').default,
        "title": "Thống kê nhặt quà",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-events/:id",
        "component": require('@/pages/GiftBuilding/DetailEvent/index').default,
        "title": "Quản lý sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-gift-events",
        "component": require('@/pages/GiftBuilding/CreateEvent/index').default,
        "title": "Tạo sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-gift-building",
        "component": require('@/pages/GiftBuilding/CreateGift/index').default,
        "title": "Quản lý quà tặng",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-building/:id",
        "component": require('@/pages/GiftBuilding/DetailGift/index').default,
        "title": "Chi tiết quà tặng",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/list-received-gift/:id",
        "component": require('@/pages/GiftBuilding/ListReceivedGift/index').default,
        "title": "ListReceivedGift",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/enterprise_customer_request",
        "component": require('@/pages/CustomerLocabonusRequest/index').default,
        "title": "enterprise_customer_request",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/enterprise_customer_request/:id",
        "component": require('@/pages/CustomerRequestDetail/index').default,
        "title": "enterprise_customer_request",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher_urbox/*",
        "component": require('@/pages/VoucherUrbox/index').default,
        "title": "voucher_urbox",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher_urbox",
        "component": require('@/pages/VoucherUrbox/index').default,
        "title": "voucher_urbox",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/released",
        "component": require('@/pages/Voucher/index').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/history",
        "component": require('@/pages/Voucher/index').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/bad_reports",
        "component": require('@/pages/Voucher/index').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher",
        "component": require('@/pages/Voucher/index').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/statistic_voucher/:id",
        "component": require('@/pages/Voucher/pages/StatisticVoucher').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/released/:id",
        "component": require('@/pages/Voucher/pages/ManageTypeVoucher').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/released-partner/:id",
        "component": require('@/pages/Voucher/pages/ManageTypeVoucherPartner').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/transfer_statistics",
        "component": require('@/pages/Voucher/index').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/transfer_history/:id",
        "component": require('@/pages/Voucher/pages/TransferHistory').default,
        "title": "voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/liveness",
        "component": require('@/pages/Liveness/index').default,
        "title": "liveness",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/liveness-detail/:id",
        "component": require('@/pages/Liveness/pages/LivenessDetail').default,
        "title": "liveness",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/transfer_voucher_web",
        "component": require('@/pages/TransferVoucherWeb/index').default,
        "title": "transfer_voucher_web",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event",
        "component": require('@/pages/DiscountEvent/index').default,
        "title": "discount_event",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event/report",
        "component": require('@/pages/DiscountEvent/index').default,
        "title": "discount_event",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create_discount_event",
        "component": require('@/pages/DiscountEvent/Component/CreateDiscountEvent').default,
        "title": "discount_event",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event/:id",
        "component": require('@/pages/DiscountEvent/Component/DetailDiscountEvent').default,
        "title": "discount_event",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event/report/:id",
        "component": require('@/pages/DiscountEvent/Component/DetailStaticVoucherEvent').default,
        "title": "discount_event",
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/products",
        "component": require('@/pages/Products/index').default,
        "title": "product_management",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/products/:id",
        "component": require('@/pages/Products/Component/DetailProduct').default,
        "title": "product_management",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/order",
        "component": require('@/pages/Order').default,
        "title": "order_management",
        "wrappers": [require('@/layouts/Wrapper').default]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
