import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';
export enum ORDER_STATUS {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
}
export const getAllEnterprise = (search?: string) => {
  const params: any = {};
  if (!!search) {
    params.search = search;
  }
  return privateRequest(request.get, API_PATH.ENTERPRISE_ORDER, { params });
};

export const getListOrder = (
  {
    current,
    pageSize,
    status,
  }: { current: number; pageSize: number; status: ORDER_STATUS },
  formData: {
    search?: string;
    enterprise_id?: number;
    toDate?: string[];
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
    status,
  };
  if (!!formData.search) {
    params.search = formData.search;
  }
  if (!!formData.enterprise_id) {
    params.enterprise_id = formData.enterprise_id;
  }
  if (Array.isArray(formData.toDate)) {
    params.date_from = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.date_to = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  return privateRequest(request.get, API_PATH.LIST_ORDER, {
    params,
  }).then((response) => {
    return {
      list: response.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: response?.total,
    };
  });
};

export const getOrderDetailService = ({
  current,
  pageSize,
  idOrder,
}: {
  current: number;
  pageSize: number;
  idOrder: string;
}) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  return privateRequest(request.get, API_PATH.DETAIL_ORDER(idOrder), {
    params,
  }).then((response) => {
    return {
      list: response?.data?.order_details?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: response?.total,
      data: response,
    };
  });
};
