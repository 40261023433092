import React from 'react';
import ReactEcharts from 'echarts-for-react';
interface IPropChart {
  data: any[];
}
const GenderChart = ({ data }: IPropChart) => {
  const options = {
    title: {
      text: 'Biểu đồ giới tính của người nhặt quà',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'horizontal',
      bottom: 0,
    },
    series: [
      {
        name: 'Giới tính',
        type: 'pie',
        radius: '60%',
        data: data?.map((item: any) => ({
          value: item?.count,
          name:
            item?._id === 'MALE'
              ? 'Nam'
              : item?._id === 'FEMALE'
              ? 'Nữ'
              : 'Khác',
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts option={options}></ReactEcharts>
    </div>
  );
};

export default GenderChart;
