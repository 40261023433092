import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName?: string;
    toDate: string[];
    type?: string;
  },
): Promise<Result> => {
  if (!formData.fullName) {
    formData.fullName = '';
  }

  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format()
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format()
    : '';

  const data = {
    query: `
    mutation {
      get_dao_list_transaction(
        input: {
          page_index:${current ? current - 1 : 0},
          page_size:${pageSize || 10},
          date_start: "${fromDate}",
          date_end: "${toDate}",
          search_text:"${formData.fullName}",
          ${formData.type ? `type: ${formData.type}` : ''}
        }
      ) {
        total
        total_locabonus
        transactions {
          record_id
          amount
          type
          description
          created_at
        }
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.get_dao_list_transaction?.transactions.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    // console.log(res?.data?.get_dao_list_transaction?.total_locabonus);

    return {
      total: res?.data?.get_dao_list_transaction?.total,
      list: data,
      total_locabonus: res?.data?.get_dao_list_transaction?.total_locabonus,
    };
  });
};

export const getTotalLCB = (): Promise<any> => {
  const data = {
    query: `
    mutation {
      get_dao_list_transaction(
        input: {
          page_index:1,
          page_size:1,
        }
      ) {
        total_locabonus
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    return {
      total_locabonus: res?.data?.get_dao_list_transaction?.total_locabonus,
    };
  });
};
export const handleKycService = (
  userId: number | string,
  isAccept: boolean,
  reason?: string,
) => {
  const data = {
    query: `
      mutation {
        admin_handle_kyc_request(handle: { userId: ${userId}, status: ${isAccept}, ${
      reason ? `reason: "${reason}"` : ''
    } }) {
          message
        }
      }    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};
