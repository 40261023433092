import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Row, Select, Slider, message } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CreateGroupUser, createGroupUser, listProviceUser } from '../service';
import styles from '../index.less';
interface IModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}
const AddGroupUserModal = ({ isOpen, setIsOpen, refresh }: IModalProps) => {
  const listProvince = useRequest(listProviceUser);
  const [form] = Form.useForm();
  const handleClose = () => {
    form.resetFields();
    setIsOpen(false);
  };
  const onFinish = (val: any) => {
    console.log(val);
    const payload: CreateGroupUser = {
      name: val.name,
      age_range_min: val.age[0],
      age_range_max: val.age[1],
      gender: val.gender,
      provinces:
        val?.provinces === 'all'
          ? []
          : Array.isArray(val.provinces)
          ? val.provinces
          : [val.provinces],
    };
    createGroup.run(payload);
  };
  const createGroup = useRequest(createGroupUser, {
    manual: true,
    onSuccess(res) {
      message.success('Tạo nhóm user thành công');
      refresh();
      handleClose();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
      console.log(err);
    },
  });

  const [modeSelect, setModeSelect] = useState<any>();

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      width={600}
      title="Tạo nhóm user"
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Tên nhóm user"
          rules={[{ required: true, message: 'Vui lòng nhập' }]}
        >
          <Input placeholder="Nhập tên nhóm user" />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Giới tính"
          rules={[{ required: true, message: 'Vui lòng chọn' }]}
          initialValue="ALL"
        >
          <Select>
            <Select.Option value="ALL">Tất cả</Select.Option>
            <Select.Option value="MALE">Nam</Select.Option>
            <Select.Option value="FEMALE">Nữ</Select.Option>
            {/* <Select.Option value="OTHER">Giới tính khác</Select.Option> */}
          </Select>
        </Form.Item>
        <Form.Item label="Độ tuổi" name="age" initialValue={[20, 50]}>
          <Slider
            range
            tooltip={{
              open: true,
              placement: 'left',
            }}
          />
        </Form.Item>
        <Form.Item label="Thành phố" name="provinces" initialValue={'all'}>
          <Select
            placeholder="Chọn thành phố"
            mode={modeSelect}
            onChange={(val) => {
              if (val === 'all' || val?.includes('all')) {
                setModeSelect('');
                form.setFieldValue('provinces', 'all');
              } else {
                setModeSelect('multiple');
              }
            }}
          >
            <Select.Option key={-1} value={'all'}>
              Tất cả
            </Select.Option>
            {listProvince.data?.map((item: any, i: number) => (
              <Select.Option key={i} value={item?.province}>
                {item?.province}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row justify="center" className={styles.addGroupButton}>
          <Button
            danger
            type="primary"
            ghost
            onClick={handleClose}
            className={styles.addButton}
          >
            Huỷ
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className={styles.addButton}
            loading={createGroup.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddGroupUserModal;
