import React from 'react';
import { checkRoleAdmin } from '@/utils/apis/common';
import { ENVIRONMENTS, ROLE_TYPE } from '@/utils/constant';
import { PERMISSIONS } from '@/utils/enum';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Skeleton,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';

import { useIntl, useHistory, useRequest } from 'umi';

import styles from '../index.less';
import Dialog from './Dialog';
import { getTableData, switchStatusAdmin } from '../service';

interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: string;
}
const AdminManage = () => {
  const [openDialog, setOpenDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const [form] = Form.useForm();

  const infoUser = JSON.parse(
    window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    ) as string,
  );

  const switchActiveRequest = useRequest(switchStatusAdmin, {
    manual: true,
    onSuccess(res: any) {
      if (res) {
        message.success(formatMessage({ id: 'message_success' }));
      } else {
        message.error('Không có quyền');
      }
      search.submit();
    },
    onError() {
      search.submit();
    },
  });

  const history = useHistory();
  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const handleViewAdmin = (idAdmin: number | string) => {
    history.push('/admin-detail/' + idAdmin.toString());
  };
  const handleEditAdmin = (idAdmin: number | string) => {
    history.push('/admin_edit_account/' + idAdmin.toString());
  };
  const handleNewAdmin = () => {
    history.push('/admin_new_account/');
  };
  const handleActiveAdmin = (isAdmin: number | string) => {
    setIdSelected(isAdmin);
    setOpenDialog.set(true);
  };

  const onSwitchStatus = (id: string) => {
    switchActiveRequest.run(id);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_full_name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'const_column_email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'const_column_phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'const_column_create_date',
      dataIndex: 'dateOfBirth',
      align: 'right',
      key: 'dateOfBirth',
    },
    {
      title: 'const_column_status',
      dataIndex: 'status',
      key: 'status',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.status === true ? (
              <Tag color="green">{formatMessage({ id: 'status_active' })}</Tag>
            ) : (
              <Tag color="red">{formatMessage({ id: 'status_inactive' })}</Tag>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_action',
      dataIndex: 'custom',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.activeButton}>
            {infoUser?.role?.permissions?.admin_management_level === 'FULL' && (
              <>
                <Tooltip title={formatMessage({ id: 'general_edit' })}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEditAdmin(record.id)}
                  >
                    <EditOutlined className="primary-color" />
                  </div>
                </Tooltip>
                <Tooltip
                  title={formatMessage({ id: 'general_view_infomation' })}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewAdmin(record.id)}
                  >
                    <EyeOutlined className="purple-color" />
                  </div>
                </Tooltip>
                <Tooltip
                  title={formatMessage({ id: 'general_tooltip_delete' })}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleActiveAdmin(record.id)}
                  >
                    <DeleteOutlined className="red-color" />
                  </div>
                </Tooltip>
              </>
            )}

            {infoUser?.role?.permissions?.admin_management_level === 'FULL' && (
              <Tooltip title={formatMessage({ id: 'status' })}>
                <Switch
                  defaultChecked={record.status}
                  onClick={() => {
                    onSwitchStatus(record?.id);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_admin' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      {checkRoleAdmin(ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL) ===
        PERMISSIONS.FULL && (
        <Button onClick={() => handleNewAdmin()}>
          {formatMessage({ id: 'general_add_admin' })}
        </Button>
      )}
    </div>
  );
  return (
    <div>
      <div className={styles.tableComponent}>
        {searchForm}
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            rowKey={(item) => item.id}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )}
    </div>
  );
};

export default AdminManage;
