import {
  Breadcrumb,
  Form,
  Select,
  Table,
  Input,
  Row,
  Button,
  message,
  Skeleton,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useIntl, history } from 'umi';
import styles from './index.less';
import { getTableData, requestChangeStatus } from './service';
import { useAntdTable, useRequest } from 'ahooks';
import moment from 'moment';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

// const { Option } = Select;

export default () => {
  const [form] = Form.useForm();
  const optionStatus = [
    {
      value: 1,
      label: 'Hoạt động',
    },
    {
      value: 0,
      label: 'Không hoạt động',
    },
  ];
  const optionOrder = [
    {
      label: 'Truy cập tháng lớn nhất',
      value: 'cost_by_month-desc',
    },
    {
      label: 'Truy cập tháng nhỏ nhất',
      value: 'cost_by_month-asc',
    },
    {
      label: 'Tổng truy cập lớn nhất',
      value: 'cost_by_total-desc',
    },
    {
      label: 'Tổng truy cập nhỏ nhất',
      value: 'cost_by_total-asc',
    },
  ];
  const { tableProps, search, loading, error } = useAntdTable(getTableData, {
    defaultPageSize: 10,
    form,
    onError: (error: any) => {
      message.error(
        error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
      );
    },
  });

  const onChangeStatus = useRequest(
    (id: number, status: boolean) => requestChangeStatus(id, status),
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
      },
      onError: () => {
        message.error('Thất bại');
      },
    },
  );

  const { submit } = search;
  const { formatMessage } = useIntl();

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Tên biển quảng cáo',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tên Building',
      dataIndex: 'building_name',
      key: 'building_name',
    },
    {
      title: 'Truy cập tháng này',
      dataIndex: 'cost_by_month',
      key: 'cost_by_month',
    },
    {
      title: 'Tổng lượt truy cập',
      dataIndex: 'cost_by_total',
      key: 'cost_by_total',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 180,
      render: (value: any, record: any, index: number) => {
        return (
          <Space size={20}>
            <Row className={styles.action}>
              <Tooltip title="Xem">
                <EyeOutlined
                  className="purple-color"
                  onClick={() => history.push(`/billboards/${record.id}`)}
                />
              </Tooltip>

              <Tooltip title="Sửa">
                <EditOutlined
                  className="primary-color"
                  onClick={() =>
                    history.push(`/update-billboards/${record.id}`)
                  }
                />
              </Tooltip>
            </Row>
            <Switch
              defaultChecked={record.status}
              onChange={(value: boolean) =>
                onChangeStatus.run(record.id, value)
              }
            />
          </Space>
        );
      },
    },
  ];

  const onOrder = () => {
    const order = form.getFieldValue('order').split('-');
    form.setFieldValue('sort_key', order?.[0]);
    form.setFieldValue('sort_dir', order?.[1]);
    submit();
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        autoComplete="off"
        className={styles.searchForm}
        layout="inline"
      >
        <Row>
          <Form.Item name="sort_key" hidden></Form.Item>
          <Form.Item name="sort_dir" hidden></Form.Item>
          <Form.Item name="search_text" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm Tên builing, Tên biển quảng cáo"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="status" className={styles.searchItem}>
            <Select
              options={optionStatus}
              placeholder="Trạng thái"
              allowClear
              onChange={submit}
            />
          </Form.Item>
          <Form.Item name="order" className={styles.searchItem}>
            <Select
              options={optionOrder}
              placeholder="Sắp xếp theo"
              allowClear
              onChange={onOrder}
            />
          </Form.Item>
        </Row>
      </Form>
      <Button onClick={() => history.push('/create-billboards')}>
        Tạo quảng cáo
      </Button>
    </div>
  );
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Danh sách biển quảng cáo</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            rowKey="id"
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )}
      </div>
    </>
  );
};
