import moment from 'moment';
import { ITemplateBuilding } from '../service';
import styles from './index.less';
import { Card, Rate, Typography } from 'antd';
import { useHistory } from 'umi';
export default ({ template }: { template: ITemplateBuilding }) => {
  const history = useHistory();
  return (
    <Card
      onClick={() => history.push(`/building-template/${template.id}`)}
      className={styles.item}
    >
      <img
        src={template.image_primary}
        alt={template.image_primary}
        className={styles.img}
      />
      <Typography.Text strong className={styles.title}>
        {template.name}
      </Typography.Text>
      <p className={styles.create}>
        Ngày tạo: {moment(template?.created_at).format('DD/MM/YYYY')}
      </p>
      <Rate disabled value={template.rating} className={styles.rate} />
      <p>{template.cost} LocaBonus</p>
    </Card>
  );
};
