import { DeleteOutlined, LeftOutlined, UserOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  Upload,
  UploadFile,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { history, Link, useIntl } from 'umi';
import styles from './index.less';

import { useTranslate } from '@/utils/hooks/useTranslate';
import { useRequest, useToggle } from 'ahooks';
import Dialog from './Components/Dialog';
import { onSubmitValue } from './service';
import { getAllRole } from '../AdminEditAccount/service';
import { PERMISSIONS } from '@/utils/enum';
import {
  GoogleMap,
  InfoWindow,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import {
  AVERAGE_INVOICE,
  BUSINESSS_SCALE,
  BUSINESS_TYPE,
  ENVIRONMENTS,
} from '@/utils/constant';
import UploadEnterpriseInfo from '@/components/UploadEnterpriseInfo';
import { API_PATH } from '@/utils/apis';
import { RcFile } from 'antd/lib/upload';
import MapReaflat from '@/components/MapReaflat';
import { paternPhone } from '@/utils/patern';
import { formatInputNumber } from '@/utils/apis/common';
import { getListOrgs } from '../UsersInfo/service';

export default () => {
  ///MAP
  const [pickerPosition, setPickerPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  } | null>(null);

  const [pos, setPos] = useState<any>({});

  const [position, setPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  }>({});
  const [fileList, setFileList] = useState<{
    enterprise_image: UploadFile[];
    license_image: UploadFile[];
    identity_backside: UploadFile[];
    identity_front: UploadFile[];
    passport_image: UploadFile[];
    bill_template: UploadFile[];
    qr_transfer: UploadFile[];
    logo: UploadFile[];
  }>({
    enterprise_image: [],
    license_image: [],
    identity_backside: [],
    identity_front: [],
    passport_image: [],
    bill_template: [],
    qr_transfer: [],
    logo: [],
  });

  const [identityType, setIdentityType] = useState(1);
  const [centerMap, setCenterMap] = useState<any>({
    lat: 20.99827918867865,
    lng: 105.8252441372345,
  });

  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );
    form.setFieldValue('province_id', province?.id);

    setPickerPosition({
      lat: pos?.lat,
      lng: pos?.long,
      customName: pos?.name,
    });
  };

  // const deletePosition = (i: number) => {
  //   const newList = listPosition?.slice();
  //   newList?.splice(i, 1);
  //   // @ts-ignore
  //   setListPosition(newList);
  // };

  ////==================================================================
  const { formatMessage } = useIntl();

  const [dataRole, setDataRole] = useState<any[]>([]);

  useRequest(getAllRole, {
    onSuccess(res: any) {
      if (res?.data?.get_list_role?.data) {
        setDataRole(res?.data?.get_list_role?.data);
      }
    },
  });

  const [selectedRoleId, setSelectedRoleId] = React.useState<string>('');
  const [checkedRole, setCheckedRole] = React.useState<any>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedRoleId && selectedRoleId !== '') {
      handleRoleChecked(selectedRoleId);
    }
  }, [selectedRoleId]);

  const handleRoleChecked = (id: string) => {
    const roles = dataRole.find((dataRole) => dataRole.id === id);
    if (!roles.permissions) return;
    const listRoleCheck = [];
    for (let role in roles.permissions) {
      if (role === 'id') continue;
      if (roles.permissions[role] === PERMISSIONS.FULL) {
        listRoleCheck.push(`${role}_${PERMISSIONS.FULL}`);
        listRoleCheck.push(`${role}_${PERMISSIONS.READ}`);
      }
      if (roles.permissions[role] === PERMISSIONS.READ) {
        listRoleCheck.push(`${role}_${PERMISSIONS.READ}`);
      }
    }
    setCheckedRole(listRoleCheck);
  };

  const resetForm = () => {
    form.resetFields();
    setPosition({});
    setPickerPosition(null);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const requestCreateUser = useRequest(onSubmitValue, {
    manual: true,
    onSuccess(data: any) {
      if (data?.errors) {
        if (data?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else if (
          (data.errors[0].message = 'account identity already exist')
        ) {
          message.error(formatMessage({ id: 'message_add_business_failure' }));
        }
      } else {
        history.push('/business');
        resetForm();
        message.success(formatMessage({ id: 'message_business_success' }));
      }
    },
  });

  const { Option } = Select;

  const handleChange = (value: string) => {
    setSelectedRoleId(value);
  };

  const { t } = useTranslate();
  const [openDialog, setOpenDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const handleDelete = () => {
    setOpenDialog.set(true);
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const requestOrgs = useRequest(getListOrgs);

  const onFinish = (values: any) => {
    const submitValues = {
      ...values,
      email: values.email,
      phone: values.phone,
      password: values.password,
      address: pickerPosition?.customName,
      status: values.status,
      lat: pickerPosition?.lat,
      lng: pickerPosition?.lng,
      register_create_gift: values.register_create_gift ? true : false,
      register_pickup_gift_location: values.register_pickup_gift_location
        ? true
        : false,
      register_release_voucher: values.register_release_voucher ? true : false,
      // roleId: values.roleId,
    };
    const data = {
      query: `
      mutation {
        admin_add_new_enterprise(
          input: {
            name: "${submitValues.name}"
            email: "${submitValues.email}"
            password: "${submitValues.password}"
            phone: "${submitValues.phone}"
            address: "${submitValues.address}"
            brand_name: "${submitValues.brand_name}"
            tax_code: "${submitValues.tax_code}"
            business_type: ${submitValues.business_type}
            scale: ${submitValues.scale}
            average_invoice: ${submitValues.average_invoice}
            bank: "${submitValues.bank}"
            bank_account_name: "${submitValues.bank_account_name}"
            bank_account_number: "${submitValues.bank_account_number}"
            owner_name: "${submitValues.owner_name}"
						limit_to_give_lb: ${submitValues.limit_to_give_lb}
						register_create_gift: ${submitValues.register_create_gift}
						register_pickup_gift_location: ${submitValues.register_pickup_gift_location}
						register_release_voucher: ${submitValues.register_release_voucher}
            status: ${
              submitValues.status !== undefined ? submitValues.status : true
            }
            lat:${submitValues.lat}
            long:${submitValues.lng}
						description: "${submitValues.description}"
						province_id: "${submitValues.province_id}"
            ${
              fileList.enterprise_image.length
                ? `enterprise_image: "${fileList.enterprise_image[0]?.response.path}"`
                : ''
            }
            ${
              fileList.license_image.length
                ? `license_image: "${fileList.license_image[0]?.response.path}"`
                : ''
            }
            ${
              fileList.identity_front.length
                ? `identity_front: "${fileList.identity_front[0]?.response.path}"`
                : ''
            }
            ${
              fileList.identity_backside.length
                ? `identity_backside: "${fileList.identity_backside[0]?.response.path}"`
                : ''
            }
            ${
              fileList.passport_image.length
                ? `passport_image: "${fileList.passport_image[0]?.response.path}"`
                : ''
            }
            ${
              fileList.bill_template.length
                ? `bill_template: "${fileList.bill_template[0]?.response.path}"`
                : ''
            }
						${
              fileList.qr_transfer.length
                ? `qr_transfer: "${fileList.qr_transfer[0]?.response.path}"`
                : ''
            }
						${fileList.logo.length ? `logo: "${fileList.logo[0]?.response.path}"` : ''}
          }
        ) {
          data {
            name
            email
            phone
            lat
            long
            password
          }
          message
        }
      }
    `,
    };
    requestCreateUser.run(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/business" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'business_new_account' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.tableComponent}>
        <Form
          form={form}
          layout="horizontal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          className={styles.mainInfoUser}
          autoComplete="off"
          onFinish={onFinish}
          labelWrap
          labelAlign="left"
          labelCol={{
            span: 5,
          }}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="name"
                label={t('const_column_business_name')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('const_column_business_name'),
                    }),
                  },
                ]}
              >
                <Input placeholder={t('const_column_business_name')} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="phone"
                label="SĐT"
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: 'Số điện thoại',
                    }),
                  },
                  {
                    pattern: paternPhone,
                    message: t('error.patern', {
                      field: t('phone_number'),
                    }),
                  },
                ]}
              >
                <Input placeholder={t('phone_number')} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="email"
                label={t('email')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('email'),
                    }),
                  },
                  {
                    type: 'email',
                    message: t('error.email', {
                      field: t('email'),
                    }),
                  },
                ]}
              >
                <Input placeholder={t('email')} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="password"
                label={t('password')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('password'),
                    }),
                  },
                  {
                    min: 8,
                    max: 99,
                    message: t('error.password', {
                      field: t('password'),
                    }),
                  },
                ]}
              >
                <Input.Password placeholder={t('password')} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="enter_password"
                label={t('enter_password')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: form.getFieldValue('password') ? true : false,
                    message: t('error.require', {
                      field: t('password'),
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('password_no_match')));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('enter_password')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="status"
            label={t('status')}
            style={{
              textAlign: 'start',
            }}
          >
            <Switch />
          </Form.Item>

          <Row
            justify="space-between"
            style={{
              margin: '15px 0',
            }}
          >
            <h2>Thông tin doanh nghiệp</h2>
          </Row>

          <Form.Item
            name="tax_code"
            label={t('tax_code')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('tax_code_input')} />
          </Form.Item>
          <Form.Item
            name="brand_name"
            label={t('brand_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('brand_name')} />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'business_type' })}
            name="business_type"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              placeholder={t('business_type_input')}
              options={BUSINESS_TYPE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'scale' })}
            name="scale"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              placeholder={t('scale_input')}
              options={BUSINESSS_SCALE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'average_invoice' })}
            name="average_invoice"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              placeholder={t('average_invoice_input')}
              options={AVERAGE_INVOICE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'limit_to_give_lb' })}
            name="limit_to_give_lb"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <InputNumber
              controls={false}
              style={{
                width: '100%',
              }}
              formatter={formatInputNumber}
              addonAfter="VND"
              min={1}
            />
          </Form.Item>
          <div
            style={{
              margin: '20px 0',
            }}
          >
            <Form.Item>
              <Checkbox value={true} checked={true}>
                Đăng ký làm địa điểm Locabiz
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_create_gift" valuePropName="checked">
              <Checkbox defaultChecked={false} checked={false}>
                Đăng ký thả quà
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="register_pickup_gift_location"
              valuePropName="checked"
            >
              <Checkbox defaultChecked={false} checked={false}>
                Đăng ký địa điểm nhận quà
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_release_voucher" valuePropName="checked">
              <Checkbox defaultChecked={false} checked={false}>
                Đăng ký phát hành voucher
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            className="uploadItem"
            name="logo"
            label="Logo"
            getValueFromEvent={(e) => normFile(e)}
          >
            <Upload
              accept=".png,.jpg,.jpeg"
              action={
                ENVIRONMENTS.API_URL +
                `${
                  ENVIRONMENTS.DEV_ENV ? API_PATH.UPLOAD_DEV : API_PATH.UPLOAD
                }`
              }
              onPreview={onPreview}
              fileList={fileList.logo}
              listType="picture-card"
              onChange={(val: any) => {
                setFileList((state: any) => ({
                  ...state,
                  logo: val.fileList,
                }));
              }}
              onRemove={() => {
                setFileList((state: any) => ({
                  ...state,
                  logo: [],
                }));
              }}
            >
              {fileList.logo.length < 1 && 'Tải lên'}
            </Upload>
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả ngắn"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={2} placeholder="Nhập mô tả" />
          </Form.Item>
          <Row>
            <Col xs={24}>
              <Form.Item
                // name="address"
                label={t('address')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('address'),
                    }),
                  },
                ]}
              >
                {/* @ts-ignore */}
                <Input
                  placeholder={t('address')}
                  value={pickerPosition?.customName}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Khu vực" name="province_id">
            <Select
              placeholder="Chọn khu vực"
              options={requestOrgs.data?.data?.get_all_orgs?.map(
                (item: any) => ({
                  label: item.name,
                  value: item.id,
                }),
              )}
            />
          </Form.Item>
          <Row
          // style={{
          //   textAlign: 'center',
          // }}
          >
            <Col xs={24}>
              <Form.Item>
                <MapReaflat
                  setPos={onSelectPos}
                  listPosition={[]}
                  loading={false}
                />
                {pickerPosition ? (
                  <Row gutter={16}>
                    <Col span={12} className={styles.dialogFormItem}>
                      <Form.Item label="Lat">
                        <Input value={pickerPosition.lat} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={styles.dialogFormItem}>
                      <Form.Item label="Long">
                        <Input value={pickerPosition.lng} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Form.Item>
            </Col>
          </Row>

          <div className={styles.lisenceImage}>
            <Row>
              <Col span={12}>
                <Form.Item
                  className="uploadItem"
                  name="enterprise_image"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.enterprise_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: [],
                      }));
                    }}
                  >
                    {fileList.enterprise_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh chụp cửa hàng</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="uploadItem"
                  name="license_image"
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.license_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: [],
                      }));
                    }}
                  >
                    {fileList.license_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh đăng ký kinh doanh</p>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  className="uploadItem"
                  name="bill_template"
                  style={{
                    margin: '10px 0',
                  }}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.bill_template}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: [],
                      }));
                    }}
                  >
                    {fileList.bill_template.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh hóa đơn</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="uploadItem"
                  name="qr_transfer"
                  style={{
                    margin: '10px 0',
                  }}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.qr_transfer}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: [],
                      }));
                    }}
                  >
                    {fileList.qr_transfer.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh QR chuyển khoản</p>
              </Col>
            </Row>
          </div>

          <h2 className={styles.subtitle}>Thông tin ngân hàng</h2>
          <Form.Item
            name="bank_account_name"
            label={t('bank_account_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('bank_account_name_input')} />
          </Form.Item>
          <Form.Item
            name="bank"
            label={t('bank')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('bank')} />
          </Form.Item>
          <Form.Item
            name="bank_account_number"
            label={t('bank_account_number')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('bank_account_number_input')} />
          </Form.Item>
          <h2 className={styles.subtitle}>Thông tin chủ doanh nghiệp</h2>
          <Form.Item
            name="owner_name"
            label={t('owner_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={t('owner_name')} />
          </Form.Item>

          <div className={styles.identityUpload}>
            <Row
              align="middle"
              className={styles.identityType}
              style={{
                margin: '15px 0',
              }}
            >
              <span className={styles.identityTypeLabel}>
                Giấy tờ của chủ quán:
              </span>
              <Select
                style={{
                  marginLeft: '18px',
                }}
                className={styles.selectIdentityType}
                onChange={(val) => setIdentityType(val)}
                value={identityType}
                options={[
                  {
                    label: 'CCCD/CMND',
                    value: 1,
                  },
                  {
                    label: 'Hộ chiếu',
                    value: 2,
                  },
                ]}
              />
            </Row>
            {identityType === 1 ? (
              <Row
                style={{
                  textAlign: 'center',
                }}
              >
                <Col span={12}>
                  <Form.Item
                    className="uploadItem"
                    name="identity_front"
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOAD
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_front}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: [],
                        }));
                      }}
                    >
                      {fileList.identity_front.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt trước</p>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="uploadItem"
                    name="identity_backside"
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOAD
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_backside}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: [],
                        }));
                      }}
                    >
                      {fileList.identity_backside.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt sau</p>
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Form.Item
                  className="uploadItem"
                  name="passport_image"
                  rules={[
                    {
                      required: identityType === 2 ? true : false,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOAD
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.passport_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: [],
                      }));
                    }}
                  >
                    {fileList.passport_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Hộ chiếu
                </p>
              </Row>
            )}
          </div>

          <Row
            align="middle"
            justify="center"
            className={styles.submitButtonGroup}
          >
            <Col>
              <Button
                danger
                onClick={handleDelete}
                className={styles.addButton}
              >
                {t('general_cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.addButton}
                loading={requestCreateUser.loading}
              >
                {t('general_save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={idSelected}
        />
      )}
    </>
  );
};
