import { Breadcrumb, Space, Tabs } from 'antd';
import { Link, history } from 'umi';

import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import Info from './Components/Info';
import Report from './Components/Report';
import { LeftOutlined } from '@ant-design/icons';

// const { Option } = Select;

export default () => {
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/billboards" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Chi tiết biển quảng cáo
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin biển quảng cáo" key="1">
            <Info />
          </TabPane>
          <TabPane tab="Báo cáo tương tác" key="2">
            <Report />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
