export default {
  // page.component.translation
  'index.hello': 'ZH - {name}',
  'index.title': 'ZH Title',
  'about.title': 'ZH - About - Title',
  // error
  error: 'Có lỗi xảy ra',
  'error.require': '{field} không được để trống.',
  'error.email': 'Email không đúng định dạng.',
  'error.patern': '{field} không đúng định dạng.',
  'error.password': '{field} phải lớn hơn 8 kí tự',
  image_empty: 'Bắt buộc đính kèm ảnh',
  ok_text: 'Có',
  cancel_text: 'Không',
  should_delete: 'Bạn có muốn xoá không ?',
  password_no_match: 'Mật khẩu không khớp',
  organization: 'Quốc gia',
  nation: 'Tỉnh/ Thành phố',
  date_created: 'Ngày tham gia',
  required: 'Vui lòng nhập',
  //mesages

  STT: 'STT',
  message_success: 'Thành công !',
  message_send_success: 'Gửi thành công !',
  message_kyc_success: 'Cập nhật trạng thái xác thực thành công !',
  message_user_success: 'Cập nhật tài khoản thành công !',
  message_add_user_success: 'Thêm tài khoản thành công !',
  message_coupon_success: 'Cập nhật mã khuyến mãi thành công !',
  message_add_coupon_success: 'Thêm mã khuyến mãi thành công !',
  message_failure: 'Thất bại !',
  message_kyc_failure: 'Cập nhật trạng thái xác thực thất bại !',
  message_user_failure: 'Cập nhật tài khoản thất bại !',
  message_add_user_failure: 'Cập nhật tài khoản thất bại !',
  message_coupon_failure: 'Cập nhật mã khuyến mãi thất bại !',
  message_add_coupon_failure: 'Thêm mã khuyến mãi thất bại !',
  message_admin_success: 'Cập nhật admin thành công !',
  message_admin_failure: 'Cập nhật admin thất bại !',
  message_delete_admin_success: 'Xoá admin thành công !',
  message_delete_admin_failure: 'Xoá admin thất bại !',
  message_add_admin_success: 'Thêm mới admin thành công !',
  message_add_admin_failure: 'Tài khoản đã tồn tại!',
  message_add_business_failure: 'Số điện thoại hoặc email đã tồn tại!',
  message_delete: 'Bạn chắc chắn muốn xoá tài khoản này ?',
  message_restore: 'Bạn muốn khôi phục tài khoản này ?',
  message_status: 'Bạn muốn thay đổi trạng thái tài khoản này?',
  message_lock: 'Bạn muốn khóa tài khoản này ?',
  message_unlock: 'Bạn muốn mở khóa tài khoản này ?',
  message_delete_decentralization: 'Bạn chắc chắn muốn xoá nhóm quyền này?',
  message_switch_success: 'Cập nhật trạng thái thành công',
  message_business_success: 'Thêm mới tài khoản thành công',
  // Form

  password: 'Mật khẩu',
  setting: 'Cài đặt hệ thống',
  membership: 'Gói membership',
  locabonus: 'Số lượt tặng',
  enter_password: 'Nhập lại mật khẩu',
  user_or_phone_number: 'Tài khoản hoặc số điện thoại',
  phone_number: 'Số điện thoại',

  tax_code: 'Mã số thuế',
  tax_code_input: 'Mã số thuế/Mã số hộ kinh doanh',
  brand_name: 'Tên thương hiệu',
  business_type: 'Ngành nghề',
  business_type_input: 'Chọn ngành nghề kinh doanh',
  scale: 'Quy mô',
  scale_input: 'Chọn quy mô khách hàng',
  average_invoice: 'Trung bình 1 hóa đơn',
  limit_to_give_lb: 'Giới hạn tặng Locabonus',
  abaha_id: 'Liên kết cửa hàng Abaha',
  average_invoice_input: 'Chọn giá trị trung bình 1 hóa đơn',
  license_image: 'Ảnh đăng ký kinh doanh',
  bank_information: 'Thông tin ngân hàng',
  bank_account_name: 'Chủ tài khoản',
  bank_account_name_input: 'Chủ tài khoản ngân hàng',
  bank_account_number: 'Số tài khoản',
  bank_account_number_input: 'Số tài khoản ngân hàng',
  bank: 'Ngân hàng',
  bank_input: 'Tên ngân hàng',
  enterprise_owner_information: 'Thông tin chủ doanh nghiệp',
  owner_name: 'Tên chủ doanh nghiệp',
  enterprise_image:
    'Ảnh chụp cửa hàng (Cần có đủ thông tin tên và địa chỉ trên biển hiệu)',
  bill_template: 'Ảnh chụp hóa đơn của cửa hàng',
  owner_name_input: 'Nhập tên chủ doanh nghiệp',

  login: 'Đăng nhập',
  register: 'Đăng ký',
  fullname: 'Họ và tên',
  reason: 'Nhập lý do',
  status: 'Trạng thái',
  gift: 'Quà tặng',
  avatar: 'Ảnh đại diện',
  gifts_time: 'Thời gian diễn ra',
  gifts_name: 'Tên quà tặng',
  gifts_count: 'Số lượng quà tặng',
  piece_count: 'Số lượng mảnh ghép',
  status_verify: 'Trạng thái xác thực',
  address: 'Địa chỉ',
  email: 'Email',
  name: 'Tên',
  identification_code: 'Mã định danh',
  date_of_birth: 'Ngày sinh',
  kyc_type: 'Loại giấy tờ',
  content: 'Nội dung',
  title: 'Tiêu đề',
  thumbnail: 'Ảnh thumbnail',
  image: 'Hình ảnh',
  sale_time: 'Thời gian bán',
  date_provided: 'Ngày cấp',
  place_of_issue: 'Nơi cấp',
  reason_reject: 'Lý do từ chối',
  promotion_code: 'Mã khuyến mãi',
  promotion_send: 'Hình thức gửi',
  promotion_name: 'Tên chương trình',
  promotion_time: 'Thời gian chương trình',
  picture_verified: 'Ảnh chụp xác minh',
  promotion_range: 'Phạm vi áp dụng',
  promotion_type: 'Hình thức khuyến mãi',
  discount: 'Giảm giá',
  from_date: 'Từ ngày',
  to_date: 'Đến ngày',
  method_name: 'Tên phương thức',
  payment_type: 'Loại phương thức',
  description: 'Mô tả',
  display: 'Hiển thị',
  receiverAccount: 'Tài khoản nhận điểm của đối tác',
  referenceNumber: 'Mã tham chiếu của đối tác',
  user: 'Người dùng',
  topic: 'Chủ đề',
  decentralization: 'Phân quyền',
  notification_recipient: 'Người nhận thông báo',
  total_Locabonus: 'Locabonus Tài khoản tổng',
  total_locabonus_mount: 'Tổng Locabonus',
  handle_dispute: 'Xử lý tranh chấp',
  transfer_voucher_web: 'Yêu cầu chuyển Voucher Web-App',
  company: 'Công ty thành viên',
  locabonus_transfer: 'Thống kê giao dịch LocaBonus',
  locabonus_release: 'Thống kê Locabonus phát hành',
  vietnamese: 'Tiếng Việt',
  english: 'Tiếng Anh',
  account: 'Tài khoản',
  logout: 'Đăng xuất',
  auth_login: 'Locamos CMS',
  auth_phone: 'Số điện thoại',
  auth_password: 'Mật khẩu',
  status_active: 'Hoạt động',
  status_inactive: 'Không hoạt động',
  status_deleted: 'Đã xóa',
  version: 'Danh sách phiên bản',
  withdraw: 'Thu hồi Locabonus',

  //navigation
  // navitation_home: 'Trang chủ',
  navigation_profile: 'Tài khoản của tôi',
  navigation_user: 'Quản lý tài khoản người dùng',
  navigation_user_info: 'Chi tiết tài khoản người dùng',
  navigation_user_kyc: 'Quản lý yêu cầu KYC',
  navigation_liveness: 'Quản lý yêu cầu liveness',
  navigation_locabonus_release: 'Thống kê Locabonus phát hành',
  navigation_user_kyc_info: 'Chi tiết yêu cầu xác thực',
  navigation_customer_request: 'Chi tiết yêu cầu',
  navigation_new_gifts: 'Tạo quà tặng',
  navigation_edit_gifts: 'Chỉnh sửa quà tặng',
  navigation_gifts_infomation: 'Thông tin quà tặng',
  navigation_admin: 'Quản lý tài khoản admin',
  navigation_vihicle: 'Xe',
  navigation_lock: 'Khoá xe',
  navigation_travel: 'Chuyến đi',
  navigation_gifts: 'Quản lý quà tặng',
  navigation_ticket: 'Vé thuê xe',
  navigation_method: 'Phương thức thanh toán',
  navigation_promotion: 'Mã khuyến mại',
  navigation_tutorial: 'Thông tin hưỡng dẫn sử dụng',
  navigation_news: 'Tin tức',
  navigation_rate: 'Đánh giá',
  navigation_notification_template: 'Danh mục thông báo',
  navigation_notification: 'Quản lý thông báo',
  navigation_group_decentralization: 'Quản lý nhóm phân quyền',
  navigation_blacklist_device: 'Danh sách máy bị khóa',
  navigation_list_gift: 'Danh sách nhận quà',
  navigation_business: 'Tài khoản doanh nghiệp',
  navigation_enterprise_customer_request: 'Yêu cầu tặng Locabonus',
  navigation_list_business: 'Danh sách tài khoản doanh nghiệp',
  navigation_list_company: 'Danh sách công ty thành viên',
  navigation_history_locaBonus: 'Lịch sử tặng LocaBonus',

  //breadcrumb
  user_management: 'Quản lý người dùng',
  user_management_list_user: 'Quản lý người dùng',
  user_management_list_kyc: 'Xác thực người dùng',
  create_group_decentralization: 'Tạo nhóm quyền',
  admin_edit_account: 'Sửa tài khoản admin',
  admin_view_account: 'Xem tài khoản admin',
  admin_new_account: 'Tạo tài khoản admin',
  notification_create_new: 'Tạo thông báo mới',
  notification_detail: 'Chi tiết thông báo',
  business_new_account: 'Tạo tài khoản doanh nghiệp',
  business_name: 'Tên doanh nghiệp',
  enterprise_customer_request: 'Yêu cầu tặng Locabonus',
  voucher_urbox: 'Quản lý Voucher Urbox',
  admin_management_detail_user: 'Chi tiết tài khoản admin',
  voucher: 'Quản lý voucher Locamos',
  liveness: 'Quản lý yêu cầu liveness',
  discount_event: 'Quản lý CTKM',
  discount_event_detail: 'Thống kê tài khoản áp dụng',
  order_management: 'Quản lý đơn hàng',
  product_management: 'Quản lý sản phẩm',
  //general

  general_view_infomation: 'Xem thông tin',
  general_edit_infomation: 'Sửa thông tin',
  general_point_success: 'Thành công',
  general_point_pending: 'Đang chờ',
  general_point_fail: 'Thất bại',
  general_preview_image: 'Phóng to',
  general_tooltip_show_infomation: 'Xem thông tin',
  general_tooltip_delete: 'Xoá',
  general_tooltip_restore: 'Khôi phục',
  general_tooltip_active: 'Trạng thái hoạt động',
  general_tooltip_lock: 'Khóa',
  general_tooltip_unlock: 'Mở khóa',
  general_tooltip_accept: 'Duyệt ',
  general_tooltip_denied: 'Từ chối',
  general_tooltip_send_noti: 'Gửi thông báo',
  general_export_excel: 'Xuất excel',

  general_add: 'Thêm mới',
  general_accept: 'Xác nhận',
  general_add_admin: 'Thêm tài khoản',
  general_edit: 'Chỉnh sửa',
  general_save: 'Lưu',
  general_cancel: 'Huỷ',
  general_verify: 'Xác thực',
  general_gender: 'Giới tính',
  general_gender_male: 'Nam',
  general_gender_female: 'Nữ',
  general_gender_other: 'Khác',
  general_all: 'Tất cả',
  general_user: 'Người dùng',
  general_on: 'Bật',
  general_off: 'Tắt',
  general_noti_user: 'Theo người dùng',
  general_transfer_type: 'Loại giao dịch',
  general_amount_locabonus: 'Số lượng LocaBonus',
  general_noti_topic: 'Theo chủ đề',
  general_personal_info: 'Thông tin cá nhân',
  general_gifts_info: 'Thông tin quà tặng',

  general_payment_vtc: 'VTC_PAY',
  general_payment_domestic_bank: 'Ngân hàng nội địa',
  general_payment_international_card: 'Thẻ thanh toán quốc tế',

  general_status_active: 'Trạng thái hoạt động',
  general_inactive: 'Không hoạt động',
  general_active: 'Hoạt động',
  general_deleted: 'Đã xóa',
  general_checkpoint: 'Tạm khóa',
  general_status_account: 'Trạng thái xác thực',
  general_approved_by: 'Phương thức duyệt',
  general_approved_admin: 'Duyệt tay',
  general_approved_system: 'Duyệt tự động',
  general_denied_verify: 'Từ chối xác thực',
  general_denied_kyc: 'Từ chối',
  general_accept_kyc: 'Phê duyệt',
  general_success_verify: 'Xác thực thành công',
  general_waiting_verify: 'Chờ xác thực',

  //enterprise_type:
  general_enterprise_normal: 'DN thường',
  general_enterprise_locacafe: 'Locacafe',
  general_enterprise_locamart: 'Locamart',
  //business_type
  general_food_drink: 'Ăn uống',
  general_restaurant: 'Nhà hàng',
  general_hotel: 'Khách sạn',
  general_fashion: 'Thời trang',
  general_grocery: 'Tạp hóa',
  general_mart: 'Siêu thị',
  general_healthcare: 'Chăm sóc sức khỏe',
  general_houseware: 'Gia dụng',

  FOOD_DRINK: 'Ăn uống',
  RESTAURANT: 'Nhà hàng',
  HOTEL: 'Khách sạn',
  FASHION: 'Thời trang',
  GROCERY: 'Tạp hóa',
  MART: 'Siêu thị',
  HEALTHCARE: 'Chăm sóc sức khỏe',
  HOUSEWARE: 'Gia dụng',

  PENDING: 'Chờ xác thực',
  ACCEPT: 'Đã xác thực',
  REFUSE: 'Từ chối xác thực',
  //business_scale
  general_small_scale: 'Từ 100 - 300 khách/ngày',
  general_medium_scale: 'Từ 300 - 1000 khách/ngày',
  general_large_scale: 'Trên 1000 khách/ ngày',

  //business_average
  general_from50to200: 'Từ 50.000 - 200.000',
  general_from200to500: 'Từ 200.000 - 500.000',
  general_from500to1000: 'Từ 500.000 - 1 triệu',
  general_from1000to3000: 'Từ 1 triệu - 3 triệu',
  general_over3000: 'Trên 3 triệu',

  general_kyc_not_verified: 'Chưa xác thực',
  general_pending_kyc: 'Chờ xác thực',
  general_verified_kyc: 'Đã xác thực',
  general_cancel_kyc: 'Từ chối xác thực',
  general_pending_request: 'Chờ xử lý',
  general_approve_request: 'Đã duyệt',
  general_refuse_request: 'Đã từ chối',
  general_meet: 'MEET_TO_EARN',
  general_use: 'USE_TO_EARN',
  general_withdraw: 'WITHDRAW',

  general_kyc_type: 'Loại giấy tờ',
  general_kyc_type_IDcard: 'CMTND/CCCD/Hộ chiếu',
  general_kyc_type_passport: 'Hộ chiếu',
  general_kyc_type_driving_license: 'Bằng lái xe',

  general_kyc_photo_type: 'Loại hình ảnh',
  general_kyc_photo_type_front: 'Ảnh giấy tờ mặt trước',
  general_kyc_photo_type_back: 'Ảnh giấy tờ mặt sau',

  general_type_notification: 'Loại thông báo',
  notification_to_push: 'Bản thông báo đẩy',
  notification_via_sms: 'Thông báo qua SMS',
  notification_via_email: 'Thông báo qua Email',
  general_puzzle_piece_list: 'Danh sách mảnh ghép',
  general_tooltip_edit: 'Chỉnh sửa',
  general_loca: 'Thêm/Trừ lượt tặng',
  general_add_invite_code: 'Thêm người giới thiệu',

  general_checkpoint_keep_login: 'Đăng nhập liên tục',
  general_checkpoint_many_account: 'Đăng nhập nhiều tài khoản',
  general_checkpoint_admin_add: 'Admin khóa',
  general_checkpoint_fake_gps: 'Fake GPS',
  //form
  form_search_text: 'Nhập từ khoá',
  form_search_text_admin: 'Tìm kiếm theo tên, email, sđt',
  form_search_text_action: 'Tìm kiếm theo người tác động, nội dung tác động',
  form_search_text_device: 'Tìm kiếm theo mã thiết bị hoặc UserId',
  form_search_title: 'Tìm kiếm theo tiêu đề',
  form_search_name_decentralization: 'Tìm kiếm theo tên nhóm quyền',
  form_search_blacklist_domain: 'Tìm kiếm thành viên theo tên, email, sđt',
  form_search_name: 'Tìm kiếm theo tên người dùng',
  time_sending: 'Thời gian gửi',

  //column table
  const_column_empty: 'Không có dữ liệu',
  const_column_full_name: 'Họ và tên',
  const_column_gifts_name: 'Ảnh mảnh ghép',
  const_column_count: 'Số lượng',
  const_column_position: 'Vị trí',
  const_column_phone_number: 'Số điện thoại',
  const_column_email: 'Email',
  const_column_email_or_phone: 'Email hoặc số điện thoại',
  const_column_date_of_birth: 'Ngày sinh',
  const_column_create_date: 'Thời gian tạo',
  const_column_invited_by: 'Người giới thiệu',
  const_column_request_at: 'Thời gian yêu cầu',
  const_column_pickup_time: 'Thời gian',
  const_column_pickup_code: 'Mã vật phẩm',
  const_column_pickup_item_name: 'Tên vật phẩm',
  const_column_pickup_position: 'Toạ độ lấy vật phẩm (vĩ độ kinh độ)',
  const_column_update_time: 'Thời gian cập nhật',
  const_column_update_by: 'Người cập nhật',
  const_column_update_info: 'Nội dung cập nhật',
  const_column_status: 'Trạng thái',
  const_column_verify_status: 'Xác thực',
  const_column_action: 'Hành động',
  const_column_title: 'Tiêu đề',
  const_column_content: 'Nội dung',
  const_column_content_image: 'Nội dung và hình ảnh (nếu có)',
  const_column_description_image: 'Mô tả và hình ảnh (nếu có)',
  const_column_star: 'Sao',
  const_column_message: 'Phản hồi',
  const_column_group_name: 'Tên nhóm quyền',
  const_column_date_editend: 'Ngày sửa đổi lần cuối',
  const_column_item_name: 'Tên mảnh ghép',
  const_column_password: 'Mật khẩu',
  const_column_business_name: 'Tên doanh nghiệp',
  const_column_local: 'Số Loca bonus',

  const_column_local_user: 'Số LB user nhận',
  const_column_local_business: 'Số LB DN nhận',

  const_column_phone: 'Số điện thoại',
  const_column_employee: 'Nhân viên',
  const_column_receiver: 'Người nhận',
  const_column_loca: 'Số Loca bonus',
  const_column_img_bill: 'Ảnh hoá đơn',

  const_column_customer: 'Tên khách hàng',
  const_column_use_service: 'Số lần sử dụng dịch vụ',
  const_column_loca_received: 'Số Loca bonus đã nhận',
  const_column_gift: 'Số lần nhận quà',

  const_column_bill: 'Giá trị hóa đơn',
  const_column_point_target: 'Chi tiêu tích điểm',
  const_column_cumulative_target: 'Chi tiêu tích lũy',
  const_column_deal_id: 'ID giao dịch',
  const_column_deal: 'Loại giao dịch',
  const_column_locabonus: 'Số LB nhận',

  const_column_dispute_time: 'Thời gian phát sinh',
  const_column_code: 'Mã giao dịch',
  const_column_dispute_people: 'Người chuyển điểm',
  const_column_dispute_recipient: 'Người nhận điểm',

  //business
  business_member: 'Gói lượt tặng',
  busness_point: 'Số lượt Tặng',
  customer_list_go_shop: 'Danh sách khách hàng đến quán',
};
