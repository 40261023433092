import { privateRequest, request, API_PATH } from '@/utils/apis';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
  totalLB: number;
}

export const getPointLocaBonus = (
  {
    current,
    pageSize,
    id,
  }: {
    current: number;
    pageSize: number;
    id: number;
  },
  formData?: {
    toDate?: string[];
  },
): Promise<Result> => {
  const fromDate = Array.isArray(formData?.toDate)
    ? moment(formData?.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData?.toDate)
    ? moment(formData?.toDate[1]).format('YYYY-MM-DD')
    : '';
  const query = `
  mutation {
    get_detail_user_transaction_history(input: {
     	page_index:${current ? current - 1 : 0},
      page_size:${pageSize},
      user_id: ${id},
			start_time: "${fromDate}",
			end_time: "${toDate}"
    }) {
      total
      totalLB
      data {
        created_at
        transaction_id
        user_id
        type
        amount
        description
        status
      }
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  }).then((res: any) => {
    const data = res?.data?.get_detail_user_transaction_history?.data?.map(
      (user: any, index: any) => ({
        ...user,
        stt: (current ? current - 1 : 0) * Number(pageSize) + (index + 1),
      }),
    );
    return {
      total: res?.data?.get_detail_user_transaction_history?.total,
      list: data,
      totalLB: res?.data?.get_detail_user_transaction_history?.totalLB,
    };
  });
};

export const getOneUser = (userId: string) => {
  // const query = `

  // mutation {
  //   get_user_profile(userId: ${userId}
  //                   ) {
  //     userId
  //     full_name
  //     phone
  //     email
  //     date_created
  //     organization {
  //       name
  //       code
  //       id
  //     }
  //     nation {
  //       name
  //       code
  //       id
  //     }
  //     status
  //     kyc_status
  //     ref_status
  //     user_profile {
  //       userId
  //       avatar
  //       full_name
  //       date_of_birth
  //       date_created
  //       gender
  //       address
  //       bluetooth_id
  //       invite_code
  //       organization {
  //         id
  //         name
  //       }
  //       nation {
  //         name
  //       }
  //       kyc_status
  //       meet_count_default
  //       meet_count_bonus
  //       ref_status
  //       email
  //       phone
  //     }
  //     update_history {
  //       author
  //       update_at
  //       update_field
  //       old_value
  //       new_value
  //     }
  //     user_identity {
  //       id
  //       userId
  //       request_at
  //       full_name
  //       phone
  //       email
  //       type_identity
  //       verification_status
  //       identity_backside
  //       identity_front
  //       verify_image
  //       date_of_birth
  //       date_of_issue
  //       place_of_issue
  //       identity_id
  //       address
  //     }
  //     parent {
  //       userId
  //       full_name
  //       phone
  //       email
  //       date_created
  //       status
  //       kyc_status
  //       ref_status
  //     }
  //     children {
  //       userId
  //       full_name
  //       phone
  //       email
  //       date_created
  //       status
  //       kyc_status
  //       ref_status
  //     }
  //   }
  // }

  // `;
  return privateRequest(
    request.get,
    API_PATH.GET_ONE_USER.replace(':id', userId),
  );
};

export const getUserHistory = ({
  current,
  pageSize,
  id,
}: {
  current: number;
  pageSize: number;
  id: number;
}) => {
  const query = `?user_id=${id}&page_size=${pageSize}&page_index=${
    current - 1
  }`;
  return privateRequest(request.get, API_PATH.GET_USER_HISTORY + query).then(
    (res) => {
      const data = res.update_history;
      return {
        list: data,
        total: res?.total,
      };
    },
  );
};

interface FilterChildren {
  page_size: number;
  page_index: number;
}

export const getUserChildren = (
  {
    current,
    pageSize,
    id,
  }: {
    current: number;
    pageSize: number;
    id: number;
  },
  formData: {
    search_value: string;
  },
) => {
  let query = `page_index=${current - 1}&page_size=${pageSize}`;
  Object.entries(formData).forEach(([key, value]) => {
    if (value) {
      query += `&${key}=${value}`;
    }
  });
  return privateRequest(
    request.get,
    API_PATH.GET_USER_CHILDREN.replace(':id', id.toString()) + '?' + query,
  ).then((res) => {
    return {
      total: res?.total,
      list: res?.children,
      invited_success: res?.invited_success,
    };
  });
};

export const getChildren = (id: number) => {
  return privateRequest(request.get, API_PATH.GET_CHILDREN + `?id=${id}`);
};

export const getUserIdentity = (userId: string) => {
  return privateRequest(
    request.get,
    API_PATH.GET_USER_IDENTITY.replace(':id', userId),
  );
};

export const getDataPickupHistory = (userId: any) => {
  const query = `
  mutation {
    getItemPickUpHistory(userId: ${userId}) {
      receive_time
      gift_pieceId
      name
      receive_at
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const updateUserInfo = (formData: any) => {
  const data = {
    query: `    
      mutation {
        admin_update_user(
          updateInput: {
            userId: ${formData.userId}
            ${formData.full_name ? `full_name: "${formData.full_name}"` : ''},
            ${
              formData.date_of_birth
                ? `date_of_birth: "${formData.date_of_birth}"`
                : ''
            }
            ${formData.phone ? `phone: "${formData.phone}"` : ''}
						${formData.auth_phone ? `auth_phone: "${formData.auth_phone}"` : ''}
            ${formData.email ? `email: "${formData.email}"` : ''}
            ${formData.gender ? `gender: "${formData.gender}"` : ''}
            ${formData.orgsId ? `organization_id: "${formData.orgsId}"` : ''}
            ${formData?.address ? `address:"${formData.address}"` : ''}
						${formData?.nationId ? `nation_id: "${formData.nationId}"` : ''}
          }
        ) {
          message
        }
      }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const submitTransferAccount = (payload: any) => {
  const { fromId, toId } = payload;
  const data = {
    query: `    
      mutation {
        transfer_account(input: {
          fromId: ${fromId}
          toId: ${toId}
        })
      }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const blockUserTransfer = (
  userId: number,
  block: boolean,
  reason: string,
) => {
  const data = {
    query: `mutation {
      admin_block_user_transaction(input: { userId: ${userId}, block: ${block}, reason: "${reason}" }) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const blockBizUser = (input: {
  userId: number;
  block: boolean;
  reason?: string;
}) => {
  return privateRequest(request.post, API_PATH.ADMIN_BLOCK_BIZ_USER, {
    data: input,
  });
};

export const cancelMembershipUser = (userId: number, reason: string) => {
  return privateRequest(request.post, API_PATH.CANCEL_MEMBERSHIP, {
    data: {
      userId,
      reason,
    },
  });
};

export const resetPinUser = (userId: number) => {
  const data = {
    query: `mutation {
      resetPinUser(userId: ${userId}) {
        message
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const handleKycService = (
  userId: number | string,
  isAccept: boolean,
  reason?: string,
) => {
  const data = {
    query: `
      mutation {
        admin_handle_kyc_request(handle: { userId: ${userId}, status: ${isAccept}, ${
      reason ? `reason: "${reason}"` : ''
    } }) {
          message
        }
      }    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getListOrgs = () => {
  const data = {
    query: `
    {
      get_all_orgs {
        id
        name
        code
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getListNation = () => {
  const data = {
    query: `
    {
      get_all_nation {
        id
        name
        code
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getDataMeetHistory = (
  {
    current,
    pageSize,
    id,
  }: {
    current: number;
    pageSize: number;
    id: number;
  },
  formData: {
    toDate: string[] | null;
  },
) => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `{
      list_user_meet_history(id: ${id}, input: { page_index: ${
      current - 1
    }, page_size: ${pageSize}, ${
      formData.toDate ? `from_date: "${fromDate}", to_date: "${toDate}"` : ''
    } }) {
        data {
          id
          user_id
          connect_user_id
          location_id
          created_at
          updated_at
          meet_percentage
          lat
          long
          request_user_lat
          request_user_long
          connect_user_lat
          connect_user_long
          loca_bonus
          image
          link_search
          location_name
          connect_user {
            user_profile {
              full_name
            }
          }
        }
        total
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.list_user_meet_history?.data?.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.list_user_meet_history?.total,
      list: data,
    };
  });
};

export const getListDeviceLogin = (
  {
    current,
    pageSize,
    userId,
  }: { current: number; pageSize: number; userId: number },
  formData: {
    searchValue: string;
    toDate: string[] | null;
  },
) => {
  if (formData.searchValue === undefined) {
    formData.searchValue = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `mutation {
      user_login_device(input: {user_id: ${userId}, page_size: ${pageSize}, page_index: ${
      current - 1
    }, search_value: "${formData.searchValue}" ${
      formData.toDate ? `startTime: "${fromDate}", endTime: "${toDate}"` : ''
    }},) {
        total
        data {
          time
          device_id
					version_app
					os
					is_root
        }
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res) => {
      const data = res?.data?.user_login_device?.data?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.user_login_device?.total,
        list: data,
      };
    },
  );
};

export const withdrawUserPoint = (input: {
  userId: number;
  amount: number;
}) => {
  const data = {
    query: `mutation {
      withdraw_user_point(input: {user_id: ${input.userId}, amount: ${input.amount}}) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getDataCallHistory = (
  {
    current,
    pageSize,
    user_id,
  }: { current: number; pageSize: number; user_id: number },
  formData: {
    toDate?: string[];
    searchValue?: string;
  },
) => {
  const params: {
    user_id: number;
    page_size: number;
    page_index: number;
    start_time?: string;
    end_time?: string;
    partner_id?: number;
  } = {
    user_id,
    page_index: current,
    page_size: pageSize,
  };
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!formData.searchValue) {
    params.partner_id = +formData.searchValue;
  }
  if (!!fromDate && !!toDate) {
    params.start_time = fromDate;
    params.end_time = toDate;
  }
  return privateRequest(request.get, API_PATH.CALL_HISTORY, { params }).then(
    (res) => {
      return {
        list: res.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + index + 1,
        })),
        total: res.total,
        total_call_count: res.total_call_count,
        total_receive_count: res.total_receive_count,
      };
    },
  );
};
export const getLivenessProfile = (userId: number, sync?: boolean) => {
  const data = {
    query: `{
			list_similar_faces_by_liveness(id: ${userId}) {
				user_id
				is_liveness
				liveness_session
				liveness_reason
				url_liveness
				status
				list_similar_faces(similarInput: {${sync ? `sync: ${sync}` : ''}}) {
					img
					id
					full_name
					match_rate
				}
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const verifyLivenessProfile = (userId: number, reason: string) => {
  const data = {
    query: `mutation {
			verify_liveness_profile(id: ${userId}, reason: "${reason}") {
				message
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const refuseLivenessProfile = (userId: number, reason: string) => {
  const data = {
    query: `mutation {
			refuse_liveness_profile(id: ${userId}, reason: "${reason}") {
				message
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const setTransferLimitService = (input: {
  user_id: number;
  receiver_id: number;
  limit: number;
}) => {
  return privateRequest(request.post, API_PATH.TRANSFER_LIMIT, {
    data: { ...input },
  });
};
