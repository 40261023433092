import react, { useState } from 'react';
import { Tabs } from 'antd';
import DisputeLocabonus from './Component/DisputeLocabonus';
import styles from './index.less';
import DisputeGift from './Component/DisputeGift';
import { useHistory } from 'umi';
import DisputeVoucher from './Component/DisputeVoucher';
import { PERMISSIONS } from '@/utils/enum';
import { useAuth } from '@/utils/hooks/useAuth';
export default () => {
  const [tab, setTab] = useState<string>('1');
  const history = useHistory();
  const { getAdminLocalStore } = useAuth();
  const admin: any = getAdminLocalStore();
  const renderTab = () => {
    switch (history.location.pathname) {
      case '/handle_dispute':
        return <DisputeLocabonus />;
      case '/handle_dispute_gift':
        return <DisputeGift />;
      case '/handle_dispute_voucher':
        return <DisputeVoucher />;
    }
  };
  return (
    <>
      <Tabs
        onChange={(key: string) => {
          history.push(key);
        }}
        defaultActiveKey={history.location.pathname.replace('/', '')}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="Chuyển điểm" key="handle_dispute"></Tabs.TabPane>
        <Tabs.TabPane tab="Chuyển quà" key="handle_dispute_gift"></Tabs.TabPane>
        <Tabs.TabPane
          tab="Chuyển voucher"
          key="handle_dispute_voucher"
        ></Tabs.TabPane>
        {/* {admin?.role?.permissions?.handle_dispute_gift_level !==
          PERMISSIONS.NONE && (
        )} */}
      </Tabs>
      {renderTab()}
    </>
  );
};
