import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { Skeleton } from 'antd';
import { getUserHistory } from '../service';
import { useAntdTable } from 'ahooks';
interface DataType {
  key: string;
  index: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
  update_at: string;
  new_value: string;
  author: string;
}

const UpdateHistory = () => {
  const params = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const { tableProps } = useAntdTable(getUserHistory, {
    defaultParams: [{ current: 1, pageSize: 10, id: Number(params.id) }],
    onSuccess: (res) => {
      // console.log(res);
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
    },
    {
      title: 'const_column_update_time',
      dataIndex: 'update_at',
      key: 'update_at',
      render(_: any, record: any) {
        return record.update_at;
      },
    },
    {
      title: 'const_column_update_info',
      dataIndex: 'new_value',
      key: 'new_value',
      render(_: any, record: any) {
        if (record?.update_field === 'date_of_birth') {
          return (
            <>
              date_of_birth:
              {record.old_value
                ? moment(record.old_value).format('DD/MM/YYYY') + ' > '
                : ''}
              {record.new_value
                ? moment(record.new_value).format('DD/MM/YYYY')
                : ''}
            </>
          );
        } else {
          return (
            <>
              {`${record?.update_field}: `}
              {record.old_value ? record.old_value + ' > ' : ''}
              {record.new_value ? record.new_value : ''}
              {record?.reason_update ? `. Lý do: ${record?.reason_update}` : ''}
            </>
          );
        }
      },
    },
    {
      title: 'const_column_update_by',
      dataIndex: 'author',
      key: 'author',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  return (
    <>
      <div className={styles.mainInfoUser}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(record) => record.key}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
        />
      </div>
    </>
  );
};

export default UpdateHistory;
