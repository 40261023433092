import {
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Tag,
} from 'antd';
import { Link, useIntl, useParams } from 'umi';
import styles from './index.less';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditFilled,
  LeftOutlined,
} from '@ant-design/icons';
import { useRequest, useToggle } from 'ahooks';
import { addFaceToBlacklist, getKycDetail } from './service';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ENVIRONMENTS, STATUS_KYC } from '@/utils/constant';
import { useTranslate } from '@/utils/hooks/useTranslate';
import {
  EditKYC,
  editKycUser,
  handleKycService,
  handleRefuseKyc,
} from '../Kyc/service';
import Dialog from '../Kyc/Components/Dialog';
import { StatusKyc } from '@/utils/enum';
import { genAVT } from '@/utils/apis/common';
import SameFace from './Components/SameFace';
import BlacklistFace from './Components/BlacklistFace';

export default () => {
  const params: any = useParams();
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(true);
  const { t } = useTranslate();
  const infoKyc = useRequest(getKycDetail, {
    manual: true,
    onSuccess(res) {
      if (res.data) setData(res.data.get_profile);
    },
  });

  const requestHandleKyc = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors?.[0]?.message === 'No face detected in the image.') {
          message.error('Không phát hiện khuôn mặt');
        } else if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error(res?.errors?.[0]?.message);
        }
      } else {
        message.success('Duyệt đơn KYC thành công');
        getData();
      }
    },
  });
  const requestHandleKycReject = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        message.error('Từ chối đơn KYC thất bại');
      } else {
        message.success('Từ chối đơn KYC thành công');
        getData();
      }
    },
  });

  const handleEditKyc = useRequest(editKycUser, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        if (
          res?.data?.admin_edit_user_kyc?.message ===
          'Edit user kyc successfully'
        ) {
          message.success('Sửa thành công');
        } else {
          message.error(res?.data?.admin_edit_user_kyc?.message);
        }
        infoKyc.run(params.id);
        setDisable(true);
      }
    },
  });

  const handleKyc = (
    userId: number | string,
    isAccept: boolean,
    reason?: string,
  ) => {
    if (reason) {
      requestHandleKycReject.run(userId, isAccept, reason);
    } else {
      requestHandleKyc.run(userId, isAccept, reason);
    }
  };

  const handleAcceptUser = (idUser: number | string) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn có chắc chắn muốn phê duyệt tài khoản này không?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleKyc(idUser, true);
      },
    });
  };

  const requestHandleKycRejectVerified = useRequest(handleRefuseKyc, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Từ chối đơn KYC thất bại');
        }
      } else {
        message.success('Từ chối đơn KYC thành công');
      }
      getData();
    },
  });

  const addFaceBlacklist = useRequest(addFaceToBlacklist, {
    manual: true,
    onSuccess: (res) => {
      formAddFace.resetFields();
      message.success('Thêm ảnh vào danh sách blacklist thành công');
    },
    onError(err: any) {
      formAddFace.resetFields();
      message.error(err?.data?.message);
    },
  });

  const [formRefuseVerified] = Form.useForm();
  const checkStatusKyc = (status: StatusKyc) => {
    switch (status) {
      case StatusKyc.ACCEPT:
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case StatusKyc.NOT_REQUEST:
        return <Tag color="gray">Chưa xác thực</Tag>;
      case StatusKyc.PENDING:
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case StatusKyc.REFUSE:
        return (
          <Tag color="red" icon={<CloseOutlined />}>
            Từ chối xác thực
          </Tag>
        );
      default:
        break;
    }
  };
  const handleRejectUserVerified = (idUser: number) => {
    const onFinish = (val: any) => {
      requestHandleKycRejectVerified.run({
        userId: idUser,
        reason: val.reason,
      });
    };

    Modal.confirm({
      icon: null,
      title: 'Lý do từ chối yêu cầu xác thực:',
      width: 600,
      content: (
        <Form
          form={formRefuseVerified}
          id="formRefuseVerified"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={formatMessage({ id: 'reason' })}
              rows={4}
            />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuseVerified',
        loading: requestHandleKycRejectVerified.loading,
      },

      onCancel() {
        formRefuseVerified.resetFields();
      },
      onOk() {
        if (formRefuseVerified.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const [formAddFace] = Form.useForm();
  const handleAddFaceBlacklist = (idUser: number) => {
    const onFinish = (val: any) => {
      addFaceBlacklist.run({
        image: data?.verify_image,
        name: idUser.toString(),
        note: val.note,
      });
    };

    Modal.confirm({
      icon: null,
      title:
        'Bạn có muốn thêm ảnh selfie của user vào blacklist khuôn mặt không ?',
      width: 600,
      content: (
        <Form
          form={formAddFace}
          id="formAddFace"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="note"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={formatMessage({ id: 'reason' })}
              rows={4}
            />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formAddFace',
        loading: addFaceBlacklist.loading,
      },

      onCancel() {
        formAddFace.resetFields();
      },
      onOk() {
        if (formAddFace.getFieldValue('note')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const getData = () => {
    if (params.id) infoKyc.run(params.id);
  };

  const setFields = () => {
    if (data) {
      form.setFieldsValue({
        id: data?.userId,
        full_name: data?.full_name,
        address: data?.address,
        date_of_birth: data?.date_of_birth ? moment(data?.date_of_birth) : null,
        identity_id: data?.identity_id,
        place_of_issue: data?.place_of_issue,
        date_of_issue: data?.date_of_issue ? moment(data?.date_of_issue) : null,
        reason: data?.reason,
        matchRate: `${Number(data?.matchRate * 100).toFixed(2)} %`,
        reason_not_auto_kyc: data?.reason_not_auto_kyc,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [params]);
  useEffect(() => {
    setFields();
  });
  const { formatMessage } = useIntl();

  const handleSubmitEditKyc = (val: any) => {
    const payload: EditKYC = {
      ...val,
    };
    handleEditKyc.run(payload, data.userId);
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/kyc" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {formatMessage({ id: 'navigation_user_kyc_info' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <div
          style={{
            textAlign: 'end',
            padding: '0 100px',
          }}
        >
          <Button
            type="primary"
            danger
            ghost
            onClick={() => handleAddFaceBlacklist(data?.userId)}
          >
            Thêm vào Blacklist
          </Button>
          <a
            style={{
              color: 'Highlight',
            }}
            href={`/user-management/${data?.userId}`}
            target="_blank"
            className={styles.linkUser}
          >
            Xem thông tin User
          </a>
          <Button
            icon={<EditFilled />}
            title="Chỉnh sửa thông tin KYC"
            onClick={() => setDisable(!disable)}
          ></Button>
        </div>

        {data && !infoKyc.loading ? (
          <Form
            form={form}
            disabled={disable}
            labelCol={{
              span: 3,
            }}
            labelAlign="left"
            labelWrap
            wrapperCol={{
              span: 8,
            }}
            onFinish={handleSubmitEditKyc}
          >
            <Form.Item label="ID" name="id">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={formatMessage({ id: 'fullname' })}
              name="full_name"
            >
              <Input />
            </Form.Item>
            <Form.Item label={formatMessage({ id: 'address' })} name="address">
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item
              label={formatMessage({ id: 'date_of_birth' })}
              name="date_of_birth"
            >
              <DatePicker format={'DD/MM/YYYY'} placeholder="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item
              label={formatMessage({ id: 'general_kyc_type_IDcard' })}
              name="identity_id"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={formatMessage({ id: 'date_provided' })}
              name="date_of_issue"
            >
              <DatePicker format={'DD/MM/YYYY'} placeholder="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item
              label={formatMessage({ id: 'place_of_issue' })}
              name="place_of_issue"
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            {data?.reason && (
              <Form.Item
                name="reason"
                label={formatMessage({ id: 'reason_reject' })}
              >
                <Input disabled />
              </Form.Item>
            )}

            {data?.reason_not_auto_kyc && (
              <Form.Item name="reason_not_auto_kyc" label="Ghi chú">
                <Input.TextArea rows={2} disabled />
              </Form.Item>
            )}

            <Form.Item label="Tỉ lệ khớp khuôn mặt" name="matchRate">
              <Input disabled />
            </Form.Item>
            <Form.Item label={formatMessage({ id: 'status' })}>
              {/* <Input
                disabled
                value={
                  data?.verification_status
                    ? formatMessage({
                        id:
                          STATUS_KYC.find(
                            (i) => i.value === data.verification_status,
                          )?.name || '',
                      })
                    : ''
                }
              /> */}
              {checkStatusKyc(data?.verification_status)}{' '}
              {!data.is_liveness &&
                data?.verification_status === StatusKyc.PENDING && (
                  <Tag color="gray">Chưa có liveness</Tag>
                )}
            </Form.Item>
            <Form.Item>
              <Row align="middle" justify="end">
                <Button
                  type="ghost"
                  danger
                  style={{
                    margin: '0 20px',
                  }}
                  onClick={() => setDisable(true)}
                >
                  Hủy
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={handleEditKyc.loading}
                >
                  Sửa
                </Button>
              </Row>
            </Form.Item>
          </Form>
        ) : (
          <Skeleton active />
        )}
        {data && !infoKyc.loading ? (
          <>
            <Card
              title={formatMessage({ id: 'picture_verified' })}
              // size="small"
              className={styles.primaryCard}
            >
              <Row gutter={[24, 24]}>
                {data.identity_front && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <div className={styles.verifiedImage}>
                      <Image
                        wrapperClassName={styles.antImage}
                        src={`${data?.identity_front}`}
                      />
                    </div>
                  </Col>
                )}
                {data.identity_backside && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <div className={styles.verifiedImage}>
                      <Image
                        wrapperClassName={styles.antImage}
                        src={`${data?.identity_backside}`}
                      />
                    </div>
                  </Col>
                )}
                {data.verify_image && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <div className={styles.verifiedImage}>
                      <Image
                        wrapperClassName={styles.antImage}
                        src={`${data.verify_image}`}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
            {data?.liveness_url && (
              <Card
                title="Ảnh liveness"
                // size="small"
                className={styles.primaryCard}
              >
                <Row gutter={[24, 24]}>
                  {data.liveness_url && (
                    <>
                      {data?.liveness_url?.map((url: string, i: number) => (
                        <Col xs={24} sm={12} md={8} lg={8} key={i}>
                          <div className={styles.verifiedImage}>
                            <Image
                              wrapperClassName={styles.antImage}
                              src={`${url}`}
                              fallback="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            />
                          </div>
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              </Card>
            )}
            <SameFace id={data?.userId} />
            <BlacklistFace image={data?.verify_image} />
            {data.verification_status === StatusKyc.PENDING &&
              !!data?.liveness_url?.[0] && (
                <Row
                  align="middle"
                  justify="center"
                  className={styles.submitButtonGroup}
                >
                  <Col>
                    <Button
                      danger
                      onClick={() => setRejectDialog.set(true)}
                      className={styles.addButton}
                    >
                      {t('general_denied_kyc')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleAcceptUser(data.userId);
                      }}
                      className={styles.addButton}
                    >
                      {t('general_accept_kyc')}
                    </Button>
                  </Col>
                </Row>
              )}
            {data.verification_status === StatusKyc.PENDING &&
              !data?.liveness_url?.[0] && (
                <Row
                  align="middle"
                  justify="center"
                  className={styles.submitButtonGroup}
                >
                  <Button
                    danger
                    onClick={() => setRejectDialog.set(true)}
                    className={styles.addButton}
                  >
                    {t('general_denied_kyc')}
                  </Button>
                </Row>
              )}
            {data.verification_status === StatusKyc.ACCEPT && (
              <Row
                align="middle"
                justify="center"
                className={styles.submitButtonGroup}
              >
                <Button
                  danger
                  onClick={() => {
                    handleRejectUserVerified(data.userId);
                  }}
                  className={styles.addButton}
                >
                  {t('general_denied_kyc')}
                </Button>
              </Row>
            )}
          </>
        ) : (
          <Skeleton active />
        )}
      </div>

      {rejectDialog && (
        <Dialog
          open={rejectDialog}
          setOpen={(b) => {
            setRejectDialog.set(b);
          }}
          onConfirm={(reason) => {
            if (data.userId) handleKyc(data.userId, false, reason);
            setRejectDialog.set(false);
          }}
          itemEdit={data.userId}
          loading={requestHandleKycRejectVerified.loading}
        />
      )}
    </>
  );
};
