import React, { useEffect, useCallback } from 'react';
import {
  Form,
  Input,
  Switch,
  Space,
  message,
  Col,
  Row,
  Button,
  Tag,
} from 'antd';
import styles from './index.less';
import { useRequest } from 'ahooks';
import ModalReject from '../ModalReject';
import ModalAccept from '../ModalAccept';
import FormGift from '../FormGift';
import { useParams } from 'umi';
import {
  useGetGiftDetail,
  Status,
  OptionStatus,
  ICreateGift,
  requestUpdateGift,
  Autogenerated,
} from '../../service';
import dayjs from 'dayjs';
import moment from 'moment';
import { history } from 'umi';
import { EditOutlined } from '@ant-design/icons';
import { checkURL } from '@/utils/apis/common';
import { TypeGift as TypeGiftLCM } from '../../service';
// const { Option } = Select;
export enum TypeGift {
  BONUS = 1,
  VOUCHER = 2,
}
export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [fileListCrop, setfileListCrop] = React.useState<any[]>([]);
  const UserDetail = useRequest(useGetGiftDetail, {
    defaultParams: [param?.id],
    onSuccess(res) {
      // console.log(res);
      const data = res?.data?.get_gift?.data;
      form.setFieldsValue({
        ...data,
        type_id: data?.name,
        enterprise_id: data?.business_name,
      });
      form.setFieldValue('time_end', moment(data?.time_end));
      form.setFieldValue('status', data?.status == 'ACTIVE' ? true : false);
      form.setFieldValue('quantity', data?.puzzle_gifts?.length);
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data?.image,
        },
      ]);
      if (!!data?.url_cropping_image) {
        if (checkURL(data?.url_cropping_image)) {
          setfileListCrop([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: data?.url_cropping_image,
            },
          ]);
        }
      } else {
        setfileListCrop([
          {
            uid: '-1',
            name: 'image.png',
            status: 'error',
          },
        ]);
      }
      setDataForm({ ...data });
    },
  });
  const [dataForm, setDataForm] = React.useState<ICreateGift>();

  const [isEdit, setIsEdit] = React.useState(false);

  const onFinish = (values: any) => {
    // const values = form.getFieldsValue();
    delete values['voucher'];
    delete values['enterprise_id'];
    const formatData: ICreateGift = {
      // ...dataForm,
      ...values,
      name:
        dataForm?.type === TypeGiftLCM.GIFT_VOUCHER_LOCAMOS
          ? values?.type_id
          : values?.name,
      image: values.image?.[0]?.response?.url
        ? `${values.image?.[0]?.response?.url}${values.image?.[0]?.response?.path}`
        : values.image,
      url_cropping_image:
        values.url_cropping_image?.[0]?.response?.url ??
        values.url_cropping_image,
      puzzle_gifts: values?.puzzle_gifts?.map((item: any) => ({
        ...item,
        image: item.image?.[0]?.response?.url
          ? `${item.image?.[0]?.response?.url}${item.image?.[0]?.response?.path}`
          : item.image,
      })),
      time_end: dayjs(values.time_end).format(),
      status: values.status ? 'ACTIVE' : 'NOT_ACTIVE',
      user_receive_gift: dataForm?.user_receive_gift,
    };

    delete formatData['type_id'];

    onCreateGift.run(formatData);
  };
  const onCreateGift = useRequest(
    (payload: ICreateGift) => {
      return requestUpdateGift(payload, param?.id);
    },
    {
      manual: true,
      onSuccess: (res) => {
        if (res.errors) {
          message.error(res.errors?.[0]?.extensions?.response?.message?.[0]);
        } else {
          message.success('Thành công');
          history.push('/gift-building');
        }
      },
      onError: (err: any) => {
        message.error(err.data.message);
      },
    },
  );
  const onEdit = () => {
    setIsEdit(!isEdit);
    form.setFieldValue('isEdit', !isEdit);
  };
  const getDataForm = (name: string, value: any) => {
    setDataForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const convertStatus = (statusCode: string) => {
    const data: any = OptionStatus.filter((status) => {
      if (status.value == statusCode) {
        return true;
      } else {
        return false;
      }
    });
    return data[0] || {};
  };

  // const Conten = useCallback(() => {
  //   if (!!dataForm) {
  //     return (
  //       <>
  //         <FormGift
  //           fileList={fileList}
  //           fileListCrop={fileListCrop}
  //           setFileList={setFileList}
  //           setfileListCrop={setfileListCrop}
  //           getData={dataForm}
  //           setData={getDataForm}
  //           form={form}
  //           UserDetail={UserDetail}
  //           onChange={setDataForm}
  //         />
  //       </>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // }, [dataForm]);

  const ActionModel = useCallback(() => {
    if (!!dataForm && dataForm.gift_status == Status.DUYET) {
      return (
        <Space>
          <Button onClick={onEdit}>Sửa</Button>
        </Space>
      );
    } else if (!!dataForm && dataForm.gift_status == Status.TU_CHOI) {
      return <Space></Space>;
    } else {
      return (
        <Space>
          <ModalAccept
            id={param?.id}
            onReload={() => {
              history.push('/gift-building');
            }}
          >
            <Button type="primary">Duyệt</Button>
          </ModalAccept>
          <ModalReject
            id={param?.id}
            onReload={() => {
              history.push('/gift-building');
            }}
          >
            <Button type="primary" ghost danger>
              Từ chối
            </Button>
          </ModalReject>
          <Button onClick={onEdit} type="primary" ghost icon={<EditOutlined />}>
            Sửa
          </Button>
        </Space>
      );
    }
  }, [dataForm]);
  const giftStatus = convertStatus(
    UserDetail.data?.data?.get_gift?.data.gift_status,
  );
  return (
    <div className={styles.infoGift}>
      <Row justify="space-between">
        <Col>
          <p className={styles.giftSummary}>
            ID: {UserDetail.data?.data?.get_gift?.data.id}
          </p>
          <p className={styles.giftSummary}>
            Trạng thái:{' '}
            {UserDetail.data?.data?.get_gift?.data.gift_status ==
            Status.TU_CHOI ? (
              <>
                <Tag color={giftStatus?.color}>{giftStatus?.label}</Tag>
                Lý do: {UserDetail.data?.data?.get_gift?.data.refusal_reason}
              </>
            ) : (
              <Tag color={giftStatus?.color}>{giftStatus?.label}</Tag>
            )}
          </p>
          <p className={styles.giftSummary}>
            Người tạo:{' '}
            {UserDetail.data?.data?.get_gift?.data.business_id ? (
              <>
                DN {UserDetail.data?.data?.get_gift?.data.business_id} -{' '}
                {UserDetail.data?.data?.get_gift?.data.business_name} -{' '}
                {UserDetail.data?.data?.get_gift?.data.business_mobile}
              </>
            ) : (
              <>{UserDetail.data?.data?.get_gift?.data.created?.email}</>
            )}
          </p>
        </Col>
        <Col>
          {isEdit ? (
            <Space>
              <Button onClick={onEdit} type="primary" ghost>
                Huỷ
              </Button>
              <Button
                onClick={() => {
                  form.submit();
                }}
              >
                Lưu
              </Button>
            </Space>
          ) : (
            <ActionModel />
          )}
        </Col>
      </Row>
      <Form
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        className={styles.formSearch}
        layout="vertical"
        initialValues={{
          typeGift: TypeGift.BONUS,
          isEdit: isEdit,
          isEditPuzzle: false,
        }}
        disabled={!isEdit}
        scrollToFirstError={true}
      >
        {!!dataForm && (
          <FormGift
            fileList={fileList}
            fileListCrop={fileListCrop}
            setFileList={setFileList}
            setfileListCrop={setfileListCrop}
            getData={dataForm}
            setData={getDataForm}
            form={form}
            UserDetail={UserDetail}
            onChange={setDataForm}
          />
        )}
      </Form>
    </div>
  );
};
