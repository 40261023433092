import {
  AutoComplete,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { Actions } from 'ahooks/lib/useToggle';
import {
  ReleaseVoucherEnterprise,
  getListTypeVoucher,
  searchAllEnterprise,
} from '../service';
interface ReleaseVoucherModalProps {
  isOpenModal: boolean;
  setIsOpenModal: Actions<boolean>;
  refresh: () => void;
}
const VoucherModal = ({
  isOpenModal,
  setIsOpenModal,
  refresh,
}: ReleaseVoucherModalProps) => {
  const [form] = Form.useForm();
  const handleClose = () => {
    setIsOpenModal.toggle();
    form.resetFields();
  };
  const [atOptions, setAtOptions] = useState<any[]>([]);
  const [selectedVoucher, setSelectedVoucher] = useState<any>();
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>();
  const requestSearchVoucher = useRequest(getListTypeVoucher, {
    defaultParams: [
      {
        current: 1,
        pageSize: 50,
      },
      {},
    ],
    debounceWait: 1000,
    onSuccess: (res) => {
      setAtOptions(() =>
        res.list?.map((item: any) => ({
          ...item,
          value: item?.name,
        })),
      );
    },
    onError: (err) => {},
  });

  const searchEnterprise = useRequest(searchAllEnterprise, {
    manual: true,
    debounceWait: 500,
    onSuccess: (res) => {
      if (res) {
        setOptionEnterprise((state) => state.concat(res));
      }
    },
  });

  // const optionEnterprise =
  //   searchEnterprise.data?.data?.admin_get_list_enterprise?.data;
  const [optionEnterprise, setOptionEnterprise] = useState<any[]>([]);
  const handleSelectVoucher = (value: string) => {
    const option = atOptions.find((item) => item.value === value);
    if (!!option) {
      setSelectedVoucher(option?.id);
      setOptionEnterprise([]);
    }
  };
  const handleSelectEnterprise = (value: any) => {
    const option = optionEnterprise.find((item: any) => item.name === value);

    setSelectedEnterprise(option.id);
  };

  const handleSearchEnterprise = (searchValue: string) => {
    setOptionEnterprise([]);
    searchEnterprise.run({
      size: 30,
      typeId: selectedVoucher,
      searchValue: searchValue,
    });
  };

  // const onLoadMoreEnterprise = async (e: any) => {
  //   const { target } = e;

  //   if (
  //     (target as any).scrollTop + (target as any).offsetHeight ===
  //     (target as any).scrollHeight
  //   ) {
  //     // if not load all;
  //     searchEnterprise.run({
  //       size: 30,
  //       typeId: selectedVoucher,
  //     });
  //   }
  // };

  const releaseVoucher = useRequest(ReleaseVoucherEnterprise, {
    manual: true,
    onSuccess() {
      message.success('Phát hành voucher thành công');
      handleClose();
      refresh();
    },
    onError(err: any) {
      if (err?.data?.message === 'ENTERPISE_NOT_REGISTER_RELEASE_VOUCHER') {
        message.error('Doanh nghiệp chưa đăng ký phát hành voucher');
      } else if (err?.data?.message === 'ENTERPRISE_NOT_VERIFIED') {
        message.error('Doanh nghiệp chưa xác thực');
      } else {
        message.error(
          err?.data?.message
            ? 'Vui lòng chọn Doanh nghiệp và Voucher hợp lệ'
            : 'Có lỗi xảy ra',
        );
      }
    },
  });
  const handleSearchVoucher = (searchValue: string) => {
    requestSearchVoucher.run(
      { current: 1, pageSize: 50 },
      { textSearch: searchValue },
    );
  };

  const onSubmit = (val: any) => {
    const payload = {
      typeId: selectedVoucher,
      enterpriseId: selectedEnterprise,
      amount: val.amount,
      inventory_rate: Number(val.inventory_rate) / 100,
    };

    releaseVoucher.run({
      ...payload,
    });
  };

  useEffect(() => {
    if (selectedVoucher) {
      searchEnterprise.run({
        size: 30,
        typeId: selectedVoucher,
      });
    }
  }, [selectedVoucher]);

  return (
    <Modal
      open={isOpenModal}
      footer={null}
      onCancel={handleClose}
      title="Phát hành voucher"
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Loại voucher"
          name="voucher"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại voucher',
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            onClear={() => setSelectedVoucher(null)}
            placeholder="Chọn voucher"
            options={atOptions.map((item: any, i: number) => ({
              ...item,
              key: `${item?.value}_${i}`,
            }))}
            notFoundContent={
              requestSearchVoucher.loading ? <Spin /> : <>Trống</>
            }
            onSelect={(data) => handleSelectVoucher(data)}
            onSearch={(searchValue) => handleSearchVoucher(searchValue)}
          />
        </Form.Item>

        <Form.Item
          label="Doanh nghiệp phát hành"
          name="enterprise_id"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn doanh nghiệp',
            },
          ]}
        >
          <Select
            disabled={!selectedVoucher}
            showSearch
            allowClear
            placeholder="Chọn doanh nghiệp"
            options={
              optionEnterprise.length
                ? optionEnterprise?.map((item: any, i: number) => ({
                    label: item.name,
                    value: item.name,
                    key: `${item?.name}_${i}`,
                  }))
                : []
            }
            notFoundContent={searchEnterprise.loading ? <Spin /> : <>Trống</>}
            onSelect={(data) => handleSelectEnterprise(data)}
            onSearch={handleSearchEnterprise}
            // onPopupScroll={onLoadMoreEnterprise}
          />
        </Form.Item>
        <Form.Item
          initialValue={100}
          label="Số lượng voucher phát hành"
          name="amount"
          normalize={(value) => {
            const isNumber = /^\d+$/.test(value);
            if (isNumber) {
              return value;
            } else {
              return null;
            }
          }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (Number(value) === 0) {
                  return Promise.reject(
                    new Error('Số lượng phát hành không được bằng 0'),
                  );
                }
                if (!value) {
                  return Promise.reject(new Error('Vui lòng nhập'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            style={{
              width: '50%',
            }}
            disabled
            placeholder="Nhập số lượng"
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          label="Tỷ lệ phát hành voucher vào kho voucher"
          name="inventory_rate"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (Number(value) < 0 || Number(value) > 100) {
                  return Promise.reject(new Error('Tỷ lệ phải từ 0% đến 100%'));
                }
                if (!value) {
                  if (Number(value) === 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Vui lòng nhập'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            style={{
              width: '50%',
            }}
            addonAfter="%"
            placeholder="Nhập tỷ lệ"
          />
        </Form.Item>
        <Row justify="center">
          <Button
            style={{
              margin: '10px',
            }}
            onClick={handleClose}
          >
            Hủy
          </Button>
          <Button
            style={{
              margin: '10px',
            }}
            type="primary"
            htmlType="submit"
            loading={releaseVoucher.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default VoucherModal;
