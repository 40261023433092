import { OPTION_STATUS_ACTIVE } from '@/utils/constant';
import { StatusAccount } from '@/utils/enum';
import { CheckOutlined, EyeOutlined, StopOutlined } from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useIntl, useHistory } from 'umi';
import styles from './index.less';
import { getTableData } from './service';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  stt: number;
  time: string;
  gift: string;
  fullname: string;
  phone: string;
  email: string;
  address: string;
}
const data: DataType[] = [
  {
    key: '1',
    stt: 1,
    time: '11:11:11 11/11/2022',
    gift: 'Iphone 11',
    fullname: 'Nguyễn vân anh',
    phone: '0123456789',
    email: 'nvanh@gmail.com',
    address: '123 quan hoa, cầu giấy, hà nội',
  },
  {
    key: '2',
    stt: 2,
    time: '11:11:11 11/11/2022',
    gift: 'Iphone 11',
    fullname: 'Nguyễn vân anh',
    phone: '0123456789',
    email: 'nvanh@gmail.com',
    address: '123 quan hoa, cầu giấy, hà nội',
  },
  {
    key: '3',
    stt: 3,
    time: '11:11:11 11/11/2022',
    gift: 'Iphone 11',
    fullname: 'Nguyễn vân anh',
    phone: '0123456789',
    email: 'nvanh@gmail.com',
    address: '123 quan hoa, cầu giấy, hà nội',
  },
  {
    key: '4',
    stt: 4,
    time: '11:11:11 11/11/2022',
    gift: 'Iphone 11',
    fullname: 'Nguyễn vân anh',
    phone: '0123456789',
    email: 'nvanh@gmail.com',
    address: '123 quan hoa, cầu giấy, hà nội',
  },
];
export default () => {
  const [openDialog, setOpenDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const [form] = Form.useForm();
  const history = useHistory();

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      defaultPageSize: 10,
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );
  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'sale_time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'gift',
      dataIndex: 'gift',
      key: 'gift',
    },
    {
      title: 'fullname',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'auth_phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'address',
      dataIndex: 'address',
      key: 'address',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullname" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_name' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="gift" className={styles.searchItem}>
          <Select
            style={{ width: 200 }}
            placeholder="Lọc theo qùa tặng"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA!.children as unknown as string)
                .toLowerCase()
                .localeCompare(
                  (optionB!.children as unknown as string).toLowerCase(),
                )
            }
            onChange={submit}
          >
            <Option value="ip11">Iphone 11</Option>
            <Option value="ip12">Iphone 12</Option>
            <Option value="ip13">Iphone 13</Option>
            <Option value="ip14">Iphone 14</Option>
            <Option value="ip15">Iphone 15</Option>
            <Option value="ip16">Iphone 16</Option>
          </Select>
        </Form.Item>
      </Form>

      <Button>{formatMessage({ id: 'general_export_excel' })}</Button>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_list_gift' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            dataSource={data}
            scroll={{ x: 1000 }}
            // {...tableProps}
          />
        )}
      </div>
      {/* {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};
