import React, { useEffect, useState, useCallback, memo } from 'react';
import {
  Button,
  Col,
  Form,
  FormListFieldData,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Upload,
  UploadFile,
} from 'antd';
import styles from './index.less';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import { ENVIRONMENTS } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
import MapReaflat from '@/components/MapReaflat';
import { IPuzzleGift, Status, LocationDropGift, TypeGift } from '../../service';
import SelectNFT from '@/components/SelectNFT';
import { RcFile } from 'antd/lib/upload';
import UploadImage from '@/components/UploadImage';
import { FormInstance } from 'antd/es/form/Form';
import { checkURL } from '@/utils/apis/common';

interface IProps {
  getData: any;
  index: number;
  clearable: boolean;
  isEditPuzzle: boolean;
  onClear: () => void;
  field: FormListFieldData;
  disable?: boolean;
  propsInput: any;
  setData: any;
  form: FormInstance<any>;
}

const FormCollection = memo((props: IProps) => {
  const {
    field,
    form,
    index,
    clearable,
    onClear,
    setData,
    getData,
    disable,
    isEditPuzzle,
    propsInput,
  } = props;

  const [addressType, setAddressType] = useState(LocationDropGift.OTHER);
  const [fileList, setFileList] = useState<any[]>([]);
  const [position, setPosition] = useState<{
    lat: number;
    long: number;
  }>();
  const fields = form.getFieldsValue();
  const address = fields.puzzle_gifts?.[index];
  useEffect(() => {
    if (getData?.puzzle_gifts?.length && !!getData?.puzzle_gifts[0]?.image) {
      if (checkURL(getData?.puzzle_gifts[0]?.image)) {
        setFileList([
          {
            uid: `-${index}`,
            name: 'image.png',
            status: 'done',
            url: getData?.puzzle_gifts[index]?.image,
          },
        ]);
      } else {
        if (!!getData?.puzzle_gifts[0]?.image) {
          setFileList([
            {
              uid: `-${index}`,
              name: 'image.png',
              status: 'error',
            },
          ]);
        } else {
          setFileList([]);
        }
      }
      if (!window.location.pathname.includes('create-gift-building')) {
        setPosition({
          lat: getData?.puzzle_gifts?.[index]?.latitude,
          long: getData?.puzzle_gifts?.[index]?.longitude,
        });
        setAddressType(getData?.puzzle_gifts?.[index]?.address_type);
      }
    }
    // return () => {
    //   console.log('unmounting form collection');
    // };
  }, [getData]);

  const onSelectPos = (pos: any) => {
    if (!!pos) {
      const newPos = {
        id_nft: pos?.id,
        address: pos?.name,
        latitude: pos?.lat,
        longitude: pos?.long,
      };
      // setPosition({
      //   lat: newPos.latitude,
      //   long: newPos.longitude,
      // });
      const temp = form.getFieldsValue();
      temp.puzzle_gifts[index] = {
        ...temp.puzzle_gifts[index],
        ...newPos,
      };
      // setData(`puzzle_gifts`, temp.puzzle_gifts);
      form.setFieldsValue(temp);
    }
  };

  // const MapDetail = useCallback(() => {
  //   if (position && position?.lat) {
  //     return (
  //       <MapReaflat
  //         setPos={onSelectPos}
  //         listPosition={[]}
  //         loading={false}
  //         mapItemDetail={{
  //           address: address.address,
  //           lat: position?.lat,
  //           long: position.long,
  //         }}
  //       />
  //     );
  //   } else {
  //     return (
  //       <MapReaflat
  //         setPos={onSelectPos}
  //         listPosition={[]}
  //         loading={false}
  //         mapItemDetail={null}
  //       />
  //     );
  //   }
  // }, [position]);
  return (
    <div className={styles.backgroundColor}>
      <Row
        justify="space-between"
        style={{
          padding: '20px',
        }}
      >
        <Col span={11} xl={11} lg={8} sm={24} xs={24}>
          <Form.Item
            label="Tên mảnh ghép"
            name={[field.name, 'name']}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên mảnh ghép',
              },
            ]}
          >
            <Input {...propsInput} placeholder="Tên mảnh ghép" />
          </Form.Item>
          <Form.Item
            label="Vị trí thả quà"
            name={[field.name, 'address_type']}
            initialValue={addressType}
          >
            <Select
              {...propsInput}
              onChange={(val) => {
                setAddressType(val);
              }}
              options={
                getData?.type !== TypeGift.GIFT_LOCABONUS
                  ? [
                      {
                        value: LocationDropGift.OTHER,
                        label: 'Tùy chọn',
                      },
                      {
                        value: LocationDropGift.NFT,
                        label: 'NFT',
                      },
                    ]
                  : [
                      {
                        value: LocationDropGift.OTHER,
                        label: 'Tùy chọn',
                      },
                      {
                        value: LocationDropGift.NFT,
                        label: 'NFT',
                      },
                      {
                        value: LocationDropGift.ALL_NFT,
                        label: 'Tất cả NFT',
                      },
                    ]
              }
            />
          </Form.Item>
          <UploadImage
            propsUpload={propsInput}
            label="Ảnh mảnh ghép"
            fileList={fileList}
            setFileList={setFileList}
            name={[field.name, 'image']}
            compressor={true}
            required={true}
          />
        </Col>

        <Col span={11} xl={11} lg={14} sm={24} xs={24}>
          {addressType === LocationDropGift.OTHER && (
            <>
              <Form.Item label="Địa chỉ" name={[field.name, 'address']}>
                <Input />
              </Form.Item>
              <Form.Item
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn địa chỉ',
                  },
                ]}
                name={[field.name, 'address']}
                {...propsInput}
              >
                <MapReaflat
                  setPos={onSelectPos}
                  listPosition={[]}
                  loading={false}
                  mapItemDetail={
                    getData?.puzzle_gifts?.[index]
                      ? {
                          address: getData?.puzzle_gifts?.[index]?.address,
                          lat: getData?.puzzle_gifts?.[index]?.latitude,
                          long: getData?.puzzle_gifts?.[index]?.longitude,
                        }
                      : null
                  }
                />
              </Form.Item>
              <Row gutter={32}>
                <Col span={10}>
                  <Form.Item label="Lat" name={[field.name, 'latitude']}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="Long" name={[field.name, 'longitude']}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {addressType === LocationDropGift.NFT && (
            <>
              <SelectNFT
                fromName="address"
                index={index}
                className={styles.locationNFT}
                onChange={(data: any) => {
                  if (!!data) {
                    const { label, value, key } = data;
                    let coordinates: any;
                    if (!!key) {
                      coordinates = key?.split(',');
                    }
                    const newPos: any = {
                      id_nft: value,
                      address: label,
                      latitude: Number(coordinates[0]),
                      longitude: Number(coordinates[1]),
                    };

                    setPosition({
                      lat: newPos.latitude,
                      long: newPos.longitude,
                    });
                    const fields = form.getFieldsValue();
                    fields.puzzle_gifts[index] = {
                      ...fields.puzzle_gifts[index],
                      ...newPos,
                    };
                    form.setFieldsValue({ ...fields });
                  }
                }}
                allowClear={true}
                loadOnMount={true}
              />
              <Row gutter={32}>
                <Col span={10}>
                  <span>Lat: {position?.lat}</span>
                </Col>
                <Col span={10}>
                  <span>Long: {position?.long}</span>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {clearable && isEditPuzzle == true && (
        <Button
          icon={<CloseOutlined />}
          danger
          onClick={() => {
            const quantity = form.getFieldValue('quantity');
            form.setFieldValue('quantity', quantity - 1);
            onClear();
          }}
          style={{
            background: 'whitesmoke',
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        ></Button>
      )}
    </div>
  );
});

export default FormCollection;
