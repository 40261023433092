import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useIntl, useParams, useRequest } from 'umi';
import PersonalInfo from './Components/PersonalInfo';

import styles from './index.less';
import LocaBonus from './Components/LocaBonus';
import { getShopDetail } from './service';
import { LocabonusHistory } from './Components/LocabonusHistory';
import EnterpriseCustomer from './Components/EnterpriseCustomer';
import BillConfig from './Components/BillConfig';
import VoucherConfig from './Components/VoucherConfig';
import VoucherImport from './Components/VoucherImport';
import AliasStatistic from './Components/AliasStatistic';

interface InfoAccount {
  shop_id: number;
  email: string;
  name: string;
  phone: string;
  address: string;
  status?: string;
  create_at?: string;
  point?: number;
  lat?: number;
  lng?: number;
  bill_template?: string;
  qr_transfer?: string;
  register_release_voucher: boolean;
  partner_id?: number;
  can_use_qr_alias?: boolean;
}
interface HistoryUpdate {
  stt?: any;
  dayUpdate: string;
  infoUpdate: string;
  byUpdate: string;
}
export default () => {
  const params = useParams<{ id: string }>();
  const [activeTabs, setActiveTabs] = React.useState(1);
  const [infoAccount, setInfoAccount] = useState<InfoAccount | null>(null);
  const [updateHis, setUpdateHis] = useState<HistoryUpdate[] | null>(null);

  const { id } = params;
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (id) {
      getDataBusinessRequest.run(id);
    } else {
      setInfoAccount(null);
    }
  }, [params]);

  const getDataBusinessRequest = useRequest(getShopDetail, {
    manual: true,
    onSuccess(res: any) {
      const data = res?.admin_get_enterprise_detail;
      const infoRes: InfoAccount = {
        ...data,
        email: data.email,
        name: data.name,
        phone: data.phone,
        address: data.address,
        shop_id: data.shop_id,
        status: data.status,
        lat: data.lat,
        lng: data.long,
      };

      setInfoAccount(infoRes);
      window.scrollTo({
        top: 0,
      });
    },
  });

  const renderTab = () => {
    switch (activeTabs) {
      case 1:
        return (
          <>
            <PersonalInfo
              infoAccount={infoAccount}
              refresh={getDataBusinessRequest.refresh}
            />
          </>
        );

      case 2:
        return (
          <>
            <LocaBonus />
          </>
        );
      case 3:
        return (
          <>
            <LocabonusHistory />
          </>
        );
      case 4:
        return (
          <>
            <EnterpriseCustomer />
          </>
        );
      case 5:
        return (
          <>
            <BillConfig
              enterpriseId={+params.id}
              bill_template={infoAccount?.bill_template || ''}
              qr_transfer={infoAccount?.qr_transfer || ''}
            />
          </>
        );
      case 6:
        return (
          <>
            <VoucherConfig enterpriseId={+params.id} />
          </>
        );
      case 7:
        return (
          <>
            <AliasStatistic enterprise_id={+params.id} />
          </>
        );
      case 8:
        return (
          <>
            <VoucherImport partner_id={infoAccount?.partner_id} />
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/business" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {formatMessage({ id: 'navigation_list_business' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveTabs(parseInt(key));
          }}
          className="tab-on-card"
        >
          <Tabs.TabPane tab="Thông tin doanh nghiệp" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử lượt tặng" key="2"></Tabs.TabPane>
          <Tabs.TabPane tab="Lịch sử tặng Locabonus" key="3"></Tabs.TabPane>
          <Tabs.TabPane tab="Danh sách khách hàng" key="4"></Tabs.TabPane>
          <Tabs.TabPane tab="Cài đặt hóa đơn" key="5"></Tabs.TabPane>
          {infoAccount?.register_release_voucher && (
            <Tabs.TabPane tab="Cài đặt voucher" key="6"></Tabs.TabPane>
          )}
          {infoAccount?.register_release_voucher && (
            <Tabs.TabPane
              tab="Thống kê sử dụng mã Alias"
              key="7"
            ></Tabs.TabPane>
          )}
          {!!infoAccount?.partner_id && (
            <Tabs.TabPane tab="Voucher import" key="8"></Tabs.TabPane>
          )}
        </Tabs>
        <Card className="card-after-tab" size="small">
          {renderTab()}
        </Card>
      </div>
    </>
  );
};
