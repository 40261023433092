import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Input, DatePicker } from 'antd';
import React from 'react';
import { Link, useIntl, useParams } from 'umi';
import styles from '../index.less';
// import UploadImage from '@/components/UploadImage';
import { useAntdTable } from 'ahooks';

import Table, { ColumnsType } from 'antd/lib/table';
import { statisticUseVoucherDetail } from '../service';
import { formatNumber } from '@/utils/apis/common';
import dayjs from 'dayjs';
const DetailDiscountEvent = () => {
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search } = useAntdTable(statisticUseVoucherDetail, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        user_id: Number(params.id),
      },
      {
        search: '',
      },
    ],
    form,
  });
  const { submit } = search;

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Điểm áp dụng',
      dataIndex: ['biz', 'name'],
    },
    {
      title: 'Thời gian áp dụng',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Chương trình áp dụng',
      dataIndex: 'name',
    },
    {
      title: 'Loại LocaVoucher',
      dataIndex: 'voucher_type',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer} style={{ marginTop: 20 }}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên cửa hàng"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/discount_event/report" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'discount_event_detail' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(item) => Math.random() * item.created_at}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default DetailDiscountEvent;
