import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Select,
  message,
  Breadcrumb,
  Row,
  Spin,
} from 'antd';
import { Link, history } from 'umi';

import styles from './index.less';
import {
  ICreateShop,
  getListEnterprise,
  requestCreateShop,
  useBuilding,
  useShop,
} from '../service';
import { useRequest } from 'ahooks';
import { normalizeNumber } from '@/utils/normalize';
import { LeftOutlined } from '@ant-design/icons';
import { useState } from 'react';

// const { Option } = Select;

export default () => {
  const [form] = Form.useForm();
  const { building } = useBuilding();
  const { shop, getShop, loadingShop } = useShop();
  const [listOptionEnterprise, setListOptionEnterprise] = useState<any[]>([]);
  const listEnterprise = useRequest(getListEnterprise, {
    debounceWait: 500,
    onSuccess(res) {
      setListOptionEnterprise(res?.data?.admin_get_list_enterprise?.data);
    },
  });

  const createShop = useRequest(
    async (data: ICreateShop) => {
      return requestCreateShop(data);
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('Tạo gian hàng thành công');
        history.push('/stall');
      },
      onError: (err: any) => {
        message.error(err?.data.message);
      },
    },
  );
  const onFinish = (data: ICreateShop) => {
    createShop.run(data);
  };

  const handleSelectEnterprise = (value: any) => {
    const option = listOptionEnterprise.find(
      (item: any) => item.name === value,
    );

    form.setFieldsValue({
      address: option.address,
      representative: option.owner_name,
      phone: option.phone,
      email: option.email,
    });
  };
  const handleSearchEnterprise = (searchValue: string) => {
    setListOptionEnterprise([]);
    listEnterprise.run(searchValue);
  };
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/stall" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Tạo gian hàng
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Divider orientation="left">Thông tin chung</Divider>

        <div className={styles.formInfoGeneral}>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={styles.formSearch}
            layout="vertical"
            initialValues={{
              status: true,
            }}
          >
            <Form.Item
              name="name"
              label="Tên gian hàng"
              rules={[
                { required: true, message: 'Vui lòng nhập tên gian hàng' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="building_id"
              label="Toà nhà"
              className={styles.locationNFT}
            >
              <Select
                placeholder="Lựa chọn toà nhà"
                optionFilterProp="children"
                options={building?.map((building: any) => {
                  return {
                    label: `${building.name}`,
                    value: `${building.id}`,
                  };
                })}
                onChange={(id: number) => {
                  form.setFieldValue('building_entities_id', null);
                  getShop(id);
                }}
              />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building = getFieldValue('building_id');
                const isDisabled = building && !loadingShop ? false : true;
                return (
                  <Form.Item
                    name="building_entities_id"
                    label="Vị trí gian hàng"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (building && !value) {
                            return Promise.reject(
                              new Error('Vui lòng chọn vị trí gian hàng'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Lựa chọn vị trí gian hàng"
                      options={shop?.map((shop: any) => {
                        return {
                          value: shop.id,
                          label: shop.name,
                        };
                      })}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              name="vr_oid"
              label="ID VR tour"
              className={styles.locationNFT}
            >
              <Input />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.vr_oid !== curValues.vr_oid
              }
            >
              {({ getFieldValue }) => {
                const vr_oid = getFieldValue('vr_oid');
                return (
                  <div className={styles.preview}>
                    <p>Preview Toà nhà</p>
                    <iframe
                      src={`https://nft.locamos.com/${vr_oid}`}
                      title="preview"
                      width="100%"
                      height="600"
                    />
                  </div>
                );
              }}
            </Form.Item>
            {/* <div className={styles.preview}>
            <p>Preview gian hàng</p>
            <iframe
              src="https://nft.locamos.com/default-bb18d073-0036-4155-a37b-68dc300ce30a"
              title="preview"
              width="100%"
              height="600"
            />
          </div> */}
            <Divider orientation="left">Thông tin chủ gian hàng</Divider>
            <Form.Item name="unit_name" label="Tên đơn vị">
              {/* <Input placeholder="tên công ty, cửa hàng, đơn vị,..." /> */}
              <Select
                showSearch
                allowClear
                placeholder="Chọn doanh nghiệp"
                options={
                  listOptionEnterprise.length
                    ? listOptionEnterprise?.map((item: any, i: number) => ({
                        label: item.name,
                        value: item.name,
                        key: `${item?.name}_${i}`,
                      }))
                    : []
                }
                notFoundContent={listEnterprise.loading ? <Spin /> : <>Trống</>}
                onSelect={(data) => handleSelectEnterprise(data)}
                onSearch={handleSearchEnterprise}
                // onPopupScroll={onLoadMoreEnterprise}
              />
            </Form.Item>
            <Form.Item name="address" label="Địa chỉ">
              <Input placeholder="Địa chỉ" />
            </Form.Item>
            <Form.Item name="representative" label="Người đại diện">
              <Input placeholder="Người đại diện" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Số điện thoại"
              normalize={(value, prevValue) =>
                normalizeNumber(value, prevValue)
              }
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const regexPhoneNumber = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
                    if (!value) {
                      return Promise.reject(
                        new Error('Vui lòng nhập số điện thoại!'),
                      );
                    } else if (!value.match(regexPhoneNumber)) {
                      return Promise.reject(
                        new Error(
                          'Vui lòng nhập đúng định dạng số điện thoại!',
                        ),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder="Số điện thoại" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const regexEMail =
                      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập email!'));
                    } else if (!value.match(regexEMail)) {
                      return Promise.reject(
                        new Error('Vui lòng nhập đúng định dạng email!'),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Space size={15}>
              <p>Trạng thái</p>
              <Form.Item
                name="status"
                className={styles.status}
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Space>
            <Row justify="center">
              <Button
                className="btn-submit"
                type="primary"
                ghost
                danger
                onClick={() => history.push('/stall')}
              >
                Huỷ
              </Button>
              <Button className="btn-submit" type="primary" htmlType="submit">
                Lưu
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
