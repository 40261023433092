import { APPROVED_BY, STATUS_KYC } from '@/utils/constant';
import { StatusAccount, StatusKyc } from '@/utils/enum';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  LeftOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { useAntdTable, useRequest, useToggle } from 'ahooks';
import {
  Breadcrumb,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import { useIntl, history, useHistory } from 'umi';
import Dialog from './Components/Dialog';
import styles from './index.less';
import { getTableData, handleKycService, handleRefuseKyc } from './service';

const { Option } = Select;
interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

export default function KycManageMent() {
  const { RangePicker } = DatePicker;
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const [form] = Form.useForm();

  const history = useHistory();
  const dateFormat = 'DD/MM/YYYY';

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const requestHandleKyc = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res.errors[0].message === 'No face detected in the image.') {
          message.error('Không phát hiện khuôn mặt');
        } else if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        message.success('Duyệt đơn KYC thành công');
        refresh();
      }
    },
  });

  const requestHandleKycReject = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Từ chối đơn KYC thất bại');
        }
      } else {
        message.success('Từ chối đơn KYC thành công');
      }
      refresh();
    },
  });

  const requestHandleKycRejectVerified = useRequest(handleRefuseKyc, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Từ chối đơn KYC thất bại');
        }
      } else {
        message.success('Từ chối đơn KYC thành công');
      }
      refresh();
    },
  });

  const handleKyc = (
    userId: number | string,
    isAccept: boolean,
    reason?: string,
  ) => {
    requestHandleKyc.run(userId, isAccept, reason);
  };

  const handleKycReject = (
    userId: number | string,
    isAccept: boolean,
    reason?: string,
  ) => {
    requestHandleKycReject.run(userId, isAccept, reason);
  };

  const { type, changeType, submit, reset } = search;

  // const handleViewUser = (idUser: number | string) => {
  //   history.push('/kyc-detail/' + idUser.toString());
  // };

  const handleAcceptUser = (idUser: number | string) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn có chắc chắn muốn phê duyệt tài khoản này không?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        loading: requestHandleKyc.loading,
      },
      onOk() {
        handleKyc(idUser, true);
      },
    });
  };

  const handleRejectUser = (idUser: number | string) => {
    setIdSelected(idUser);
    setRejectDialog.set(true);
  };

  const [formRefuseVerified] = Form.useForm();
  const handleRejectUserVerified = (idUser: number) => {
    const onFinish = (val: any) => {
      requestHandleKycRejectVerified.run({
        userId: idUser,
        reason: val.reason,
      });
    };

    Modal.confirm({
      icon: null,
      title: 'Lý do từ chối yêu cầu xác thực:',
      width: 600,
      content: (
        <Form
          form={formRefuseVerified}
          id="formRefuseVerified"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={formatMessage({ id: 'reason' })}
              rows={4}
            />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuseVerified',
        loading: requestHandleKycRejectVerified.loading,
      },

      onCancel() {
        formRefuseVerified.resetFields();
      },
      onOk() {
        if (formRefuseVerified.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_request_at',
      dataIndex: 'request_at',
      align: 'right',
      key: 'request_at',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.request_at
              ? moment(record.request_at).format('HH:mm:ss DD/MM/YYYY')
              : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_full_name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'const_column_email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'const_column_phone',
      dataIndex: 'phone',
      align: 'right',
      key: 'phone',
    },
    {
      title: 'general_kyc_type',
      dataIndex: 'type_identity',
      key: 'type_identity',
    },
    {
      title: 'const_column_status',
      dataIndex: 'active',
      key: 'active',
      render: (value: any, record: any, index: number) => {
        const status = STATUS_KYC.find(
          (i) => i.value === record.verification_status,
        );
        return (
          <Tag
            icon={
              status?.value === StatusKyc.ACCEPT ? (
                <CheckOutlined />
              ) : status?.value === StatusKyc.PENDING ? (
                <ClockCircleOutlined />
              ) : (
                <CloseOutlined />
              )
            }
            color={
              status?.value === StatusKyc.ACCEPT
                ? 'green'
                : status?.value === StatusKyc.PENDING
                ? 'warning'
                : 'red'
            }
          >
            {status?.name ? formatMessage({ id: status.name }) : <></>}
          </Tag>
        );
      },
    },
    {
      title: 'const_column_action',
      dataIndex: 'custom',
      align: 'center',
      width: '12%',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip
              title={formatMessage({ id: 'general_tooltip_show_infomation' })}
            >
              <a
                href={'/kyc-detail/' + record?.id?.toString()}
                style={{
                  margin: '0 10px',
                }}
              >
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
            {record.verification_status === StatusKyc.PENDING && (
              <>
                {record?.liveness_url?.length ? (
                  <>
                    <Tooltip
                      title={formatMessage({ id: 'general_tooltip_denied' })}
                    >
                      <div
                        style={{ cursor: 'pointer', margin: '0 10px' }}
                        onClick={() => handleRejectUser(record.userId)}
                      >
                        <StopOutlined className="red-color" />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={formatMessage({ id: 'general_tooltip_accept' })}
                    >
                      <div
                        style={{ cursor: 'pointer', margin: '0 10px' }}
                        onClick={() => handleAcceptUser(record.userId)}
                      >
                        <CheckOutlined className="green-color" />
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={formatMessage({ id: 'general_tooltip_denied' })}
                    >
                      <div
                        style={{ cursor: 'pointer', margin: '0 10px' }}
                        onClick={() => handleRejectUser(record.userId)}
                      >
                        <StopOutlined className="red-color" />
                      </div>
                    </Tooltip>
                  </>
                )}
              </>
            )}
            {record.verification_status === StatusKyc.ACCEPT && (
              <Tooltip title={formatMessage({ id: 'general_tooltip_denied' })}>
                <div
                  style={{ cursor: 'pointer', margin: '0 10px' }}
                  onClick={() => handleRejectUserVerified(record.userId)}
                >
                  <StopOutlined className="red-color" />
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        // className={styles.searchForm}
        initialValues={{
          status: StatusKyc.PENDING,
        }}
        layout="inline"
      >
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_admin' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          // initialValue={StatusKyc.PENDING}
        >
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'general_status_account' })}
          >
            {STATUS_KYC.map((item, i) => (
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="approvedBy"
          className={styles.searchItem}
          // initialValue={StatusKyc.PENDING}
        >
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'general_approved_by' })}
          >
            {APPROVED_BY.map((item, i) => (
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="is_liveness" className={styles.searchItem}>
          <Select onChange={submit} allowClear placeholder="Liveness">
            <Option value={true}>Thành công</Option>
            <Option value={false}>Thất bại</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_user_kyc' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            rowKey="id"
            {...tableProps}
          />
        )}
      </div>
      {rejectDialog && (
        <Dialog
          open={rejectDialog}
          setOpen={(b) => {
            setRejectDialog.set(b);
            refresh();
          }}
          onConfirm={(reason) => {
            if (idSelected) handleKycReject(idSelected, false, reason);
            setRejectDialog.set(false);
          }}
          itemEdit={idSelected}
          loading={requestHandleKycReject.loading}
        />
      )}
    </>
  );
}
