import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Input, Table } from 'antd';
import styles from './index.less';
import React from 'react';

import { Link, useIntl, useParams } from 'umi';
import { useAntdTable } from 'ahooks';
import { getTableData } from './service';
import { ColumnsType } from 'antd/lib/table';

interface DataType {
  user_profile: {
    full_name: string;
  };
  phone: string;
  email: string;
  invite_count: number;
}
const CompanyInfo = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const { tableProps, search } = useAntdTable(getTableData, {
    form,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        id: Number(params.id),
      },
      {
        name: '',
      },
    ],
  });
  const { submit } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên thành viên',
      dataIndex: 'full_name',
      render: (_, rc) => <>{rc?.user_profile?.full_name}</>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Số người giới thiệu',
      dataIndex: 'invite_count',
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchItem}>
        <Form.Item name="name">
          <Input.Search
            placeholder={'Tìm kiếm tên thành viên'}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div>
      <>
        <Breadcrumb className={styles.breadcrumb}>
          <Link to="/company" className={styles.previousEditLink}>
            <LeftOutlined />
            <Breadcrumb.Item>{`Thành viên của công ty`}</Breadcrumb.Item>
          </Link>
        </Breadcrumb>
        {searchForm}
        <div className={styles.tableComponent}>
          <Table {...tableProps} columns={columns} />
        </div>
      </>
    </div>
  );
};

export default CompanyInfo;
