import { STATUS_DESPUTE } from './../../utils/constant/index';
import { __mfsu } from './../../.umi/core/pluginRegister';
import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';
import { StatusDespute } from '@/utils/enum';

interface Result {
  total: number;
  list: any[];
}

export enum TRANSFER_TYPE {
  GIFT = 'GIFT',
  EVOUCHER = 'EVOUCHER',
}
export const getDisputeData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    value?: string;
    toDate: string[];
    type?: string;
  },
): Promise<Result> => {
  if (!formData.value) {
    formData.value = '';
  }

  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `
    mutation {
      get_list_transfer_disputes(input: { 
        page_size: ${pageSize || 10}, 
        page_index: ${current ? current - 1 : 0},
        search_value:"${formData.value}",
        end_time:"${toDate}",
        start_time:"${fromDate}" 
        ${formData.type ? `status: ${formData.type}` : ''}
      }) {
        total
        data {
          transfer_id
          request_user_name
          request_user_email
          request_user_phone
          request_user_code
          amount
          receiver_name
          receiver_code
          receiver_email
          receiver_phone
          transfer_content
          transfer_fee
          expired_at
          transaction_id
          disputed_at
          images
          dispute_handled
          
        }
      }
    }
    

    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.get_list_transfer_disputes?.data.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.get_list_transfer_disputes?.total,
      list: data,
    };
  });
};

export const getDisputeGiftData = (
  {
    current,
    pageSize,
    type,
  }: { current: number; pageSize: number; type?: TRANSFER_TYPE },
  formData: {
    searchValue?: string;
    toDate?: string[];
    status?: string;
  },
): Promise<Result> => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `{
			getListDisputeTransferGift(input: {
				pageSize: ${pageSize}, 
				pageIndex: ${current - 1},
				${formData.status ? `status: ${formData.status},` : ''}
				startAt: "${fromDate}" ,
				endAt: "${toDate}", 
				${type ? `type: ${type},` : ''}
				searchValue: "${formData.searchValue ? formData.searchValue : ''}" 
			}) {
				data {
					disputeAt
					id
					requestUserName
					receiverName
					giftName
					status
				}
				total
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.getListDisputeTransferGift?.data.map(
      (e: any, index: any) => ({
        ...e,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }),
    );
    return {
      total: res?.data?.getListDisputeTransferGift?.total,
      list: data,
      errors: res?.errors,
    };
  });
};

export const adminHandleDispute = (id: any, status: any) => {
  const data = {
    query: `
mutation {
  admin_handle_dispute_transfer(input: {
    transfer_id:${id}
    handle:${status}
  }) {
    message
  }
}
    `,
  };

  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};
