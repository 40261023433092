import React, { useState } from 'react';
import { Breadcrumb, Form, Tabs } from 'antd';
import StatisticVoucherUrbox from './component/StatisticVoucherUrbox';
import ReleasedVoucherUrbox from './component/ReleasedVoucherUrbox';
import HistoryVoucherUrbox from './component/HistoryVoucherUrbox';
import styles from './index.less';
import { useHistory, useLocation } from 'umi';
const VoucherUrvox = () => {
  const location = useLocation();
  const history = useHistory();
  const renderTab = () => {
    switch (location.pathname) {
      case '/voucher_urbox':
        return <StatisticVoucherUrbox />;
      case '/voucher_urbox/released':
        return <ReleasedVoucherUrbox />;
      case '/voucher_urbox/history':
        return <HistoryVoucherUrbox />;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý Voucher Urbox</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane
          tab="Thống kê Voucher Urbox"
          key="/voucher_urbox"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Phát hành Voucher"
          key="/voucher_urbox/released"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Lịch sử đổi Voucher"
          key="/voucher_urbox/history"
        ></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </div>
  );
};

export default VoucherUrvox;
