import React from 'react';
import { Button, Form, Input, Table, Tooltip } from 'antd';

import { FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useAntdTable, useToggle } from 'ahooks';
import VoucherModal from '../component/CreateVoucherModal';
import { getListEnterpriseVoucher } from '../service';
import { formatNumber } from '@/utils/apis/common';
import { PERMISSIONS } from '@/utils/enum';
import { useAuth } from '@/utils/hooks/useAuth';
import styles from '../index.less';
import { Link } from 'umi';
const LocamosVoucher = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    // {
    //   title: 'Thời gian',
    //   align: 'right',
    //   dataIndex: 'created_at',
    //   render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    // },
    {
      title: 'Tên doanh nghiệp',
      dataIndex: 'enterprise_name',
    },
    {
      title: 'Loại voucher',
      dataIndex: 'voucher_name',
    },
    {
      title: 'Số lượng voucher phát hành',
      width: '15%',
      align: 'right',
      dataIndex: 'released_count',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Số lượng voucher đã sử dụng',
      width: '15%',
      align: 'right',
      dataIndex: 'used_count',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <Link
          to={`/statistic_voucher/${record.voucher_type_id}?enteprise_id=${record?.enteprise_id}`}
        >
          <Tooltip title="Thống kê sử dụng voucher">
            <FileTextOutlined className="primary-color" />
          </Tooltip>
        </Link>
      ),
    },
  ];
  const [isOpenModal, setIsOpenModal] = useToggle();
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(
    getListEnterpriseVoucher,
    {
      form,
    },
  );

  const { getAdminLocalStore } = useAuth();
  const admin: any = getAdminLocalStore();
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên DN, loại Voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        {/* <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item> */}
      </Form>
      {admin?.role?.permissions?.release_voucher_management_level ===
        PERMISSIONS.FULL && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsOpenModal.toggle()}
        >
          Tạo voucher
        </Button>
      )}
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => `${item.voucher_type_id}${item.enteprise_id}`}
        />
      </div>
      {isOpenModal && (
        <VoucherModal
          refresh={refresh}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </div>
  );
};

export default LocamosVoucher;
