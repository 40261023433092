import { Form, Input, Switch, Table, message } from 'antd';
import React from 'react';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import {
  ActiveVoucherUrvox,
  GetListVoucherUrbox,
  StatusVoucherUrbox,
} from '../service';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import { useRequest } from 'umi';
const StatisticVoucherUrbox = () => {
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(GetListVoucherUrbox, {
    form,
  });
  const ActiveVoucher = useRequest(ActiveVoucherUrvox, {
    manual: true,
    onSuccess: (res) => {
      message.success('Cập nhật trạng thái thành công');
      refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const handleChangeStatus = (id: number, status: boolean) => {
    if (status) {
      ActiveVoucher.run(id, StatusVoucherUrbox.ACTIVE);
    } else {
      ActiveVoucher.run(id, StatusVoucherUrbox.NOT_ACTIVE);
    }
  };
  const { submit } = search;
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên voucher',
      dataIndex: 'name',
    },
    {
      title: 'Mệnh giá',
      dataIndex: 'value',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Đã phát hành',
      dataIndex: 'amount',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Đã đổi',
      dataIndex: 'daDoi',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Có thể đổi',
      dataIndex: 'coTheDoi',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Đã nhặt quà',
      dataIndex: 'daNhatQua',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Có thể nhặt quà',
      dataIndex: 'coTheNhatQua',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Trạng thái hiển thị trên kho voucher',
      dataIndex: 'status',
      align: 'right',
      render: (value, record) => (
        <>
          {
            <Switch
              defaultChecked={value === 'ACTIVE'}
              onChange={(val) => handleChangeStatus(Number(record.ref_id), val)}
            />
          }
        </>
      ),
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên DN, loại Voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.ref_id}
        />
      </div>
      ;
    </>
  );
};

export default StatisticVoucherUrbox;
