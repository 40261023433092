import { useRequest } from 'ahooks';
import { Form, Modal, Select, message } from 'antd';
import React from 'react';
import { requestUpdateBanner, useGetBannerNoSelected } from '../../service';
interface IProps {
  children: any;
  building_id?: number;
  id: number;
  refresh?: () => void;
  name?: string;
}
const ModalEdit = (props: IProps) => {
  const [form] = Form.useForm();
  const { children, building_id = 1, id, refresh, name } = props;

  const [visible, setVisible] = React.useState(false);
  const onVisible = () => {
    form.setFieldValue('building_entities_id', null);
    setVisible(!visible);
  };
  const { banner, loading } = useGetBannerNoSelected();
  const onUpdateBanner = useRequest(
    (data: any) => requestUpdateBanner(id, data, building_id),
    {
      manual: true,
      onSuccess: () => {
        setVisible(false);
        refresh && refresh();
        message.success('Thành công');
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );
  const onChange = () => {};
  const onOk = () => {
    const value = form.getFieldValue('building_entities_id');
    onUpdateBanner.run(value);
  };
  return (
    <>
      <span onClick={onVisible}>{children}</span>
      <Modal
        title={name}
        open={visible}
        onOk={onOk}
        onCancel={onVisible}
        centered
      >
        {!loading && (
          <Form onFieldsChange={onChange} form={form}>
            <Form.Item name="building_entities_id">
              <Select
                options={banner?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default ModalEdit;
