import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  UploadFile,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import UploadImage from '@/components/UploadImage';
import {
  createTypeVoucherPartner,
  getDetailTypeVoucherPartner,
  updateTypeVoucherPartner,
} from '../service';
import { ENVIRONMENTS } from '@/utils/constant';
const ManageTypeVoucherPartner = () => {
  const param: { id: string } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const createType = useRequest(createTypeVoucherPartner, {
    manual: true,
    onSuccess: (res) => {
      message.success('Tạo voucher thành công');
      history.push('/voucher/released?type=partner');
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const updateType = useRequest(updateTypeVoucherPartner, {
    manual: true,
    onSuccess: (res) => {
      message.success('Sửa voucher thành công');
      // setDisabled(true);
      // detailType.run(param.id);
      history.push('/voucher/released?type=partner');
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const detailType = useRequest(getDetailTypeVoucherPartner, {
    manual: true,
    onSuccess: (res) => {
      form.setFieldsValue({
        ...res,
        pool_percent: Math.floor(res.pool_percent * 100),
      });
      setFileList([
        {
          uid: '-1',
          name: 'image1.png',
          status: 'done',
          url: `${ENVIRONMENTS.BUCKET_NAME + '/' + res?.image}`,
        },
      ]);
    },
    onError(err: any) {
      message.error('Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });
  const onSubmit = (val: any) => {
    const payload = {
      ...val,
      image: val?.image?.[0]?.response?.path,
    };
    if (param.id === 'add_voucher') {
      createType.run(payload);
    } else {
      updateType.run(payload, +param.id);
    }
  };
  const onCancel = () => {
    if (param.id === 'add_voucher') {
      form.resetFields();
    } else {
      detailType.run(+param.id);
      setDisabled(true);
    }
  };
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (param.id !== 'add_voucher') {
      detailType.run(+param.id);
    }
  }, [param]);
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link
            to="/voucher/released?type=partner"
            className={styles.previousLink}
          >
            <LeftOutlined />
          </Link>
          Tạo loại voucher đối tác
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.formVoucher}>
        {param.id !== 'add_voucher' && (
          <Button
            icon={<EditOutlined />}
            onClick={() => setDisabled(!disabled)}
            title="Sửa"
          ></Button>
        )}
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          disabled={param.id == 'add_voucher' ? false : disabled}
        >
          <Row>
            <Col span={10}>
              <Form.Item
                label="Tên loại voucher"
                name="name"
                rules={[
                  {
                    validator: (_: any, value: string) => {
                      if (!!value) {
                        if (value.length >= 50) {
                          return Promise.reject('Tối đa 50 ký tự');
                        }
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Vui lòng nhập');
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập tên loại voucher" />
              </Form.Item>
              <Form.Item
                label="Giá trị voucher"
                name="value"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!!value || value === 0) {
                        if (value <= 0) {
                          return Promise.reject('Giá trị phải lớn hơn 0');
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject('Vui lòng nhập');
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  controls={false}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  placeholder="Nhập giá trị voucher"
                  addonAfter="VND"
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Mô tả"
                name="description"
                rules={[
                  {
                    validator: (_: any, value: string) => {
                      if (!!value) {
                        if (value.length >= 500) {
                          return Promise.reject('Tối đa 500 ký tự');
                        }
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Vui lòng nhập');
                      }
                    },
                  },
                ]}
              >
                <Input.TextArea rows={2} placeholder="Nhập nội dung mô tả" />
              </Form.Item>
              <Form.Item
                label="Điều kiện áp dụng"
                name="condition"
                rules={[
                  {
                    validator: (_: any, value: string) => {
                      if (!!value) {
                        if (value.length >= 500) {
                          return Promise.reject('Tối đa 500 ký tự');
                        }
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Vui lòng nhập');
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập điều kiện áp dụng" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <UploadImage
              propsUpload={
                param.id !== 'add_voucher' && {
                  disabled: disabled,
                }
              }
              fileList={fileList}
              setFileList={setFileList}
              label="Ảnh voucher"
              name="image"
            />
          </Row>

          <Row>
            <Button
              type="primary"
              ghost
              className={styles.submitButton}
              onClick={onCancel}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitButton}
              loading={
                param.id === 'add_voucher'
                  ? createType.loading
                  : updateType.loading
              }
            >
              Lưu
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ManageTypeVoucherPartner;
