import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export interface UserGroup {
  id: string;
  name: string;
}
export interface CreateGroupUser {
  name: string;
  age_range_min: number;
  age_range_max: number;
  gender: string;
  province?: string;
  provinces?: string[];
}
export interface IGroupUserData extends CreateGroupUser {
  id: string;
  created_at?: string;
  is_deleted?: boolean;
}

export interface CreateNotificationInput {
  title: string;
  body: string;
  link: string;
  sending_time: string;
  user_groups: UserGroup[];
}

export interface UpdateNotificationInput extends CreateNotificationInput {}

export const getListGroupUser = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    searchText?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.searchText) {
    params.textSearch = formData.searchText;
  }
  return privateRequest(request.get, API_PATH.GROUP_USER, { params }).then(
    (response) => {
      return {
        list: response?.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        })),
        total: response?.data?.count,
      };
    },
  );
};

export const listProviceUser = () => {
  return privateRequest(request.get, API_PATH.LIST_PROVINCE_USER);
};

export const createGroupUser = (input: CreateGroupUser) => {
  return privateRequest(request.post, API_PATH.GROUP_USER, { data: input });
};

export const updateGroupUser = (input: IGroupUserData) => {
  return privateRequest(request.put, API_PATH.GROUP_USER + '/' + input.id, {
    data: {
      name: input.name,
      age_range_min: input.age_range_min,
      age_range_max: input.age_range_max,
      gender: input.gender,
      provinces: input.provinces,
    },
  });
};

export const getListNotifications = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchText?: string;
    toDate?: string[];
    status?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.searchText) {
    params.textSearch = formData.searchText;
  }
  if (Array.isArray(formData.toDate)) {
    params.startDate = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.endDate = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  if (!!formData.status) {
    params.status = formData.status;
  }
  return privateRequest(request.get, API_PATH.NOTIFICATION, { params }).then(
    (response) => {
      return {
        list: response?.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        })),
        total: response?.total,
      };
    },
  );
};

export const deleteNotification = (id: number) => {
  return privateRequest(request.delete, API_PATH.NOTIFICATION + '/' + id);
};

export const getDetailNotification = (id: number) => {
  return privateRequest(request.get, API_PATH.NOTIFICATION + '/' + id);
};

export const createNotification = (input: CreateNotificationInput) => {
  return privateRequest(request.post, API_PATH.NOTIFICATION, { data: input });
};

export const updateNotification = (
  input: UpdateNotificationInput,
  id: number,
) => {
  return privateRequest(request.put, API_PATH.NOTIFICATION + '/' + id, {
    data: input,
  });
};
