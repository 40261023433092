import { STATUS_KYC } from '@/utils/constant';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useAntdTable, useRequest, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useHistory, useIntl } from 'umi';
import styles from './index.less';
import { getTableData, switchStatusUser } from './service';

const { Option } = Select;

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const mockData = [
  {
    key: '1',
    id: 1,
    name: 'John Brown',
  },
  {
    id: 2,
    key: '2',
    name: 'Joe Black',
  },
  {
    id: 3,
    key: '3',
    name: 'Jim Green',
  },
  {
    id: 4,
    key: '4',
    name: 'Joe Black',
  },
];

export default () => {
  const [openDialog, setOpenDialog] = useToggle(false);
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );

  const { push } = useHistory();
  const [form] = Form.useForm();

  const { tableProps, search, params, refresh, loading, error } = useAntdTable(
    getTableData,
    {
      defaultPageSize: 10,
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const requestSwitchStatus = useRequest(switchStatusUser, {
    manual: true,
    onSuccess: (res: any) => {
      message.success(formatMessage({ id: 'message_user_success' }));
      refresh();
    },
    onError: (rej: any) => {
      message.error(formatMessage({ id: 'message_user_failure' }));
      refresh();
    },
  });

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const handleViewGift = (id: number | string) => {
    push('/gifts-infomation/' + id);
  };
  const handleEditGift = (idGift: number | string) => {
    push('/edit-gift/' + idGift);
  };

  const handleDeleteGift = (idGift: number | string) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn có chắc chắn muốn phê duyệt tài khoản này không?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {},
    });
  };

  const handleRejectUser = (idGift: number | string) => {
    setIdSelected(idGift);
    setRejectDialog.set(true);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_full_name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'const_column_phone_number',
      dataIndex: 'phone',
      key: 'phone',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.phone ? '+' + record.phone : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'const_column_create_date',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
    },
    {
      title: 'const_column_status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'const_column_action',
      dataIndex: 'custom',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip
              title={formatMessage({ id: 'general_tooltip_show_infomation' })}
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewGift(record.id)}
              >
                <EyeOutlined />
              </div>
            </Tooltip>
            <Tooltip title={formatMessage({ id: 'general_tooltip_edit' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleEditGift(record.id)}
              >
                <EditOutlined />
              </div>
            </Tooltip>
            <Tooltip title={formatMessage({ id: 'general_tooltip_delete' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteGift(record.id)}
              >
                <DeleteOutlined />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const addNewGift = () => {
    push('/new-gift');
  };

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_admin' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="isActive"
          className={styles.searchItem}
          initialValue={STATUS_KYC[0].value}
        >
          <Select onChange={submit}>
            {STATUS_KYC.map((item) => (
              <Option value={item.value}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <DatePicker.RangePicker
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Button
          onClick={() => {
            addNewGift();
          }}
          className={styles.addNewButton}
        >
          {formatMessage({ id: 'general_add' })}
        </Button>
      </Form>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_gifts' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          //@ts-ignore
          columns={columns}
          rowKey={(record) => record.id}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
          dataSource={mockData}
          // {...tableProps}
        />
        {/* {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )} */}
      </div>
      {/* {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};
