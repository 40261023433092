import { useAntdTable, useRequest } from 'ahooks';
import React from 'react';
import { getEventGift, updateEventGift } from '../service';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
  message,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import { history } from 'umi';
import styles from '../index.less';
import moment from 'moment';
const EventGift = () => {
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(getEventGift, {
    form,
  });

  const { submit } = search;
  const updateStatus = useRequest(updateEventGift, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Tên sự kiện',
      dataIndex: 'name',
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'create_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'start_time',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'end_time',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Người tạo',
      dataIndex: ['user', 'full_name'],
      render: (value, record) => <>{value ?? record?.user?.email}</>,
    },
    {
      title: 'Hành động',
      width: '10%',
      render: (_, record) => (
        <>
          <Space size={20}>
            <Tooltip title="Xem">
              <EyeOutlined
                className="purple-color"
                onClick={() => history.push(`/gift-events/${record.id}`)}
              />
            </Tooltip>

            <Switch
              defaultChecked={record.status}
              onChange={(value: boolean) => {
                updateStatus.run(record?.id, {
                  name: record.name,

                  end_time: moment(record.end_time).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  start_time: moment(record.start_time).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  status: value,
                });
              }}
            />
          </Space>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className={styles.searchContainer}>
        <Form
          form={form}
          autoComplete="off"
          className={styles.searchForm}
          layout="inline"
        >
          <Form.Item name="search_text" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm id, tên sự kiện"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="toDate" className={styles.searchItem}>
            <DatePicker.RangePicker
              onChange={submit}
              format={['DD/MM/YYYY', 'DD/MM/YY']}
            />
          </Form.Item>
          <Form.Item name="is_expired" className={styles.searchItem}>
            <Select
              onChange={submit}
              placeholder="Trạng thái"
              allowClear
              options={[
                {
                  key: '1',
                  value: true,
                  label: 'Còn hạn',
                },
                {
                  key: '2',
                  value: false,
                  label: 'Hết hạn',
                },
              ]}
            />
          </Form.Item>
        </Form>
        <Button
          onClick={() => history.push('/create-gift-events')}
          className={styles.createBuilding}
        >
          Tạo sự kiện
        </Button>
      </div>
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          rowKey={(item) => item.id}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default EventGift;
