import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import { StatusAccount } from '@/utils/enum';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName?: string;
    toDate: string[];
    filterBy?: string;
  },
): Promise<Result> => {
  if (!formData.fullName) {
    formData.fullName = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `
    mutation {
      admin_get_list_users(input: {
        date_created_from:"${fromDate}",
        date_created_to:"${toDate}",
        page_index:${current ? current - 1 : 0},
        page_size:${pageSize},
        search_text:"${formData.fullName}",
        ${formData.filterBy ? `filterBy: ${formData.filterBy}` : ''}
      }) {
        total
        data {
          userId
          full_name
          phone
          email
          is_checkpoint
          date_created
          status
          deleted
          user_profile {
            address
            avatar
            date_created
            date_of_birth
            full_name
            gender
          }
          invite_by {
            email
            full_name
            phone
          }
        }
      }
    }
    `,
  };

  return privateRequest(request.post, API_PATH.default, {
    data,
  }).then((res: any) => {
    const data = res?.data?.admin_get_list_users?.data?.map(
      (user: any, index: any) => ({
        ...user,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        fullName: user.full_name,
        phone: user.phone,

        status: user.status,
      }),
    );
    return {
      total: res?.data?.admin_get_list_users?.total,
      list: data,
    };
  });
};

export const deleteUserData = (id: number) => {
  const query = `
    mutation {
      admin_delete_user(userId: ${id}) {
        message
      }
    }
`;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const restoreUserData = (id: number) => {
  const query = `mutation {
    restore_deleted_user(id: ${id}) {
      message
    }
  }`;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const openCheckpoint = (id: number, reason: string) => {
  const data = {
    query: `mutation {
      open_checkpoint(uid: ${id}, reason: "${reason}")
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
// export const getUserData = (id: any) => {
//   return privateRequest(request.get, API_PATH.USER + '/' + id);
// };

// export const switchStatusUser = (user: any, payload: StatusAccount) => {
//   return privateRequest(request.put, API_PATH.USER + '/' + user.id, {
//     data: {
//       ...user,
//       status: payload,
//     },
//   });
// };

export const changeUserStatus = (
  id: number,
  status: boolean,
  reason: string,
) => {
  const data = {
    query: `mutation {
      admin_update_user(updateInput: { userId: ${id}, status: ${status}, reason_update: "${reason}" }) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
