import { LocaBonusTransferType } from '@/utils/enum';

export const LOCABONUS_TRANSFER = [
  {
    value: LocaBonusTransferType.MEET_TO_EARN,
  },
  {
    value: LocaBonusTransferType.USE_TO_EARN,
  },
  {
    value: LocaBonusTransferType.TRANSFER,
  },
  {
    value: LocaBonusTransferType.GET,
  },
  {
    value: LocaBonusTransferType.TRANSFER_TO_WEB,
  },
  {
    value: LocaBonusTransferType.GIFT,
  },
  {
    value: LocaBonusTransferType.WITHDRAW,
  },
  {
    value: LocaBonusTransferType.USER_FEE,
  },
  {
    value: LocaBonusTransferType.BUY_VOUCHER,
  },
  {
    value: LocaBonusTransferType.BUY_EVOUCHER_URBOX,
  },
  {
    value: LocaBonusTransferType.CALL_TO_EARN,
  },
];
