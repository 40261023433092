import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  UploadFile,
  message,
} from 'antd';
import { EditOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useRequest, history, useParams } from 'umi';
import UploadImage from '@/components/UploadImage';
import { formatInputNumber } from '@/utils/apis/common';
import DrawerGroupProduct from './DrawerGroupProduct';
import {
  VERIFY_PRODUCT_STATUS,
  confirmProductService,
  detailProductService,
  getBundleUpdateProduct,
  getProductCategory,
  getProductProperty,
  updateProductService,
} from '../service';
import DrawerPropertyProduct from './DrawerPropertyProduct';
import ListPropertyTable from './ListPropertyTable';
import InputBundleProduct from './InputBundleProduct';
import { ENVIRONMENTS } from '@/utils/constant';
import { renderProductStatus } from '..';
interface IdataSource {
  key: number;
  main_prop_name: string;
  image_link: UploadFile[];
  status: boolean;
  sub_prop: {
    id?: number;
    sub_prop_name: string;
    price: number;
    sku_code: string;
  }[];
}
interface DataSourceType extends Array<IdataSource> {}

const DetailProduct = () => {
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [isOpenGroupProduct, setIsOpenGroupProduct] = useState(false);
  const [isOpenPropertyProduct, setIsOpenPropertyProduct] = useState(false);
  const [checkedKeysProperty, setCheckedKeysProperty] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [dataSource, setDataSource] = useState<DataSourceType>([
    {
      key: 1,
      main_prop_name: '',
      image_link: [],
      status: false,
      sub_prop: [
        {
          id: 0,
          sub_prop_name: '',
          price: 0,
          sku_code: '',
        },
      ],
    },
  ]);
  const params: {
    id: string;
  } = useParams();
  const detailProduct = useRequest(detailProductService, {
    defaultParams: [+params.id],
    onSuccess(response) {
      const res = response?.data;

      setSelectedRowKey(res?.category?.id);
      const bundle = res?.bundle?.map((item: any, index: number) => {
        const rs: any = {
          bundled_products_default_id: item?.id,
          maxium_pick: item?.maxium_property,
        };
        if (item?.bundle_product_properties?.length > 1) {
          item?.bundle_product_properties?.forEach(
            (bundleProp: any, i: number) => {
              rs[i] = {
                name: bundleProp.name,
                price: bundleProp.price,
                prop_id: bundleProp.id,
              };
              rs['prop_id'] = bundleProp.id;
              rs['active'] = bundleProp?.active;
            },
          );
        } else {
          rs.prop_id = item?.bundle_product_properties?.[0]?.id;
          rs.name = item?.bundle_product_properties?.[0].name;
          rs.price = item?.bundle_product_properties?.[0].price;
          rs.active = true;
        }
        return rs;
      });

      form.setFieldsValue({
        ...res,
        category_id: res?.category?.id,
        bundles_products: bundle,
      });

      setFileList(
        res?.images?.map((item: any, i: number) => ({
          uid: `-${i + 1}`,
          name: item?.link,
          status: 'done',
          url: item?.link,
        })),
      );
      const data: DataSourceType = res?.product_property_mixed?.map(
        (item: any, i: number) => ({
          key: i,
          main_prop_name: item?.main_name,
          status: item?.secondary?.[0]?.status,
          image_link: [
            {
              uid: `-${i + 1}`,
              name: item?.image_link,
              status: 'done',
              url: item?.image_link,
            },
          ],
          sub_prop: item?.secondary?.map((se: any) => ({
            id: se?.mixed_prop_id,
            sub_prop_name: se?.secondary_name,
            price: +se?.price,
            sku_code: se?.sku_code,
          })),
        }),
      );
      if (res?.product_property_mixed?.length > 0) {
        setCheckedKeysProperty((state) => {
          const keys = [...state];
          if (!!res?.product_property_mixed?.[0]?.main_parent?.id) {
            keys.push(res?.product_property_mixed?.[0]?.main_parent?.id);
          }
          if (
            res?.product_property_mixed?.[0]?.secondary?.[0]?.secondary_parent
              ?.id
          ) {
            keys.push(
              res?.product_property_mixed?.[0]?.secondary?.[0]?.secondary_parent
                ?.id,
            );
          }
          return keys;
        });
      }
      setDataSource(data);
    },
    onError: (error: any) => {
      console.log(error);
    },
    formatResult: (res) => res,
  });
  const updateProduct = useRequest(updateProductService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.status !== 200) {
        message.error(res.message);
      } else {
        message.success('Chỉnh sửa sản phẩm thành công');
        detailProduct.run(+params.id);
        // history.push('/products');
      }
    },
    onError: (error: any) => {
      message.error(error?.data?.message ?? 'Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });
  const confirmProduct = useRequest(confirmProductService, {
    manual: true,
    onSuccess: (res: any) => {
      console.log(res);
      if (res?.status !== 200) {
        message.error(res.message ?? 'Có lỗi xảy ra');
      } else {
        message.success('Thành công!');
        detailProduct.run(+params.id);
      }
    },
    formatResult: (res) => res,
  });
  const onSubmit = (val: any) => {
    const product_prop_mixed: any[] = [];
    const bundles_products: any[] = [];

    dataSource.forEach((item) => {
      item?.sub_prop?.forEach((prop) => {
        const propsMixed: any = {
          property_main_name: item.main_prop_name,
          image_link:
            item?.image_link?.[0]?.url ??
            item.image_link[0]?.response?.url +
              item.image_link[0]?.response?.path,
          price: +prop.price,
          sku_code: prop.sku_code,
          status: item.status ? true : false,
        };
        if (!!prop.sub_prop_name) {
          propsMixed.property_secondary_name = prop.sub_prop_name;
        }
        if (!!prop.id) {
          propsMixed['product_property_mixed_id'] = prop.id;
        }
        product_prop_mixed.push(propsMixed);
      });
    });
    if (val?.bundles_products?.length > 0) {
      val?.bundles_products?.forEach((item: any) => {
        if (Object.keys(item).some((key) => Number.isInteger(+key))) {
          Object.keys(item).forEach((key) => {
            if (Number.isInteger(+key)) {
              bundles_products.push({
                bundle_id: item?.bundled_products_default_id,
                prop_id: item[key]?.prop_id,
                name: item[key].name,
                price: item[key].price,
                maximum_pick: item?.maxium_pick,
                active: item?.active ? true : false,
              });
            }
          });
        } else {
          bundles_products.push({
            prop_id: item?.prop_id,
            bundle_id: item.bundled_products_default_id,
            name: item.name,
            price: item.price,
            maximum_pick: item.maxium_pick,
            active: item.active ? true : false,
          });
        }
      });
    }
    const payload: any = {
      name: val?.name,
      sku_code: val?.sku_code,
      images: val?.images?.map((item: any) => {
        const imageLink: any = {};
        if (!!item?.id) {
          imageLink.image_id = item?.id;
        }
        if (!!item?.response?.path) {
          imageLink.image_link = item?.response?.url + item?.response?.path;
        }
        if (!!item?.url) {
          imageLink.image_link = item?.url;
        }
        if (item?.link) {
          imageLink.image_link = item?.link;
        }
        return imageLink;
      }),
      category_id: val?.category_id,
      description: val?.description,
      unit: val?.unit,
      price: val?.price,
      property_parent_main_id: checkedKeysProperty[0],
      property_parent_secondary_id: checkedKeysProperty[1],
      product_prop_mixed: product_prop_mixed,
      bundle_products: bundles_products,
    };

    // console.log(val, payload);

    updateProduct.run(payload, +params.id);
  };

  const category = useRequest(getProductCategory, {
    manual: true,
  });

  useEffect(() => {
    const owner_id = detailProduct?.data?.data?.owner_id;
    if (owner_id) {
      category.run(owner_id);
      property.run(owner_id);
      bundleProducts.run(+params.id, owner_id);
    }
  }, [detailProduct?.data]);
  const property = useRequest(getProductProperty, {
    manual: true,
  });
  const bundleProducts = useRequest(getBundleUpdateProduct, {
    manual: true,
  });
  const onChooseCategory = (id: number) => {
    form.setFieldValue('category_id', id);
  };
  const handleApproveProduct = () => {
    return Modal.confirm({
      // icon: null,
      title: 'Xác nhận duyệt sản phẩm',
      content: 'Bạn có chắc chắn muốn duyệt sản phẩm này?',
      okText: 'Duyệt',
      cancelText: 'Hủy',
      onOk() {
        confirmProduct.run(+params.id, VERIFY_PRODUCT_STATUS.VERIFIED);
      },
    });
  };
  const handleDenyProduct = () => {
    return Modal.confirm({
      icon: null,
      title: 'Xác nhận từ chối duyệt sản phẩm',
      content: 'Bạn có chắc chắn muốn từ chối duyệt sản phẩm này?',
      okText: 'Từ chối',
      cancelText: 'Hủy',
      onOk() {
        confirmProduct.run(+params.id, VERIFY_PRODUCT_STATUS.DENIED);
      },
    });
  };
  return (
    <>
      <Link to="/products" className={styles.previousEditLink}>
        <LeftOutlined />
        <p>Danh sách sản phẩm</p>
      </Link>
      {detailProduct.loading ? (
        <Skeleton loading />
      ) : (
        <div className={styles.wrapper}>
          <Form
            disabled={!isEdit}
            form={form}
            scrollToFirstError
            labelCol={{
              span: 5,
            }}
            labelAlign="left"
            labelWrap
            onFinish={onSubmit}
          >
            <Row
              justify="end"
              style={{
                marginBottom: 20,
              }}
            >
              <Button
                disabled={false}
                icon={<EditOutlined />}
                style={{
                  margin: '0 5px',
                }}
                type="primary"
                ghost
                onClick={() => setIsEdit(!isEdit)}
              >
                Chỉnh sửa
              </Button>

              {detailProduct?.data?.data?.verify_status ===
                VERIFY_PRODUCT_STATUS.PENDING && (
                <>
                  <Button
                    disabled={false}
                    style={{
                      margin: '0 5px',
                    }}
                    type="primary"
                    loading={confirmProduct.loading}
                    onClick={handleApproveProduct}
                  >
                    Duyệt
                  </Button>
                  <Button
                    disabled={false}
                    style={{
                      margin: '0 5px',
                    }}
                    type="primary"
                    ghost
                    danger
                    loading={confirmProduct.loading}
                    onClick={handleDenyProduct}
                  >
                    Từ chối
                  </Button>
                </>
              )}
            </Row>
            {/* <Row
              justify="end"
              style={{
                marginBottom: 20,
              }}
            >
              <Button
                type="primary"
                className="btn-submit"
                htmlType="submit"
                loading={updateProduct.loading}
              >
                Lưu
              </Button>
              <Button
                type="primary"
                ghost
                className="btn-submit"
                onClick={() => history.push('/products')}
              >
                Huỷ
              </Button>
            </Row> */}
            <Form.Item label="Trạng thái">
              {renderProductStatus(detailProduct?.data?.data?.verify_status)}
            </Form.Item>
            <Form.Item
              name="name"
              label="Tên sản phẩm"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên sản phẩm',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Nhập tên sản phẩm" />
            </Form.Item>
            <Form.Item
              name="sku_code"
              label="Mã SKU"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mã SKU',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Nhập mã SKU" />
            </Form.Item>
            <UploadImage
              name="images"
              label="Hình ảnh sản phẩm (Ảnh sản phẩm đại diện là ảnh đầu tiên)"
              setFileList={setFileList}
              fileList={fileList}
              required={true}
              amountUpload={8}
              groupUpload={true}
              propsUpload={{
                disabled: !isEdit,
              }}
            />
            <Form.Item label="Nhóm sản phẩm" name="category_id">
              <Select
                placeholder={'Nhóm sản phẩm'}
                onClick={() => setIsOpenGroupProduct(true)}
                open={false}
                options={category?.data?.map((item: any) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Form.Item>
            <Form.Item label="Mô tả sản phẩm" name="description">
              <Input.TextArea rows={3} placeholder={'Mô tả sản phẩm'} />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Đơn vị tính"
                  name="unit"
                  labelCol={{
                    span: 10,
                  }}
                >
                  <Input
                    placeholder={'Đơn vị tính'}
                    style={{
                      width: '80%',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Giá bán"
                  name="price"
                  labelCol={{
                    span: 10,
                  }}
                >
                  <InputNumber
                    formatter={formatInputNumber}
                    placeholder={'Giá bán'}
                    style={{
                      width: '80%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Các lựa chọn sản phẩm">
              <Button
                type="primary"
                ghost
                icon={<PlusOutlined />}
                style={{
                  border: 'none',
                }}
                onClick={() => setIsOpenPropertyProduct(true)}
              >
                Thêm lựa chọn
              </Button>
            </Form.Item>
            {checkedKeysProperty.length > 0 && (
              <ListPropertyTable
                dataSource={dataSource}
                setDataSource={setDataSource}
                flexibleCol={property.data?.filter((item: any) =>
                  checkedKeysProperty.includes(item?.id),
                )}
              />
            )}
            <Form.List name="bundles_products">
              {(fields, { add, remove }) => (
                <>
                  <Form.Item label="Sản phẩm bán kèm">
                    <Button
                      type="primary"
                      ghost
                      icon={<PlusOutlined />}
                      style={{
                        border: 'none',
                      }}
                      onClick={() => add()}
                    >
                      Thêm sản phẩm bán kèm
                    </Button>
                  </Form.Item>
                  {fields.map((field) => (
                    <InputBundleProduct
                      key={field.key}
                      field={field}
                      form={form}
                      bundleProduct={bundleProducts}
                      remove={remove}
                    />
                  ))}
                </>
              )}
            </Form.List>
            {isEdit && (
              <Row
                justify="center"
                style={{
                  margin: '20px 0',
                }}
              >
                <Button
                  type="primary"
                  className="btn-submit"
                  htmlType="submit"
                  loading={updateProduct.loading}
                >
                  Lưu
                </Button>
                <Button
                  type="primary"
                  ghost
                  className="btn-submit"
                  onClick={() => {
                    setIsEdit(false);
                    detailProduct.run(+params.id);
                  }}
                >
                  Huỷ
                </Button>
              </Row>
            )}
          </Form>

          <DrawerGroupProduct
            setSelectedRowKey={setSelectedRowKey}
            selectedRowKey={selectedRowKey}
            onChooseCategory={onChooseCategory}
            category={category}
            open={isOpenGroupProduct}
            setOpen={setIsOpenGroupProduct}
          />
          <DrawerPropertyProduct
            dataSource={dataSource}
            setDataSource={setDataSource}
            checkedKeys={checkedKeysProperty}
            setCheckedKeys={setCheckedKeysProperty}
            property={property}
            open={isOpenPropertyProduct}
            setOpen={setIsOpenPropertyProduct}
          />
        </div>
      )}
    </>
  );
};

export default DetailProduct;
