import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Row,
  Switch,
  Table,
  Tabs,
  Tooltip,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  EditOutlined,
  FileTextOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { render } from 'react-dom';
import { Link, Location, history, useLocation, useParams } from 'umi';
import { useAntdTable, useRequest } from 'ahooks';
import {
  getListTypePartnerVoucher,
  getListTypeVoucher,
  updateTypeVoucher,
} from '../service';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
const TypeVoucher = () => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState<'enterprise' | 'partner'>('enterprise');
  const {
    tableProps: typeLcmVoucher,
    search,
    refresh,
  } = useAntdTable(getListTypeVoucher, {
    form,
  });
  const { tableProps: typePartnerVoucher, search: searchPartner } =
    useAntdTable(getListTypePartnerVoucher, {
      form,
    });
  const { submit } = search;
  const changeDisplayVoucher = useRequest(updateTypeVoucher, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      refresh();
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const { submit: submitPartner } = searchPartner;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên loại voucher"
            allowClear
            onSearch={tab === 'enterprise' ? submit : submitPartner}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={tab === 'enterprise' ? submit : submitPartner}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        href={
          tab === 'enterprise'
            ? '/voucher/released/add_voucher'
            : '/voucher/released-partner/add_voucher'
        }
        // onClick={() => setIsOpenModal.toggle()}
      >
        Tạo loại voucher
      </Button>
    </div>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '10%',
    },
    {
      title: 'Tên loại voucher',
      dataIndex: 'name',
    },
    {
      title: 'Thời gian',
      align: 'right',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Giá trị voucher',
      align: 'right',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '15%',
      render: (_, record) => (
        <Row justify="space-around">
          <Tooltip title="Sửa">
            <Link to={`/voucher/released/${record?.id}`}>
              <EditOutlined className="purple-color" />
            </Link>
          </Tooltip>
          <Tooltip title="Thống kê sử dụng voucher">
            <Link to={`/statistic_voucher/${record?.id}`}>
              <FileTextOutlined className="primary-color" />
            </Link>
          </Tooltip>
          <Tooltip title="Lịch sử đổi voucher">
            <Link to={`/voucher/transfer_history/${record?.id}`}>
              <OrderedListOutlined />
            </Link>
          </Tooltip>
          <Tooltip title="Hiển thị">
            <Switch
              defaultChecked={record?.display}
              onChange={(val) => {
                changeDisplayVoucher.run(
                  {
                    display: val,
                  },
                  record?.id,
                );
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];
  const columns2: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '10%',
    },
    {
      title: 'Thời gian',
      align: 'right',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Tên loại voucher',
      dataIndex: 'name',
    },
    {
      title: 'Giá trị voucher',
      align: 'right',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (_, record) => (
        <>
          <Tooltip title="Sửa">
            <Link to={`/voucher/released-partner/${record?.id}`}>
              <EditOutlined className="purple-color" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];
  const location: any = useLocation();
  useEffect(() => {
    console.log(location?.query?.type);

    if (location?.query?.type === 'partner') {
      setTab('partner');
    } else if (
      location?.query?.type === 'enterprise' ||
      !location?.query?.type
    ) {
      setTab('enterprise');
    }
  }, [location]);

  const TypeVoucherLocamos = (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...typeLcmVoucher}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </>
  );
  const TypeVoucherPartner = (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...typePartnerVoucher}
          columns={columns2}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </>
  );
  const renderTab = () => {
    switch (tab) {
      case 'enterprise':
        return TypeVoucherLocamos;
      case 'partner':
        return TypeVoucherPartner;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <div className={styles.searchContainer}>
        <Tabs
          defaultActiveKey={
            location?.query?.type === 'partner' ? 'partner' : 'enterprise'
          }
          onChange={(key: any) => {
            setTab(key);
            form.resetFields();
            history.push(`/voucher/released?type=${key}`);
          }}
        >
          <Tabs.TabPane tab="Doanh nghiệp" key="enterprise"></Tabs.TabPane>
          <Tabs.TabPane tab="Đối tác" key="partner"></Tabs.TabPane>
        </Tabs>
      </div>
      {renderTab()}
    </div>
  );
};

export default TypeVoucher;
