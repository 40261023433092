import { ENVIRONMENTS } from '@/utils/constant';
import { DeleteOutlined } from '@ant-design/icons';
import {
  GoogleMap,
  InfoWindow,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import { useSetState, useToggle } from 'ahooks';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Upload,
} from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
//@ts-ignore
import React, { useState } from 'react';
import { useHistory, useIntl, useLocation } from 'umi';
import styles from '../index.less';
const { Option } = Select;
interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit: any;
}

interface IUser {}

const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  children,
  ...rest
}) => {
  const google = window.google;
  const { push } = useHistory();

  const loadScript = useLoadScript({
    googleMapsApiKey: ENVIRONMENTS.DEFAULT_API_MAP,
    libraries: ['drawing', 'places'],
  });

  const [pickedPosition, setPickedPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  } | null>(null);

  const [listPosition, setListPosition] = useState<
    | {
        lat?: number;
        lng?: number;
        customName?: string;
      }[]
    | null
  >([]);

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ]);
  const [searchBoxRef, setSearchBoxRef] = useState(null);
  const [editable, setEditable] = useToggle(true);
  const [userInfo, setUserInfo] = useSetState<any>(itemEdit);

  const [centerMap, setCenterMap] = useState<any>({
    lat: 21.041174897797408,
    lng: 105.815752083667,
  });

  const { formatMessage } = useIntl();

  const piece_count_select = Form.useWatch('piece_count_select', form);

  //@ts-ignore
  const onLoad = (ref) => {
    setSearchBoxRef(ref);
  };

  const onPlacesChanged = () => {
    //@ts-ignore
    const place = searchBoxRef?.getPlaces()[0];
    const newLocation = {
      lat: place?.geometry?.location.lat(),
      lng: place?.geometry?.location.lng(),
    };
    setCenterMap(newLocation);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onFinish = (values: any) => {};

  const onAddPosition = () => {
    const newList: any = listPosition?.slice();
    newList?.push(pickedPosition);
    setListPosition(newList);
  };

  const renderPopup = () => {
    if (!pickedPosition) return;
    return (
      <InfoWindow
        position={pickedPosition}
        onCloseClick={() => {
          setPickedPosition(null);
          return null;
        }}
      >
        <div className={styles.popupMap}>
          <h3>{pickedPosition.customName}</h3>
          <p>lat: {pickedPosition.lat}</p>
          <p>lng: {pickedPosition.lng}</p>
          <Button onClick={onAddPosition} type="primary">
            Chọn
          </Button>
        </div>
      </InfoWindow>
    );
  };

  const onPointClick = (event: any) => {
    const geoCode = new window.google.maps.Geocoder();
    geoCode.geocode(
      {
        location: { lat: event.latLng?.lat(), lng: event.latLng?.lng() },
      },
      (results, status) => {
        console.log(
          '🚀 ~ file: Dialog.tsx:173 ~ onPointClick ~ results',
          results,
        );

        if (results && status == google.maps.GeocoderStatus.OK) {
          setPickedPosition({
            lat: event.latLng?.lat(),
            lng: event.latLng?.lng(),
            customName: results[0].formatted_address,
          });
        }
      },
    );
    setPickedPosition({
      lat: event.latLng?.lat(),
      lng: event.latLng?.lng(),
    });
  };

  const deletePosition = (i: number) => {
    const newList = listPosition?.slice();
    newList?.splice(i, 1);
    setListPosition(newList);
  };

  return (
    <>
      <Modal
        title={
          itemEdit
            ? editable
              ? formatMessage({ id: 'general_edit_infomation' })
              : formatMessage({ id: 'general_view_infomation' })
            : formatMessage({ id: 'general_add' })
        }
        centered
        width={900}
        onCancel={() => setOpen(false)}
        visible={open}
        footer={null}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={userInfo}
        >
          <Row gutter={16}>
            <Col span={24} className={styles.dialogFormItem}>
              <Form.Item
                name="piece_name"
                label={'Tên mảnh ghép'}
                // initialValue={userInfo.phone}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} className={styles.dialogFormItem}>
              <Form.Item
                name="piece_image"
                label="Ảnh mảnh ghép"
                // initialValue={userInfo.fullName}
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'error.require' },
                      {
                        field: formatMessage({ id: 'piece_image' }),
                      },
                    ),
                  },
                ]}
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} className={styles.dialogFormItem}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="piece_count_select"
                    label={'Số lượng mảnh ghép '}
                    initialValue={'customize'}
                  >
                    <Select>
                      <Option value="customize">Tuỷ chỉnh</Option>
                      <Option value="nolimit">Không giới hạn</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className={styles.formWithoutLabel}>
                    {piece_count_select === 'customize' ? <Input /> : null}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24} className={styles.dialogFormItem}>
              <Form.Item
                name="timeAppear"
                label="Thời gian xuất hiện"
                // initialValue={userInfo.phone}
              >
                {/* @ts-ignore */}
                <DatePicker.RangePicker
                  picker="date"
                  className="w-100"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Vị trí" name="map">
                {loadScript.isLoaded ? (
                  <GoogleMap
                    zoom={14}
                    // onLoad={(m) => setMap(m)}
                    center={centerMap}
                    mapContainerClassName={styles.mapContainer}
                    clickableIcons={false}
                    onClick={(e) => {
                      if (e) {
                        onPointClick(e);
                      }
                    }}
                  >
                    <StandaloneSearchBox
                      onLoad={onLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        type="text"
                        placeholder="Tìm kiếm"
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `240px`,
                          height: `40px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          top: '10px',
                          left: '50%',
                          marginLeft: '-120px',
                        }}
                      />
                    </StandaloneSearchBox>
                    {renderPopup()}
                  </GoogleMap>
                ) : (
                  <Skeleton active />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              {listPosition?.map((e, i) => {
                return (
                  <Row gutter={16}>
                    <Col span={11} className={styles.dialogFormItem}>
                      <Form.Item label="Lat">
                        <Input value={e.lat} />
                      </Form.Item>
                    </Col>
                    <Col span={11} className={styles.dialogFormItem}>
                      <Form.Item label="Lng">
                        <Input value={e.lng} />
                      </Form.Item>
                    </Col>
                    <Col span={2} className={styles.deleteWrap}>
                      <DeleteOutlined
                        className={styles.deleteIcon}
                        onClick={() => {
                          deletePosition(i);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <div className={styles.addGroupButton}>
            <Button
              danger
              onClick={() => setOpen(false)}
              className={styles.addButton}
            >
              {formatMessage({ id: 'general_cancel' })}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              className={styles.addButton}
            >
              {formatMessage({ id: 'general_save' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
