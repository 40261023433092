import React, { useEffect } from 'react';
import styles from '../../index.less';
import { validateNumber } from '@/utils/patern';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Row, Spin, message } from 'antd';
import { getOneUser, setTransferLimitService } from '../../service';
interface IPropsModal {
  isOpen: boolean;
  userId: number;
  onCancel: () => void;
  inititalTransferLimit: {
    receiver_id?: string;
    limit?: any;
  } | null;
  refresh: () => void;
}

const LimitTransferDialog = ({
  isOpen,
  onCancel,
  userId,
  inititalTransferLimit,
  refresh,
}: IPropsModal) => {
  const [form] = Form.useForm();
  const searchUserInfo = useRequest(getOneUser, {
    manual: true,
    debounceWait: 1000,
    onSuccess(res: any) {
      console.log(res);
    },
    onError() {
      message.error('Không tìm thấy User');
    },
  });
  const onSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateNumber(e.target.value)) {
      searchUserInfo.run(e.target.value);
    }
  };
  const setTransferLimit = useRequest(setTransferLimitService, {
    manual: true,
    onSuccess: () => {
      refresh();
      message.success('Thành công');
      onCancel();
    },
    onError: (err: any) => {
      if (err?.data) {
        if (err?.data?.statusCode === 403) message.error('Không có quyền');
        else {
          message.error('Có lỗi xảy ra!');
        }
      }
    },
  });
  const onFinish = (val: any) => {
    setTransferLimit.run({
      user_id: userId,
      limit: +val?.limit,
      receiver_id: +val?.receiver_id,
    });
  };
  useEffect(() => {
    if (!!inititalTransferLimit?.receiver_id) {
      searchUserInfo.run(inititalTransferLimit.receiver_id);
    }
  }, [inititalTransferLimit]);
  return (
    <Modal
      title="Cài đặt hạn mức chuyển voucher"
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={500}
    >
      <Form
        form={form}
        layout="horizontal"
        initialValues={inititalTransferLimit ?? {}}
        labelCol={{
          span: 8,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Id User muốn chuyển"
          name="receiver_id"
          className={styles.searchIdUser}
          rules={[
            {
              validator(_, value) {
                if (!!value) {
                  if (!validateNumber(value)) {
                    return Promise.reject('Vui lòng nhập số ID');
                  }
                  return Promise.resolve();
                }
                return Promise.reject('Vui lòng nhập');
              },
            },
          ]}
        >
          <Input placeholder="Nhập Id" onChange={onSearchUser} />
        </Form.Item>
        <div className={styles.userIdProfile}>
          {searchUserInfo.loading ? (
            <Spin />
          ) : (
            searchUserInfo.data?.data?.user_profile?.full_name
          )}
        </div>
        <Form.Item
          label="Hạn mức chuyển"
          name="limit"
          rules={[
            {
              validator(_, value) {
                if (!!value) {
                  if (!validateNumber(value)) {
                    return Promise.reject('Vui lòng nhập số');
                  }
                  return Promise.resolve();
                }
                return Promise.reject('Vui lòng nhập');
              },
            },
          ]}
        >
          <Input placeholder="Nhập hạn mức" />
        </Form.Item>
        <Row justify="center">
          <Button
            type="primary"
            ghost
            danger
            className="btn-submit"
            style={{
              padding: '0 30px',
            }}
            onClick={onCancel}
          >
            Hủy
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            loading={setTransferLimit.loading}
            style={{
              padding: '0 30px',
            }}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default LimitTransferDialog;
