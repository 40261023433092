import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchValue: string;
    date: string[];
    type: number;
  },
) => {
  if (formData.searchValue === undefined) {
    formData.searchValue = '';
  }

  const fromDate = Array.isArray(formData.date)
    ? moment(formData.date[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.date)
    ? moment(formData.date[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `mutation {
      get_did_blacklist(input: {page_size: ${pageSize}, page_index: ${
      current - 1
    }, date_start: "${fromDate}", date_end: "${toDate}", search_value: "${
      formData.searchValue
    }" ${
      formData.type !== undefined ? `, block_type: ${formData.type}` : ''
    }}) {
        total
        data {
          id
          device_id
          became_blacklist_at
          became_blacklist_by
          type
        }
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_did_blacklist.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.get_did_blacklist?.total,
        list: result,
      };
    },
  );
};

export const unlockDevice = (id: string) => {
  const data = {
    query: `mutation {
      remove_from_blacklist(id: ${id}) {
        message
      }
    }
    `,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const addDeviceToBlacklist = (device_id: string) => {
  const data = {
    query: `mutation {
      add_device_to_blacklist(device_id: "${device_id}")
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
export const getConfig = () => {
  const data = {
    query: `mutation {
      get_acc_per_device_config {
        day
        week
        month
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data });
};

export const setConfig = (value: {
  day: number;
  month: number;
  week: number;
}) => {
  const data = {
    query: `mutation {
      edit_acc_per_device_config(input: {day: ${value.day}, month: ${value.month}, week: ${value.week}})
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};
