import {
  Form,
  Input,
  DatePicker,
  Table,
  Button,
  Select,
  message,
  Tag,
  Space,
  Tooltip,
  Tabs,
} from 'antd';
import React, { useState } from 'react';
import styles from '../index.less';
import { Status, TypeGift, getTableData } from '../service';
import { useAntdTable } from 'ahooks';
import { useIntl, history, useLocation } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import ModalAccept from '../Components/ModalAccept';
import ModalReject from '../Components/ModalReject';
const { RangePicker } = DatePicker;
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const ListGift = () => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState('1');
  const location: any = useLocation();
  const optionStatus = [
    {
      value: Status.CHO_DUYET,
      label: 'Chờ duyệt',
      color: 'warning',
    },
    {
      value: Status.DUYET,
      label: 'Đã duyệt',
      color: 'green',
    },
    {
      value: Status.TU_CHOI,
      label: 'Đã từ chối',
      color: 'volcano',
    },
  ];
  const optionGiftType = [
    {
      value: TypeGift.GIFT_COLLECTION,
      label: TypeGift.GIFT_COLLECTION,
      color: 'warning',
    },
    {
      value: TypeGift.GIFT_LOCABONUS,
      label: TypeGift.GIFT_LOCABONUS,
      color: 'green',
    },
    {
      value: TypeGift.GIFT_NFT,
      label: TypeGift.GIFT_NFT,
      color: 'volcano',
    },
    {
      value: TypeGift.GIFT_VOUCHER_URBOX,
      label: TypeGift.GIFT_VOUCHER_URBOX,
      color: 'volcano',
    },
    {
      value: TypeGift.GIFT_LOCABONUS_QR_BIZ,
      label: TypeGift.GIFT_LOCABONUS_QR_BIZ,
      color: 'volcano',
    },
    {
      value: TypeGift.GIFT_VOUCHER_LOCAMOS,
      label: TypeGift.GIFT_VOUCHER_LOCAMOS,
    },
  ];
  const { tableProps, search, params, refresh, loading, error, run } =
    useAntdTable(getTableData, {
      defaultParams: [
        {
          current: 1,
          pageSize: 10,
          is_enterprise: location.query?.is_enterprise == 'true' ? true : false,
        },
        {},
      ],
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    });
  const { submit } = search;
  const { formatMessage } = useIntl();

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 80,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (values: string, record: any, index: number) => {
        return <>{moment(values).format('HH:mm:ss DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Tên quà',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Loại quà tặng',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Số lượng quà',
      key: 'limit',
      render: (record: any) => {
        return (
          <>
            {record.received}/{record.limit}
          </>
        );
      },
    },
    {
      title: 'Người tạo',
      key: 'created',
      render: (record: any) => {
        return <>{record?.created?.email}</>;
      },
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'time_end',
      key: 'time_end',
      width: 200,
      render: (value: string, record: any, index: number) => {
        return <>{moment(value).format('HH:mm:ss DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'gift_status',
      key: 'gift_status',
      render: (value: string) => {
        {
          const data: any = optionStatus.filter((status) => {
            if (status.value == value) {
              return true;
            } else {
              return false;
            }
          });
          return (
            <Tag color={data[0]?.color} key={value}>
              {data[0]?.label}
            </Tag>
          );
        }
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        // console.log('🚀 ~ file: index.tsx:127 ~ record:', record);
        if (record.gift_status != Status.CHO_DUYET) {
          return (
            <Space
              className={styles.action}
              style={{
                justifyContent: 'center',
              }}
            >
              <Tooltip title="Xem">
                <a href={`/gift-building/${record.id}`}>
                  <EyeOutlined className="purple-color" />
                </a>
              </Tooltip>
              {record?.gift_status === Status.DUYET && (
                <Tooltip title="Xem danh sách nhặt quà">
                  <a href={`/list-received-gift/${record.id}`}>
                    <OrderedListOutlined />
                  </a>
                </Tooltip>
              )}
            </Space>
          );
        } else {
          return (
            <Space className={styles.action}>
              <Tooltip title="Xem">
                <a href={`/gift-building/${record.id}`}>
                  <EyeOutlined className="purple-color" />
                </a>
              </Tooltip>
              <ModalAccept
                id={record.id}
                onReload={() => {
                  submit();
                }}
              >
                <Tooltip title="Duyệt">
                  <CheckOutlined className="green-color" />
                </Tooltip>
              </ModalAccept>

              <ModalReject
                id={record.id}
                onReload={() => {
                  submit();
                }}
              >
                <Tooltip title="Từ chối">
                  <CloseOutlined className="red-color" />
                </Tooltip>
              </ModalReject>
            </Space>
          );
        }
      },
    },
  ];
  const onChangeDatePicker = () => {
    const value = form.getFieldValue('date');
    if (value) {
      const startDate = moment(value?.[0]).startOf('day').toISOString();
      const endDate = moment(value?.[1]).endOf('day').toISOString();
      form.setFieldValue('start_date', startDate);
      form.setFieldValue('end_date', endDate);
    } else {
      form.setFieldValue('start_date', '');
      form.setFieldValue('end_date', '');
    }
    submit();
  };

  return (
    <>
      <Tabs
        defaultActiveKey={location.query?.is_enterprise === 'true' ? '2' : '1'}
        onChange={(key) => {
          form.resetFields();
          setTab(key);
          history.push(
            key === '2'
              ? '/gift-building?is_enterprise=true'
              : '/gift-building',
          );
          run(
            {
              current: 1,
              pageSize: 10,
              is_enterprise: key === '1' ? false : true,
            },
            {},
          );
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="Quà hệ thống" key="1"></Tabs.TabPane>
        <Tabs.TabPane tab="Quà doanh nghiệp" key="2"></Tabs.TabPane>
      </Tabs>
      <div className={styles.searchContainer}>
        <Form
          form={form}
          autoComplete="off"
          className={styles.searchForm}
          layout="inline"
        >
          <Form.Item name="search_text" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm tên quà tặng, tên building"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="date" className={styles.searchItem}>
            <RangePicker
              onChange={onChangeDatePicker}
              format={['DD/MM/YYYY', 'DD/MM/YY']}
            />
          </Form.Item>
          <Form.Item name="status" className={styles.searchItem}>
            <Select
              placeholder="Trạng thái"
              allowClear
              options={optionStatus}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item name="gift_type" className={styles.searchItem}>
            <Select
              placeholder="Loại quà"
              allowClear
              options={optionGiftType}
              onChange={submit}
            />
          </Form.Item>
        </Form>
        <Button
          onClick={() =>
            history.push(
              `/create-gift-building${
                location.query?.is_enterprise === 'true'
                  ? '?is_enterprise=true'
                  : ''
              }`,
            )
          }
          className={styles.createBuilding}
        >
          Tạo quà tặng
        </Button>
      </div>
      <div className={styles.tableComponent}>
        <Table
          rowKey="id"
          columns={columns}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
          {...tableProps}
        />
      </div>
    </>
  );
};

export default ListGift;
