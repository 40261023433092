import React, { useState } from 'react';
import {
  Breadcrumb,
  Form,
  Select,
  Input,
  Row,
  Button,
  Col,
  Pagination,
  Skeleton,
} from 'antd';
import { history } from 'umi';

import styles from './index.less';
import ItemTemplate from './ItemTemplate';
import { ITemplateBuilding, useGetBuildingTemplate } from './service';
import { useDebounceFn } from 'ahooks';

// const { Option } = Select;

export default () => {
  const [form] = Form.useForm();
  const optionStatus = [
    {
      value: 1,
      label: 'Hoạt động',
    },
    {
      value: 0,
      label: 'Không hoạt động',
    },
  ];
  const optionOrder = [
    {
      label: 'Tên A-Z',
      value: 'name-desc',
    },
    {
      label: 'Tên Z-A',
      value: 'name-asc',
    },
    {
      label: 'Sao cao -> thấp',
      value: 'rating-desc',
    },
    {
      label: 'Sao thấp -> cao',
      value: 'rating-asc',
    },
    {
      label: 'Giá cao -> thấp',
      value: 'cost-desc',
    },
    {
      label: 'Giá thấp -> cao',
      value: 'cost-asc',
    },
  ];
  const [page, setPage] = useState(1);
  const { buildingTemplate, run, total, loading } = useGetBuildingTemplate();
  // const { run: onSearch } = useDebounceFn(
  //   () => {

  //   },
  //   {
  //     wait: 500,
  //   },
  // );
  const changeDataForm = () => {
    setPage(1);
    const value = form.getFieldsValue();
    const order = form.getFieldValue('order')?.split('-');
    const sort_key = {
      sort_key: order?.[0],
      sort_dir: order?.[1],
    };
    delete value.order;
    run({ ...value, ...sort_key }, 1);
  };
  React.useEffect(() => {
    const value = form.getFieldsValue();
    const order = form.getFieldValue('order')?.split('-');
    const sort_key = {
      sort_key: order?.[0],
      sort_dir: order?.[1],
    };
    delete value.order;
    run({ ...value, ...sort_key }, page);
  }, [page]);
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        onFieldsChange={changeDataForm}
        form={form}
        autoComplete="off"
        layout="inline"
        className={styles.searchForm}
      >
        <Form.Item name="search_text" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên mẫu tòa nhà, ID VR Tour"
            allowClear
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select options={optionStatus} placeholder="Trạng thái" allowClear />
        </Form.Item>
        <Form.Item name="order" className={styles.searchItem}>
          <Select options={optionOrder} placeholder="Sắp xếp theo" allowClear />
        </Form.Item>
      </Form>
      <Button onClick={() => history.push('/create-template')}>
        Tạo mẫu mới
      </Button>
    </div>
  );
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Danh sách mẫu toà nhà</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Row gutter={20} wrap>
          {loading ? (
            <Skeleton />
          ) : (
            buildingTemplate?.map(
              (template: ITemplateBuilding, index: number) => {
                return (
                  <Col span={6} key={index} lg={6} sm={12} xs={24}>
                    <ItemTemplate template={template} />
                  </Col>
                );
              },
            )
          )}
        </Row>
        <Row
          justify="end"
          style={{
            padding: '30px 0',
          }}
        >
          <Pagination
            total={total}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </Row>
      </div>
    </>
  );
};
