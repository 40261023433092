import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import dayjs from 'dayjs';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  // `page=${current}&pageSize=${pageSize}`

  const data = {
    query: `
    mutation {
      get_list_admin(search_object: { 
        search_text: "${formData.fullName}"
        page_index: ${current ? current - 1 : 0}
        page_size:  ${pageSize || 10}
      }) {
        message
        data {
          email
          phone
          id
          status
          admin_profile {
            address
            admin_id
            avatar
            date_created
            date_modified
            date_of_birth
            full_name
            id
          }
          role {
            id
            role_name
            is_active
            permissions {
              id
              admin_management_level
              role_group_level
              user_management_level
              kyc_management_level
              gift_management_level
              gift_recive_management_level
              item_management_level
              report_management_level
              system_setting_level
            }
          }
        }
        total
      }
    }    
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_list_admin.data?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
          full_name: e?.admin_profile?.full_name,
          dateOfBirth: dayjs(e?.admin_profile?.date_created).format(
            'HH:mm:ss DD/MM/YYYY',
          ),
        }),
      );
      return {
        total: res?.data?.get_list_admin?.total,
        list: result,
      };
    },
  );
};

export const getTableDataHistory = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchValue: string;
    date: string[];
  },
): Promise<Result> => {
  if (formData.searchValue === undefined) {
    formData.searchValue = '';
  }
  const fromDate = Array.isArray(formData.date)
    ? moment(formData.date[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.date)
    ? moment(formData.date[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `{
      list_admin_change_history(input: { page_index: ${
        current - 1
      }, page_size: ${pageSize}, search: "${formData.searchValue}", ${
      fromDate ? `from_date: "${fromDate}"` : ''
    }, ${toDate ? `to_date: "${toDate}"` : ''} }) {
        total
        data {
          id
          admin_id
          change_log
          updated_at
          user {
            id
            email
            admin_profile {
              full_name
            }
          }
        }
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.list_admin_change_history.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.list_admin_change_history?.total,
        list: result,
      };
    },
  );
};

export const deleteAdmin = (id: any) => {
  const query = `
  mutation {
    delete_admin(input: { id: "${id}" })
  }

  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const switchStatusAdmin = (id: any) => {
  const query = `
    mutation {
      switch_status_admin(id: "${id}")
    }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
