import { STATUS_KYC, STATUS_LOCABONUS } from '@/utils/constant';

import { useAntdTable, useRequest, useToggle } from 'ahooks';
import {
  Breadcrumb,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl, history, useHistory } from 'umi';
import styles from './index.less';
import { getTableData, getTotalLCB, handleKycService } from './service';
import { formatNumber } from '@/utils/apis/common';

const { Option } = Select;
interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

export default () => {
  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();

  const dateFormat = 'DD/MM/YYYY';

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const totalLCB = useRequest(getTotalLCB);

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      align: 'right',
      key: 'created_at',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.created_at
              ? moment(record.created_at).format('HH:mm:ss DD/MM/YYYY')
              : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_deal_id',
      dataIndex: 'record_id',
      key: 'record_id',
    },
    {
      title: 'const_column_deal',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'const_column_locabonus',
      align: 'right',
      dataIndex: 'amount',
      render: (_: any, record: any) => <>{formatNumber(record?.amount)}</>,
      key: 'amount',
    },
    {
      title: 'content',
      dataIndex: 'description',
      key: 'description',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        className={styles.searchForm}
        layout="inline"
        initialValues={{
          toDate: [moment(new Date()), moment(new Date())],
        }}
      >
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo Id giao dịch"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="type"
          className={styles.searchItem}
          initialValue={null}
        >
          <Select onChange={submit} allowClear placeholder="Loại giao dịch">
            {STATUS_LOCABONUS.map((item) => (
              <Option value={item.value}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <Row align="middle" justify="space-between">
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>
            {formatMessage({ id: 'total_Locabonus' })}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div>
          {`${formatMessage({
            id: 'total_locabonus_mount',
          })}:`}
          <strong className="primary-color">{`${formatNumber(
            totalLCB.data?.total_locabonus,
          )}`}</strong>
        </div>
      </Row>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
      {/* {rejectDialog && (
        <Dialog
          open={rejectDialog}
          setOpen={(b) => {
            setRejectDialog.set(b);
            refresh();
          }}
          onConfirm={(reason) => {
            if (idSelected) handleKyc(idSelected, false, reason);
            setRejectDialog.set(false);
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};
