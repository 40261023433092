export const paternPhone: RegExp =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$|(0[3|5|7|8|9])+([0-9]{8})\b/g;
export const paternPhoneLogin: RegExp =
  /^(0|\+84)(\s|\.)?(3[2-9]|7[06789]|8[012345689]|9[0134678])([0-9]{7,9})$/;
export const paternpassWord: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,15}$/;
export const paternEmail: RegExp =
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const paternUrl: RegExp =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const patternInt: RegExp = /^\d+$/;

export function validatePhone(phone: string) {
  const paternPhone: RegExp = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
  return paternPhone.test(phone);
}
export function validateNumber(number: string) {
  const paternNumber: RegExp = /^\d+$/;
  return paternNumber.test(number);
}
