import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  message,
} from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'umi';
import styles from '../index.less';
import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { paternUrl } from '@/utils/patern';
import { ColumnsType } from 'antd/lib/table';
import { useAntdTable, useRequest } from 'ahooks';
import { UserGroup, createNotification, getListGroupUser } from '../service';
import SelectGroupUserModal from './SelectGroupUserModal';
import dayjs from 'dayjs';
import moment from 'moment';

const CreateNotification = () => {
  const [optionUser, setOptionUser] = useState<'all' | 'custom'>('all');
  const [groupUser, setGroupUser] = useState<UserGroup[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const history = useHistory();
  const onDeleteGroup = (id: string) => {
    setGroupUser(groupUser.filter((item) => item.id !== id));
  };
  const createNoti = useRequest(createNotification, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      message.success('Tạo thông báo thành công');
      history.push('/notification');
    },
    onError: (err) => {
      console.log(err);
      message.error('Có lỗi xảy ra');
    },
  });
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nhóm user',
      dataIndex: 'name',
    },
    {
      title: 'Hành động',
      align: 'center',
      // width: '10%',
      dataIndex: 'id',
      render: (value, record) => (
        <Tooltip title="Xoá">
          <DeleteOutlined
            style={{
              cursor: 'pointer',
            }}
            className="red-color"
            onClick={() => onDeleteGroup(record?.id)}
          />
        </Tooltip>
      ),
    },
  ];
  const disabledHours = () => {
    const hours = [];
    const currentHour = moment().hour();

    for (let i = currentHour - 1; i > 0; i--) {
      hours.push(i);
    }

    return hours;
  };

  const disabledMinutes = (selectedHour: any) => {
    const minutes = [];
    const currentMinute = moment().minute();
    if (selectedHour === moment().hour()) {
      for (let i = currentMinute - 1; i > 0; i--) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  const onSubmit = (val: any) => {
    createNoti.run({
      ...val,
      user_groups: groupUser,
    });
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/notification" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>Tạo thông báo</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.createNotiWrapper}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          onFinish={onSubmit}
        >
          <Form.Item
            label="Thời gian gửi"
            name="sending_time"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn',
              },
            ]}
          >
            <DatePicker
              showTime
              format={'DD/MM/YYYY HH:mm'}
              disabledDate={(currentDate) =>
                currentDate < dayjs().add(-1, 'day')
              }
              disabledTime={() => ({
                disabledHours: disabledHours,
                disabledMinutes: disabledMinutes,
              })}
            />
          </Form.Item>
          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tiêu đề thông báo" />
          </Form.Item>
          <Form.Item
            label="Nội dung"
            name="body"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input.TextArea placeholder="Nhập nội dung thông báo" rows={3} />
          </Form.Item>
          <Form.Item
            label="Link điều hướng"
            name="link"
            rules={[
              {
                required: false,
              },
              {
                pattern: paternUrl,
                message: 'Không đúng định dạng',
              },
            ]}
          >
            <Input placeholder="Nhập link" />
          </Form.Item>
          <Form.Item label="Nhóm user">
            <Select
              onChange={(val) => {
                setOptionUser(val);
              }}
              defaultValue={optionUser}
            >
              <Select.Option value="all">Tất cả</Select.Option>
              <Select.Option value="custom">Tuỳ chọn</Select.Option>
            </Select>
          </Form.Item>
          {optionUser === 'custom' && (
            <div>
              <Button
                type="primary"
                ghost
                style={{
                  margin: '15px 0',
                }}
                onClick={() => setIsOpenModal(true)}
              >
                Thêm nhóm user nhận thông báo
              </Button>
              <Table columns={columns} dataSource={groupUser} rowKey="id" />
            </div>
          )}
          <Row
            justify="center"
            style={{
              marginTop: '20px',
            }}
          >
            <Button
              type="primary"
              ghost
              danger
              className="btn-submit"
              onClick={() => history.push('/notification')}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              loading={createNoti.loading}
            >
              Lưu
            </Button>
          </Row>
        </Form>
        {isOpenModal && (
          <SelectGroupUserModal
            isOpen={isOpenModal}
            selectedGroup={groupUser}
            setGroupUser={setGroupUser}
            onClose={() => setIsOpenModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CreateNotification;
