import React, { useState } from 'react';
import styles from '../index.less';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { STATUS_KYC, STATUS_REPORT_VOUCHER } from '@/utils/constant';
import { ColumnsType } from 'antd/lib/table';
import { StatusReportVoucher } from '@/utils/enum';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import { BadReportVoucherService, handleReportService } from '../service';
import dayjs from 'dayjs';
import DetailBadReport from './DetailBadReport';
import { useIntl, useRequest } from 'umi';

const BadReportVoucher = () => {
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(
    BadReportVoucherService,
    {
      form,
    },
  );
  const [isOpenDetail, setIsOpenDetail] = useToggle(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'updated_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Người báo xấu',
      render: (value: any, record: any) => (
        <>
          {record?.user_id} - {record?.user?.email}
        </>
      ),
    },
    {
      title: 'Tên doanh nghiệp',
      dataIndex: ['enterprise', 'name'],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: StatusReportVoucher) => (
        <>
          {value === StatusReportVoucher.REFUSE ? (
            <Tag color="red" icon={<CloseOutlined />}>
              Đã từ chối
            </Tag>
          ) : value === StatusReportVoucher.APPROVE ? (
            <Tag color="green" icon={<CheckOutlined />}>
              Đã duyệt
            </Tag>
          ) : value === StatusReportVoucher.PENDING ? (
            <Tag color="warning" icon={<ClockCircleOutlined />}>
              Chờ xử lý
            </Tag>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (_: any, record: any) => (
        <Row justify="space-around">
          <Tooltip title="Xem">
            <EyeOutlined
              style={{
                cursor: 'pointer',
              }}
              className="purple-color"
              onClick={() => {
                setIsOpenDetail.set(true);
                setSelectedId(record?.id);
              }}
            />
          </Tooltip>
          {record?.status === StatusReportVoucher.PENDING && (
            <>
              <Tooltip title="Phê duyệt">
                <CheckOutlined
                  className="green-color"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleApproveReport(record?.id)}
                />
              </Tooltip>
              <Tooltip title="Từ chối">
                <CloseOutlined
                  className="red-color"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRefuseReport(record?.id)}
                />
              </Tooltip>
            </>
          )}
        </Row>
      ),
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm ID/tên user, ID/tên DN"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="status" initialValue={StatusReportVoucher.PENDING}>
          <Select
            allowClear
            onChange={submit}
            placeholder="Trạng thái"
            options={STATUS_REPORT_VOUCHER.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </Form.Item>
      </Form>
    </div>
  );

  const { formatMessage } = useIntl();
  const handleReportVoucher = useRequest(handleReportService, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
      refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const [formRefuse] = Form.useForm();
  const handleRefuseReport = (id: string) => {
    const onSubmitReason = (value: any) => {
      handleReportVoucher.run({
        id,
        status: StatusReportVoucher.REFUSE,
        reason: value.reason,
      });
    };
    return Modal.confirm({
      icon: null,
      title: 'Lý do từ chối yêu cầu báo xấu',
      content: (
        <Form
          id="formRefuse"
          layout="vertical"
          form={formRefuse}
          onFinish={onSubmitReason}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: handleReportVoucher.loading,
      },
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onCancel() {
        formRefuse.resetFields();
      },
      onOk() {
        if (formRefuse.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const handleApproveReport = (id: string) => {
    return Modal.confirm({
      icon: null,
      title: 'Phê duyệt báo xấu voucher',
      content: `Bạn có chắc chắn muốn phê duyệt báo xấu voucher này không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        loading: handleReportVoucher.loading,
      },
      onOk() {
        handleReportVoucher.run({
          id: id,
          status: StatusReportVoucher.APPROVE,
        });
      },
    });
  };
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
      {isOpenDetail && (
        <DetailBadReport
          isOpen={isOpenDetail}
          setIsOpen={setIsOpenDetail}
          id={selectedId}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default BadReportVoucher;
