import { Button, Form, Input, Modal, Row, Tag, message } from 'antd';
import React from 'react';
import renderStatusTransferVoucherWeb from '../index';
interface IModalProps {
  isOpen: boolean;
  setIsOpen: any;
  refresh: () => void;
  data: any;
}
import { TransferVoucherWeb } from '@/utils/enum';
import { useRequest } from 'ahooks';
import { confirmTransferRequest } from '../service';
import dayjs from 'dayjs';
const DetailTransferVoucherModal = ({
  isOpen,
  setIsOpen,
  refresh,
  data,
}: IModalProps) => {
  const onCancel = () => {
    setIsOpen(false);
  };
  const confirmTransfer = useRequest(confirmTransferRequest, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      refresh();
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const renderStatus = (status: TransferVoucherWeb) => {
    switch (status) {
      case TransferVoucherWeb.CANCELED:
        return <Tag color="grey">Không thành công</Tag>;
      case TransferVoucherWeb.REQUESTED:
        return <Tag color="warning">Đang xử lý</Tag>;
      case TransferVoucherWeb.REFUSED:
        return <Tag color="red">Từ chối</Tag>;
      case TransferVoucherWeb.CONFIRMED:
        return <Tag color="green">Hoàn thành</Tag>;
      case TransferVoucherWeb.CALLBACK_ERROR:
        return <Tag color="red">Lỗi đồng bộ</Tag>;
      default:
        break;
    }
  };
  const handleApproveTransfer = (id: number) => {
    return Modal.confirm({
      title: 'Xác nhận yêu cầu chuyển voucher',
      content: 'Bạn có chắc chắn muốn xác nhận yêu cầu chuyển voucher?',
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      okButtonProps: {
        type: 'primary',
      },
      icon: null,
      onOk: () => {
        confirmTransfer.run({
          request_id: id,
          confirm: true,
        });
      },
    });
  };
  const [formRefuse] = Form.useForm();
  const handleRefuseTransfer = (id: number) => {
    const onSubmitRefuse = (val: any) => {
      confirmTransfer.run({
        request_id: id,
        confirm: false,
        note: val.reason,
      });
    };
    return Modal.confirm({
      title: 'Xác nhận từ chối chuyển voucher',
      icon: null,
      content: (
        <>
          <p>Bạn có chắc chắn muốn từ chối yêu cầu chuyển voucher?</p>
          <Form
            layout="vertical"
            onFinish={onSubmitRefuse}
            form={formRefuse}
            id="formRefuse"
          >
            <Form.Item
              label="Lý do"
              name="reason"
              rules={[{ required: true, message: 'Nhập lý do' }]}
            >
              <Input.TextArea rows={4} placeholder="Nhập lý do từ chối" />
            </Form.Item>
          </Form>
        </>
      ),
      okText: 'Xác nhận',
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: confirmTransfer.loading,
      },
      onCancel: () => {
        formRefuse.resetFields();
      },
    });
  };
  return (
    <Modal
      title="Chi tiết giao dịch"
      onCancel={onCancel}
      footer={null}
      open={isOpen}
      width={600}
    >
      <Form
        initialValues={{
          ...data,
          handle_by: data?.handle_by?.full_name,
          handle_at: dayjs(data?.handle_at).format('HH:mm DD/MM/YYYY'),
        }}
        labelCol={{
          span: 6,
        }}
        labelWrap
        labelAlign="left"
      >
        <b>Thông tin người chuyển</b>
        <Form.Item name="email" label="Email">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="phone_number" label="Số điện thoại">
          <Input readOnly />
        </Form.Item>
        <b>Thông tin người nhận</b>
        <Form.Item name="receiver_name" label="Người nhận">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="receiver_email" label="Email">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="receiver_phone" label="Số điện thoại">
          <Input readOnly />
        </Form.Item>
        <b>Chi tiết giao dịch</b>
        <Form.Item name="quantity" label="Số lượng voucher">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="request_description" label="Nội dung giao dịch">
          <Input readOnly />
        </Form.Item>
        <Form.Item label="Trạng thái">{renderStatus(data?.status)}</Form.Item>
        {data?.status === TransferVoucherWeb.REFUSED && (
          <Form.Item label="Lý do" name="note">
            <Input readOnly />
          </Form.Item>
        )}
        {data?.status === TransferVoucherWeb.CANCELED && (
          <Form.Item
            label="Lý do"
            name="reason"
            initialValue="Qua 72h tự động hủy giao dịch"
          >
            <Input readOnly />
          </Form.Item>
        )}
        {(data?.status === TransferVoucherWeb.CONFIRMED ||
          data?.status === TransferVoucherWeb.REFUSED) && (
          <>
            <Form.Item name="handle_by" label="Admin duyệt">
              <Input readOnly />
            </Form.Item>
            <Form.Item name="handle_at" label="Ngày duyệt">
              <Input readOnly />
            </Form.Item>
          </>
        )}
      </Form>
      {data?.status === TransferVoucherWeb.REQUESTED && (
        <Row justify="center">
          <Button
            type="primary"
            className="btn-submit"
            danger
            onClick={() => handleRefuseTransfer(data?.id)}
          >
            Từ chối
          </Button>
          <Button
            type="primary"
            className="btn-submit"
            onClick={() => handleApproveTransfer(data?.id)}
          >
            Xác nhận
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default DetailTransferVoucherModal;
