import React, { useState } from 'react';
import styles from '../index.less';
import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Tabs,
  message,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'umi';
import {
  COREVOUCHER_TYPE,
  ConfigVoucher,
  UpdateConfigVoucher,
} from '../service';
import { formatInputNumber } from '@/utils/apis/common';
enum OptionLimit {
  LIMIT = 'LIMIT',
  NO_LIMIT = 'NO_LIMIT',
}
const VoucherConfig = ({ enterpriseId }: { enterpriseId: number }) => {
  const validator = (_: any, value: any) => {
    if (!value) {
      if (value === 0) {
        return Promise.reject('Vui lòng nhập giá trị lớn hơn 0');
      }
      return Promise.reject('Vui lòng nhập giá trị');
    }
    return Promise.resolve();
  };
  const [disable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const [optionLimit, setOptionLimit] = useState<OptionLimit>(
    OptionLimit.NO_LIMIT,
  );
  const [tab, setTab] = useState<COREVOUCHER_TYPE>('DVOUCHER');
  const configVoucher = useRequest(ConfigVoucher, {
    defaultParams: [
      {
        id: enterpriseId,
        type: 'DVOUCHER',
      },
    ],
    onSuccess(res) {
      form.setFieldsValue({
        max_reduce_percent: Number(res?.max_reduce_percent) * 100,
        maximum_reduce_value: Number(res?.maximum_reduce_value),
        minimum_value_apply_voucher: Number(res?.minimum_value_apply_voucher),
        limit_voucher_per_day: Number(res?.limit_voucher_per_day),
      });
      if (!!res?.limit_used_voucher) {
        setOptionLimit(OptionLimit.LIMIT);
        form.setFieldsValue({
          option_limit: OptionLimit.LIMIT,
          limit_used_voucher: res?.limit_used_voucher,
        });
      } else {
        setOptionLimit(OptionLimit.NO_LIMIT);
        form.setFieldsValue({
          option_limit: OptionLimit.NO_LIMIT,
        });
      }
    },
    formatResult: (res) => res,
  });
  const updateConfig = useRequest(UpdateConfigVoucher, {
    manual: true,
    onSuccess(res) {
      message.success('Thay đổi cài đặt thành công');
      configVoucher.refresh();
      setDisable(true);
    },
    onError(err: any) {
      message.error('Có lỗi xảy ra');
    },
  });
  const onSubmit = (val: any) => {
    updateConfig.run(enterpriseId, {
      type: tab,
      maxApplyPercent: Number(val.max_reduce_percent) / 100,
      maxApplyValue: val.maximum_reduce_value,
      maxUseCount: val.limit_used_voucher ? val.limit_used_voucher : null,
      minValueToApply: val.minimum_value_apply_voucher,
      limitUseDvoucher: val.limit_voucher_per_day,
    });
  };
  return (
    <>
      <Tabs
        className={styles.tabs}
        onChange={(key: any) => {
          setTab(key);
          configVoucher.run({ id: enterpriseId, type: key });
        }}
        defaultActiveKey={tab}
      >
        <Tabs.TabPane tab="D-Voucher" key="DVOUCHER"></Tabs.TabPane>
        <Tabs.TabPane tab="C-Voucher" key="CVOUCHER"></Tabs.TabPane>
      </Tabs>
      <Row>
        <Col span={6} lg={14} xl={10} xxl={6} md={18} sm={18} xs={24}>
          {configVoucher.loading ? (
            <Spin />
          ) : (
            <div className={styles.settingVoucher}>
              <Button
                icon={<EditOutlined />}
                onClick={() => setDisable(!disable)}
                className={styles.editBtn}
              >
                Chỉnh sửa
              </Button>
              <Form
                layout="vertical"
                disabled={disable}
                form={form}
                onFinish={onSubmit}
              >
                <Form.Item
                  label="Hạn mức áp dụng voucher / giá trị hóa đơn"
                  name="max_reduce_percent"
                  rules={[
                    {
                      validator,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập hạn mức"
                    addonAfter="%"
                    className={styles.InputItem}
                    min={0}
                    defaultValue={100}
                  />
                </Form.Item>
                <Form.Item
                  label="Số tiền tối thiểu để được áp dụng voucher"
                  name="minimum_value_apply_voucher"
                  initialValue={0}
                  rules={[
                    {
                      validator,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số tiền tối thiểu"
                    addonAfter="VND"
                    className={styles.InputItem}
                    step={1000}
                    formatter={formatInputNumber}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Số tiền tối đa được giảm / 1 hóa đơn"
                  name="maximum_reduce_value"
                  rules={[
                    {
                      validator,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số tiền tối đa"
                    addonAfter="VND"
                    className={styles.InputItem}
                    step={1000}
                    formatter={formatInputNumber}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Số voucher tối đa 1 user có thể sử dụng tại DN"
                  name="option_limit"
                >
                  <Select
                    defaultValue={OptionLimit.NO_LIMIT}
                    className={styles.InputItem}
                    onChange={(val) => setOptionLimit(val)}
                    options={[
                      {
                        label: 'Không giới hạn',
                        value: OptionLimit.NO_LIMIT,
                      },
                      {
                        label: 'Có giới hạn',
                        value: OptionLimit.LIMIT,
                      },
                    ]}
                  />
                </Form.Item>
                {optionLimit === OptionLimit.LIMIT && (
                  <Form.Item
                    label="Số lượng voucher"
                    name="limit_used_voucher"
                    rules={[
                      {
                        validator,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Nhập số lượng voucher tối đa"
                      formatter={formatInputNumber}
                      className={styles.InputItem}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label={`Số ${
                    tab === 'DVOUCHER' ? 'D-Voucher' : 'C-Voucher'
                  } tối đa được sử dụng/ngày`}
                  name="limit_voucher_per_day"
                  rules={[
                    {
                      validator(rule, value) {
                        if (!!value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            'Vui lòng nhập số voucher lớn hơn 0',
                          );
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số voucher tối đa"
                    formatter={formatInputNumber}
                    className={styles.InputItem}
                    min={0}
                  />
                </Form.Item>
                {!disable && (
                  <Form.Item>
                    <Row>
                      <Button
                        className={styles.submitBtn}
                        type="primary"
                        ghost
                        onClick={() => {
                          setDisable(true);
                          configVoucher.refresh();
                        }}
                      >
                        Hủy
                      </Button>
                      <Button
                        className={styles.submitBtn}
                        type="primary"
                        htmlType="submit"
                        loading={updateConfig.loading}
                      >
                        Lưu
                      </Button>
                    </Row>
                  </Form.Item>
                )}
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default VoucherConfig;
