import React, { useState } from 'react';
import styles from '../index.less';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
  Upload,
  UploadFile,
  message,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ENVIRONMENTS } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
import UploadImage from '@/components/UploadImage';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { paternPhone } from '@/utils/patern';
import { Link, useIntl, useParams, useRequest } from 'umi';
import {
  IUpdateBrand,
  TYPE_ENTERPRISE,
  approveBrandService,
  getDetailBrand,
  updateDetailBrand,
} from '../service';
const BusinessInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslate();
  const params = useParams<{ id: string }>();
  const detailBrand = useRequest(getDetailBrand, {
    defaultParams: [+params.id],
    onSuccess: (res) => {
      setTypeEnterprise(res.type_of_enterprise);
      form.setFieldsValue({
        ...res,
        email_receive_receipt: Array.isArray(res.email_receive_receipt)
          ? res.email_receive_receipt
          : [res.email_receive_receipt],
      });
      if (res?.identity_backside && res?.identity_front) {
        setFileList((state) => ({
          ...state,
          identity_backside: [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: ENVIRONMENTS.BUCKET_NAME + '/' + res?.identity_backside,
            },
          ],
          identity_front: [
            {
              uid: '-2',
              name: 'image.png',
              status: 'done',
              url: ENVIRONMENTS.BUCKET_NAME + '/' + res?.identity_front,
            },
          ],
        }));
      }
      if (res?.register_image) {
        setFileList((state) => ({
          ...state,
          register_image: [
            {
              uid: '-3',
              name: 'image.png',
              status: 'done',
              url: ENVIRONMENTS.BUCKET_NAME + '/' + res?.register_image,
            },
          ],
        }));
      }
      if (res?.identity_holding_image) {
        setFileList((state) => ({
          ...state,
          identity_holding_image: [
            {
              uid: '-3',
              name: 'image.png',
              status: 'done',
              url: ENVIRONMENTS.BUCKET_NAME + '/' + res?.identity_holding_image,
            },
          ],
        }));
      }
    },
  });
  const updateBrand = useRequest(updateDetailBrand, {
    manual: true,
    onSuccess: (res) => {
      setIsEdit(false);
      message.success('Cập nhật thông tin thành công');
      detailBrand.run(+params.id);
    },
    onError: (err: any) => {
      console.log(err.data);
      message.error(err.data?.messsage || 'Có lỗi xảy ra');
    },
  });
  const onSubmit = (val: any) => {
    const payload: IUpdateBrand = {
      ...val,
      identity_front: Array.isArray(val.identity_front)
        ? val.identity_front?.[0]?.response.path
        : val.identity_front,
      identity_backside: Array.isArray(val.identity_backside)
        ? val.identity_backside?.[0]?.response.path
        : val.identity_backside,
      register_image: Array.isArray(val.register_image)
        ? val.register_image?.[0]?.response.path
        : val.register_image,
      identity_holding_image: Array.isArray(val.identity_holding_image)
        ? val.identity_holding_image?.[0]?.response.path
        : val.identity_holding_image,
    };
    updateBrand.run(+params.id, payload);
  };
  const [typeEnterprise, setTypeEnterprise] = useState('');
  const [fileList, setFileList] = useState<{
    register_image: UploadFile[];
    identity_backside: UploadFile[];
    identity_front: UploadFile[];
    identity_holding_image: UploadFile[];
  }>({
    register_image: [],
    identity_backside: [],
    identity_front: [],
    identity_holding_image: [],
  });

  const { formatMessage } = useIntl();
  const approveBrand = useRequest(approveBrandService, {
    manual: true,
    onSuccess: (res) => {
      setIsEdit(false);
      detailBrand.run(+params?.id);
      message.success('Thành công');
    },
    onError: (err: any) => {
      console.log(err.data);
      message.error('Có lỗi xảy ra');
    },
  });
  const handleApproveBrand = () => {
    return Modal.confirm({
      icon: null,
      title: 'Phê duyệt doanh nghiệp',
      content: 'Bạn có chắc chắn muốn duyệt doanh nghiệp này?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk: () => {
        approveBrand.run(+params?.id, {
          confirm: true,
        });
      },
      okButtonProps: {
        loading: approveBrand.loading,
      },
    });
  };
  const [formRefuse] = Form.useForm();
  const handleRefuseBrand = () => {
    const onSubmitRefuse = (val: any) => {
      console.log(val);
      approveBrand.run(+params?.id, {
        confirm: false,
        reason: val.reason,
      });
    };
    return Modal.confirm({
      icon: null,
      title: 'Từ chối duyệt doanh nghiệp',
      content: (
        <>
          <Form
            form={formRefuse}
            onFinish={onSubmitRefuse}
            id="formRefuse"
            layout="vertical"
          >
            <Form.Item
              label="Lý do từ chối"
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Nhập lý do',
                },
              ]}
            >
              <Input.TextArea placeholder="Nhập lý do từ chối" />
            </Form.Item>
          </Form>
        </>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: approveBrand.loading,
      },
      onOk: () => {
        if (!formRefuse.getFieldValue('reason')) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      onCancel() {
        formRefuse.resetFields();
      },
    });
  };
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ACCEPT':
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case 'PENDING':
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case 'REFUSE':
        return (
          <>
            <Tag color="red" icon={<CloseOutlined />}>
              Từ chối xác thực
            </Tag>{' '}
            Lý do: {detailBrand.data?.refuse_reason}
          </>
        );
      case 'NOT_REQUEST':
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
      default:
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <Link to="/business/brand" className={styles.previousLink}>
          <LeftOutlined />
          Danh sách pháp nhân
        </Link>
      </div>
      <Row justify="space-between">
        <Space align="start">
          <Typography.Title className={styles.titleTab} level={4}>
            Thông tin pháp nhân
          </Typography.Title>
          <Button
            disabled={false}
            icon={<EditOutlined />}
            ghost
            type="primary"
            onClick={() => setIsEdit(true)}
          ></Button>
        </Space>
        {detailBrand.data?.verify_status === 'PENDING' && (
          <Space align="start">
            <Button
              type="primary"
              disabled={false}
              onClick={handleApproveBrand}
            >
              Phê duyệt
            </Button>
            <Button
              type="primary"
              ghost
              danger
              disabled={false}
              onClick={handleRefuseBrand}
            >
              Từ chối
            </Button>
          </Space>
        )}
      </Row>
      {detailBrand.loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          disabled={!isEdit}
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          labelWrap
          onFinish={onSubmit}
        >
          <Row justify="center">
            <Col span={20} xxl={20} xl={20} lg={24} sm={24} xs={24}>
              <Form.Item label="Trạng thái">
                {renderStatus(detailBrand.data?.verify_status)}
              </Form.Item>
              <b>1. Thông tin thuế</b>
              <Form.Item
                name="type_of_enterprise"
                label="Loại hình kinh doanh"
                rules={[
                  {
                    message: 'Vui lòng chọn',
                    required: true,
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setTypeEnterprise(e.target.value)}
                >
                  <Radio value={TYPE_ENTERPRISE.INDIVIDUAL}>Cá nhân</Radio>
                  <Radio value={TYPE_ENTERPRISE.BUSINESS_HOUSEHOLD}>
                    Hộ kinh doanh
                  </Radio>
                  <Radio value={TYPE_ENTERPRISE.COMPANY}>Công ty</Radio>
                </Radio.Group>
              </Form.Item>
              {(typeEnterprise === TYPE_ENTERPRISE.COMPANY ||
                typeEnterprise === TYPE_ENTERPRISE.BUSINESS_HOUSEHOLD) && (
                <Form.Item
                  label="Tên hộ kinh doanh/công ty"
                  name="enterprise_name"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên hộ kinh doanh/công ty" />
                </Form.Item>
              )}
              <Form.Item
                name="address"
                label="Địa chỉ đăng ký kinh doanh"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập',
                  },
                ]}
              >
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
              {/* <Form.Item
              name="email_receive_receipt"
              label="Email nhận hoá đơn điện tử"
              rules={[
                {
                  required: true,
                  message: t('error.require', {
                    field: t('email'),
                  }),
                },
                {
                  type: 'email',
                  message: t('error.email', {
                    field: t('email'),
                  }),
                },
              ]}
            >
              <Input placeholder="Email nhận hoá đơn" />
            </Form.Item> */}
              <Row
                style={{
                  margin: '15px 0',
                }}
              >
                <Col span={5}>
                  <div
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Email nhận hoá đơn điện tử:
                  </div>
                </Col>
                <Row
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Form.List
                    name="email_receive_receipt"
                    initialValue={['']}
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (names.length > 5) {
                            return Promise.reject(new Error('Tối đa 5 email'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            labelCol={{
                              span: 5,
                            }}
                            wrapperCol={{
                              span: 14,
                            }}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập',
                              },
                            ]}
                            key={field.key}
                          >
                            <Space>
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: t('error.require', {
                                      field: t('email'),
                                    }),
                                  },
                                  {
                                    type: 'email',
                                    message: t('error.email', {
                                      field: t('email'),
                                    }),
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder="Nhập email"
                                  style={{
                                    width: '400px',
                                  }}
                                />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <>
                                  {isEdit && (
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      style={{
                                        marginLeft: '10px',
                                      }}
                                      onClick={() => remove(field.name)}
                                    />
                                  )}{' '}
                                </>
                              ) : null}
                            </Space>
                          </Form.Item>
                        ))}

                        <Button
                          type="primary"
                          ghost
                          style={{
                            width: '150px',
                          }}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          disabled={
                            isEdit
                              ? fields.length === 5
                              : detailBrand.data?.verify_status === 'ACCEPT'
                              ? true
                              : !isEdit
                          }
                        >
                          Thêm email
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </Row>
              </Row>
              <Form.Item
                name="tax_code"
                label={t('tax_code')}
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <Input placeholder={t('tax_code_input')} />
              </Form.Item>
              {(typeEnterprise === TYPE_ENTERPRISE.COMPANY ||
                typeEnterprise === TYPE_ENTERPRISE.BUSINESS_HOUSEHOLD) && (
                <UploadImage
                  label="Ảnh giấy phép đăng ký kinh doanh"
                  name="register_image"
                  fileList={fileList.register_image}
                  required={true}
                  setFileList={(val: any) => {
                    setFileList((state: any) => ({
                      ...state,
                      register_image: val,
                    }));
                  }}
                  propsUpload={{
                    disabled: detailBrand.data?.verified ? true : !isEdit,
                  }}
                />
              )}
              <b>2. Thông tin định danh</b>

              <Form.Item
                name="representative_name"
                label="Đại diện pháp luật"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập',
                  },
                ]}
              >
                <Input placeholder="Tên người đại diện pháp luật" />
              </Form.Item>
              <Form.Item
                name="identity_number"
                label="Số CCCD/CMND"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập',
                  },
                  {
                    pattern: /^[0-9]{10,12}$/,
                    message: 'Mã CCCD/CMND phải từ 10-12 số',
                  },
                ]}
              >
                <Input placeholder="Số CCCD/CMND" />
              </Form.Item>
              <Form.Item label="Ảnh chụp CCCD/CMND">
                <Row
                  wrap
                  style={{
                    textAlign: 'center',
                  }}
                  justify="space-evenly"
                >
                  <Col>
                    <UploadImage
                      name="identity_front"
                      required={true}
                      fileList={fileList.identity_front}
                      setFileList={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: val,
                        }));
                      }}
                      propsUpload={{
                        disabled: detailBrand.data?.verified ? true : !isEdit,
                      }}
                    />
                    <p>Mặt trước</p>
                  </Col>
                  <Col>
                    <UploadImage
                      name="identity_backside"
                      required={true}
                      fileList={fileList.identity_backside}
                      setFileList={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: val,
                        }));
                      }}
                      propsUpload={{
                        disabled: detailBrand.data?.verified ? true : !isEdit,
                      }}
                    />
                    <p>Mặt sau</p>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Ảnh đang cầm CCCD/CMND (Chụp rõ mặt cùng với CCCD/CMND)">
                <Row
                  wrap
                  style={{
                    textAlign: 'center',
                  }}
                  justify="space-evenly"
                >
                  <Col>
                    <UploadImage
                      name="identity_holding_image"
                      required={true}
                      fileList={fileList.identity_holding_image}
                      setFileList={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_holding_image: val,
                        }));
                      }}
                      propsUpload={{
                        disabled: detailBrand.data?.verified ? true : !isEdit,
                      }}
                    />
                    <p>Ảnh chụp cùng CCCD</p>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{
              margin: '20px 0',
            }}
          >
            <Button
              type="primary"
              ghost
              danger
              className="btn-submit"
              onClick={() => {
                form.resetFields();
                setFileList({
                  register_image: [],
                  identity_backside: [],
                  identity_front: [],
                  identity_holding_image: [],
                });
                setIsEdit(false);
                detailBrand.run(+params.id);
              }}
              disabled={!isEdit}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              loading={updateBrand.loading}
              disabled={!isEdit}
            >
              Lưu
            </Button>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default BusinessInfo;
