import { Tabs } from 'antd';
import { useHistory, useLocation } from 'umi';
import styles from './index.less';
import './index.less';
import LinkAccountEnterprise from './Components/LinkAccountEnterprise';
import ListStore from './Components/ListStore';
import ListBrand from './Components/ListBrand';
const PATH = {
  STORE: '/business/store',
  BRAND: '/business/brand',
  LINK_ACCOUNT: '/business/link-account',
};
export default () => {
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Tabs
        className={styles.tabs}
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
      >
        <Tabs.TabPane
          tab="Thông tin cửa hàng"
          key={PATH.STORE}
          tabKey={PATH.STORE}
        >
          <ListStore />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Thông tin pháp nhân"
          key={PATH.BRAND}
          tabKey={PATH.BRAND}
        >
          <ListBrand />
        </Tabs.TabPane>
        {/* <Tabs.TabPane
          tab="Liên kết tài khoản"
          key={PATH.LINK_ACCOUNT}
          tabKey={PATH.LINK_ACCOUNT}
        >
          <LinkAccountEnterprise />
        </Tabs.TabPane> */}
      </Tabs>

      {/* {openDialog && (
        <Dialog
          refresh={refresh}
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};
