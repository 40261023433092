import {
  AutoComplete,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { Actions } from 'ahooks/lib/useToggle';
import { ReleaseVoucherUrbox, searchAllVoucher } from '../service';
interface ReleaseVoucherModalProps {
  isOpenModal: boolean;
  setIsOpenModal: Actions<boolean>;
  refresh: () => void;
}
const ReleaseVoucherModal = ({
  isOpenModal,
  setIsOpenModal,
  refresh,
}: ReleaseVoucherModalProps) => {
  const [form] = Form.useForm();
  const handleClose = () => {
    setIsOpenModal.toggle();
    form.resetFields();
  };
  const [atOptions, setAtOptions] = useState<any[]>([]);
  const [selectedVoucher, setSelectedVoucher] = useState<any>();
  const handleSelectVoucher = (value: string) => {
    const option = atOptions.find((item) => item.value === value);
    if (!!option) {
      setSelectedVoucher({
        title: option.title,
        id: option.id,
        quantity: option.quantity,
      });
    }
  };
  const requestSearchVoucher = useRequest(searchAllVoucher, {
    defaultParams: [
      {
        page: 1,
        limit: 50,
      },
    ],
    debounceWait: 1000,
    onSuccess: (res) => {
      setAtOptions(() =>
        res.data?.map((item: any) => ({
          ...item,
          value: item?.title,
        })),
      );
    },
    onError: (err) => {},
  });
  const releaseVoucher = useRequest(ReleaseVoucherUrbox, {
    manual: true,
    onSuccess() {
      message.success('Phát hành voucher thành công');
      handleClose();
      refresh();
    },
    onError(err) {
      //@ts-ignore
      message.error(err?.data?.message || 'Có lỗi xảy ra');
    },
  });
  const handleSearchVoucher = (searchValue: string) => {
    requestSearchVoucher.run({ textSearch: searchValue, page: 1, limit: 20 });
  };

  const onSubmit = (val: any) => {
    releaseVoucher.run({
      id: selectedVoucher?.id,
      title: selectedVoucher?.title,
      quantity: val.quantity,
    });
  };

  return (
    <Modal
      open={isOpenModal}
      footer={null}
      onCancel={handleClose}
      title="Phát hành voucher"
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Chọn voucher"
          name="voucher"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn voucher phát hành',
            },
          ]}
        >
          <AutoComplete
            allowClear
            placeholder="Chọn voucher"
            options={atOptions}
            onSelect={(data) => handleSelectVoucher(data)}
            onSearch={(searchValue) => handleSearchVoucher(searchValue)}
          />
        </Form.Item>
        {selectedVoucher && (
          <p
            style={{
              fontSize: '13px',
              marginLeft: '10px',
            }}
          >
            Tổng số voucher: <span>{selectedVoucher?.quantity}</span>
          </p>
        )}
        <Form.Item
          label="Số lượng voucher phát hành"
          name="quantity"
          normalize={(value) => {
            const isNumber = /^\d+$/.test(value);
            if (isNumber) {
              return value;
            } else {
              return null;
            }
          }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!selectedVoucher) {
                  return Promise.reject(new Error('Vui lòng chọn voucher'));
                }
                if (Number(selectedVoucher.quantity) < Number(value)) {
                  return Promise.reject(new Error('Voucher không đủ số lượng'));
                }
                if (Number(value) === 0) {
                  return Promise.reject(
                    new Error('Số lượng phát hành không được bằng 0'),
                  );
                }
                if (!value) {
                  return Promise.reject(new Error('Vui lòng nhập'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            style={{
              width: '50%',
            }}
            placeholder="Nhập số lượng"
          />
        </Form.Item>
        <Row justify="center">
          <Button
            style={{
              margin: '10px',
            }}
            onClick={handleClose}
          >
            Hủy
          </Button>
          <Button
            style={{
              margin: '10px',
            }}
            type="primary"
            htmlType="submit"
            loading={releaseVoucher.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReleaseVoucherModal;
