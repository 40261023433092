import { ENVIRONMENTS } from '@/utils/constant';
import { isNil, omitBy } from 'lodash';

export function formatNumber(x: number): string {
  if (!x) return '0';
  const str = Number(x).toFixed(2).toString();
  const arrStr = str.split('.');
  const regex = (val: string) => {
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };
  const convertNum =
    arrStr.length === 1 ? regex(str) : `${regex(arrStr[0])},${arrStr[1]}`;
  const splitNum = convertNum.split(',');
  if (splitNum[1] === '00') {
    return splitNum[0];
  } else return convertNum;
}

export const formatInputNumber = (value: number | undefined) => {
  if (!`${value}`.substring(0, `${value}`.lastIndexOf('.'))) {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const dau = `${value}`.substring(0, `${value}`.lastIndexOf('.'));
  const duoi = `${value}`.substring(String(value).lastIndexOf('.'));
  return `${dau.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${duoi}`;
};

export const genAVT = (avatar: string) => {
  if (!avatar) return '';
  const isValid = avatar.includes('uploads/');
  if (isValid) {
    return avatar.split('/').reverse()[0];
  }
  return avatar;
};

export const checkRoleAdmin = (role: string) => {
  return JSON.parse(
    localStorage.getItem(ENVIRONMENTS.ADMIN_STORAGE_KEY as string) as string,
  )?.role?.permissions[role];
};

export const toLowerCaseNonAccent = (str: string) => {
  if (!str) return '';
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
  str = str.replace(/\u02C6|\u0306|\u031B/g, '');
  return str;
};

export const toLowerCaseNonAccentBs = (str: string) => {
  if (!str) return '';
  const removeSpace = toLowerCaseNonAccent(str).replace(/\s+/g, '');
  return removeSpace.toUpperCase();
};
export const formatHide = (str: string, showCount: number) => {
  if (str?.length > showCount * 2) {
    return str.slice(0, showCount) + '****' + str.slice(str.length - showCount);
  }
  return str;
};

export function toLowerCaseNonAccentVietnamese(str: string) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
  str = str.replace(/\u02C6|\u0306|\u031B/g, '');
  const removeSpace = str.replace(/\s+/g, '');
  return removeSpace.toUpperCase();
}

const randomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const checkBucketImage = (pathname: string) => {
  if (pathname.includes(ENVIRONMENTS.BUCKET_NAME as string)) {
    return pathname;
  }
  return ENVIRONMENTS.BUCKET_NAME + '/' + pathname;
};

export function checkURL(url: string) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export const generateBodyGraphql = (data: Object, enumKey?: string[]) => {
  const special_key = [
    'receive_item',
    'conditions_receiving',
    'steps_exchange',
  ];
  const onConvertType = (item: any) => {
    const keyStr = Object.keys(data);
    // const keyStr = `.type.status.type_address_receive.address_type.limit_time.address_latitude.address_longitude.limit.locabonus.`;
    const [key, value] = item;
    if (special_key.includes(key)) {
      return `${key}: ${JSON.stringify(value)}`;
    }
    if (
      typeof value == 'number' ||
      typeof value == 'boolean' ||
      enumKey?.includes(key)
    ) {
      return `
			 ${key}: ${value}`;
    } else {
      return `
			 ${key}: "${value}"`;
    }
  };
  const onConvert = (input: Object) => {
    return `
			 ${Object.entries(input).map((i) => {
         return onConvertType(i);
       })}
		 `;
  };
  const dataNotNull = omitBy(data, isNil);
  return onConvert(dataNotNull);
};

export function isLatitude(maybeLat: any) {
  const latF = Number(maybeLat);

  if (Number.isNaN(latF)) return false;
  return latF >= -90 && latF <= 90;
}
export function isLongitude(maybeLon: any) {
  const lonF = Number(maybeLon);

  if (Number.isNaN(lonF)) return false;
  return lonF >= -180 && lonF <= 180;
}
export function validatorPositiveNumber(_: any, value: any) {
  if (!value) {
    if (value == 0) {
      return Promise.reject('Vui lòng nhập số dương');
    }
    return Promise.reject();
  } else if (value < 0) {
    return Promise.reject('Vui lòng nhập số dương');
  }
  return Promise.resolve();
}

export const convertTimeUnit = (timeUnit: string) => {
  switch (timeUnit) {
    case 'hour':
      return 'giờ';
    case 'day':
      return 'ngày';
    case 'week':
      return 'tuần';
    case 'month':
      return 'tháng';
    case 'year':
      return 'năm';
    default:
      return 'ngày';
  }
};
export function deepCopy(obj: any): any {
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  // Xử lý mảng
  if (Array.isArray(obj)) {
    const copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Xử lý đối tượng
  const copy = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      //@ts-ignore
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
}
