import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Skeleton,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { getLocameet, setUpLocameet } from '../service';
import { useRequest } from 'umi';
import { EditOutlined } from '@ant-design/icons';
interface InfoData {
  nums_of_scan: number;
  meet_time: number;
  meet_count_default: number;
  locabonus_limit_per_meet: number;
  landing_page_meet_count_bonus: number;
  meet_count_membership: number;
  locabonus_limit_per_meet_night: number;
  locabonus_limit_user_to_earn: number;
  display_ads: boolean;
  meet_range_end: number;
  meet_range_start: number;
  liveness_profile: boolean;
  register_social_network: boolean;
  locacafe_limit: number;
  voucher_invite_bonus: number;
  voucher_register_bonus: number;
}
const Locameet = () => {
  const { t } = useTranslate();
  const [isDisabled, setIsDisabled] = useState(true);
  const setLocaRequest = useRequest(setUpLocameet, {
    manual: true,
    onSuccess: (res) => {
      if (!res) {
        message.error('Không có quyền');
      } else {
        message.success('Thành công');
      }
      setIsDisabled(true);
    },
  });
  const onFinish = (values: any) => {
    const data = {
      query: `
        mutation {
          setup_loca_meet(input: { 
            nums_of_scan: ${values.nums_of_scan},
            meet_time:${values.meet_time} 
            locabonus_limit_per_meet:${values.locabonus_limit_per_meet}
            meet_count_default:${values.meet_count_default}
            landing_page_meet_count_bonus:${values.landing_page_meet_count_bonus}
            meet_count_membership: ${values.meet_count_membership}
            locabonus_limit_per_meet_night: ${values.locabonus_limit_per_meet_night}
            locabonus_limit_user_to_earn: ${values?.locabonus_limit_user_to_earn}
						display_ads: ${values?.display_ads}
						meet_range_start: ${values?.meet_range_start}
						meet_range_end: ${values?.meet_range_end}
						liveness_profile: ${values?.liveness_profile}
						register_social_network: ${values?.register_social_network}
						locacafe_limit: ${values?.locacafe_limit}
						voucher_invite_bonus: ${values?.voucher_invite_bonus}
						voucher_register_bonus: ${values?.voucher_register_bonus}
          }) {
            message
          }
        }
      `,
    };
    setLocaRequest.run(data);
  };
  const getLocameetRequest = useRequest(getLocameet, {
    manual: true,
    onSuccess: (res: any) => {
      const data = res.get_info_setup_loca_meet;

      const infoRes: InfoData = {
        nums_of_scan: data.nums_of_scan,
        meet_time: data.meet_time,
        meet_count_default: data.meet_count_default,
        locabonus_limit_per_meet: data.locabonus_limit_per_meet,
        landing_page_meet_count_bonus: data.landing_page_meet_count_bonus,
        meet_count_membership: data.meet_count_membership,
        locabonus_limit_per_meet_night: data.locabonus_limit_per_meet_night,
        locabonus_limit_user_to_earn: data.locabonus_limit_user_to_earn,
        display_ads: data.display_ads,
        ...data,
      };
      form.setFieldsValue(infoRes);
    },
  });
  const [form] = Form.useForm();
  useEffect(() => {
    getLocameetRequest.run();
  }, []);
  return (
    <div>
      <div className={styles.setting}>
        <h1 className={styles.titleSetting}>LocaMeet</h1>
        <Button
          icon={<EditOutlined />}
          ghost
          type="primary"
          className={styles.editBtn}
          onClick={() => {
            setIsDisabled(!isDisabled);
            if (!isDisabled) {
              getLocameetRequest.refresh();
            }
          }}
        ></Button>
        {getLocameetRequest.loading ? (
          <Skeleton active />
        ) : (
          <Form
            disabled={isDisabled}
            className={styles.formItem}
            onFinish={onFinish}
            name="basic"
            labelCol={{ span: 4 }}
            form={form}
            labelWrap={true}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item
              label="Thời gian countdown Bluetooth (phút)"
              name="meet_time"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>

            <Form.Item
              label="Số lần quét Bluetooth"
              name="nums_of_scan"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lần quét là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia cho 1 cuộc meet"
              name="locabonus_limit_per_meet"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia cho 1 cuộc meet (Tại Locacafe)"
              name="locacafe_limit"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia cho 1 cuộc Biz"
              name="locabonus_limit_user_to_earn"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số LB tối đa chia (Giờ thấp điểm)"
              name="locabonus_limit_per_meet_night"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Lượt meet mặc định/ ngày"
              name="meet_count_default"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Luợt meet cộng thêm/ngày"
              name="landing_page_meet_count_bonus"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt tặng là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Luợt meet cộng cho gói membership/ngày"
              name="meet_count_membership"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số lượt cộng thêm là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số D-voucher được tặng khi giới thiệu"
              name="voucher_invite_bonus"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số voucher phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Form.Item
              label="Số D-voucher được tặng nhập mã giới thiệu"
              name="voucher_register_bonus"
              rules={[
                { required: true, message: 'không được để trống' },
                {
                  pattern: /[0-9]/,
                  message: 'Số voucher phải là chữ số',
                },
              ]}
            >
              <Input type="number" className={styles.formConfigInput} />
            </Form.Item>
            <Row wrap={true}>
              <Col span={4} xs={24} sm={24} lg={4}>
                <span
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Khoảng thời gian meet:{' '}
                </span>
              </Col>
              <Col span={3} xs={10} lg={6} xl={3}>
                <Form.Item
                  name="meet_range_start"
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          if (Number(value) === 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Không được để trống'),
                          );
                        }
                        if (Number(value) < 0 || Number(value) > 23) {
                          return Promise.reject('Vui lòng nhập từ 0-23 giờ');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber addonAfter="h" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <b>~</b>
              </Col>
              <Col span={3} xs={10} lg={6} xl={3}>
                <Form.Item
                  name="meet_range_end"
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          if (Number(value) === 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject('không được để trống');
                        }
                        if (value < 0 || value > 23) {
                          return Promise.reject('Vui lòng nhập từ 0-23 giờ');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber addonAfter="h" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Bắt buộc liveness"
              name="liveness_profile"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled} />
            </Form.Item>
            <Form.Item
              label="Hiển thị quảng cáo"
              name="display_ads"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled} />
            </Form.Item>
            <Form.Item
              label="Đăng ký tài khoản MXH"
              name="register_social_network"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled} />
            </Form.Item>
            {!isDisabled && (
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Row>
                  <Button
                    className={styles.submitBtn}
                    danger
                    onClick={() => {
                      setIsDisabled(true);
                      if (!isDisabled) {
                        getLocameetRequest.refresh();
                      }
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    className={styles.submitBtn}
                    type="primary"
                    htmlType="submit"
                    loading={setLocaRequest.loading}
                  >
                    Lưu
                  </Button>
                </Row>
              </Form.Item>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};

export default Locameet;
