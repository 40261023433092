import React, { useEffect } from 'react';
import { Form, Table } from 'antd';
import styles from './index.less';
import { DeleteFilled } from '@ant-design/icons';
import ModalAddUser, { IListUser } from '../ModalAddUser';
import { Status } from '../../service';
import { uniqBy, concat } from 'lodash';
interface IProps {
  getData: any;
  setData: any;
  form: any;
}
export default (props: IProps) => {
  const { form, getData = [], setData } = props;
  // console.log('🚀 ~ file user: form user', getData);
  const [userGift, setUserGift] = React.useState<IListUser[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'user_id',
      width: 80,
      key: 'user_id',
      // render: (_: any, record: any, index: number) => {
      //   return <>{index + 1}</>;
      // },
    },
    {
      title: 'Tên User',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Hành động',
      width: 150,
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: IListUser, index: number) => {
        const onEdit = form.getFieldValue('isEdit');
        if (onEdit == true && getData?.gift_status != Status.DUYET) {
          return (
            <div
              onClick={() => onDeleteDataTable(record._id)}
              className={styles.delete}
            >
              <DeleteFilled />
            </div>
          );
        }
        if (onEdit == undefined) {
          return (
            <div
              onClick={() => onDeleteDataTable(record._id)}
              className={styles.delete}
            >
              <DeleteFilled />
            </div>
          );
        }
      },
    },
  ];

  const onDeleteDataTable = (_id: string) => {
    const newData = userGift.filter((item: IListUser) => item._id !== _id);
    setUserGift(newData);
    setData('user_receive_gift', newData);
  };

  const onGetUser = (data: IListUser[]) => {
    let newData: any;
    if (userGift && userGift.length > 0) {
      newData = concat(data, userGift);
    } else {
      newData = data;
    }

    newData = uniqBy(newData, '_id');
    setUserGift(newData);
    setData('user_receive_gift', newData);
  };

  useEffect(() => {
    // console.log('🚀 ~ file user: useEffect getData.user_receive_gift ')
    setUserGift(getData.user_receive_gift);
  }, [getData.user_receive_gift]);

  return (
    <>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.set_all_user !== curValues.set_all_user
        }
      >
        {({ getFieldValue }) => {
          const set_all_user = getFieldValue('set_all_user');
          if (set_all_user) {
            return;
          } else if (getData.gift_status == Status.DUYET) {
            return (
              <>
                <Table
                  // rowKey="_id"
                  // key="user_gift_id"
                  dataSource={userGift}
                  columns={columns}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </>
            );
          } else {
            return (
              <>
                <ModalAddUser dataUser={userGift} getUser={onGetUser}>
                  Thêm User
                </ModalAddUser>
                <Table
                  // rowKey="_id"
                  // key="user_gift_id"
                  dataSource={userGift}
                  columns={columns}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </>
            );
          }
        }}
      </Form.Item>
    </>
  );
};
