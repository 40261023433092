import Table, { ColumnsType } from 'antd/lib/table';
import { useIntl } from 'umi';
import styles from '../index.less';
type Props = {};
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const mockData = [
  {
    key: '1',
    id: 1,
    name: 'John Brown',
  },
  {
    id: 2,
    key: '2',
    name: 'Joe Black',
  },
  {
    id: 3,
    key: '3',
    name: 'Jim Green',
  },
  {
    id: 4,
    key: '4',
    name: 'Joe Black',
  },
];
const PickupGiftAccount = (props: Props) => {
  const { formatMessage } = useIntl();
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_update_time',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'const_column_update_info',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'const_column_update_by',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  return (
    <div className={styles.mainInfoUser}>
      <Table
        //@ts-ignore
        columns={columns}
        rowKey={(record) => record.id}
        locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
        scroll={{ x: 1000 }}
        dataSource={mockData}
        // {...tableProps}
      />
    </div>
  );
};

export default PickupGiftAccount;
