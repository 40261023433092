import React from 'react';
import styles from './index.less';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Breadcrumb,
  Skeleton,
  Table,
  Tag,
  Tooltip,
  message,
  Modal,
} from 'antd';
import { STATUS_KYC } from '@/utils/constant';
import { useIntl, useRequest } from 'umi';
import { useAntdTable } from 'ahooks';
import { getListLiveness } from './service';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { StatusKyc } from '@/utils/enum';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  ReloadOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  refuseLivenessProfile,
  verifyLivenessProfile,
} from '../UsersInfo/service';
const { RangePicker } = DatePicker;
const { Option } = Select;
const Liveness = () => {
  const [form] = Form.useForm();
  const { tableProps, search, loading, refresh } = useAntdTable(
    getListLiveness,
    {
      form,
    },
  );
  const { submit } = search;
  const verifyLiveness = useRequest(verifyLivenessProfile, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      if (res.errors) {
        message.error('Có lỗi xảy ra');
      } else {
        message.success('Duyệt livenes thành công');
        refresh();
        formVerify.resetFields();
      }
    },
    formatResult: (res) => res,
  });
  const refuseLiveness = useRequest(refuseLivenessProfile, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      if (res.errors) {
        message.error('Có lỗi xảy ra');
      } else {
        message.success('Từ chối livenes thành công');
        refresh();
        formVerify.resetFields();
      }
    },
    formatResult: (res) => res,
  });
  const [formVerify] = Form.useForm();
  const { formatMessage } = useIntl();
  const handleVerifyLiveness = (userId: number) => {
    const onFinish = (val: any) => {
      verifyLiveness.run(userId, val.reason);
    };
    return Modal.confirm({
      title: 'Duyệt liveness?',
      width: 600,
      content: (
        <Form
          form={formVerify}
          id="formVerify"
          onFinish={onFinish}
          layout="vertical"
        >
          <p>Bạn muốn duyệt livenes tài khoản này?</p>
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'reason' })} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        loading: verifyLiveness.loading,
        htmlType: 'submit',
        form: 'formVerify',
      },
      okText: 'Xác nhận',
      onOk() {
        if (formVerify.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
      onCancel() {
        formVerify.resetFields();
      },
    });
  };
  const handleRefuseLiveness = (userId: number) => {
    const onFinish = (val: any) => {
      refuseLiveness.run(userId, val.reason);
    };
    return Modal.confirm({
      title: 'Reset liveness',
      width: 600,
      content: (
        <Form
          form={formVerify}
          id="formVerify"
          onFinish={onFinish}
          layout="vertical"
        >
          <p>Bạn muốn reset livenes tài khoản này?</p>
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'reason' })} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        loading: refuseLiveness.loading,
        htmlType: 'submit',
        form: 'formVerify',
      },
      okText: 'Xác nhận',
      onOk() {
        if (formVerify.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
      onCancel() {
        formVerify.resetFields();
      },
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'liveness_at',
      render: (value) => (
        <>{value ? dayjs(value).format('HH:mm DD/MM/YYYY') : null}</>
      ),
    },
    {
      title: 'ID User',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên User',
      dataIndex: 'full_name',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: any) => {
        const status = STATUS_KYC.find((i) => i.value === value);
        return (
          <Tag
            icon={
              status?.value === StatusKyc.ACCEPT ? (
                <CheckOutlined />
              ) : status?.value === StatusKyc.PENDING ? (
                <ClockCircleOutlined />
              ) : (
                <CloseOutlined />
              )
            }
            color={
              status?.value === StatusKyc.ACCEPT
                ? 'green'
                : status?.value === StatusKyc.PENDING
                ? 'warning'
                : 'red'
            }
          >
            {status?.name ? formatMessage({ id: status.name }) : <></>}
          </Tag>
        );
      },
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip
              title={formatMessage({ id: 'general_tooltip_show_infomation' })}
            >
              <a
                href={'/liveness-detail/' + record?.user_id?.toString()}
                style={{
                  margin: '0 10px',
                }}
              >
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
            {(record.status === StatusKyc.PENDING ||
              record.status === StatusKyc.REFUSE) && (
              <>
                <>
                  <Tooltip title="Reset liveness">
                    <div style={{ cursor: 'pointer', margin: '0 10px' }}>
                      <ReloadOutlined
                        className="red-color"
                        onClick={() => handleRefuseLiveness(record?.user_id)}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({ id: 'general_tooltip_accept' })}
                  >
                    <div style={{ cursor: 'pointer', margin: '0 10px' }}>
                      <CheckOutlined
                        className="green-color"
                        onClick={() => handleVerifyLiveness(record?.user_id)}
                      />
                    </div>
                  </Tooltip>
                </>
              </>
            )}
            {record.status === StatusKyc.ACCEPT && (
              <>
                <>
                  <Tooltip title="Reset liveness">
                    <div style={{ cursor: 'pointer', margin: '0 10px' }}>
                      <ReloadOutlined
                        className="red-color"
                        onClick={() => handleRefuseLiveness(record?.user_id)}
                      />
                    </div>
                  </Tooltip>
                </>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo Id, tên user"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          // initialValue={StatusKyc.PENDING}
        >
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'general_status_account' })}
          >
            {STATUS_KYC.map((item, i) => (
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý yêu cầu Liveness</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
            rowKey={(item) => item.request_id}
          />
        )}
      </div>
    </div>
  );
};

export default Liveness;
