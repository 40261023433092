import React, { useState } from 'react';
import { useIntl, useRequest } from 'umi';
import { enterpriseUpdateInviter, resName } from '../../service';
import { Button, Form, Input, Modal, Row, message } from 'antd';

const AddInviter = ({
  open,
  refresh,
  infoAccount,
  setOpen,
}: {
  open: boolean;
  refresh: () => void;
  infoAccount: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [name, setName] = useState('');
  const nameRequest = useRequest(resName, {
    manual: true,
    onSuccess: (res: any) => {
      setName(res.enterprise_get_invite_name.message);
    },
  });

  const addInviter = useRequest(enterpriseUpdateInviter, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
        refresh();
        formInvite.resetFields();
      } else {
        message.success('Thành công');
        onClose();
        refresh();
      }
    },
    onError() {
      message.error('Mã giới thiệu không tồn tại');
    },
    formatResult: (res) => res,
  });

  const handleChangeInviter = (e: any) => {
    nameRequest.run(e.target.value);
  };

  const onSubmitAddInviter = (val: any) => {
    addInviter.run({
      enterprise_id: infoAccount?.enterprise_id,
      code: val?.code,
    });
  };

  const [formInvite] = Form.useForm();
  const { formatMessage } = useIntl();

  const onClose = () => {
    formInvite.resetFields();
    setName('');
    setOpen(false);
  };
  return (
    <Modal
      title="Thêm người giới thiệu"
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        id="formInvite"
        layout="vertical"
        form={formInvite}
        onFinish={onSubmitAddInviter}
      >
        <Form.Item
          label="Người giới thiệu"
          name="code"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mã giới thiệu',
            },
          ]}
        >
          <Input
            placeholder="Nhập mã giới thiệu"
            onBlur={handleChangeInviter}
          />
        </Form.Item>
        <>{name && <span>Người giới thiệu: {name}</span>}</>
        <Row justify="center">
          <Button
            style={{
              margin: '0 15px',
            }}
            onClick={onClose}
          >
            Hủy
          </Button>
          <Button type="primary" htmlType="submit" loading={addInviter.loading}>
            Xác nhận
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddInviter;
