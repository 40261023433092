import React, { useState } from 'react';
import styles from '../index.less';
import { Card, Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import { useRequest } from 'ahooks';
import {
  getAllEventGift,
  getAllGift,
  getChartAgeStatistics,
  getChartGenderStatistics,
  getChartPickedUpStatistics,
  getChartProvinceStatistics,
} from '../service';
import GenderChart from './Component/GenderChart';
import CityChart from './Component/CityChart';
import RangeAgeChart from './Component/RangeAgeChart';
import TopGiftChart from './Component/TopGiftChart';
const StatisticGift = () => {
  const [events, setEvents] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [form] = Form.useForm();
  const requestSearchEvent = useRequest(getAllEventGift, {
    onSuccess: (res: any) => {
      setEvents(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
    debounceWait: 1000,
  });
  const requestSearchGift = useRequest(getAllGift, {
    onSuccess: (res: any) => {
      setGifts(res);
    },
    onError: (err) => {
      console.log(err);
    },
    debounceWait: 1000,
  });
  const onChangeDate = (date: any) => {
    dataAgeChart.run({
      ...form.getFieldsValue(),
      toDate: date,
    });
    dataGenderChart.run({
      ...form.getFieldsValue(),
      toDate: date,
    });
    dataPickedUpChart.run({
      ...form.getFieldsValue(),
      toDate: date,
    });
    dataProvinceChart.run({
      ...form.getFieldsValue(),
      toDate: date,
    });
  };
  const onChangeEvent = (id: number) => {
    dataAgeChart.run({
      ...form.getFieldsValue(),
      eventId: id,
    });
    dataGenderChart.run({
      ...form.getFieldsValue(),
      eventId: id,
    });
    dataPickedUpChart.run({
      ...form.getFieldsValue(),
      eventId: id,
    });
    dataProvinceChart.run({
      ...form.getFieldsValue(),
      eventId: id,
    });
  };
  const onChangeGift = (id: number) => {
    dataAgeChart.run({
      ...form.getFieldsValue(),
      giftId: id,
    });
    dataGenderChart.run({
      ...form.getFieldsValue(),
      giftId: id,
    });
    dataPickedUpChart.run({
      ...form.getFieldsValue(),
      giftId: id,
    });
    dataProvinceChart.run({
      ...form.getFieldsValue(),
      giftId: id,
    });
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        autoComplete="off"
        className={styles.searchForm}
        layout="inline"
      >
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            onChange={onChangeDate}
            format={['DD/MM/YYYY', 'DD/MM/YY']}
          />
        </Form.Item>
        <Form.Item name="eventId" className={styles.searchItem}>
          <Select
            allowClear
            placeholder="Chọn sự kiện"
            options={events.map((item: any) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))}
            onChange={onChangeEvent}
            showSearch
            filterOption={false}
            onSearch={(val) => requestSearchEvent.run(val)}
            notFoundContent={
              requestSearchEvent.loading ? (
                <Spin size="small" />
              ) : (
                'Không có dữ liệu'
              )
            }
          />
        </Form.Item>
        <Form.Item name="giftId" className={styles.searchItem}>
          <Select
            // onChange={submit}
            allowClear
            placeholder="Chọn quà tặng"
            options={gifts.map((item: any) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))}
            onChange={onChangeGift}
            showSearch
            filterOption={false}
            onSearch={(val) => requestSearchGift.run(val)}
            notFoundContent={
              requestSearchGift.loading ? (
                <Spin size="small" />
              ) : (
                'Không có dữ liệu'
              )
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
  const dataGenderChart = useRequest(getChartGenderStatistics, {
    defaultParams: [
      {
        ...form.getFieldsValue(),
      },
    ],
  });
  const dataPickedUpChart = useRequest(getChartPickedUpStatistics, {
    defaultParams: [
      {
        ...form.getFieldsValue(),
      },
    ],
  });
  const dataAgeChart = useRequest(getChartAgeStatistics, {
    defaultParams: [
      {
        ...form.getFieldsValue(),
      },
    ],
  });
  const dataProvinceChart = useRequest(getChartProvinceStatistics, {
    defaultParams: [
      {
        ...form.getFieldsValue(),
      },
    ],
  });

  return (
    <div>
      {searchForm}
      <div className={styles.chartWrapper}>
        <Row justify="space-between" gutter={[24, 24]}>
          <Col span={12} xxl={12} lg={24} xl={12} sm={24} xs={24}>
            <Card>
              <GenderChart data={dataGenderChart.data} />
            </Card>
          </Col>
          <Col span={12} xxl={12} lg={24} xl={12} sm={24} xs={24}>
            <Card>
              <CityChart data={dataProvinceChart.data} />
            </Card>
          </Col>
        </Row>
        <Card
          style={{
            marginTop: '20px',
          }}
        >
          <RangeAgeChart data={dataAgeChart.data} />
        </Card>
        <Card
          style={{
            marginTop: '20px',
          }}
        >
          <TopGiftChart data={dataPickedUpChart.data} />
        </Card>
      </div>
    </div>
  );
};

export default StatisticGift;
