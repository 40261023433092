import { API_PATH, privateRequest, request } from '@/utils/apis';

export enum TypeBill {
  INVOICE = 'INVOICE',
  QR_CODE = 'QR_CODE',
  KIOTVIET = 'KIOTVIET',
}
export const GetCustomerRequest = (id: number) => {
  return privateRequest(request.get, API_PATH.CUSTOMER_REQUEST_DETAIL(id));
};

export const ApproveCustomerRequest = (input: {
  request_id: number;
  approve: boolean;
  spending: number;
  bill_code?: string;
  note_time?: string;
  reason?: string;
}) => {
  return privateRequest(request.post, API_PATH.APPROVE_REQUEST_CUSTOMER, {
    data: input,
  });
};
