import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import React from 'react';
import styles from '../index.less';
import { STATUS_LOCABONUS, STATUS_NOTI } from '@/utils/constant';
import { useAntdTable, useRequest } from 'ahooks';
import { deleteNotification, getListNotifications } from '../service';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { StatusNofi } from '@/utils/enum';
import { Status } from '@/pages/GiftBuilding/service';
const { Option } = Select;
const { RangePicker } = DatePicker;
const NotificationUser = () => {
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(getListNotifications, {
    form,
  });
  const { submit } = search;
  const deleteNoti = useRequest(deleteNotification, {
    manual: true,
    onSuccess(res) {
      message.success('Xóa thành công');
      refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });
  const onDeleteNoti = (id: number) =>
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa thông báo không?',
      okText: 'Xác nhận',
      okType: 'primary',
      cancelText: 'Huỷ',
      onOk: () => {
        deleteNoti.run(id);
      },
    });
  const renderStatus = (value: string) => {
    switch (value) {
      case 'SEND':
        return <Tag color="green">Đã gửi</Tag>;
      case 'WAITING':
        return <Tag color="gray">Chờ gửi</Tag>;
      case 'ERROR':
        return <Tag color="red">Gửi lỗi</Tag>;
      case 'SENDING':
        return <Tag color="blue">Đang gửi</Tag>;
      default:
        return <></>;
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'sending_time',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => <>{renderStatus(value)}</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '10%',
      dataIndex: 'id',
      render: (value, record) => (
        <Row justify="space-around">
          <Tooltip title="Xem">
            <a href={`/notification/${record.id}`}>
              <EyeOutlined className="purple-color" />
            </a>
          </Tooltip>
          {record.status !== 'SEND' && (
            <Tooltip title="Xoá">
              <DeleteOutlined
                onClick={() => onDeleteNoti(record?.id)}
                className="red-color"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Row>
      ),
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tiêu đề"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          initialValue={null}
        >
          <Select onChange={submit} allowClear placeholder="Trạng thái">
            {STATUS_NOTI.map((item, i) => (
              <Option key={i} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Button href="/create-notification">Tạo thông báo</Button>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table {...tableProps} columns={columns} rowKey={(item) => item.id} />
      </div>
    </div>
  );
};

export default NotificationUser;
