import { API_PATH, privateRequest, request } from '@/utils/apis';
import { StatusReportVoucher } from '@/utils/enum';
import moment from 'moment';

export enum VoucherType {
  DVOUCHER = 'DVOUCHER',
  CVOUCHER = 'CVOUCHER',
}
interface CreateTypeVoucherInput {
  name: string;
  value: number;
  description: string;
  nums_of_piece: number;
  condition: string;
  image: string;
  pool_percent: number;
  abaha_id: string;
  type: VoucherType;
}
interface UpdateTypeVoucherInput {
  name?: string;
  value?: number;
  description?: string;
  nums_of_piece?: number;
  condition?: string;
  image?: string;
  pool_percent?: number;
  abaha_id?: string;
  display?: boolean;
}
interface ReleaseVoucherInput {
  typeId: number;
  enterpriseId: number;
  amount: number;
  inventory_rate: number;
}

interface ReleasePartnerVoucherInput {
  amount: number;
  type_id: number;
}

interface CreateTypeVoucherPartnerInput {
  name: string;
  value: number;
  description: string;
  condition: string;
  image: string;
}

interface UpdateTypeVoucherPartnerInput {
  name?: string;
  value?: number;
  description?: string;
  condition?: string;
  image?: string;
}

export enum TRANSFER_VOUCHER_TYPE {
  LOCAMOS = 'LOCAMOS',
  LOCAMART = 'LOCAMART',
}

export enum TRANSFER_GIFT_STATUS {
  REQUEST = 'REQUEST',
  RECEIVER_CONFIRM = 'RECEIVER_CONFIRM',
  RECEIVER_REJECT = 'RECEIVER_REJECT',
  SUCCESS = 'SUCCESS',
  DISPUTE = 'DISPUTE',
  HANDLE_TRANSFER = 'HANDLE_TRANSFER',
  HANDLE_BACK = 'HANDLE_BACK',
  TRANSFER_FAILED = 'TRANSFER_FAILED',
}

export const searchAllEnterprise = (params: {
  size?: number;
  typeId?: number;
  searchValue?: string;
}) => {
  return privateRequest(request.get, API_PATH.ENTERPRISE_REALEASE_VOUCHER, {
    params,
  });
};

export const createTypeVoucher = (input: CreateTypeVoucherInput) => {
  return privateRequest(request.post, API_PATH.TYPE_VOUCHER, {
    data: {
      ...input,
    },
  });
};

export const updateTypeVoucher = (
  input: UpdateTypeVoucherInput,
  id: string,
) => {
  return privateRequest(request.put, API_PATH.TYPE_VOUCHER_DETAIL(id), {
    data: {
      ...input,
    },
  });
};

export const getListTypeVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate?: string[];
    textSearch?: string;
  },
) => {
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  return privateRequest(request.get, API_PATH.TYPE_VOUCHER, {
    params: {
      pageSize,
      pageIndex: current,
      name: formData.textSearch,
      startTime: fromDate,
      endTime: toDate,
    },
  }).then((res: any) => ({
    total: res.count,
    list: res.data?.map((item: any, i: number) => ({
      ...item,
      stt: i + 1 + pageSize * (current - 1),
    })),
  }));
};
export const getDetailTypeVoucher = (id: string) => {
  return privateRequest(request.get, API_PATH.TYPE_VOUCHER_DETAIL(id));
};

export const getListEnterpriseVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate: string[];
    textSearch: string;
  },
) => {
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  return privateRequest(request.get, API_PATH.ENTERPRISE_VOUCHER, {
    params: {
      pageSize,
      pageIndex: current,
      searchValue: formData.textSearch,
      startTime: fromDate,
      endTime: toDate,
    },
  }).then((res: any) => {
    return {
      total: res.count,
      list: res.data?.map((item: any, i: number) => ({
        ...item,
        stt: i + 1 + pageSize * (current - 1),
      })),
    };
  });
};

export const getStatisticPartnerVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate: string[];
    textSearch: string;
  },
) => {
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  }

  return privateRequest(request.get, API_PATH.PARTNER_VOUCHER_STATISTICS, {
    params: {
      pageSize,
      pageIndex: current,
      searchValue: formData.textSearch,
    },
  }).then((res: any) => {
    return {
      total: res.data?.total,
      list: res.data?.data?.map((item: any, i: number) => ({
        ...item,
        stt: i + 1 + pageSize * (current - 1),
      })),
    };
  });
};

export const getReleaseHistoryPartnerVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate?: string[];
    textSearch?: string;
  },
) => {
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  return privateRequest(request.get, API_PATH.PARTNER_VOUCHER, {
    params: {
      pageSize,
      pageIndex: current,
      searchValue: formData.textSearch,
      startTime: fromDate,
      endTime: toDate,
    },
  }).then((res: any) => {
    return {
      total: res.data?.total,
      list: res.data?.data?.map((item: any, i: number) => ({
        ...item,
        stt: i + 1 + pageSize * (current - 1),
      })),
    };
  });
};

export const createTypeVoucherPartner = (
  input: CreateTypeVoucherPartnerInput,
) => {
  return privateRequest(request.post, API_PATH.PARTNER_VOUCHER_TYPE, {
    data: input,
  });
};

export const updateTypeVoucherPartner = (
  input: UpdateTypeVoucherPartnerInput,
  id: number,
) => {
  return privateRequest(request.put, API_PATH.PARTNER_VOUCHER_TYPE_DETAIL(id), {
    data: {
      ...input,
    },
  });
};

export const getDetailTypeVoucherPartner = (id: number) => {
  return privateRequest(request.get, API_PATH.PARTNER_VOUCHER_TYPE_DETAIL(id));
};

export const getListTypePartnerVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData?: {
    textSearch: string;
    toDate: string[];
  },
) => {
  const fromDate = Array.isArray(formData?.toDate)
    ? moment(formData?.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData?.toDate)
    ? moment(formData?.toDate[1]).format('YYYY-MM-DD')
    : '';
  const params: any = {
    pageSize,
    pageIndex: current,
  };
  if (!!formData?.textSearch) {
    params.searchValue = formData?.textSearch;
  }
  if (!!fromDate && !!toDate) {
    params.startTime = fromDate;
    params.endTime = toDate;
  }
  return privateRequest(request.get, API_PATH.PARTNER_VOUCHER_TYPE, {
    params,
  }).then((res) => {
    return {
      list: res.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: res.total,
    };
  });
};

export const getAllTypePartnerVoucher = (search?: string) => {
  const params: any = {
    pageSize: 20,
    pageIndex: 1,
  };
  if (!!search) {
    params.searchValue = search;
  }
  return privateRequest(request.get, API_PATH.PARTNER_VOUCHER_TYPE, {
    params,
  });
};

export const releasePartnerVoucherType = (
  input: ReleasePartnerVoucherInput,
) => {
  return privateRequest(request.post, API_PATH.PARTNER_VOUCHER_TYPE_RELEASE, {
    data: input,
  });
};

export const ReleaseVoucherEnterprise = (input: ReleaseVoucherInput) => {
  return privateRequest(request.post, API_PATH.RELEASE_VOUCHER, {
    data: {
      ...input,
    },
  });
};

export const GetStatisticEnterpriseVoucher = (
  {
    current,
    pageSize,
    enterpriseId,
    typeId,
  }: {
    current: number;
    pageSize: number;
    typeId: number;
    enterpriseId?: number;
  },
  formData: {
    toDate?: string[];
    searchValue?: string;
  },
) => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const params: {
    enterpriseId?: number;
    typeId: number;
    pageSize: number;
    pageIndex: number;
    startTime?: string;
    endTime?: string;
    searchValue?: string;
  } = {
    typeId,
    pageSize,
    pageIndex: current,
    searchValue: formData.searchValue?.trim() ?? '',
  };
  if (!!enterpriseId) {
    params.enterpriseId = enterpriseId;
  }
  if (!!fromDate && !!toDate) {
    (params['startTime'] = fromDate), (params['endTime'] = toDate);
  }
  return privateRequest(request.get, API_PATH.STATISTIC_ENTERPRISE_VOUCHER, {
    params,
  }).then((res) => {
    return {
      list: res.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + index + 1,
      })),
      total: res.count,
      enterprise_name: res?.enterprise_name,
      voucher_name: res?.voucher_name,
    };
  });
};

export const HistoryReleasedVoucher = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate?: string[];
    textSearch?: string;
  },
) => {
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  return privateRequest(request.get, API_PATH.HISTORY_RELEASE_VOUCHER, {
    params: {
      pageSize,
      pageIndex: current,
      searchValue: formData.textSearch,
      startTime: fromDate,
      endTime: toDate,
    },
  }).then((res: any) => ({
    total: res.count,
    list: res.data?.map((item: any, i: number) => ({
      ...item,
      stt: i + 1 + pageSize * (current - 1),
    })),
    statistic: res.statistic,
  }));
};

export const BadReportVoucherService = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    textSearch: string;
    toDate: string[];
    status: StatusReportVoucher;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
    status: formData.status,
  };
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  } else {
    params.search_text = formData.textSearch;
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!fromDate && !!toDate) {
    params.start_date = fromDate;
    params.toDate = toDate;
  }
  return privateRequest(request.get, API_PATH.BAD_REPORT_VOUCHER, {
    params,
  }).then((res) => {
    return {
      list: res.data?.map((item: any, i: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (i + 1),
      })),
      total: res.total,
    };
  });
};

export const DetailReportService = (id: string) => {
  return privateRequest(request.get, API_PATH.DETAIL_BAD_REPORT_VOUCHER(id));
};

export const handleReportService = ({
  id,
  status,
  reason,
}: {
  id: string;
  status: StatusReportVoucher;
  reason?: string;
}) => {
  const payload: {
    status: StatusReportVoucher;
    reason?: string;
  } = {
    status: status,
  };

  if (reason) {
    payload['reason'] = reason;
  }
  return privateRequest(request.put, API_PATH.DETAIL_BAD_REPORT_VOUCHER(id), {
    data: payload,
  });
};

export const getTransferStatistics = (
  {
    current,
    pageSize,
    type,
  }: { current: number; pageSize: number; type: TRANSFER_VOUCHER_TYPE },
  formData: {
    textSearch?: string;
    toDate?: string[];
  },
) => {
  const params: any = {
    pageIndex: current,
    pageSize: pageSize,
    type,
  };
  if (formData.textSearch === undefined) {
    formData.textSearch = '';
  } else {
    params.searchValue = formData.textSearch;
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!fromDate && !!toDate) {
    params.startTime = fromDate;
    params.endTime = toDate;
  }
  return privateRequest(request.get, API_PATH.VOUCHER_TRANSFER_STATISTICS, {
    params,
  }).then((res) => {
    return {
      list: res.data?.map((item: any, i: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (i + 1),
      })),
      total: res.total,
    };
  });
};

export const getVoucherTransferHistory = (
  {
    current,
    pageSize,
    typeId,
  }: { current: number; pageSize: number; typeId: number },
  formData: {
    textSearch?: string;
    toDate?: string[];
  },
) => {
  const params: any = {
    pageIndex: current,
    pageSize: pageSize,
    typeId,
  };
  if (!formData.textSearch) {
    formData.textSearch = '';
  } else {
    if (!!Number(formData.textSearch)) {
      params.userId = formData.textSearch;
    } else {
      params.userId = 1;
    }
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  if (!!fromDate && !!toDate) {
    params.startTime = fromDate;
    params.endTime = toDate;
  }
  return privateRequest(request.get, API_PATH.EXCHANGE_VOUCHER, {
    params,
  }).then((res) => {
    return {
      list: res.data?.map((item: any, i: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (i + 1),
      })),
      total: res.total,
      data: {
        quantity: res.quantity,
        voucher_name: res.voucher_name,
      },
    };
  });
};
