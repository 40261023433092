import React from 'react';
import ReactEcharts from 'echarts-for-react';
interface IPropChart {
  data: any[];
}
const CityChart = ({ data }: IPropChart) => {
  const options = {
    title: {
      text: 'Biểu đồ theo thành phố người nhặt quà',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'horizontal',
      bottom: 0,
    },
    series: [
      {
        type: 'pie',
        radius: '60%',
        // data: [
        //   { value: 1048, name: 'Hà Nội' },
        //   { value: 735, name: 'TP.Hồ Chí Minh' },
        //   { value: 580, name: 'Quảng Ninh' },
        //   { value: 484, name: 'Đà Nẵng' },
        //   { value: 300, name: 'Nghệ An' },
        //   { value: 300, name: 'Vĩnh Phúc' },
        // ],
        data: data?.map((item: any) => ({
          name: item.key,
          value: item.count,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts option={options}></ReactEcharts>
    </div>
  );
};

export default CityChart;
