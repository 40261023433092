import React from 'react';
import { formatNumber } from '@/utils/apis/common';
import { useAuth } from '@/utils/hooks/useAuth';
import { useAntdTable, useToggle } from 'ahooks';
import { Button, Form, Input } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import styles from '../index.less';
import { PERMISSIONS } from '@/utils/enum';
import { PlusOutlined } from '@ant-design/icons';
import ReleasePartnerVoucherModal from '../component/ReleasePartnerVoucherModal';
import { getStatisticPartnerVoucher } from '../service';

const PartnerVoucher = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '10%',
    },

    {
      title: 'Loại voucher',
      dataIndex: 'name',
    },
    {
      title: 'Voucher đã phát hành',
      width: '15%',
      // align: 'right',
      dataIndex: 'released',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Voucher đã sử dụng',
      width: '15%',
      // align: 'right',
      dataIndex: 'used',
      render: (value) => <>{formatNumber(value)}</>,
    },
    // {
    //   title: 'Hành động',
    //   width: '10%',
    //   render: (_, record) => (
    //     <>
    //       <a
    //         href={`/statistic_voucher/${record.voucher_type_id}?enteprise_id=${record?.enteprise_id}`}
    //       >
    //         Thống kê
    //       </a>
    //     </>
    //   ),
    // },
  ];
  const [isOpenModal, setIsOpenModal] = useToggle();
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(
    getStatisticPartnerVoucher,
    {
      form,
    },
  );

  const { getAdminLocalStore } = useAuth();
  const admin: any = getAdminLocalStore();
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên DN, loại Voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      {admin?.role?.permissions?.release_voucher_management_level ===
        PERMISSIONS.FULL && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsOpenModal.toggle()}
        >
          Phát hành voucher
        </Button>
      )}
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => `${item.voucher_type_id}${item.enteprise_id}`}
        />
      </div>
      {isOpenModal && (
        <ReleasePartnerVoucherModal
          refresh={refresh}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </div>
  );
};

export default PartnerVoucher;
