import { useDebounce } from '@umijs/hooks';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useRequest } from 'umi';
import styles from '../../index.less';
import { getOneUser, submitTransferAccount } from '../../service';

const TransferAccount = ({ open, setOpen, infoAccount }: any) => {
  const [modalSubmitTrasnfer, setModalSubmitTransfer] = useState(false);
  const [inputId, setInputId] = useState({});
  const params = useParams<{ id: string }>();
  const [userId, setUserId] = useState('');
  const debounceUserId = useDebounce(userId, 1000);
  const [methodLogin, setMethodLogin] = useState('');
  const [form] = Form.useForm();

  const reqTransferAccount = useRequest(submitTransferAccount, {
    manual: true,
    onSuccess(res: any) {
      if (res?.transfer_account) {
        form.resetFields();
        switch (res?.transfer_account) {
          case 'Transfer account successfully':
            return message.success('Chuyển đổi tài khoản thành công');
          case 'Transfered User not found':
            return message.warning('Không tìm thấy người dùng đã chuyển');
          case 'Only account loged in by apple can transfer':
            return message.warning(
              'Chỉ tài khoản đăng nhập bằng apple mới chuyển được',
            );
          default:
            return message.error('Chuyển đổi tài khoản thất bại');
        }
      }
    },
    onError() {
      message.error('Transfer tài khoản thất bại');
    },
  });
  const toUserInfo = useRequest(getOneUser, {
    manual: true,
    onSuccess(res: any) {
      if (res) {
        if (checkMethodLogin(res)) {
          setMethodLogin(checkMethodLogin(res));
        } else {
          setMethodLogin('');
        }
        // console.log(res);
      }
    },
    onError() {
      setMethodLogin('');
    },
  });
  const handleCancelPopup = () => {
    setOpen(false);
    setInputId({});
    form.resetFields();
  };

  const handleSubmit = () => {
    reqTransferAccount.run(inputId);
    setModalSubmitTransfer(false);
    handleCancelPopup();
  };

  const onSubmitTransfer = (values: any) => {
    setInputId(values);
    setModalSubmitTransfer(true);
  };

  const checkMethodLogin = (user: any) => {
    if (user?.google_id) {
      return 'GoogleId';
    }
    if (user?.facebook_id) {
      return 'FacebookId';
    }
    if (user?.apple_id) {
      return 'AppleId';
    }
    return '';
  };

  useEffect(() => {
    if (debounceUserId) {
      toUserInfo.run(debounceUserId);
    }
  }, [debounceUserId]);

  return (
    <>
      <Modal
        title="Transfer tài khoản"
        centered
        width={500}
        onCancel={handleCancelPopup}
        open={open}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          form={form}
          onFinish={onSubmitTransfer}
          initialValues={{ fromId: params?.id, toId: '' }}
        >
          <Form.Item
            label="Từ tài khoản"
            name="fromId"
            className={styles.itemTransferAcc}
            rules={[{ required: true }]}
          >
            <Input placeholder="Nhập id tài khoản" disabled />
          </Form.Item>
          {infoAccount && (
            <div
              className={styles.methodLogin}
            >{`Phương thức đăng nhập: ${checkMethodLogin(infoAccount)}`}</div>
          )}
          <Form.Item
            label="Đến tài khoản"
            name="toId"
            rules={[{ required: true }]}
            className={styles.itemTransferAcc}
          >
            <Input
              placeholder="Nhập id tài khoản"
              onChange={(e) => {
                setMethodLogin('');
                setUserId(e.target.value);
              }}
            />
          </Form.Item>
          {methodLogin && userId && (
            <div
              className={styles.methodLogin}
            >{`Phương thức đăng nhập: ${methodLogin}`}</div>
          )}
          {toUserInfo.loading && (
            <div className={styles.methodLogin}>
              <Spin size="small" />
            </div>
          )}
          <Row align="middle" justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Button
                danger
                onClick={handleCancelPopup}
                className={styles.addButton}
              >
                Huỷ
              </Button>
              <Button
                type="primary"
                className={styles.addButton}
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Xác nhận transfer tài khoản"
        centered
        width={400}
        onCancel={() => setModalSubmitTransfer(false)}
        visible={modalSubmitTrasnfer}
        footer={null}
      >
        <Typography style={{ textAlign: 'center' }}>
          Tài khoản hiện tại sẽ bị xóa, bạn có chắc chắn muốn transfer tài khoản
        </Typography>
        <Row align="middle" justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Button
              danger
              onClick={() => setModalSubmitTransfer(false)}
              className={styles.addButton}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={reqTransferAccount.loading}
              className={styles.addButton}
            >
              Xác nhận
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default TransferAccount;
