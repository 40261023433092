import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { Link, useIntl, useParams } from 'umi';
import styles from '../index.less';
import { LeftOutlined } from '@ant-design/icons';
import { useRequest, useToggle } from 'ahooks';
import { getTransforDetail } from '../service';
import { useEffect, useState } from 'react';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { StatusDespute, StatusKyc } from '@/utils/enum';
import { adminHandleDispute } from '../../DisputeHandle/service';
import { ENVIRONMENTS, STATUS_DESPUTE } from '@/utils/constant';
import { genAVT } from '@/utils/apis/common';

export default () => {
  const { Title } = Typography;
  const params: any = useParams();
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const infoDispute = useRequest(getTransforDetail, {
    manual: true,
    onSuccess(res) {
      if (res.data) {
        setData(res.data.get_transfer_request_info);
        form.setFieldsValue({
          ...res.data.get_transfer_request_info,
          dispute_handled: checkNameStatus(
            res.data?.get_transfer_request_info?.dispute_handled,
          ),
        });
      }
    },
  });

  const requestHandleDispute = useRequest(adminHandleDispute, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        message.success('Thành công');
      }
      getData();
    },
  });

  const handleKyc = (Id: number | string, status: any) => {
    requestHandleDispute.run(Id, status);
  };

  const handleAcceptUser = (idUser: number | string, status: any) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn xác nhận chuyển điểm cho ${data.receiver_name} không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleKyc(idUser, status);
      },
    });
  };
  const handletRefuseUser = (idUser: number | string, status: any) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn hoàn lại điểm cho ${data.request_user_name} không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleKyc(idUser, status);
      },
    });
  };

  const getData = () => {
    if (params.id) infoDispute.run(params.id);
  };
  useEffect(() => {
    getData();
  }, [params]);

  const { formatMessage } = useIntl();

  const checkNameStatus = (record: any) => {
    const status = STATUS_DESPUTE.find((item) => item.value === record);
    return status?.name ? status.name : '';
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/handle_dispute" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {'Chi tiết tranh chấp'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        {data && !infoDispute.loading ? (
          <Card
            // title={'Thông tin người chuyển'}
            size="small"
            className={styles.primaryCard}
          >
            <Form
              form={form}
              labelAlign="left"
              labelWrap={true}
              wrapperCol={{
                span: 10,
              }}
              labelCol={{
                span: 5,
              }}
            >
              <Title level={5}>Thông tin người chuyển</Title>
              <Form.Item
                className={styles.formItem}
                label="Người chuyển"
                name="request_user_name"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="request_user_email"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Số điện thoại"
                name="request_user_phone"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Title level={5}>Thông tin nguời nhận</Title>
              <Form.Item
                className={styles.formItem}
                label="Người chuyển"
                name="receiver_name"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="receiver_email"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Số điện thoại"
                name="receiver_phone"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Title level={5}>Chi tiết giao dịch</Title>
              <Form.Item
                className={styles.formItem}
                label="Số lượng"
                name="amount"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Phí giao dịch"
                name="transfer_fee"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Nội dung"
                name="transfer_content"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Mã giao dịch"
                name="transaction_id"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Trạng thái"
                name="dispute_handled"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Người xử lý tranh chấp"
                name="handle_by"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
            </Form>
            {data && !infoDispute.loading ? (
              <>
                <Title level={5}>Ảnh chụp xác minh</Title>
                <Row gutter={[24, 24]}>
                  {data.images?.map((image: any, index: any) => {
                    return (
                      <div className={styles.verifiedImage} key={index}>
                        {image && (
                          <Image
                            wrapperClassName={styles.antImage}
                            src={`${image}`}
                          />
                        )}
                      </div>
                    );
                  })}
                </Row>

                {data.dispute_handled === StatusDespute.DISPUTE && (
                  <Row
                    align="middle"
                    justify="center"
                    className={styles.submitButtonGroup}
                  >
                    <Col>
                      <Button
                        danger
                        onClick={() =>
                          handletRefuseUser(
                            data.transfer_id,
                            StatusDespute.REFUND,
                          )
                        }
                        className={styles.addButton}
                      >
                        Hoàn điểm
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleAcceptUser(
                            data.transfer_id,
                            StatusDespute.TRANSFER,
                          );
                        }}
                        className={styles.addButton}
                      >
                        Chuyển điểm
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <Skeleton active />
            )}
          </Card>
        ) : (
          <Skeleton active />
        )}
      </div>
    </>
  );
};
