import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  InputNumber,
  Select,
  Tooltip,
  message,
  Row,
} from 'antd';
import React from 'react';
import { useIntl, useRequest } from 'umi';
import styles from './index.less';
import { useAntdTable } from 'ahooks';
import {
  addDeviceToBlacklist,
  getConfig,
  getTableData,
  setConfig,
  unlockDevice,
} from './service';
import Table, { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { SettingOutlined, UnlockOutlined } from '@ant-design/icons';
import { TYPE_CHECKPOINT } from '@/utils/constant';
const BlacklistDevice = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { tableProps, search, loading, refresh } = useAntdTable(getTableData, {
    form,
  });

  const addDevice = useRequest(addDeviceToBlacklist, {
    manual: true,
    onSuccess: (res) => {
      if (res?.data?.add_device_to_blacklist) {
        message.success('Thành công');
        refresh();
      }
      if (res?.errors) {
        message.error(res?.errors?.[0]?.message);
      }
      formAddDevice.resetFields();
    },
    formatResult: (res) => res,
  });

  const [formAddDevice] = Form.useForm();
  const handleAddDevice = () => {
    const onAddDevice = (val: any) => {
      addDevice.run(val.device_id);
    };
    return Modal.confirm({
      icon: null,
      title: 'Thêm thiết bị vào danh sách khóa',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      content: (
        <Form id="formAddDevice" form={formAddDevice} onFinish={onAddDevice}>
          <Form.Item
            name="device_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập uid thiết bị',
              },
            ]}
          >
            <Input placeholder="Nhập uid thiết bị" />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formAddDevice',
      },
      onCancel() {
        formAddDevice.resetFields();
      },
      onOk() {
        if (formAddDevice.getFieldValue('device_id')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const handleUnlockDevice = useRequest(unlockDevice, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        message.success('Mở khóa thành công');
        refresh();
      }
    },
    formatResult: (res) => res,
  });

  const renderTypeCheckpoint = (type: number) => {
    switch (type) {
      case 0:
        return formatMessage({ id: 'general_checkpoint_keep_login' });
      case 1:
        return formatMessage({ id: 'general_checkpoint_many_account' });
      case 2:
        return formatMessage({ id: 'general_checkpoint_admin_add' });
      case 3:
        return formatMessage({ id: 'general_checkpoint_fake_gps' });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'stt',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Mã thiết bị',
      dataIndex: 'device_id',
      key: 'device_id',
    },
    {
      title: 'Loại checkpoint',
      key: 'type',
      render: (_: any, record: any) => (
        <>{renderTypeCheckpoint(record?.type)}</>
      ),
    },
    {
      title: 'UserId',
      dataIndex: 'became_blacklist_by',
      align: 'right',
      key: 'became_blacklist_by',
    },
    {
      title: 'Thời gian bị khóa gần nhất',
      dataIndex: 'became_blacklist_at',
      align: 'right',
      key: 'became_blacklist_at',
      render: (_, record) => (
        <>{dayjs(record?.became_blacklist_at).format('HH:mm DD/MM/YYYY')}</>
      ),
    },

    {
      title: 'Hành động',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <>
          <Tooltip title="Mở khóa">
            <UnlockOutlined
              className="warning-color"
              style={{
                cursor: 'pointer',
                fontSize: '20px',
              }}
              onClick={() => handleUnlockDevice.run(record?.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_device' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="date" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
        <Form.Item name="type" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Loại checkpoint"
            options={TYPE_CHECKPOINT.map((item) => ({
              label: formatMessage({ id: item.name }),
              value: item.value,
            }))}
          />
        </Form.Item>
      </Form>
      <Row align="middle" justify="end">
        <Button
          type="primary"
          icon={<SettingOutlined />}
          className={styles.configBtn}
          ghost
          onClick={() => setAccPerDeviceConfig()}
        >
          Cài đặt
        </Button>
        <Button onClick={() => handleAddDevice()} className={styles.configBtn}>
          Thêm thiết bị
        </Button>
      </Row>
    </div>
  );

  const accPerDeviceConfig = useRequest(getConfig, {});
  const updateAccConfig = useRequest(setConfig, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN')
          message.error('Không có quyền');
        else {
          message.error('Có lỗi xảy ra!');
        }
      } else {
        message.success('Cài đặt thành công');

        refresh();
      }
    },
    formatResult: (res) => res,
  });
  const [formConfig] = Form.useForm();
  const setAccPerDeviceConfig = () => {
    const initialValues = accPerDeviceConfig.data?.get_acc_per_device_config;
    const onUpdateConfig = (val: any) => {
      updateAccConfig.run(val);
    };
    return Modal.confirm({
      icon: null,
      title: 'Cài đặt số lượng tài khoản đăng nhập',
      content: (
        <Form
          form={formConfig}
          onFinish={onUpdateConfig}
          id="formConfig"
          initialValues={{
            ...initialValues,
          }}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 10,
          }}
        >
          <Form.Item
            label="Checkpoint theo ngày"
            name="day"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Checkpoint theo tuần"
            name="week"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Checkpoint theo tháng"
            name="month"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formConfig',
      },
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        for (const item of formConfig.getFieldsError([
          'day',
          'month',
          'week',
        ])) {
          if (item.errors.length > 0) {
            return Promise.reject();
          }
        }
        return Promise.resolve();
      },
    });
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_blacklist_device' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </>
  );
};

export default BlacklistDevice;
