import { extend } from 'umi-request';
import { ENVIRONMENTS } from '../constant';
import TokenManagement from './TokenManagement';
import jwtDecode from 'jwt-decode';

const localeInfo = window.localStorage.getItem('umi_locale') || 'vi-VN';
const REQ_TIMEOUT = 20 * 1000;

const request = extend({
  prefix: ENVIRONMENTS.API_URL,
  timeout: REQ_TIMEOUT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // ':Authority': 'api-dev.locameet.net',
    Accept: 'application/json',
  },
});

export const getAccessToken = () => {
  const localStorageCms: string = String(
    window.localStorage.getItem(String(ENVIRONMENTS.LOCAL_STORAGE_KEY)),
  );
  return JSON.parse(localStorageCms).token;
};

const injectBearer = (token: string, configs: any) => {
  if (!configs) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeInfo,
      },
    };
  }

  if (configs.headers) {
    return {
      ...configs,
      headers: {
        ...configs.headers,
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeInfo,
      },
    };
  }

  return {
    ...configs,
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': localeInfo,
    },
  };
};

const TokenManager = new TokenManagement({
  isTokenValid: () => {
    try {
      const token = getAccessToken();
      const decoded: any = jwtDecode(token);
      const { exp } = decoded;

      const currentTime = Date.now() / 1000;

      if (exp - 5 > currentTime) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }

    // // check token sap expired truoc' 5s
    // // dayjs token.exp < now.subtract(30s) => multiple request private1 private2 private3 => 3 lan refresh token
    // return false goi den ham onRefreshToken
    // return true => cho phep' lay' token cu~
  },
  getAccessToken: () => {
    // token provider could be session/ localstorage/ ...
    const token = getAccessToken();
    return `${token}`;
  },
  onRefreshToken: async (done) => {
    const localInfo = window?.localStorage.getItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
    );

    let localInfoObject;
    if (localInfo) {
      localInfoObject = JSON.parse(localInfo);
    }
    const refreshToken = localInfoObject?.refresh_token;

    await request
      .post('/graphql', {
        data: {
          query: `
            mutation {
              refresh_token(input: "${refreshToken}", type: 1) {
                token
                refresh_token
              }
            }
          `,
        },
      })
      .then((result) => {
        if (
          result.data?.refresh_token?.token &&
          result.data?.refresh_token?.refresh_token
        ) {
          const data = result.data?.refresh_token;
          // save to local storage
          window.localStorage.setItem(
            ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
            JSON.stringify({ ...data }),
          );
          done(data.token);
          return;
        } else {
        }
        if (result?.errors?.[0]?.message) {
          window.localStorage.clear();
          window.location.href = '/';
          done(null);
          return;
        }
        done(null);
      })
      .catch((err) => {
        console.error(err);
        // logout here
        localStorage.clear();
        window.location.assign('/login');
        done(null);
      });
  },
});

const privateRequest = async (
  request: any,
  suffixUrl: string,
  configs?: any,
) => {
  const token: string = (await TokenManager.getToken()) as string;
  return request(suffixUrl, injectBearer(token, configs));
};

const API_PATH = {
  default: '/graphql',
  GET_ONE_USER: '/user/get-user/:id',
  GET_USER_HISTORY: '/user/update-history',
  GET_USER_CHILDREN: '/user/children/:id',
  GET_CHILDREN: '/user/get-children',
  GET_USER_IDENTITY: '/user/identity/:id',
  TRANSFER_LIMIT: '/transfer-limit',
  // Auth
  UPLOAD: '/api/v2/upload-service/file',
  UPLOAD_DEV: '/v2/upload-service/file',
  UPLOAD_MEDIA: '/media/',
  UPLOAD_COMPRESSOR: '/v2/image-compressor/file',
  RESIZE_IMAGE: '/upload-service/resize-image',
  // Auth
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  REFRESH_TOKEN: '/auth/refreshToken',
  FORGOT_PASSWORD: '/auth/forgotPassword',
  RESET_PASSWORD: '/auth/resetPassword',
  LOGOUT: '/auth/logout',
  USER: '/user',
  ADMIN_USER: 'admin/user',
  CANCEL_MEMBERSHIP: '/admin/cancel-membership',
  ADMIN_BLOCK_BIZ_USER: '/admin/block-biz-user',
  //bill config
  GET_ENTERPRISE_INVOICE: (id: number) =>
    `/enterprise-invoice-identication/${id}`,
  UPDATE_LISTKEY_INVOICE: `/enterprise-invoice-identication`,
  //list blacklist face
  LIST_BLACKLIST_FACE: '/face-black-list',
  EDIT_BLACKLIST_FACE: (data: any) => `/face-black-list/${data}`,
  LIST_BLACKLIST_FACE_SIMILAR: '/face-black-list/similar',
  //building
  BUILDING_TEMPLATES: '/building-templates',
  BUILDING: '/building',
  BUILDING_BANNER: '/building-banner',
  SHOP: '/building-shop',
  //detail customer request
  CUSTOMER_REQUEST_DETAIL: (id: number) =>
    `/admin/detail-customer-request/${id}`,
  APPROVE_REQUEST_CUSTOMER: '/admin/approve-customer-request',
  //voucher-urbox
  SEARCH_VOUCHER: '/evoucher-urbox',
  SEARCH_ALL_VOUCHER_URBOX: '/evoucher-urbox/urbox',
  LIST_VOUCHER_URBOX: '/evoucher-urbox/released-group',
  RELEASED_VOUCHER_URBOX: '/evoucher-urbox/released',
  UPDATE_VOUCHER_URBOX: (id: number) => `/evoucher-urbox/released/${id}`,
  HISTORY_VOUCHER_URBOX: '/evoucher-urbox/history',
  //voucher enterprise
  TYPE_VOUCHER: '/enterprise-voucher/type',
  TYPE_VOUCHER_DETAIL: (id: string) => `/enterprise-voucher/type/${id}`,
  ENTERPRISE_VOUCHER: '/enterprise-voucher/enterprise-release',
  STATISTIC_ENTERPRISE_VOUCHER: '/enterprise-voucher/use-voucher-statistic',
  RELEASE_VOUCHER: '/enterprise-voucher',
  ENTERPRISE_REALEASE_VOUCHER:
    '/enterprise-voucher/release/available-enterprise',
  HISTORY_RELEASE_VOUCHER: '/enterprise-voucher/release',
  VOUCHER_CONFIG: (id: number) =>
    `/enterprise-voucher/enteprise-voucher-config/${id}`,
  UPDATE_CONFIG: (id: number) => `/enterprise-voucher/config/${id}`,
  BAD_REPORT_VOUCHER: '/report',
  DETAIL_BAD_REPORT_VOUCHER: (id: string) => `/report/${id}`,
  VOUCHER_TRANSFER_STATISTICS: '/enterprise-voucher/transfer-statistic',
  WITHDRAW_VOUCHER: '/enterprise-voucher/withdraw-voucher',
  RELEASE_AS_GIFT: '/enterprise-voucher/release-as-gift',
  //partner-voucher
  PARTNER_VOUCHER_STATISTICS: '/partner-voucher/statistic',
  PARTNER_VOUCHER: '/partner-voucher/release',
  PARTNER_VOUCHER_TYPE: '/partner-voucher/type',
  PARTNER_VOUCHER_TYPE_DETAIL: (id: number) => `/partner-voucher/type/${id}`,
  PARTNER_VOUCHER_TYPE_RELEASE: '/partner-voucher/release',
  EXCHANGE_VOUCHER: '/enterprise-voucher/exchange-history',
  VOUCHER_IMPORT: '/enterprise/voucher-import',
  STATISTIC_USE_ALIAS: '/enterprise-voucher/statistic-use-alias',
  //kiotviet-branches
  KIOTVIET_BRANCHES: '/admin/kiotviet-branches',
  KIOTVIET_LOCAMART_BRANCHES: '/locamart/branches',
  //system config
  BANNER_CONFIG: '/banner',
  LOCACALL_CONFIG: '/locacall/config',
  BANNER_MEET: '/banner/meet-banner',
  DETAIL_BANNER_MEET: (id: number) => `/banner/meet-banner/${id}`,
  RANDOM_DISPLAY_BANNER: '/banner/meet-banner-config',
  //event gift
  EVENT_GIFT: '/gift-event',
  DETAIL_EVENT_GIFT: (id: number) => `/gift-event/${id}`,
  //discount event
  DISCOUNT_EVENT: '/discount-events',
  CONFIRM_DISCOUNT_EVENT: '/discount-events/confirm',
  ENTERPRISE_EVENT: '/discount-events/register-voucher-enterprise',
  APPLY_EVENT: '/discount-events/apply-events',
  CORE_VOUCHER_EVENT: '/discount-events/core-voucher',
  STATISTIC_USE_VOUCHER: '/discount-events/statistic-apply-event',
  STATISTIC_USE_VOUCHER_DETAIL: '/discount-events/user-apply-event',
  //transfer voucher web
  GET_LIST_TRANSFER_WEB: '/enterprise-voucher/transfer-voucher-3rd',
  APRROVER_TRANSFER_WEB: '/enterprise-voucher/confirm-voucher-3rd',
  //gift chart
  GIFT_CHART_GENDER_STATISTICS: '/gift/chart/gender',
  GIFT_CHART_AGE_STATISTICS: '/gift/chart/age',
  GIFT_CHART_PROVINCE_STATISTICS: '/gift/chart/province',
  GIFT_CHART_PICKED_UP_STATISTICS: '/gift/chart/gifts-picked-up',
  //STORE - BRAND
  LIST_BRAND: '/store/list',
  UPDATE_STATUS_STORE: '/store/status',
  DETAIL_STORE: '/store/detail',
  APPROVE_STORE: '/store/approve',
  BRAND: '/store/brand',
  APPROVE_BRAND: '/store/approve-brand',
  //order
  LIST_ORDER: '/order',
  ENTERPRISE_ORDER: '/order/enterprise',
  DETAIL_ORDER: (id: string) => `/order/admin/${id}`,
  //notification
  GROUP_USER: '/user-groups',
  LIST_PROVINCE_USER: '/user-groups/select',
  NOTIFICATION: '/notifications',
  //locacall
  CALL_HISTORY: '/locacall/user-history',
  // link account
  LIST_LINK_ACCOUNT: '/enterprise/account-link',
  UNLINK_ACCOUNT: '/enterprise/unlink-account',

  //product
  LIST_PRODUCTS: '/product/confirms',
  DETAIL_PRODUCT: (id: number) => `/product/${id}`,
  LIST_PRODUCT: '/product',
  PRODUCT_CATEGORY: '/product/category',
  PRODUCT_PROPERTY: '/product/property',
  CREATE_BUNDLE_PRODUCT: '/product/bundle-product-default',
  CREATE_BUNDLE_PRODUCT_UPDATE: (id: number) => `/product/bundle-product/${id}`,
  UPDATE_BUNDLE_PRODUCT: (id: number) =>
    `/product/bundle-product?product_id=${id}`,
  UPDATE_CATEGORY_PRODUCT: (id: number) => `/product/category/${id}`,
  CONFIRM_PRODUCT: '/product/confirms',
};

export { API_PATH, request, privateRequest };
