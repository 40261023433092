import React from 'react';
import { Form, Modal, message } from 'antd';
import styles from './index.less';
import TextArea from 'antd/lib/input/TextArea';
import { useRequest } from 'ahooks';
import { Status, requestChangeStatusGift } from '../../service';
interface IProps {
  children: any;
  id: number;
  onReload: any;
}

export default (props: IProps) => {
  const [form] = Form.useForm();
  const { children, id, onReload } = props;
  const [visible, setVisible] = React.useState(false);
  const onVisible = () => {
    setVisible(!visible);
  };
  const onChangeStatus = useRequest(
    (value: string) => {
      return requestChangeStatusGift(id, Status.TU_CHOI, value);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        onVisible();
        onReload();
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );
  const onSubmit = () => {
    form.submit();
    const value = form.getFieldsValue();
    onChangeStatus.run(value?.reason);
  };
  return (
    <>
      <span onClick={onVisible} className={styles.children}>
        {children}
      </span>
      <Modal open={visible} onCancel={onVisible} onOk={onSubmit} centered>
        <p>Bạn có muốn từ chối quà tặng này</p>
        <Form form={form}>
          <p className={styles.title}>Nhập lý do</p>
          <Form.Item
            name="reason"
            rules={[{ required: true, message: 'Vui lòng nhập lý do' }]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
