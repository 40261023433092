import React from 'react';
import { Form, Input, Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.less';
import { useGetListUserGetGift } from '../../service';
import { useDebounceFn } from 'ahooks';
interface IProps {
  children: any;
  getUser: (data: IListUser[]) => void;
  dataUser: IListUser[];
}
export interface DataType {
  key: React.Key;
  full_name: string;
}
export interface IListUser {
  key?: number;
  id?: number;
  full_name: string;
  user_id: number;
  _id: string;
}
export default (props: IProps) => {
  const [form] = Form.useForm();
  const { children, getUser, dataUser } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<IListUser[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>();
  const onVisible = () => {
    setIsVisible(!isVisible);
  };
  const onSubmit = () => {
    getUser(selectedRow);
    onVisible();
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên user',
      dataIndex: 'full_name',
    },
  ];

  const { listUser, loading, run } = useGetListUserGetGift();
  React.useEffect(() => {
    if (isVisible) {
      run('');
    }
  }, [isVisible]);
  const rowSelection = {
    onChange: (selectedKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRow(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
  };
  const { run: onSearch } = useDebounceFn(
    () => {
      const value = form.getFieldValue('search');
      run(value);
    },
    {
      wait: 1000,
    },
  );
  React.useEffect(() => {
    if (dataUser?.length) {
      setSelectedRowKeys(dataUser.map((item: any) => item._id));
    } else {
      setSelectedRowKeys([]);
    }
  }, [dataUser]);

  return (
    <>
      <span onClick={onVisible} className={styles.title}>
        {children}
      </span>
      <Modal
        open={isVisible}
        onCancel={onVisible}
        onOk={onSubmit}
        centered
        title="Chọn user"
      >
        <Form onFieldsChange={onSearch} form={form}>
          <Form.Item name="search">
            <Input placeholder="Tìm kiếm id/tên/sđt/email user" />
          </Form.Item>
        </Form>
        {selectedRowKeys && (
          <Table
            rowSelection={rowSelection}
            rowKey="_id"
            columns={columns}
            dataSource={listUser?.map((item: IListUser) => {
              return {
                full_name: item.full_name,
                user_id: item.user_id,
                _id: item._id,
                key: item.user_id,
              };
            })}
            pagination={{ pageSize: 5 }}
            loading={loading}
          />
        )}
      </Modal>
    </>
  );
};
