import { useAntdTable, useToggle } from 'ahooks';
import { Button, DatePicker, Form, Input, Table } from 'antd';
import React from 'react';
import { GetReleasedVoucherUrbox } from '../service';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
import styles from '../index.less';
import { PlusOutlined } from '@ant-design/icons';
import ReleaseVoucherModal from './ReleaseVoucherModal';
const ReleasedVoucherUrbox = () => {
  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useToggle(false);
  const { tableProps, search, refresh } = useAntdTable(
    GetReleasedVoucherUrbox,
    {
      form,
    },
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian phát hành',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'Tên voucher',
      dataIndex: 'name',
    },
    {
      title: 'Giá trị voucher',
      align: 'right',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Số lượng voucher',
      align: 'right',
      dataIndex: 'amount',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Người phát hành',
      dataIndex: ['created', 'email'],
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm tên DN, loại Voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setIsOpenModal.toggle()}
      >
        Phát hành voucher
      </Button>
    </div>
  );
  return (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
      {isOpenModal && (
        <ReleaseVoucherModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          refresh={refresh}
        />
      )}
      ;
    </>
  );
};

export default ReleasedVoucherUrbox;
