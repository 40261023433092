import {
  Breadcrumb,
  Form,
  Select,
  Table,
  Input,
  Row,
  Button,
  message,
  Skeleton,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useIntl, history, useRequest } from 'umi';

import styles from './index.less';
import { useAntdTable } from 'ahooks';
import { getTableData, requestChangeStatus } from './service';
import moment from 'moment';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

// const { Option } = Select;

export default () => {
  const [form] = Form.useForm();
  const optionStatus = [
    {
      value: 1,
      label: 'Hoạt động',
    },
    {
      value: 0,
      label: 'Không hoạt động',
    },
  ];
  const optionOrder = [
    {
      label: 'Truy cập tháng lớn nhất',
      value: 'cost_by_month-desc',
    },
    {
      label: 'Truy cập tháng nhỏ nhất',
      value: 'cost_by_month-asc',
    },
    {
      label: 'Tổng truy cập lớn nhất',
      value: 'cost_by_total-desc',
    },
    {
      label: 'Tổng truy cập nhỏ nhất',
      value: 'cost_by_total-asc',
    },
  ];
  const { tableProps, search, params, refresh, loading, error } = useAntdTable(
    getTableData,
    {
      defaultPageSize: 10,
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );
  const onChangeStatus = useRequest(
    (status: boolean, id: number) => {
      return requestChangeStatus(status, id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        refresh();
      },
      onError: () => {
        message.error('err');
      },
    },
  );
  const { type, changeType, submit, reset } = search;
  const { formatMessage } = useIntl();
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên building',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Vị trí NFT',
      dataIndex: 'address_nft',
      key: 'address_nft',
    },
    {
      title: 'Chủ sở hữu',
      dataIndex: 'user_owner_nft',
      key: 'user_owner_nft',
      render: (value, record, index) => {
        return (
          <>
            <p>{value}</p>
          </>
        );
      },
    },
    {
      title: 'Truy cập tháng này',
      dataIndex: 'cost_by_month',
      key: 'cost_by_month',
    },
    {
      title: 'Tổng lượt truy cập',
      dataIndex: 'cost_by_total',
      key: 'cost_by_total',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: any, record: any, index: number) => {
        return <>{moment(record.created_at).format('DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 180,
      render: (value: any, record: any, index: number) => {
        return (
          <Space size={20}>
            <Row className={styles.action}>
              <Tooltip title="Xem">
                <EyeOutlined
                  className="purple-color"
                  onClick={() => history.push(`/loca-building/${record.id}`)}
                />
              </Tooltip>

              <Tooltip title="Sửa">
                <EditOutlined
                  className="primary-color"
                  onClick={() =>
                    history.push(`/update-loca-building/${record.id}`)
                  }
                />
              </Tooltip>
            </Row>
            <Switch
              defaultChecked={record.status}
              onChange={(value: boolean) =>
                onChangeStatus.run(value, record.id)
              }
            />
          </Space>
        );
      },
    },
  ];
  const onOrder = () => {
    const order = form.getFieldValue('order')?.split('-');
    form.setFieldValue('sort_key', order?.[0]);
    form.setFieldValue('sort_dir', order?.[1]);
    submit();
  };

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        autoComplete="off"
        className={styles.searchForm}
        layout="inline"
      >
        <Form.Item hidden name="sort_key"></Form.Item>
        <Form.Item hidden name="sort_dir"></Form.Item>
        <Form.Item name="search_text" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm ID NFT, vị trí building, tên building"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            options={optionStatus}
            placeholder="Trạng thái"
            onChange={submit}
            allowClear
          />
        </Form.Item>
        <Form.Item name="order" className={styles.searchItem}>
          <Select
            options={optionOrder}
            placeholder="Sắp xếp theo"
            onChange={onOrder}
            allowClear
          />
        </Form.Item>
      </Form>
      <Button onClick={() => history.push('/create-building')}>
        Tạo LocaBuilding
      </Button>
    </div>
  );
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Danh sách LocaBuilding</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            rowKey="id"
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )}
      </div>
    </>
  );
};
