import { privateRequest, request, API_PATH } from '@/utils/apis';
import moment from 'moment';
import {
  AverageInvoice,
  BusinessScale,
  BusinessType,
  CustomerBizPerDay,
  EnterpriseType,
} from '@/utils/enum';
import { generateBodyGraphql } from '@/utils/apis/common';

interface Result {
  total: number;
  list: any[];
}

export interface BranchPartner {
  address: string;
  name: string;
  lat: number;
  long: number;
}
export interface UpdateConfigInput {
  type: COREVOUCHER_TYPE;
  maxApplyPercent?: number;
  maxApplyValue?: number;
  maxUseCount?: number;
  minValueToApply?: number;
  limitUseDvoucher?: number;
}

export interface UpdateEnterpriseInput {
  enterprise_id: number;
  address?: string;
  email?: string;
  name?: string;
  new_password?: string;
  phone?: string;
  status?: boolean;
  register_create_gift?: boolean;
  register_pickup_gift_location?: boolean;
  register_release_voucher?: boolean;
  is_locacafe?: boolean;
  logo?: string;
  description?: string;
  website_url?: string;
  online_url?: string;
  link_location?: number;
  link_location_name?: string;
  kiotviet_id?: number;
  kiotviet_name?: string;
  lat?: number;
  long?: number;
  brand_name?: string;
  tax_code?: string;
  business_type?: BusinessType;
  scale?: BusinessScale;
  average_invoice?: AverageInvoice;
  limit_to_give_lb?: number;
  abaha_id?: string;
  bank?: string;
  bank_account_name?: string;
  bank_account_number?: string;
  owner_name?: string;
  enterprise_image?: string;
  license_image?: string;
  identity_front?: string;
  identity_backside?: string;
  passport_image?: string;
  bill_template?: string;
  qr_transfer?: string;
  type?: EnterpriseType;
  can_use_qr_alias?: boolean;
}

export type COREVOUCHER_TYPE = 'DVOUCHER' | 'CVOUCHER';

export const getShopDetail = (shop_id: any) => {
  const query = `
  mutation {
    admin_get_enterprise_detail(enterprise_id: ${shop_id}) {
      enterprise_id
      name
      email
      phone
      address
      created_at
      status
      turns
      lat
      long
      enterprise_image
      license_image
      identity_backside
      identity_front
      passport_image
      brand_name
      tax_code
      business_type
      scale
      average_invoice
      bill_template
      bank
      bank_account_name
      bank_account_number
      owner_name
      verified
      verify_status
			limit_to_give_lb
			register_create_gift
      register_pickup_gift_location
      register_release_voucher
			qr_transfer
			is_locacafe
			abaha_id
			link_location
			link_location_name
			kiotviet_id
  		kiotviet_name
			is_not_use_voucher
			can_withdraw
			description
			website_url
			online_url
			logo
			partner_id
			type
			can_use_qr_alias
			branches {
				name
				address
				lat
				long
			}
			province_id
    }
  }

`;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const getDataPickupHistory = (userId: any) => {
  const query = `
  mutation {
    getItemPickUpHistory(userId: ${userId}) {
      receive_time
      gift_pieceId
      name
      receive_at
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

interface Result {
  total: number;
  list: any[];
}

export const getCustomerHistory = (
  {
    current,
    pageSize,
    enterprise_id,
  }: { current: number; pageSize: number; enterprise_id: number },
  formData: {
    fullName: string;
    biz_per_day?: CustomerBizPerDay;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  const data = {
    query: `mutation {
      admin_get_enterprise_customer(
        input: { 
					page_index: ${current - 1},
					page_size: ${pageSize},
					search_text: "${formData.fullName}",
					${
            formData.biz_per_day === CustomerBizPerDay.GT_10
              ? `biz_per_day_gt: 10,
						biz_per_day_lt: 9999
						`
              : formData.biz_per_day === CustomerBizPerDay.LT_10
              ? `biz_per_day_gt: 0,
						biz_per_day_lt: 10`
              : ''
          }
			}
        enterprise_id: ${enterprise_id}
      ) {
        total
        historys {
          name
          phone
          email
          accrual_spending
          temporary_spending
          checkin_count
          get_point_count
          total_get_point
					requestCount
        }
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.admin_get_enterprise_customer?.historys.map(
        (e: any, index: any) => ({
          ...e,
          stt: index + 1,
        }),
      );
      return {
        total: res?.data?.admin_get_enterprise_customer?.total,
        list: result,
      };
    },
  );
};

export const updateBusiness = (input: UpdateEnterpriseInput) => {
  const data = {
    query: `mutation {
			admin_update_enterprise(input: { ${generateBodyGraphql(input, [
        'business_type',
        'scale',
        'average_invoice',
      ])} }) {
				message
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const updateBranchPartner = (id: number, input: BranchPartner[]) => {
  const data = {
    query: `mutation {
			admin_update_enterprise(input: {
				enterprise_id: ${id},
				branches: [${input.map(
          (item) => `{
					name: "${item.name}",
          address: "${item.address}",
          lat: ${item.lat},
          long: ${item.long}
				},`,
        )}]
			}) {
				message
			}
		}`,
  };

  return privateRequest(request.post, API_PATH.default, { data });
};

export const getLocaBonus = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: any,
  ...rest: any
): Promise<Result> => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).toISOString()
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).toISOString()
    : '';

  const data = {
    query: `
  mutation {
    enterprise_give_bonus_history(input: {
      page_size: ${pageSize}
      page_index: ${current}
      start_time:"${fromDate}",
      end_time:"${toDate}",
      enterprise_id: ${rest ? rest[0].allFormData?.id : 0}
    }) {
      turns
      total
			package_can_select
      history {
        created_at
        description
        turn
        point
        package
      }
    }
  }
  `,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.enterprise_give_bonus_history?.history.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.enterprise_give_bonus_history?.total,
        list: result,
        turns: res?.data?.enterprise_give_bonus_history?.turns,
        package_can_select:
          res?.data?.enterprise_give_bonus_history?.package_can_select,
      };
    },
  );
};

export const getLocaBonusHistory = (
  {
    current,
    pageSize,
    enterprise_id,
  }: { current: number; pageSize: number; enterprise_id: number },
  formData: any,
): Promise<Result> => {
  if (!formData.fullName) {
    formData.fullName = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).toISOString()
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).toISOString()
    : '';

  const data = {
    query: `mutation {
      admin_get_enterprise_history(
        input: { 
          page_size:  ${pageSize || 10}
          page_index: ${current ? current - 1 : 0}
           ${formData.status ? 'type:' + formData.status : ''} 
          start_time: "${fromDate}"
          end_time: "${toDate}"
          name: "${formData.fullName}"
         }
        enterprise_id: ${enterprise_id}
      ) {
        total
        total_customer_amt
        turns
        total_enterprise_amt
        data {
          created_at
          staff_name
          receive_customer
          customer_amt
          enterprise_amt
          spending
          type
          rating
          bill_path
        }
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      // console.log(res?.data?.get_list_admin.data);

      const result = res?.data?.admin_get_enterprise_history.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
          staff_name: e.staff_name,
          created_at: e.created_at,
        }),
      );
      return {
        total: res?.data?.admin_get_enterprise_history?.total,
        total_customer_amt:
          res?.data?.admin_get_enterprise_history?.total_customer_amt,
        total_enterprise_amt:
          res?.data?.admin_get_enterprise_history?.total_enterprise_amt,
        turns: res?.data?.admin_get_enterprise_history?.turns,
        list: result,
      };
    },
  );
};

export const increasePoint = (data: any) => {
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const resName = (code: string) => {
  const data = {
    query: `mutation {
      enterprise_get_invite_name(input: {invite_code: "${code}"}) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const enterpriseUpdateInviter = (input: {
  enterprise_id: number;
  code: string;
}) => {
  const data = {
    query: `mutation {
      update_enterprise_inviter(input: {enterprise_id: ${input.enterprise_id}, code: "${input.code}"}) {
        message
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const adminVerifyEnterprise = (input: {
  status: boolean;
  reason?: string;
  enterprise_id: number;
}) => {
  const data = {
    query: `mutation {
      verify_enterprise(input: {status: ${input.status}, ${
      input.reason ? `reason: "${input.reason}"` : ''
    } , enterprise_id: ${input.enterprise_id}}) {
        message
      }
    }`,
  };

  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

interface IUpdateListkey {
  enterpriseId: number;
  listKey: string[];
  imageUrl: string;
}

export const adminUpdateListkeyInvoice = (input: IUpdateListkey) => {
  return privateRequest(request.put, API_PATH.UPDATE_LISTKEY_INVOICE, {
    data: input,
  });
};

export const adminGetListkeyInvoice = ({
  enterpriseId,
  type,
}: {
  enterpriseId: number;
  type: string;
}) => {
  return privateRequest(
    request.get,
    API_PATH.GET_ENTERPRISE_INVOICE(enterpriseId) + '?' + `type=${type}`,
  );
};

//config voucher
export const ConfigVoucher = ({
  id,
  type,
}: {
  id: number;
  type: COREVOUCHER_TYPE;
}) => {
  return privateRequest(request.get, API_PATH.VOUCHER_CONFIG(id), {
    params: { type },
  });
};

export const UpdateConfigVoucher = (id: number, input: UpdateConfigInput) => {
  return privateRequest(request.put, API_PATH.UPDATE_CONFIG(id), {
    data: input,
  });
};

export const WithdrawVoucherEnterprise = (enterpriseId: number) => {
  return privateRequest(request.post, API_PATH.WITHDRAW_VOUCHER, {
    data: {
      enterprise_id: enterpriseId,
    },
  });
};

export const listImportVoucherPartner = ({
  current,
  pageSize,
  partner_id,
  is_offline,
}: {
  current: number;
  pageSize: number;
  partner_id?: number;
  is_offline?: string;
}) => {
  return privateRequest(request.get, API_PATH.VOUCHER_IMPORT, {
    params: {
      partner_id,
      is_offline,
    },
  }).then((response) => {
    console.log(response);

    return {
      list: response?.available_voucher?.map((item: any, index: number) => ({
        ...item,
        stt: index + 1,
      })),
      total: response?.available_voucher?.length,
    };
  });
};

export const statisticUseAlias = (
  {
    current,
    pageSize,
    enterprise_id,
  }: { current: number; pageSize: number; enterprise_id: number },
  formData: {
    search?: string;
    date?: string[];
  },
) => {
  const params: {
    page_size: number;
    page_index: number;
    enterprise_id: number;
    search_value?: string;
    start_time?: string;
    end_time?: string;
  } = {
    page_index: current,
    page_size: pageSize,
    enterprise_id,
  };
  if (!!formData.search) {
    params.search_value = formData.search?.trim();
  }
  const dateFrom = Array.isArray(formData.date)
    ? moment(formData.date[0]).toISOString()
    : '';
  const dateTo = Array.isArray(formData.date)
    ? moment(formData.date[1]).toISOString()
    : '';
  if (!!dateFrom && !!dateTo) {
    params.start_time = dateFrom;
    params.end_time = dateTo;
  }
  return privateRequest(request.get, API_PATH.STATISTIC_USE_ALIAS, {
    params,
  }).then((response) => {
    return {
      list: response?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + index + 1,
      })),
      total: response?.data?.total,
    };
  });
};

export const updateStatusStore = (store_id: number) => {
  return privateRequest(
    request.put,
    API_PATH.UPDATE_STATUS_STORE + '/' + store_id,
  );
};
