import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS, ROLE_TYPE, fullKeyRole } from '@/utils/constant';

interface Result {
  total: number;
  list: any[];
}
//@ts-ignore

const list_permission = Object.keys(ROLE_TYPE).map((key) => ROLE_TYPE[key]);
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: Object,
): Promise<Result> => {
  let query = `page=${current}&pageSize=${pageSize}`;
  Object.entries(formData).forEach(([key, value]) => {
    if (value) {
      query += `&${key}=${value}`;
    }
  });

  return privateRequest(request.get, API_PATH.ADMIN_USER + '?' + query).then(
    (res: any) => {
      const data = res?.data?.map((e: any, index: any) => ({
        ...e,
        stt: (res?.page - 1) * res?.pageSize + index + 1,
      }));
      return {
        total: res?.total,
        list: data,
      };
    },
  );
};

export const createDataRole = (role_data: any) => {
  const infoRole = role_data.permissions.reduce((k: any, v: any) => {
    const { key, ...value } = v;
    k[key] = value;
    return k;
  }, {});

  const data = {
    query: `
    mutation {
      create_role(input: { 
        role_name: "${role_data.role_name}", 
        is_active: true,
        permissions: {
					${list_permission
            .map(
              (item) =>
                `${item} :${infoRole[item] ? infoRole[item].value : 'NONE'}`,
            )
            .join(',')}
        } 
      }) {
        id
        role_name
        is_active
        permissions {
          id
					${fullKeyRole()}
        }
        date_modified
      }
    }
    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const editDataRole = (role_data: any) => {
  const infoRole = role_data.permissions.reduce((k: any, v: any) => {
    const { key, ...value } = v;
    k[key] = value;
    return k;
  }, {});

  const data = {
    query: `
    mutation {
      update_role(input: { 
        role_name: "${role_data.role_name}", 
        id:"${role_data.id}",
        is_active: true,
        permissions: {
					${list_permission
            .map(
              (item) =>
                `${item} :${infoRole[item] ? infoRole[item].value : 'NONE'}`,
            )
            .join(',')}
        } 
      }) {
        id
        role_name
        is_active
        permissions {
          id
					${fullKeyRole()}
        }
        date_modified
      }
    }
    
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getRole = (id: string) => {
  const data = {
    query: `
      {
        find_role(id: "${id}") {
          id
          role_name
          is_active
          permissions {
            id
						${fullKeyRole()}
          }
        }
      }
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};
