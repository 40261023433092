import React from 'react';
import { Modal, message } from 'antd';
import styles from './index.less';
import { useRequest } from 'ahooks';
import { Status, requestChangeStatusGift } from '../../service';
interface IProps {
  children: any;
  onReload: any;
  id: number;
}
export default (props: IProps) => {
  const { children, id, onReload } = props;
  const [visible, setVisible] = React.useState(false);
  const [loadings, setLoadings] = React.useState<boolean>(false);
  const onVisible = () => {
    setVisible(!visible);
  };
  const onChangeStatus = useRequest(
    () => {
      return requestChangeStatusGift(id, Status.DUYET, '');
    },
    {
      manual: true,
      onSuccess: (data) => {
        setLoadings(false);
        message.success('Thành công');
        onVisible();
        onReload();
      },
      onError: (err: any) => {
        setLoadings(false);
        message.error(err?.data?.message);
      },
    },
  );
  const onSubmit = () => {
    setLoadings(true);
    onChangeStatus.run();
  };

  return (
    <>
      <span onClick={onVisible} className={styles.children}>
        {children}
      </span>
      <Modal
        open={visible}
        onCancel={onVisible}
        onOk={onSubmit}
        centered
        confirmLoading={loadings}
      >
        <p>Bạn có chắc chắn muốn phê duyệt quà tặng này không?</p>
      </Modal>
    </>
  );
};
