import { Form, Input, message, Modal, Select, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useRequest } from 'umi';
import { createVersion } from '../service';
import styles from '../index.less';

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit?: any;
  refreshData: () => void;
  children?: any;
  app: string;
}

const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  children,
  refreshData,
  app,
  ...rest
}) => {
  const [form] = Form.useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [type, setType] = useState('IOS');

  const createVersionRequest = useRequest(createVersion, {
    manual: true,
    onSuccess: (data) => {
      if (data?.errors) {
        message.error('Không có quyền');
      } else {
        message.success('Thành công');
        refreshData();
      }
      setOpen(false);
    },
    onError(e) {},
    formatResult: (res) => res,
  });
  const handleOk = (e: any) => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleChange = (values: any) => {
    setType(values);
  };
  const onFinish = (value: any) => {
    const data = {
      query: `
      mutation {
        createVersion(createVersionInput: { 
          type: ${type},
          version:"${value.input}"
          status: ${value.statusVersion || false},
        }) {
          version
          status
          created_at
          status
          type
        }
      }
      `,
    };
    const dataLocaBiz = {
      query: `
      mutation {
        createLocaBizVersion(input: { 
          type: ${type},
          version:"${value.input}"
          status: ${value.statusVersion || false},
        }) {
          version
          status
          created_at
          status
          type
        }
      }
      `,
    };
    if (app === 'LocaMeet') {
      createVersionRequest.run(data);
    } else {
      createVersionRequest.run(dataLocaBiz);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        title={`Cập nhật phiên bản ${app}`}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={(value) => {
            onFinish(value);
          }}
          autoComplete="off"
        >
          <div className={styles.boxInput}>
            <Form.Item>
              <Select
                onChange={handleChange}
                defaultValue={type}
                style={{ width: 100, marginRight: '10px' }}
                options={[
                  {
                    value: 'IOS',
                    label: 'IOS',
                  },
                  {
                    value: 'ANDROID',
                    label: 'ANDROID',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="input"
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                },
              ]}
            >
              <Input placeholder="Tên phiên bản" />
            </Form.Item>
          </div>
          <div className={styles.boxObligatoryUpdate}>
            <Form.Item name="statusVersion" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <p className={styles.txtObligatoryUpdate}>Bắt buộc cập nhật</p>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
