import { Tabs } from 'antd';
import React from 'react';
import { useHistory } from 'umi';
import styles from './index.less';
import NotificationUser from './NotificationUser';
import GroupUser from './GroupUser';
const NotificationManagement = () => {
  const history = useHistory();
  const renderTab = () => {
    switch (history.location.pathname) {
      case '/notification':
        return <NotificationUser />;
      case '/group_user':
        return <GroupUser />;
    }
  };
  return (
    <>
      <Tabs
        onChange={(key: string) => {
          history.push(key);
        }}
        defaultActiveKey={history.location.pathname.replace('/', '')}
        className={styles.tabs}
      >
        <Tabs.TabPane
          tab="Danh sách thông báo"
          key="notification"
        ></Tabs.TabPane>
        <Tabs.TabPane tab="Danh sách nhóm user" key="group_user"></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </>
  );
};

export default NotificationManagement;
