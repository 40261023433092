import { useTranslate } from '@/utils/hooks/useTranslate';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  SelectProps,
} from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { useEffect, useState } from 'react';
import { history, useParams, useRequest } from 'umi';
import { increasePoint } from '../../service';
import style from '../../index.less';
import { MEMBERSHIP_PACKAGE } from '@/utils/constant';
const { Option } = Select;

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit: any;
  getData: any;
  canSelect: any[];
}

const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  // children,
  getData,
  canSelect,
  ...rest
}) => {
  const { t } = useTranslate();

  const point = itemEdit;
  // const [disabled, setDisabled] = useState(false);
  // useEffect(() => {
  //   if (point > 0) {
  //     setDisabled(!disabled)
  //   }
  // }, [point])

  const [size, setSize] = useState<SizeType>('middle');

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [form] = Form.useForm();

  const params = useParams<{ id: string }>();
  const { id } = params;
  const increasePointRequest = useRequest(increasePoint, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else if (res?.errors[0]?.extensions?.code === 'BAD_USER_INPUT') {
          message.error('Chỉ được chọn gói Membership đang sử dụng');
        } else {
          message.error('Có lỗi xảy ra');
        }
        setOpen(false);
      } else {
        if (res?.data?.increase_enterprise_giveaway_turn?.increase_turn > 0) {
          message.success('Thêm lượt tặng thành công');
        } else {
          message.success('Trừ lượt tặng thành công');
        }
        getData();
        setOpen(false);
      }
    },
    formatResult: (res) => res,
  });
  const handleOk = async () => {};

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = (value: any) => {
    const data = {
      query: `
     mutation {
      increase_enterprise_giveaway_turn(
    input: { enterprise_id: ${id}, turn: ${value.locabonus}, package: "${value.member}",description:"" }
  ) {
    data {
      enterprise_id
      name
      email
      phone
      address
      created_at
      status
      turns
    }
    increase_turn
    description
    message
  }
}

    `,
    };
    increasePointRequest.run(data);
  };

  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        title="Thêm lượt tặng"
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Gói lượt tặng"
            name="member"
            rules={[
              {
                required: true,
                message: t('error.require', {
                  field: t('membership'),
                }),
              },
            ]}
            initialValue={'GOLD'}
          >
            <Select
              placeholder="Chọn gói"
              size={size}
              options={MEMBERSHIP_PACKAGE.map((pkg) => ({
                label: pkg.name,
                value: pkg.value,
                disabled: !canSelect.includes(pkg.value),
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Số lượt tặng"
            name="locabonus"
            rules={[
              {
                required: true,
                message: t('error.require', {
                  field: t('locabonus'),
                }),
              },
              // {
              //   pattern: /[0-9]/,
              //   message: 'Số lượt tặng là chữ số dương',
              // },
            ]}
          >
            <InputNumber
              placeholder="Nhập số lượt tặng"
              style={{
                width: '60%',
              }}
            />
          </Form.Item>
          <Row justify="end">
            <Button
              className={style.btnSubmitLcb}
              danger
              ghost
              type="primary"
              onClick={handleCancel}
            >
              Hủy
            </Button>
            <Button
              className={style.btnSubmitLcb}
              loading={increasePointRequest.loading}
              htmlType="submit"
              type="primary"
            >
              Xác nhận
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
