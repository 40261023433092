import React from 'react';
import styles from '../index.less';
import { DatePicker, Form, Input, Table } from 'antd';
import { useAntdTable } from 'ahooks';
import { GetHistoryVoucherUrbox } from '../service';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
const HistoryVoucherUrbox = () => {
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(GetHistoryVoucherUrbox, {
    form,
  });
  const { submit } = search;

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian đổi',
      dataIndex: 'buy_at',
      align: 'right',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'ID User',
      dataIndex: 'buyer',
      align: 'right',
    },
    {
      title: 'Tên User',
      dataIndex: 'full_name',
    },
    {
      title: 'Tên voucher',
      dataIndex: 'voucherName',
    },
    {
      title: 'Giá trị voucher',
      dataIndex: 'price',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Số lượng voucher đổi',
      dataIndex: 'quantity',
      align: 'right',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Nguồn',
      dataIndex: 'source',
      render: (value) => <>{value === 'GIFT' ? 'Nhặt quà' : 'Đổi voucher'}</>,
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo ID/tên người đổi voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.ref_id}
        />
      </div>
      ;
    </>
  );
};

export default HistoryVoucherUrbox;
