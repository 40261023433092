import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useIntl, useRequest } from 'umi';
import { getChildren } from '../service';

const Expanded = ({ id, fLevel }: { id: any; fLevel: number }) => {
  const columns: ColumnsType<any> = [
    // {
    //   title: '',
    //   width: 100,
    //   key: 'userId',
    // },
    {
      title: 'Cấp F',
      dataIndex: 'fLevel',
      key: 'fLevel',
      render: (value: any, record: any, index: number) => {
        return <>F{fLevel}</>;
      },
    },
    {
      title: 'Mã',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Họ tên',
      dataIndex: ['user_profile', 'full_name'],
      key: 'user_profile.full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ngày tham gia',
      dataIndex: 'date_created',
      key: 'date_created',
    },
  ];
  const children = useRequest(getChildren, {
    manual: true,
    onSuccess: (res) => {
      // refactoryData(res?.children, 1);
      // setTableData(res?.children);
    },
    formatResult: (res) => res,
  });

  const { formatMessage } = useIntl();
  useEffect(() => {
    children.run(id);
  }, [id]);
  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        loading={children.loading}
        showHeader={children.data?.children?.length > 0 ? true : false}
        rowKey={(record) => record.id}
        // locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
        // scroll={{ x: 1000 }}
        dataSource={children.data?.children}
        expandable={{
          expandedRowRender: (rc: any) => (
            <Expanded id={rc?.id} fLevel={fLevel + 1} />
          ),
        }}
        pagination={false}
        // {...tableProps}
      />
    </>
  );
};

export default Expanded;
