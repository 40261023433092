import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import React from 'react';
import { useIntl } from 'umi';
import styles from '../index.less';
const { Option } = Select;

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  onConfirm: (reason: string) => void;
  itemEdit: any;
  loading: boolean;
}
const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  onConfirm,
  loading,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Modal
        width={600}
        onCancel={() => setOpen(false)}
        visible={open}
        footer={null}
        title="Lý do từ chối yêu cầu xác thực:"
      >
        <Form
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          onFinish={(value) => {
            onConfirm(value.reason);
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="reason"
                label={formatMessage({ id: 'reason' })}
                // initialValue={userInfo.fullName}
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'error.require' },
                      {
                        field: formatMessage({ id: 'reason' }),
                      },
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={formatMessage({ id: 'reason' })}
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.addGroupButton}>
            {/* <Button className={styles.addButton}>Thêm mới</Button> */}
            <Button
              danger
              onClick={() => setOpen(false)}
              className={styles.addButton}
            >
              {formatMessage({ id: 'general_cancel' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.addButton}
              loading={loading}
            >
              {formatMessage({ id: 'general_accept' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
