import { Breadcrumb, Tabs } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'umi';
import LocaBizVersion from './Components/LocaBizVersion';
import LocaMeetVersion from './Components/LocaMeetVersion';
import styles from './index.less';

const ListVersion = () => {
  const { formatMessage } = useIntl();
  const [activeTabs, setActiveTabs] = React.useState(1);

  const renderTable = () => {
    switch (activeTabs) {
      case 1:
        return <LocaMeetVersion />;
      case 2:
        return <LocaBizVersion />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>{formatMessage({ id: 'version' })}</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => {
          setActiveTabs(parseInt(key));
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="LocaMeet" key="1"></Tabs.TabPane>
        <Tabs.TabPane tab="LocaBiz" key="2"></Tabs.TabPane>
      </Tabs>
      {renderTable()}
    </>
  );
};

export default ListVersion;
