import { useAntdTable } from 'ahooks';
import React from 'react';
import { PRODUCT_STATUS, getListProduct } from './service';
import {
  Breadcrumb,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip,
  Table,
} from 'antd';
import styles from './index.less';
import { useIntl } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import { blankImageUrl } from '@/utils/constant';
import { formatNumber } from '@/utils/apis/common';
import { history } from 'umi';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
export const renderProductStatus = (status: string) => {
  switch (status) {
    case 'VERIFIED':
      return (
        <Tag color="green" icon={<CheckOutlined />}>
          Đã duyệt
        </Tag>
      );
    case 'PENDING':
      return (
        <Tag color="warning" icon={<ClockCircleOutlined />}>
          Chờ duyệt
        </Tag>
      );
    case 'DENIED':
      return (
        <>
          <Tag color="red" icon={<CloseOutlined />}>
            Từ chối
          </Tag>
        </>
      );
    default:
      return (
        <Tag color="gray" icon={<ClockCircleOutlined />}>
          Chưa xác thực
        </Tag>
      );
  }
};
const ProductManagement = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search } = useAntdTable(getListProduct, {
    form,
  });
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên sản phẩm và tên DN"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          // initialValue={StatusKyc.PENDING}
        >
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái xác thực"
            options={PRODUCT_STATUS}
          ></Select>
        </Form.Item>
      </Form>
    </div>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      width: '10%',
      dataIndex: 'stt',
    },
    {
      title: 'Tên doanh nghiệp',
      dataIndex: 'enterprise_name',
    },
    {
      title: 'Mã SKU',
      dataIndex: 'sku_code',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      render: (value) => (
        <>
          <Image src={value} fallback={blankImageUrl} width={80} height={80} />
        </>
      ),
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Người duyệt',
      dataIndex: 'approved_by',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => <>{renderProductStatus(value)}</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '10%',

      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Xem">
              <EyeOutlined
                className="primary-color cursor-pointer"
                onClick={() => history.push(`/products/${record?.id}`)}
              />
            </Tooltip>
            <Tooltip title="Trạng thái">
              <Switch />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({
            id: 'product_management',
          })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default ProductManagement;
