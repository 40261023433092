import { DeleteOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Form, Image, Input, Modal, Table, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo } from 'react';
import { listFaceSimilar, removeFaceSimilar } from '../service';
interface Iprops {
  id: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const SameFaceDialog = ({ id, isOpen, setIsOpen }: Iprops) => {
  const onCancel = () => {
    setIsOpen(false);
  };
  const removeFace = useRequest(removeFaceSimilar, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      if (res?.data) {
        message.success('Xóa thành công');
      } else {
        message.error('Có lỗi xảy ra');
      }
      listFace.refresh();
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Ảnh',
      dataIndex: 'url',
      render: (value) => (
        <>
          <Image
            src={value}
            // alt="face"
            style={{
              width: '80px',
              height: '80px',
            }}
            fallback="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          />
        </>
      ),
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (value, rc) => (
        <>
          <Tooltip title="Xóa ảnh">
            <DeleteOutlined
              className="red-color"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleRemoveFace(rc?.face_id, rc?.url)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const listFace = useRequest(listFaceSimilar, {
    manual: true,
  });
  const [formRemove] = Form.useForm();
  const handleRemoveFace = (face_id: string, url: string) => {
    const onFinish = (val: any) => {
      removeFace.run({
        face_id,
        url,
        user_id: `${id}`,
        reason: val.reason,
      });
    };
    return Modal.confirm({
      title: 'Xóa ảnh khuôn mặt',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa ảnh này khỏi kho khuôn mặt?</p>
          <Form
            form={formRemove}
            id="formRemove"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="reason"
              label="Lý do"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập',
                },
              ]}
            >
              <Input placeholder="Nhập lý do" />
            </Form.Item>
          </Form>
        </>
      ),
      onCancel() {
        formRemove.resetFields();
      },
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRemove',
        loading: removeFace.loading,
      },
      onOk() {
        if (formRemove.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  useEffect(() => {
    listFace.run(id);
  }, [id]);

  return (
    <Modal
      width={500}
      title="Ảnh có khuôn mặt giống"
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Table
        loading={listFace.loading}
        dataSource={listFace.data?.data?.list_face?.map(
          (item: any, i: number) => ({ ...item, stt: i + 1 }),
        )}
        columns={columns}
        rowKey="face_id"
      />
    </Modal>
  );
};

export default SameFaceDialog;
