import { privateRequest, request, API_PATH } from '@/utils/apis';
import { fullKeyRole } from '@/utils/constant';

export const onSubmitValue = (data: any) => {
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getAdminAccount = (id: any) => {
  const query = `
  mutation {
    get_one_admin(input: { id: "${id}" }) {
      message
      data {
        email
        phone
        id
        status
        admin_profile {
          id
          full_name
          date_of_birth
          address
          date_modified
          date_created
          admin_id
          avatar
        } 
        role {
          id
          role_name
          is_active
          permissions {
            id
            admin_management_level
            role_group_level
            user_management_level
            kyc_management_level
            gift_management_level
            gift_recive_management_level
            enterprise_management_level
            item_management_level
            report_management_level
            system_setting_level
            total_locabonus_level
            handle_dispute_level
            version_management_level
            locabonus_transfer_management_level
            company_management_level
            statistic_release_locabonus_level
            blacklist_device_management
            withdraw_locabonus
						gift_level
						request_customer_management_level
            voucher_urbox_management_level
						config_user_receive_gift
						bad_report_management_level
						building_template_management_level
						building_management_level
						building_shop_management_level
						building_banner_management_level
						discount_event_management_level
          }
        } 
      }
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const getAllRole = () => {
  const query = `
    mutation {
      get_list_role(input: { page_size: 99999, page_index: 0, search_text: "", is_active: true}) {
        total
        data {
          id
          role_name
          is_active
          date_modified
          permissions {
            id
						${fullKeyRole()}
          }
        }
      }
    }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
