import React from 'react';
import styles from '../index.less';
import {
  Form,
  Input,
  Select,
  Tag,
  Tooltip,
  Table,
  DatePicker,
  message,
  Modal,
} from 'antd';
import { STATUS_VOUCHER_DISPUTE } from '@/utils/constant';
import { useIntl, useRequest } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import { StatusDespute, StatusGiftDispute } from '@/utils/enum';
import { EyeOutlined, RedoOutlined, RollbackOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { TRANSFER_TYPE, getDisputeGiftData } from '../service';
import { handleDisputeTransferGift } from '@/pages/DisputeInfo/service';
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { RangePicker } = DatePicker;
interface DataType {
  disputeAt: string;
  id: string;
  requestUserName: string;
  receiverName: string;
  giftName: string;
  status: boolean;
}
const DisputeVoucher = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { tableProps, search, refresh } = useAntdTable(getDisputeGiftData, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        type: TRANSFER_TYPE.EVOUCHER,
      },
      {},
    ],
    form,
  });
  const handleDisputeGift = useRequest(handleDisputeTransferGift, {
    manual: true,
    onSuccess(res) {
      if (res.errors) {
        message.error('Có lỗi xảy ra');
      } else {
        message.success('Thành công');
        refresh();
      }
    },
  });

  const onDisputeGift = (id: number, handle: boolean) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn ${
        handle ? 'xác nhận chuyển voucher' : 'hoàn lại voucher'
      } không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleDisputeGift.run(id, handle);
      },
    });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: '8%',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian phát sinh',
      align: 'right',
      dataIndex: 'disputeAt',
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'id',
    },
    {
      title: 'Người chuyển voucher',
      dataIndex: 'requestUserName',
    },
    {
      title: 'Người nhận voucher',
      dataIndex: 'receiverName',
    },
    {
      title: 'Tên voucher',
      dataIndex: 'giftName',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: any, record: any) => {
        const status = STATUS_VOUCHER_DISPUTE.find(
          (item) => item.value === record.status,
        );
        return (
          <>
            <Tag
              color={
                record?.status === StatusGiftDispute.DISPUTE
                  ? 'warning'
                  : record?.status === StatusGiftDispute.HANDLE_BACK
                  ? 'purple'
                  : 'green'
              }
            >
              {status?.name}
            </Tag>
          </>
        );
      },
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '10%',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.activeButton}>
            {record.status === StatusDespute.DISPUTE && (
              <>
                <Tooltip title={'Hoàn voucher'}>
                  <div style={{ cursor: 'pointer' }}>
                    <RollbackOutlined
                      className="red-color"
                      onClick={() => onDisputeGift(record?.id, false)}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={'Chuyển voucher'}>
                  <div style={{ cursor: 'pointer' }}>
                    <RedoOutlined
                      className="primary-color"
                      onClick={() => onDisputeGift(record?.id, true)}
                    />
                  </div>
                </Tooltip>
              </>
            )}

            <Tooltip title={formatMessage({ id: 'general_view_infomation' })}>
              <a
                href={`/dispute_voucher_detail/${record?.id}`}
                style={{ cursor: 'pointer' }}
              >
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo người chuyển, người nhận"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="status"
          className={styles.searchItem}
          initialValue={StatusDespute.DISPUTE}
        >
          <Select onChange={submit} allowClear placeholder="Trạng thái">
            {STATUS_VOUCHER_DISPUTE.map((item, i) => (
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

export default DisputeVoucher;
