import React, { useEffect, useState } from 'react';
import { useRequest } from 'umi';
import { adminGetListkeyInvoice, adminUpdateListkeyInvoice } from '../service';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Tabs,
  Typography,
  message,
  notification,
} from 'antd';
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ENVIRONMENTS } from '@/utils/constant';
import styles from '../index.less';
type BillConfigProps = {
  enterpriseId: number;
  bill_template: string;
  qr_transfer: string;
};
enum TypeInvoiceImage {
  INVOICE = 'INVOICE',
  QR_CODE = 'QR_CODE',
}

const BillConfig = (props: BillConfigProps) => {
  const { enterpriseId, bill_template, qr_transfer } = props;
  const [listkey, setListkey] = useState<string[]>([]);
  const [listKeyOcr, setListKeyOcr] = useState<string[]>([]);
  const [tab, setTab] = useState<TypeInvoiceImage>(TypeInvoiceImage.INVOICE);
  const getBill = useRequest(adminGetListkeyInvoice, {
    manual: true,
    onSuccess(res) {
      // console.log(res);
      if (tab === TypeInvoiceImage.INVOICE) {
        setListkey(JSON.parse(res.list_key));
        setListKeyOcr(JSON.parse(res.list_key_ocr));
      } else {
        setListkey(JSON.parse(res.list_transfer_key));
        setListKeyOcr(JSON.parse(res.list_transfer_ocr));
      }
    },
    formatResult: (res) => res,
  });

  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (enterpriseId) {
      getBill.run({
        enterpriseId: enterpriseId,
        type: tab,
      });
    }
  }, [enterpriseId, tab]);

  const [form] = Form.useForm();

  const updateListkey = useRequest(adminUpdateListkeyInvoice, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status) {
        message.success('Thêm từ khóa thành công');
        getBill.refresh();
        setEdit(false);
      } else {
        const invalidKeys = submitListkey
          .filter((item, i) => {
            if (!res?.keystatus?.[i]) {
              return item;
            }
          })
          .join(', ');
        message.error(`Không phát hiện được từ khóa: [${invalidKeys}]`, 4);
      }
      // if()
    },
    onError: (err: any) => {
      message.error(err?.data?.message || 'Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });

  const [submitListkey, setSubmitListKey] = useState<any[]>([]);

  const handleUpdateListkey = (val: any) => {
    const ocr = [];
    if (!!val.bill_code) {
      if (Array.isArray(val.bill_code)) {
        ocr.push(val.bill_code?.map((item: string) => item.trim()));
      } else {
        ocr.push(val.bill_code?.trim());
      }
    }
    if (!!val.time) {
      ocr.push(val.time?.toString()?.trim());
    }
    const payload = {
      enterpriseId: enterpriseId,
      listKey: val.listKey?.map((item: string) => item?.trim()),
      listKeyOcr: ocr,
      imageUrl:
        ENVIRONMENTS.BUCKET_NAME +
        '/' +
        `${tab === TypeInvoiceImage.INVOICE ? bill_template : qr_transfer}`,
      type: tab,
    };
    setSubmitListKey(payload.listKey);
    updateListkey.run(payload);
  };
  return (
    <div>
      {/* <Row justify="end">
        <Button onClick={() => setEdit(true)}>Sửa</Button>
      </Row> */}
      <Tabs
        className={styles.tabs}
        onChange={(key: any) => {
          setTab(key);
          setEdit(false);
        }}
        defaultActiveKey={tab}
      >
        <Tabs.TabPane tab="Ảnh hóa đơn" key={TypeInvoiceImage.INVOICE} />
        <Tabs.TabPane tab="Ảnh chuyển khoản" key={TypeInvoiceImage.QR_CODE} />
      </Tabs>
      <Row justify="space-around" className={styles.billConfig} wrap>
        <Col flex="1 0">
          <Button icon={<EditOutlined />} onClick={() => setEdit(true)}>
            Sửa
          </Button>
          {edit ? (
            <Form
              onFinish={handleUpdateListkey}
              initialValues={{
                listkey: listkey,
              }}
              labelWrap
              labelCol={{
                span: 4,
              }}
            >
              <Typography>
                <Typography.Text strong>Từ khóa bắt buộc</Typography.Text>
              </Typography>
              <Form.Item
                name="requiredKey"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue('listKey') &&
                        getFieldValue('listKey').length
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Vui lòng thêm từ khóa');
                    },
                  }),
                ]}
              ></Form.Item>
              <Form.List name="listKey" initialValue={listkey}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={(index + 1).toString()}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập từ khóa',
                          },
                        ]}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Vui lòng nhập',
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Từ khóa"
                            className={styles.listKeyInput}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{
                              marginLeft: '10px',
                            }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        className={styles.addListkeyBtn}
                        type="primary"
                        ghost
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Thêm từ khóa
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Typography>
                <Typography.Text strong>Từ khóa cần OCR</Typography.Text>
              </Typography>
              {tab === TypeInvoiceImage.INVOICE ? (
                <Form.Item
                  name="bill_code"
                  label="Mã hóa đơn"
                  labelAlign="left"
                  initialValue={listKeyOcr?.[0]}
                >
                  <Input placeholder="Nhập từ khóa cần OCR" />
                </Form.Item>
              ) : (
                <>
                  <span>Mã giao dịch: </span>
                  <Form.Item
                    name="requiredBillcode"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue('bill_code') &&
                            getFieldValue('bill_code').length
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Vui lòng thêm từ khóa');
                        },
                      }),
                    ]}
                  ></Form.Item>
                  {/* @ts-ignore */}
                  <Form.List name="bill_code" initialValue={listKeyOcr?.[0]}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            label={(index + 1).toString()}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập từ khóa',
                              },
                            ]}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: 'Vui lòng nhập',
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                placeholder="Nhập từ khóa cần OCR"
                                className={styles.listKeyInput}
                              />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                style={{
                                  marginLeft: '10px',
                                }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            className={styles.addListkeyBtn}
                            type="primary"
                            ghost
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Thêm từ khóa
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}

              <Form.Item
                name="time"
                label="Thời gian"
                labelAlign="left"
                initialValue={listKeyOcr?.[1]}
              >
                <Input placeholder="Nhập từ khóa cần OCR" />
              </Form.Item>
              {/* <Form.List name="listKeyOcr" initialValue={listKeyOcr}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={(index + 1).toString()}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Vui lòng nhập',
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Từ khóa"
                            className={styles.listKeyInput}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{
                              marginLeft: '10px',
                            }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        className={styles.addListkeyBtn}
                        type="primary"
                        ghost
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Thêm từ khóa
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List> */}
              <Form.Item>
                <Row>
                  <Button
                    danger
                    onClick={() => setEdit(false)}
                    className="btn-submit"
                  >
                    Hủy
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit"
                    loading={updateListkey.loading}
                  >
                    Lưu
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          ) : (
            <>
              <Typography>
                <Typography.Text strong>Từ khóa bắt buộc</Typography.Text>
              </Typography>
              {listkey?.length > 0 ? (
                listkey?.map((item, index) => (
                  <Input
                    value={item}
                    key={index}
                    disabled
                    style={{ width: '60%', margin: '15px 0' }}
                  />
                ))
              ) : (
                <p>Chưa có từ khóa</p>
              )}
              <Typography>
                <Typography.Text strong>Từ khóa cần OCR</Typography.Text>
              </Typography>

              <>
                <Row>
                  <p>
                    {tab === TypeInvoiceImage.INVOICE
                      ? 'Mã hóa đơn'
                      : 'Mã giao dịch'}
                  </p>
                  <Input disabled value={listKeyOcr?.[0]} />
                </Row>
                <Row>
                  <p>Thời gian</p>
                  <Input disabled value={listKeyOcr?.[1]} />
                </Row>
              </>
            </>
          )}
        </Col>
        <Col
          flex="1 0 auto"
          style={{
            textAlign: 'end',
          }}
        >
          {tab === TypeInvoiceImage.INVOICE && bill_template && (
            <Image
              style={{
                maxWidth: '400px',
                maxHeight: '600px',
              }}
              src={ENVIRONMENTS.BUCKET_NAME + '/' + bill_template}
            />
          )}
          {tab === TypeInvoiceImage.QR_CODE && qr_transfer && (
            <Image
              style={{
                maxWidth: '400px',
                maxHeight: '600px',
              }}
              src={ENVIRONMENTS.BUCKET_NAME + '/' + qr_transfer}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BillConfig;
