import { useEffect, useState } from 'react';

import MapNftComponent from './MapNftComponent';

const MapReaflat = (props: any) => {
  const { listLocation, setPos, loading, mapItemDetail } = props;
  const lang = 'vi';

  const [countryList] = useState<any>([
    {
      short_name: 'VN',
      long_name: 'Vietnam',
      display_name: 'Việt Nam (Vietnam)',
      flag_image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/255px-Flag_of_Vietnam.svg.png',
      position: { lat: '21.02274', lng: '105.836964' },
    },
    // {
    // 	short_name: 'TH',
    // 	long_name: 'Thailand',
    // 	display_name: 'ประเทศไทย (Thailand)',
    // 	flag_image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/255px-Flag_of_Thailand.svg.png',
    // 	position: { lat: '13.7563309', lng: '100.5017651' }
    // }
  ]);
  const [country, setCountry] = useState(null);
  // const [center, setCenter] = useState(null);

  useEffect(() => {
    if (!country) {
      if (countryList.length === 1) {
        setCountry(countryList[0]);
        // setCenter(countryList[0].position);
      }
      //    else {
      //     let selectCountryModal = new Modal("#selectCountryModal");
      //     selectCountryModal.show();
      //   }
    }

    return () => {
      // setCountry(null);
    };
  }, [country]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <MapNftComponent
      lang={lang}
      country={country}
      setPos={setPos}
      listLocation={listLocation}
      loading={loading}
      mapItemDetail={mapItemDetail}
    />
  );
};

export default MapReaflat;
