import { Breadcrumb, DatePicker, Form, Input, Spin } from 'antd';
import React from 'react';
import styles from '../index.less';
import { getVoucherTransferHistory } from '../service';
import { Link, useParams } from 'umi';
import Table, { ColumnsType } from 'antd/lib/table';
import { LeftOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
const TransferHistory = () => {
  const [form] = Form.useForm();
  const param: { id: string } = useParams();
  const { tableProps, search, data, loading } = useAntdTable(
    getVoucherTransferHistory,
    {
      form,
      defaultParams: [
        {
          current: 1,
          pageSize: 10,
          typeId: Number(param.id),
        },
        {},
      ],
    },
  );
  const { submit } = search;
  const RenderSourceVoucher = (source: string) => {
    switch (source) {
      case 'BUY':
        return 'Đổi voucher';
      case 'GIFT':
        return 'Nhặt quà';
      case 'COMMISSION':
        return 'Hoa hồng voucher';
      default:
        return null;
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian đổi',
      dataIndex: 'picked_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Id user',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên user',
      dataIndex: 'full_name',
    },
    {
      title: 'Số lượng voucher đổi',
      dataIndex: 'quantity',
    },
    {
      title: 'Nguồn',
      dataIndex: 'type',
      render: (value) => <>{RenderSourceVoucher(value)}</>,
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="textSearch" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm ID người đổi voucher"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <div>
        Tổng số voucher:{' '}
        {loading ? (
          <Spin />
        ) : (
          <b className="primary-color">{formatNumber(data?.data?.quantity)}</b>
        )}
      </div>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/voucher/released" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Lịch sử đổi voucher{' '}
          {loading ? <Spin /> : <b>{data?.data?.voucher_name}</b>}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

export default TransferHistory;
