import { API_PATH, privateRequest, request } from '@/utils/apis';
import { useRequest } from 'ahooks';
import axios from 'axios';

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}

interface Result {
  total: number;
  list: Item[];
}
export interface ICreateLocaBuilding {
  name: string;
  address_nft: string;
  building_template_id: number;
  status: boolean;
  lat_nft: string;
  long_nft: string;
  user_owner_nft: string;
}
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  form: Object,
): Promise<Result> => {
  let query = `page_index=${current}&page_size=${pageSize}`;
  Object.entries(form).forEach(([key, value]) => {
    if ((value || value === 0) && key !== 'order') {
      query += `&${key}=${value}`;
    }
  });
  return privateRequest(request.get, API_PATH.BUILDING + '?' + query).then(
    (res) => {
      return {
        total: res.total,
        list: res.data,
      };
    },
  );
};

// get detail building
export const requesBuilding = (id: number) => {
  return privateRequest(request.get, API_PATH.BUILDING + `/${id}`);
};
// get template building
export const useGetBuildingTemplate = () => {
  const query = `page_index=0&page_size=99&status=1`;
  const { data } = useRequest(() => {
    return privateRequest(
      request.get,
      API_PATH.BUILDING_TEMPLATES + '?' + query,
    );
  });
  return {
    buildingTemplate: data?.data,
  };
};

// get address NFT
export const useGetNFT = () => {
  const { data } = useRequest(() => {
    return axios.get(`https://find.locamos.vn/api/locations`);
  });
  return {
    address: data?.data?.data,
  };
};

//create locaBuuilding
export const requestCreateLocaBuilding = (data: ICreateLocaBuilding) => {
  return privateRequest(request.post, API_PATH.BUILDING, { data });
};

// change status

export const requestChangeStatus = (status: boolean, id: number) => {
  return privateRequest(request.put, API_PATH.BUILDING + `/${id}`, {
    data: {
      status,
    },
  });
};

// update loca building

export const requestUpdateLocaBuilding = (
  id: number,
  data: ICreateLocaBuilding,
) => {
  return privateRequest(request.put, API_PATH.BUILDING + `/${id}`, {
    data,
  });
};

//get banner no selected

export const useGetBannerNoSelected = () => {
  const query = `page_index=0&page_size=99&selected=1`;
  const { data, loading } = useRequest(() => {
    return privateRequest(request.get, API_PATH.BUILDING_BANNER + `?${query}`);
  });
  return {
    banner: data?.data,
    loading,
  };
};

// get shop no selected
export const useGetShopNoSelected = () => {
  const query = `page_index=0&page_size=99&selected=1`;
  const { data, loading } = useRequest(() => {
    return privateRequest(request.get, API_PATH.SHOP + `?${query}`);
  });
  return {
    shop: data?.data,
    loading,
  };
};

// update banner
export const requestUpdateBanner = (
  id: number,
  idBanner: number,
  idBuilding: number,
) => {
  return privateRequest(
    request.put,
    API_PATH.BUILDING_BANNER + `/${idBuilding}/${idBanner}/${id}`,
  );
};

export const requestUpdateShop = (
  id: number,
  idShop: number,
  idBuilding: number,
) => {
  return privateRequest(
    request.put,
    API_PATH.SHOP + `/${idBuilding}/${idShop}/${id}`,
  );
};
