import { useRequest, useSetState, useToggle } from 'ahooks';
import {
  Col,
  Modal,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Button,
} from 'antd';
import React, { useState } from 'react';
import { paternPhone } from '@/utils/patern';
import { useHistory, useIntl } from 'umi';
const { Option } = Select;
import styles from '../index.less';
import { OPTION_GENDER, OPTION_STATUS_ACTIVE } from '@/utils/constant';
import { StatusAccount } from '@/utils/enum';
import { paternpassWord } from '@/utils/patern';

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit?: any;
}

const Dialog: React.FC<Iprops> = ({
  open,
  setOpen,
  itemEdit,
  children,
  ...rest
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const history = useHistory();
  const [modalText, setModalText] = useState('Bạn có chắc chắn muốn huỷ');

  const handleOk = () => {
    setOpen(false);
    history.push('/decentralization');
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
