import { EditOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import ModalEdit from './ModalEdit';
import styles from './index.less';
export interface IBanner {
  id: number;
  name: string;
  order: number;
  ref_id: number;
  scene: string;
  position_name?: string;
}
interface IProps {
  banner: IBanner[];
  building_id?: number;
  isEdit?: boolean;
  refresh?: () => void;
  detail?: boolean;
}
export default (props: IProps) => {
  const {
    banner,
    building_id,
    isEdit = false,
    refresh,
    detail = false,
  } = props;
  const columns: any = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên vị trí',
      // hidden: !detail,
      dataIndex: 'position_name',
      key: 'position_name',
    },
    {
      title: 'Tên biển quảng cáo',

      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      hidden: !isEdit,
      render: (_: any, record: any, index: any) => {
        return (
          <div className={styles.edit}>
            <ModalEdit
              building_id={building_id}
              id={record.id}
              refresh={refresh}
              name={`Chọn biên quảng cáo cho ${record?.position_name}`}
            >
              <EditOutlined />
            </ModalEdit>
          </div>
        );
      },
    },
  ].filter((item) => !item.hidden);
  return (
    <>
      <Table rowKey="id" columns={columns} dataSource={banner} />
    </>
  );
};
