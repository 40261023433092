import { Data } from '@react-google-maps/api';
import { message, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useIntl, useRequest } from 'umi';
import { deleteAdmin } from '../service';
const { Option } = Select;

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  itemEdit: any;
}

interface IUser {
  address?: string;
  avatar?: any;
  createdAt?: string;
  dateOfBirth?: string;
  email?: string;
  fullName?: string;
  gender?: string;
  id?: string | number;
  identificationCode?: string;
  isActive?: true;
  phone?: string;
  points?: number;
  referralCode?: string;
  roles?: Array<any>;
  status?: string;
  updatedAt?: string;
}

const Dialog: React.FC<Iprops> = ({ open, setOpen, itemEdit, ...rest }) => {
  const id = itemEdit;
  const { formatMessage } = useIntl();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    formatMessage({ id: 'message_delete' }),
  );

  const handleOk = (e: any) => {
    // console.log(e);

    deteteRequest.run(id);
    // message.success(formatMessage({ id: 'message_success' }));
    setOpen(false);
  };
  const deteteRequest = useRequest(deleteAdmin, {
    manual: true,
    onSuccess(res: any) {
      if (res) {
        message.success(formatMessage({ id: 'message_success' }));
      }
      message.error('Không có quyền');
    },
  });
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
