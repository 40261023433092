import { API_PATH, privateRequest, request } from '@/utils/apis';

export const getTableData = (
  {
    current,
    pageSize,
    id,
  }: {
    current: number;
    pageSize: number;
    id: number;
  },
  formData: {
    name: string;
  },
) => {
  const data = {
    query: `{
      list_user_in_company(id: ${id}, input: { page_size: ${pageSize}, page_index: ${
      current - 1
    }, name: "${formData.name ? formData.name : ''}" }) {
        total 
        data {
          user_profile {
            full_name
          }
          phone
          email
          deleted
          status
          ref_status
          invite_count
        }
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res) => {
      const result = res?.data?.list_user_in_company?.data?.map(
        (item: any, index: number) => {
          return {
            ...item,
            stt: (current - 1) * pageSize + (index + 1),
          };
        },
      );
      return {
        list: result,
        total: res?.data?.list_user_in_company?.total,
      };
    },
  );
};
