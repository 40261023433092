import type { ColumnsType } from 'antd/lib/table';
import Table from 'antd/lib/table';
import { EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'umi';
import style from '../index.less';
import { findSimilarFace } from '../service';
import SameFaceDialog from './SameFaceDialog';
import { Image } from 'antd';
interface DataType {
  userId: number;
  fullName: string;
  confidence: string;
  img: string;
}

const SameFace = ({ id }: { id: number }) => {
  const similarFace = useRequest(findSimilarFace, {
    manual: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  useEffect(() => {
    similarFace.run(id);
  }, [id]);
  const cols: ColumnsType<DataType> = [
    {
      title: 'Id User',
      dataIndex: 'userId',
    },
    {
      title: 'Tên User',
      dataIndex: 'fullName',
    },
    {
      title: 'Tỷ lệ giống',
      dataIndex: 'confidence',
      render: (_, record) => (
        <>{`${(Number(record?.confidence) * 100).toFixed(2)} %`}</>
      ),
    },
    {
      title: 'Ảnh chi tiết',
      align: 'center',
      render: (_, record) => (
        <>
          <Image
            src={record?.img}
            style={{
              width: '100px',
              height: '100px',
            }}
            fallback="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          />
        </>
        // <a
        //   href={`/user-management/${record.userId}?search=${record.userId}`}
        //   target="_blank"
        //   style={{
        //     color: 'black',
        //   }}
        // >
        //   <EyeOutlined />
        // </a>
      ),
    },
  ];

  return (
    <div className={style.SameFace}>
      <div className={style.TitleSameFace}>
        Danh sách tài khoản có khuôn mặt giống
      </div>
      <Table
        columns={cols}
        dataSource={similarFace?.data?.findUserSimilarByUserId}
        rowKey={(item) => item.userId}
      />
      {isOpen && (
        <SameFaceDialog id={userId} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </div>
  );
};

export default SameFace;
