import { API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image as ImageTag,
  Input,
  Row,
  Upload,
} from 'antd';
import Tooltip from 'antd/es/tooltip';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import Table, { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link, useHistory, useIntl, useLocation, useParams } from 'umi';
import Dialog from './Components/Dialog';
import styles from './index.less';

interface IObjectProperty {
  id: number | string;
  item_count: number;
  item_image: string;
  item_name: string;
  item_lat: number | string;
  item_long: number | string;
}

const mockObject: IObjectProperty = {
  id: 1,
  item_count: 10,
  item_name: 'Tên mảnh ghép',
  item_image:
    'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  item_lat: 103.16801,
  item_long: 10.11217774,
};

export default () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [form] = Form.useForm();
  const { push } = useHistory();
  const location = useLocation();

  const [pieceCount, setPieceCount] = useState<number>(0);
  const [mockData, setMockData] = useState<IObjectProperty[]>([]);
  const [itemEdit, setItemEdit] = useState<IObjectProperty | null>(null);
  const [idEdit, setIdEdit] = useState<number | string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(true);

  const { formatMessage } = useIntl();
  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setEditable(false);
    }
  }, [location.pathname]);
  const columns: ColumnsType<IObjectProperty> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'const_column_item_name',
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: 'const_column_gifts_name',
      dataIndex: 'image',
      key: 'image',
      render(_: any, record: any) {
        return <ImageTag width={60} src={record.item_image} />;
      },
    },
    {
      title: 'const_column_count',
      dataIndex: 'item_count',
      key: 'item_count',
    },
    {
      title: 'const_column_action',
      key: 'action',
      align: 'center',
      render: (_: any, record: IObjectProperty, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip title={formatMessage({ id: 'general_edit_infomation' })}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleEditPiece(record.id, record)}
              >
                <EditOutlined />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });

  const handleEditPiece = (id: number | string, record: IObjectProperty) => {
    setItemEdit(record);
    setIdEdit(id);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (pieceCount > 0) {
      const newArray = new Array<IObjectProperty | null>(pieceCount)
        .fill(null)
        .map((_, index) => {
          return {
            ...mockObject,
            id: index + 1,
          };
        });
      setMockData(newArray);
    }
  }, [pieceCount]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const submidAddGift = (value: any): void => {};

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const params = useParams();
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/gifts" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {editable
            ? formatMessage({ id: 'navigation_new_gifts' })
            : formatMessage({ id: 'navigation_edit_gifts' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Card
          title={formatMessage({ id: 'general_gifts_info' })}
          size="small"
          className={styles.primaryCard}
        >
          <Row>
            <Col xs={24} md={12}>
              <Form
                name="basic"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={submidAddGift}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="avatar"
                  label={formatMessage({ id: 'avatar' })}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: formatMessage(
                  //       { id: 'error.require' },
                  //       {
                  //         field: formatMessage({ id: 'avatar' }),
                  //       },
                  //     ),
                  //   },
                  // ]}
                >
                  {/* @ts-ignore */}
                  <Upload
                    action={ENVIRONMENTS.API_URL + API_PATH.UPLOAD}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </Form.Item>
                <Form.Item
                  name="gifts_time"
                  label={formatMessage({ id: 'gifts_time' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'error.require' },
                        {
                          field: formatMessage({ id: 'gifts_time' }),
                        },
                      ),
                    },
                  ]}
                >
                  {/* @ts-ignore */}
                  <DatePicker.RangePicker
                    picker="date"
                    className="w-100"
                    placeholder={['Từ ngày', 'Đến ngày']}
                    disabled={[!editable, false]}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="gifts_name"
                  label={formatMessage({ id: 'gifts_name' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'error.require' },
                        {
                          field: formatMessage({ id: 'gifts_name' }),
                        },
                      ),
                    },
                  ]}
                >
                  <Input placeholder={formatMessage({ id: 'gifts_name' })} />
                </Form.Item>
                <Form.Item
                  name="gifts_count"
                  label={formatMessage({ id: 'gifts_count' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'error.require' },
                        {
                          field: formatMessage({ id: 'gifts_count' }),
                        },
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'gifts_count' })}
                    type="number"
                    disabled={!editable}
                  />
                </Form.Item>
                <Form.Item
                  name="piece_count"
                  label={formatMessage({ id: 'piece_count' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'error.require' },
                        {
                          field: formatMessage({ id: 'piece_count' }),
                        },
                      ),
                    },
                    {
                      pattern: /^0*?[1-9]\d*$/,
                      message: 'please input interger',
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'piece_count' })}
                    type="number"
                    onBlur={(e) => {
                      if (/^0*?[1-9]\d*$/.test(e.target.value))
                        setPieceCount(parseInt(e.target.value));
                    }}
                    disabled={!editable}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card
          title={formatMessage({ id: 'general_puzzle_piece_list' })}
          size="small"
          className={styles.primaryCard}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Table
                dataSource={mockData}
                rowKey={(info) => {
                  return info.id;
                }}
                className={styles.tableList}
                scroll={{ x: 1000 }}
                columns={columns}
              />
            </Col>
          </Row>
        </Card>
        <div className={styles.addGroupButton}>
          {/* <Button className={styles.addButton}>Thêm mới</Button> */}
          <Button
            danger
            onClick={() => push('/gifts')}
            className={styles.addButton}
          >
            {formatMessage({ id: 'general_cancel' })}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            className={styles.addButton}
          >
            {formatMessage({ id: 'general_save' })}
          </Button>
        </div>
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => setOpenDialog(b)}
          itemEdit={itemEdit}
        />
      )}
    </>
  );
};
