import { useAntdTable } from 'ahooks';
import { Form, Input, Modal, message } from 'antd';
import React from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import { getListDeviceLogin } from '../service';
import styles from '../index.less';
import Table, { ColumnsType } from 'antd/lib/table';
import { addDeviceToBlacklist } from '@/pages/BlacklistDevice/service';
import { LockOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
interface DataType {
  time: string;
  device_id: string;
}
const DeviceLogin = () => {
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search, refresh } = useAntdTable(getListDeviceLogin, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        userId: +params.id,
      },
      {
        searchValue: '',
        toDate: null,
      },
    ],
    form,
  });
  const { submit } = search;

  const blockDevice = useRequest(addDeviceToBlacklist, {
    manual: true,
    onSuccess: (res) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra');
        }
      } else {
        message.success('Thành công');
        refresh();
      }
    },
    formatResult: (res) => res,
  });

  const handleBlockDevice = (uid: string) => {
    return Modal.confirm({
      icon: null,
      content: 'Bạn có chắc chắn muốn thêm thiết bị vào danh sách khóa không?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      onOk() {
        blockDevice.run(uid);
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
    },
    {
      title: 'Mã thiết bị',
      dataIndex: 'device_id',
    },
    {
      title: 'Version app',
      dataIndex: 'version_app',
    },
    {
      title: 'Hệ điều hành',
      dataIndex: 'os',
    },
    {
      title: 'ROOT',
      dataIndex: 'is_root',
      render: (value) => <>{value ? 'TRUE' : 'FALSE'}</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (_, record) => (
        <>
          <LockOutlined
            onClick={() => {
              handleBlockDevice(record?.device_id);
            }}
          />
        </>
      ),
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            onSearch={submit}
            className="w-100"
            allowClear
            placeholder="Tìm kiếm mã thiết bị"
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div>
      <div className={styles.mainInfoUser}>
        {searchForm}
        <Table
          columns={columns}
          {...tableProps}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default DeviceLogin;
