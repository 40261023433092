import { useToggle } from 'ahooks';
import { Layout, Modal } from 'antd';
import React, { createContext } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Helmet, IntlProvider, useIntl, useLocation } from 'umi';
import { authRoutes } from '../../../config/routes/index';
import MainHeader from '../Header/main.header';
import Sidebar from '../Sidebar';
import '/src/global/styles.less';
import message from '../../locales/vi-VN';
const ReachableContext = createContext<string | null>(null);

const { Content } = Layout;

const App = ({ children }: any) => {
  const [collapsed, setCollapsed] = useToggle(false);

  const [modal, contextHolder] = Modal.useModal();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const findTitle = () => {
    const result = authRoutes.find((r) => r.path === location.pathname);
    return result?.title || 'Locamos CMS';
  };
  const localeInfo = localStorage.getItem('umi_locale');

  return (
    //@ts-ignore
    <IntlProvider
      locale={localeInfo as string}
      messages={message}
      onError={(error) => {}}
    >
      <ReachableContext.Provider value="Light">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{formatMessage({ id: findTitle() })}</title>
        </Helmet>
        <Layout>
          <Sidebar
            collapsed={collapsed}
            onToggle={() => setCollapsed.toggle()}
          />
          <Layout
            className="site-layout"
            style={{
              maxHeight: '100vh',
              overflowY: 'auto',
            }}
          >
            <MainHeader />
            <Content>
              <div
                className="site-layout-background"
                style={{ minHeight: 360 }}
              >
                {children}
              </div>
              {contextHolder}
            </Content>
          </Layout>
        </Layout>
      </ReachableContext.Provider>
    </IntlProvider>
  );
};

const MainLayout: React.FC = ({ children }: any) => {
  return <App>{children}</App>;
};

export default MainLayout;
