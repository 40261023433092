import React, { useState } from 'react';
import styles from '../../index.less';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Skeleton,
  Switch,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { getBannerConfig, updateBannerConfig } from '../../service';
import { ENVIRONMENTS } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
const BannerApp = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [form] = Form.useForm();
  const bannerConfig = useRequest(getBannerConfig, {
    onSuccess: (res) => {
      form.setFieldsValue({
        ...res,
      });
    },
  });
  const updateBanner = useRequest(updateBannerConfig, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      setIsDisabled(true);
      bannerConfig.refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });

  const onChange: UploadProps['onChange'] = ({ file }) => {
    setIsUploading(true);
    if (file.response) {
      form.setFieldValue('image', file.response.path);
      setIsUploading(false);
    }
  };

  const onFinish = (val: any) => {
    updateBanner.run(val);
  };
  return (
    <div className={styles.bannerWrapper}>
      <h1 className={styles.titleSetting}>Banner App</h1>
      <Button
        icon={<EditOutlined />}
        ghost
        type="primary"
        className={styles.editBtn}
        onClick={() => {
          setIsDisabled(!isDisabled);
          if (!isDisabled) {
            bannerConfig.refresh();
          }
        }}
      ></Button>
      {bannerConfig.loading ? (
        <Skeleton active />
      ) : (
        <Row justify="space-between">
          <Col span={18} xxl={18} xl={16} lg={14} sm={24} xs={24}>
            <Form
              disabled={isDisabled}
              className={styles.formItem}
              onFinish={onFinish}
              name="basic"
              labelCol={{ span: 6, xl: 6, lg: 24, sm: 24 }}
              form={form}
              labelWrap={true}
              labelAlign="left"
              wrapperCol={{ span: 18, xl: 18, lg: 24, sm: 24 }}
              autoComplete="off"
            >
              <Row>
                <Col span={16} lg={16} sm={24} xs={24}>
                  <Form.Item label="Title" name="title">
                    <Input placeholder="Nhập title của banner" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16} xxl={16} xl={16} lg={16} sm={24} xs={24}>
                  <Form.Item
                    label="Ảnh banner"
                    name="image"
                    rules={[
                      { required: true, message: 'Vui lòng nhập Url' },
                      {
                        message: 'Không đúng định dạng URL',
                        validator: (_, value) => {
                          var res = value.match(
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          );
                          if (res == null && value) {
                            return Promise.reject();
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={2}
                      readOnly
                      placeholder="Upload ảnh banner"
                    />
                  </Form.Item>
                </Col>
                <Col span={6} xxl={8} xl={24} lg={24} sm={8} xs={12}>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        // eslint-disable-next-line @typescript-eslint/space-before-function-paren
                        async validator(_, value) {
                          if (value?.[0]?.error) {
                            return Promise.reject('Tải ảnh thất bại');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Upload
                      name="file"
                      accept=".png,.jpg,.jpeg"
                      action={ENVIRONMENTS.API_URL + API_PATH.UPLOAD}
                      onChange={onChange}
                      maxCount={1}
                      // disabled={isDisabled}
                      // showUploadList={false}
                      onRemove={() => {
                        form.setFieldValue('image', '');
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          margin: '0 30px',
                        }}
                        icon={<UploadOutlined />}
                      >
                        Upload ảnh
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16} lg={16} sm={16} xs={24}>
                  <Form.Item
                    name="redirect_url"
                    label="Link điều hướng"
                    rules={[
                      // { required: true, message: 'Vui lòng nhập Url' },
                      {
                        message: 'Không đúng định dạng URL',
                        validator: (_, value) => {
                          var res = value.match(
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          );
                          if (res == null && value) {
                            return Promise.reject();
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Nhập link điều hướng" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16} lg={16} sm={16} xs={24}>
                  <Form.Item
                    label="Hiển thị"
                    name="display"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              {!isDisabled && (
                <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                  <Row>
                    <Button
                      className={styles.submitBtn}
                      danger
                      onClick={() => {
                        setIsDisabled(true);
                        if (!isDisabled) {
                          bannerConfig.refresh();
                        }
                      }}
                    >
                      Hủy
                    </Button>
                    <Button
                      className={styles.submitBtn}
                      type="primary"
                      htmlType="submit"
                      disabled={isUploading}
                      loading={updateBanner.loading}
                    >
                      Lưu
                    </Button>
                  </Row>
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col span={6} xxl={6} xl={8} lg={10} sm={24} xs={24}>
            <Image
              src={bannerConfig.data?.image}
              style={{
                // width: 400,
                height: 600,
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BannerApp;
