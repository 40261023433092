import React from 'react';
import styles from '../index.less';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { useAntdTable, useRequest } from 'ahooks';
import { ADMIN_CONFIRM_STATUS } from '@/utils/constant';
import {
  confirmDiscountEvent,
  getListDiscountEvent,
  updateDiscountEvent,
} from '../service';
import { ColumnsType } from 'antd/lib/table';
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { AdminConfirmStatus } from '@/utils/enum';
import { useHistory } from 'umi';

export const TYPE_EVENT = {
  DISCOUNT_EVENT: '/discount_event',
  DISCOUNT_EVENT_REPORT: '/discount_event/report',
};

const ListDiscountEvent = () => {
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(getListDiscountEvent, {
    form,
  });
  const history = useHistory();
  const { submit } = search;
  const confirmEvent = useRequest(confirmDiscountEvent, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
      refresh();
    },
    onError(error: any) {
      if (error?.data?.statusCode === 403) {
        message.error('Không có quyền');
      } else {
        message.error('Có lỗi xảy ra');
      }
    },
  });
  const updateEvent = useRequest(updateDiscountEvent, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      refresh();
    },
    onError: (err: any) => {
      if (err?.data?.statusCode === 403) {
        message.error('Không có quyền');
      } else {
        message.error('Có lỗi xảy ra');
      }
    },
  });
  const [formRefuse] = Form.useForm();
  const handleRefuseEvent = (id: number) => {
    const onSubmitReason = (value: any) => {
      confirmEvent.run(
        {
          confirm: false,
          note: value.reason,
        },
        id,
      );
    };
    return Modal.confirm({
      icon: null,
      title: 'Bạn muốn từ chối chương trình KM này?',
      content: (
        <Form
          id="formRefuse"
          layout="vertical"
          form={formRefuse}
          onFinish={onSubmitReason}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuse',
        loading: confirmEvent.loading,
      },
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onCancel() {
        formRefuse.resetFields();
      },
      onOk() {
        if (formRefuse.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const handleApproveEvent = (id: number) => {
    return Modal.confirm({
      icon: null,
      title: 'Phê duyệt chương trình KM',
      content: `Bạn có chắc chắn muốn phê duyệt chương trình KM này không ?`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      okButtonProps: {
        loading: confirmEvent.loading,
      },
      onOk() {
        confirmEvent.run(
          {
            confirm: true,
          },
          id,
        );
      },
    });
  };
  const handleDisplayEvent = (id: number, display: boolean) => {
    return Modal.confirm({
      title: `Xác nhận ${display ? 'hiển thị' : 'ẩn'} CTKM này?`,
      okText: 'Xác nhận',
      okType: 'primary',
      cancelText: 'Huỷ',
      onOk: () => {
        updateEvent.run(
          {
            disable: !display,
          },
          id,
        );
      },
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'event_name',
    },
    {
      title: 'Doanh nghiệp',
      dataIndex: ['enterprise', 'name'],
    },
    {
      title: 'Loại KM',
      dataIndex: 'type',
      render(value, record) {
        return value === 'PRODUCT_DISCOUNT' ? 'Trên sản phẩm' : 'Trên đơn hàng';
      },
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'start_time',
      render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'end_time',
      render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Số lượng voucher của chương trình',
      dataIndex: 'event_used_voucher',
      render: (value, record) => (
        <>
          {value}/{record?.limit_voucher_event}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => <>{renderStatus(value)}</>,
    },
    {
      title: 'Hành động',
      width: '15%',
      align: 'center',
      render: (value, record) => (
        <>
          <Row justify="space-evenly">
            <Tooltip title="Xem">
              <EyeOutlined
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => history.push(`/discount_event/${record?.id}`)}
                className="purple-color"
              />
            </Tooltip>
            {record?.status === 'REQUESTED' && (
              <>
                <Tooltip title="Duyệt">
                  <CheckOutlined
                    className="green-color"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleApproveEvent(record?.id)}
                  />
                </Tooltip>
                <Tooltip title="Từ chối">
                  <CloseOutlined
                    className="red-color"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleRefuseEvent(record?.id)}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip title="Trạng thái hiển thị">
              <div style={{ cursor: 'pointer' }}>
                <Switch
                  checked={!record?.disable}
                  className="red-color"
                  onChange={(val) => handleDisplayEvent(record?.id, val)}
                />
              </div>
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const renderStatus = (status: AdminConfirmStatus) => {
    switch (status) {
      case AdminConfirmStatus.REQUESTED:
        return <Tag color="warning">Chờ duyệt </Tag>;
      case AdminConfirmStatus.STARTED:
        return <Tag color="success">Đang chạy</Tag>;
      case AdminConfirmStatus.FINISHED:
        return <Tag color="gray">Hết hạn</Tag>;
      case AdminConfirmStatus.NOT_YET_STARTED:
        return <Tag color="gray">Chưa bắt đầu</Tag>;
      case AdminConfirmStatus.CANCELED:
        return <Tag color="red">Đã từ chối</Tag>;
      default:
        break;
    }
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên chương trình"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái"
            options={ADMIN_CONFIRM_STATUS.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
      </Form>

      <Button
        href="/create_discount_event"
        type="primary"
        icon={<PlusOutlined />}
      >
        Tạo mới
      </Button>
    </div>
  );

  return (
    <>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </>
  );
};

export default ListDiscountEvent;
