import { ENVIRONMENTS, ROLE_TYPE } from '@/utils/constant';
import { useAuth } from '@/utils/hooks/useAuth';
import {
  AlignLeftOutlined,
  BarChartOutlined,
  BookOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  GiftOutlined,
  InboxOutlined,
  InsertRowRightOutlined,
  InteractionOutlined,
  LockOutlined,
  LogoutOutlined,
  MehOutlined,
  MoneyCollectOutlined,
  NotificationOutlined,
  PartitionOutlined,
  ProfileOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  TagsOutlined,
  SwapOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Avatar, MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React from 'react';
import { useIntl, useLocation } from 'umi';
import { Link } from 'umi';
import styles from './index.less';
import { PERMISSIONS } from '@/utils/enum';
import { genAVT } from '@/utils/apis/common';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const Sidebar = ({
  children,
  collapsed,
  onToggle,
}: {
  children?: React.ReactNode | React.ReactNode[];
  collapsed: boolean;
  onToggle: () => void;
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [role, setRole] = React.useState<any>({});
  const { onLogout, admin }: any = useAuth();
  const [adminInfo, setAdminInfo] = React.useState<any>({});
  const [navigation, setNavigation] = React.useState<MenuItem[]>([]);
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: any[],
  ): MenuItem {
    return {
      key,
      children,
      label,
      icon,
    } as MenuItem;
  }
  let LIST_ITEM_NAV: any[] = [
    {
      id: [
        ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL,
        ROLE_TYPE.ROLE_GROUP_LEVEL,
        ROLE_TYPE.USER_MANAGEMENT_LEVEL,
        ROLE_TYPE.ENTERPRISE_MANAGEMENT_LEVEL,
      ].join('|'),
      name: 'Quản lý tài khoản',
      icon: <UserOutlined />,
      children: [
        {
          id: ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL,
          name: 'Tài khoản admin',
          icon: <UserOutlined />,
          link: '/admin',
          // children: [
          //   {
          //     id: ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL,
          //     icon: <UserOutlined />,
          //     name: 'navigation_admin',
          //     link: '/admin',
          //   },
          //   {
          //     id: ROLE_TYPE.ROLE_GROUP_LEVEL,
          //     icon: <PartitionOutlined />,
          //     name: 'navigation_group_decentralization',
          //     link: '/decentralization',
          //   },
          // ],
        },
        {
          id: ROLE_TYPE.USER_MANAGEMENT_LEVEL,
          name: 'Tài khoản người dùng',
          icon: <UsergroupDeleteOutlined />,
          link: '/user-management',
        },
        {
          id: ROLE_TYPE.ENTERPRISE_MANAGEMENT_LEVEL,
          icon: <TeamOutlined />,
          name: 'Tài khoản doanh nghiệp',
          link: '/business',
        },
      ],
    },
    {
      id: [
        ROLE_TYPE.VOUCHER_TYPE_MANAGEMENT_LEVEL,
        ROLE_TYPE.VOUCHER_URBOX_MANAGEMENT_LEVEL,
        ROLE_TYPE.DISCOUNT_EVENT_MANAGEMENT_LEVEL,
      ].join('|'),
      name: 'Quản lý voucher',
      icon: <GiftOutlined />,
      children: [
        {
          id:
            role['voucher_type_management_level'] !== PERMISSIONS.NONE
              ? ROLE_TYPE.VOUCHER_TYPE_MANAGEMENT_LEVEL
              : ROLE_TYPE.RELEASE_VOUCHER_MANAGEMENT_LEVEL,
          icon: <ContainerOutlined />,
          name: 'Voucher Locamos',
          link: '/voucher',
        },
        {
          id: ROLE_TYPE.VOUCHER_URBOX_MANAGEMENT_LEVEL,
          icon: <InboxOutlined />,
          name: 'Voucher Urbox',
          link: '/voucher_urbox',
        },
        {
          id: ROLE_TYPE.DISCOUNT_EVENT_MANAGEMENT_LEVEL,
          icon: <TagsOutlined />,
          name: 'Chương trình khuyến mãi',
          link: '/discount_event',
        },
      ],
    },
    {
      id: [
        ROLE_TYPE.KYC_MANAGEMENT_LEVEL,
        ROLE_TYPE.LIVENESS_MANAGEMENT_LEVEL,
        ROLE_TYPE.HANDLE_DISPUTE_LEVEL,
        ROLE_TYPE.RELEASE_VOUCHER_MANAGEMENT_LEVEL,
      ].join('|'),
      name: 'Xử lý yêu cầu',
      icon: <InteractionOutlined />,
      children: [
        {
          id: ROLE_TYPE.LIVENESS_MANAGEMENT_LEVEL,
          icon: <MehOutlined />,
          name: 'Yêu cầu liveness',
          link: '/liveness',
        },
        {
          id: ROLE_TYPE.KYC_MANAGEMENT_LEVEL,
          icon: <SolutionOutlined />,
          name: 'Yêu cầu KYC',
          link: '/kyc',
        },
        {
          id: ROLE_TYPE.REQUEST_CUSTOMER_MANAGEMENT_LEVEL,
          icon: <MoneyCollectOutlined />,
          name: 'Tặng Locabonus',
          link: '/enterprise_customer_request',
        },
        {
          id: ROLE_TYPE.HANDLE_DISPUTE_LEVEL,
          icon: <InteractionOutlined />,
          name: 'handle_dispute',
          link: '/handle_dispute',
        },
        {
          id: ROLE_TYPE.RELEASE_VOUCHER_MANAGEMENT_LEVEL,
          icon: <SwapOutlined />,
          name: 'Chuyển voucher Web-App',
          link: '/transfer_voucher_web',
        },
      ],
    },
    {
      id: [
        ROLE_TYPE.TOTAL_LOCABONUS_LEVEL,
        ROLE_TYPE.LOCABONUS_TRANSFER_LEVEL,
        ROLE_TYPE.STATISTIC_RELEASE_LOCABONUS_LEVEL,
        ROLE_TYPE.COMPANY_MANAGEMENT_LEVEL,
      ].join('|'),
      name: 'Thống kê',
      icon: <DollarCircleOutlined />,
      children: [
        {
          id: ROLE_TYPE.TOTAL_LOCABONUS_LEVEL,
          icon: <DollarCircleOutlined />,
          name: 'Locabonus DAO',
          link: '/total_Locabonus',
        },
        {
          id: ROLE_TYPE.COMPANY_MANAGEMENT_LEVEL,
          icon: <ProfileOutlined />,
          name: 'Công ty thành viên',
          link: '/company',
        },
        {
          id: ROLE_TYPE.LOCABONUS_TRANSFER_LEVEL,
          icon: <ProfileOutlined />,
          name: 'Giao dịch Locabonus',
          link: '/locabonus_transfer',
        },
        {
          id: ROLE_TYPE.STATISTIC_RELEASE_LOCABONUS_LEVEL,
          icon: <BarChartOutlined />,
          name: 'Locabonus phát hành',
          link: '/locabonus_release',
        },
      ],
    },
    {
      id: [
        ROLE_TYPE.SYSTEM_SETTING_LEVEL,
        ROLE_TYPE.VERSION_MANAGEMENT_LEVEL,
        ROLE_TYPE.BLACKLIST_DEVICE_LEVEL,
      ].join('|'),
      name: 'Cài đặt',
      icon: <SettingOutlined />,
      children: [
        {
          id: ROLE_TYPE.VERSION_MANAGEMENT_LEVEL,
          icon: <FileSyncOutlined />,
          name: 'version',
          link: '/Version',
        },
        {
          id: ROLE_TYPE.SYSTEM_SETTING_LEVEL,
          icon: <SettingOutlined />,
          name: 'setting',
          link: '/setting/locameet',
        },
        {
          id: ROLE_TYPE.BLACKLIST_DEVICE_LEVEL,
          icon: <LockOutlined />,
          name: 'navigation_blacklist_device',
          link: '/blacklist_device',
        },
      ],
    },
    {
      id: [
        ROLE_TYPE.BUILDING_TEMPLATE_MANAGEMENT_LEVEL,
        ROLE_TYPE.BUILDING_MANAGEMENT_LEVEL,
        ROLE_TYPE.BUILDING_SHOP_MANAGEMENT_LEVEL,
        ROLE_TYPE.BUILDING_BANNER_MANAGEMENT_LEVEL,
      ].join('|'),
      icon: <InsertRowRightOutlined />,
      name: 'Quản lý building',
      children: [
        {
          id: ROLE_TYPE.BUILDING_TEMPLATE_MANAGEMENT_LEVEL,
          icon: <InsertRowRightOutlined />,
          name: 'Mẫu Building',
          link: '/building-template',
        },
        {
          id: ROLE_TYPE.BUILDING_MANAGEMENT_LEVEL,
          // id: '2',
          icon: <FileTextOutlined />,
          name: 'LocaBuilding',
          link: '/loca-building',
        },
        {
          id: ROLE_TYPE.BUILDING_SHOP_MANAGEMENT_LEVEL,
          // id: '3',
          sicon: <ShopOutlined />,
          name: 'Gian hàng',
          link: '/stall',
        },
        {
          id: ROLE_TYPE.BUILDING_BANNER_MANAGEMENT_LEVEL,
          // id: '4',
          icon: <BookOutlined />,
          name: 'Biển quảng cáo',
          link: '/billboards',
        },
      ],
    },
    {
      id: ROLE_TYPE.NOTIFICATION_MANAGEMENT_LEVEL,
      icon: <NotificationOutlined />,
      name: 'navigation_notification',
      link: '/notification',
    },
    {
      id: ROLE_TYPE.ENTERPRISE_MANAGEMENT_LEVEL,
      icon: <ShoppingOutlined />,
      name: 'order_management',
      link: '/order',
    },

    // {
    //   id: ROLE_TYPE.GIFT_MANAGEMENT_LEVEL,
    //   icon: <GiftOutlined />,
    //   name: 'navigation_gifts',
    //   link: '/gifts',
    // },
    // {
    //   id: ROLE_TYPE.GIFT_RECIVE_MANAGEMENT_LEVEL,
    //   icon: <GiftOutlined />,
    //   name: 'navigation_list_gift',
    //   link: '/list_gift',
    // },

    {
      id: ROLE_TYPE.GIFT_LEVEL,
      icon: <GiftOutlined />,
      name: 'Danh sách quà tặng',
      link: '/gift-building',
    },
    {
      id: ROLE_TYPE.ENTERPRISE_MANAGEMENT_LEVEL,
      icon: <ShoppingCartOutlined />,
      name: 'product_management',
      link: '/products',
    },
  ];

  const renderLink: (link: string, title: string) => React.ReactNode = (
    link: string,
    title: string,
  ) => {
    return (
      <Link to={link} key={link}>
        {formatMessage({ id: title })}
      </Link>
    );
  };

  React.useEffect(() => {
    if (Object.keys(role).length > 0) {
      LIST_ITEM_NAV = LIST_ITEM_NAV.filter((item) => {
        if (Array.isArray(item.children)) {
          item.children = item.children?.filter((child: any) => {
            if (role[child.id] !== PERMISSIONS.NONE) {
              return true;
            }
          });

          if (Array.isArray(item.children?.children)) {
            item.children.children = item.children?.children?.filter(
              (grandChild: any) => {
                if (role[grandChild.id] !== PERMISSIONS.NONE) {
                  return true;
                }
              },
            );
          }
        }
        const splitIdRole = item.id?.split('|');
        if (splitIdRole.length > 0) {
          return splitIdRole.some(
            (idRole: any) => role[idRole] !== PERMISSIONS.NONE,
          );
        } else {
          const findRole = role[item.id];
          if (findRole !== PERMISSIONS.NONE) {
            return true;
          }
        }
      });
      const newList = LIST_ITEM_NAV.map((item, index) => {
        return getItem(
          item.link ? renderLink(item.link, item.name) : item.name,
          item.link,
          item.icon,
          item.children?.map((child: any, index: number) => {
            return getItem(
              child.link ? renderLink(child.link, child.name) : child.name,
              child.link,
              child.icon,
              child.children?.map((grandChild: any, index: number) => {
                return getItem(
                  grandChild?.link
                    ? renderLink(grandChild.link, grandChild.name)
                    : grandChild.name,
                  grandChild.link,
                  grandChild.icon,
                );
              }),
            );
          }),
        );
      });
      setNavigation(newList);
    }
  }, [role]);

  React.useEffect(() => {
    const authData: any = window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    );
    if (authData) {
      const adminObj = JSON.parse(authData);
      if (!!adminObj?.role?.permissions) {
        setRole(adminObj.role.permissions);
        setAdminInfo(adminObj.admin_profile);
      }
    }
  }, [admin]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onToggle}
      trigger={null}
      className={styles.layoutSlider}
      breakpoint="md"
      width={250}
    >
      <div className={styles.logoWrapper}>
        <AlignLeftOutlined className={styles.toggleButton} onClick={onToggle} />
        {!collapsed && (
          <img src="/assets/images/locamos-logo.png" className={styles.logo} />
        )}
      </div>
      <div className={styles.sidebarAvatar}>
        <Avatar
          src={`${ENVIRONMENTS.BUCKET_NAME}/${genAVT(adminInfo?.avatar)}`}
        />

        {!collapsed && <span>{adminInfo?.full_name}</span>}
      </div>
      {navigation.length > 0 && (
        <Menu
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
          items={navigation}
          className={styles.backgroundPrimary}
        />
      )}
      <div
        className={styles.logoutButton}
        onClick={() => {
          onLogout();
        }}
      >
        <LogoutOutlined size={24} />
        {!collapsed && <span>Đăng xuất</span>}
      </div>
    </Sider>
  );
};

export default Sidebar;
