import { genAVT } from '@/utils/apis/common';
import { ENVIRONMENTS, STATUS_KYC } from '@/utils/constant';
import { StatusKyc } from '@/utils/enum';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { useToggle } from 'ahooks';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Tag,
} from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { getUserIdentity, handleKycService } from '../service';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';

export default ({ getDataUser }: any) => {
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const kycInfo = useRequest(getUserIdentity, {
    manual: true,
    onSuccess: (res) => {},
    formatResult: (res) => res,
  });

  const { t } = useTranslate();
  const params = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const requestHandleKyc = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors?.[0]?.message === 'No face detected in the image.') {
          message.error('Không phát hiện khuôn mặt');
        } else if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error(res?.errors?.[0]?.message);
        }
      } else {
        message.success('Duyệt đơn KYC thành công');
        kycInfo.refresh();
      }
    },
    formatResult: (res) => res,
  });

  const requestHandleKycReject = useRequest(handleKycService, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Từ chối đơn KYC thất bại');
        }
      } else {
        message.success('Từ chối đơn KYC thành công');
      }
      kycInfo.refresh();
    },
  });

  const handleKyc = (
    userId: number | string,
    isAccept: boolean,
    reason?: string,
  ) => {
    requestHandleKyc.run(userId, isAccept, reason);
  };
  const handleAcceptUser = (idUser: number | string) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn có chắc chắn muốn phê duyệt tài khoản này không?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleKyc(idUser, true);
      },
    });
  };

  const [formRefuseVerified] = Form.useForm();
  const handleRejectUser = (idUser: number) => {
    const onFinish = (val: any) => {
      requestHandleKycReject.run(idUser, false, val.reason);
    };

    Modal.confirm({
      icon: null,
      title: 'Lý do từ chối yêu cầu xác thực:',
      width: 600,
      content: (
        <Form
          form={formRefuseVerified}
          id="formRefuseVerified"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={formatMessage({ id: 'reason' })}
              rows={4}
            />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formRefuseVerified',
        loading: requestHandleKycReject.loading,
      },

      onCancel() {
        formRefuseVerified.resetFields();
      },
      onOk() {
        if (formRefuseVerified.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  useEffect(() => {
    kycInfo.run(params.id);
  }, [params.id]);

  const checkStatusKyc = (status: StatusKyc) => {
    switch (status) {
      case StatusKyc.ACCEPT:
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case StatusKyc.NOT_REQUEST:
        return <Tag color="gray">Chưa xác thực</Tag>;
      case StatusKyc.PENDING:
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case StatusKyc.REFUSE:
        return (
          <Tag color="red" icon={<CloseOutlined />}>
            Từ chối xác thực
          </Tag>
        );
      default:
        break;
    }
  };

  return (
    <div className={styles.kycinfo}>
      {params.id && !kycInfo ? (
        <Skeleton active />
      ) : (
        <>
          <Card
            title={formatMessage({ id: 'general_personal_info' })}
            size="small"
            className={styles.primaryCard}
          >
            <div>
              {formatMessage({ id: 'fullname' })} :{' '}
              {kycInfo.data?.user_identity?.full_name}
            </div>
            <div>
              {formatMessage({ id: 'address' })} :{' '}
              {kycInfo.data?.user_identity?.address}{' '}
            </div>
            <div>
              {formatMessage({ id: 'date_of_birth' })} :{' '}
              {kycInfo.data?.user_identity?.date_of_birth
                ? moment(kycInfo.data?.user_identity?.date_of_birth).format(
                    'DD/MM/YYYY',
                  )
                : ''}
            </div>
            <div>
              {formatMessage({ id: 'general_kyc_type_IDcard' })}/
              {formatMessage({ id: 'general_kyc_type_passport' })} :{' '}
              {kycInfo.data?.user_identity?.identity_id}{' '}
            </div>
            <div>
              {formatMessage({ id: 'date_provided' })} :{' '}
              {kycInfo.data?.user_identity?.date_of_issue
                ? moment(kycInfo.data?.user_identity?.date_of_issue).format(
                    'DD/MM/YYYY',
                  )
                : ''}
            </div>
            <div>
              {formatMessage({ id: 'place_of_issue' })} :{' '}
              {kycInfo.data?.user_identity?.place_of_issue}
            </div>

            <div>
              {formatMessage({ id: 'status' })} :{' '}
              {checkStatusKyc(kycInfo.data?.user_identity?.verification_status)}{' '}
              {!kycInfo.data?.user_identity?.is_liveness &&
                kycInfo.data?.user_identity?.verification_status ===
                  StatusKyc.PENDING && <Tag color="gray">Chưa có liveness</Tag>}
            </div>
          </Card>
        </>
      )}
      {params.id && !kycInfo ? (
        <Skeleton active />
      ) : (
        <>
          <Card
            title={formatMessage({ id: 'picture_verified' })}
            size="small"
            className={styles.primaryCard}
          >
            <Row gutter={[24, 24]}>
              {kycInfo.data?.user_identity?.identity_front && (
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className={styles.verifiedImage}>
                    <Image
                      wrapperClassName={styles.antImage}
                      src={`${kycInfo.data?.user_identity?.identity_front}`}
                    />
                  </div>
                </Col>
              )}
              {kycInfo.data?.user_identity?.identity_backside && (
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className={styles.verifiedImage}>
                    <Image
                      wrapperClassName={styles.antImage}
                      src={kycInfo.data?.user_identity?.identity_backside}
                    />
                  </div>
                </Col>
              )}
              {kycInfo.data?.user_identity?.verify_image && (
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className={styles.verifiedImage}>
                    <Image
                      wrapperClassName={styles.antImage}
                      src={kycInfo.data?.user_identity?.verify_image}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Card>
        </>
      )}

      {kycInfo.data?.user_identity?.verification_status === StatusKyc.PENDING &&
        kycInfo.data?.user_identity?.is_liveness && (
          <Row
            align="middle"
            justify="center"
            className={styles.submitButtonGroup}
          >
            <Col>
              <Button
                danger
                onClick={() => {
                  handleRejectUser(kycInfo.data?.user_identity?.userId);
                }}
                className={styles.addButton}
              >
                {t('general_denied_kyc')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleAcceptUser(kycInfo.data?.user_identity?.userId);
                }}
                className={styles.addButton}
              >
                {t('general_accept_kyc')}
              </Button>
            </Col>
          </Row>
        )}
    </div>
  );
};
