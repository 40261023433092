export const authRoutes = [
  // {
  //   exact: true,
  //   path: '/',
  //   component: '@/pages/Users/index',
  //   title: 'navigation_user',
  //   routes: [],
  //   wrappers: ['@/layouts/Wrapper'],
  // },
  {
    exact: true,
    path: '/admin',
    component: '@/pages/Admin/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/admin-history',
    component: '@/pages/Admin/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/decentralization',
    component: '@/pages/Admin/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/admin-detail/:id',
    component: '@/pages/AdminEditAccount/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/admin_new_account/',
    component: '@/pages/AdminNewAccount/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/admin_edit_account/:id',
    component: '@/pages/AdminEditAccount/index',
    title: 'navigation_admin',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  {
    exact: true,
    path: '/kyc',
    component: '@/pages/Kyc/index',
    title: 'navigation_user_kyc',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/kyc-detail/:id',
    component: '@/pages/KycInfo/index',
    title: 'navigation_user_kyc',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/user-management',
    component: '@/pages/Users/index',
    title: 'navigation_user',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/user-management/:id',
    component: '@/pages/UsersInfo/index',
    title: 'navigation_user_info',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gifts',
    component: '@/pages/Gifts/index',
    title: 'navigation_gifts',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gifts-infomation/:id',
    component: '@/pages/GiftInfomation/index',
    title: 'navigation_gifts_infomation',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/new-gift',
    component: '@/pages/GiftsNew/index',
    title: 'navigation_gifts',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/edit-gift/:id',
    component: '@/pages/GiftsNew/index',
    title: 'navigation_gifts',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gifts-infomation/:id',
    component: '@/pages/GiftInfomation/index',
    title: 'navigation_gifts_infomation',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/list_gift',
    component: '@/pages/GiftLists/index',
    title: 'navigation_gifts',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/notification',
    component: '@/pages/Notification/index',
    title: 'navigation_notification',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/notification/:id',
    component: '@/pages/Notification/NotificationUser/DetailNotification',
    title: 'navigation_notification',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-notification',
    component: '@/pages/Notification/NotificationUser/CreateNotification',
    title: 'navigation_notification',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/group_user',
    component: '@/pages/Notification/index',
    title: 'navigation_notification',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/decentralization',
    component: '@/pages/Decentralization/index',
    title: 'navigation_group_decentralization',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/decentralization_new',
    component: '@/pages/DecentralizationInfo/index',
    title: 'navigation_group_decentralization',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/decentralization_edit/:id',
    component: '@/pages/DecentralizationInfo/index',
    title: 'navigation_group_decentralization',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/decentralization_info/:id',
    component: '@/pages/DecentralizationInfo/index',
    title: 'navigation_group_decentralization',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/store',
    component: '@/pages/Business/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/brand',
    component: '@/pages/Business/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/link-account',
    component: '@/pages/Business/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business',
    component: '@/pages/Business/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/store/:id',
    component: '@/pages/Business/Components/DetailStore',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/brand',
    component: '@/pages/Business/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business/brand/:id',
    component: '@/pages/Business/Components/DetailBrand',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business_new',
    component: '@/pages/BusinessNewAccount/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/business_detail/:id',
    component: '@/pages/BusinessInfo/index',
    title: 'navigation_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/setting/*',
    component: '@/pages/Setting/index',
    title: 'setting',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/setting/banner/*',
    component: '@/pages/Setting/index',
    title: 'setting',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-banner-meet',
    component: '@/pages/Setting/Component/Banner/CreateBannerMeet',
    title: 'setting',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/detail-banner-meet/:id',
    component: '@/pages/Setting/Component/Banner/DetailBannerMeet',
    title: 'setting',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/total_Locabonus',
    component: '@/pages/TotalLocabonus/index',
    title: 'total_Locabonus',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/handle_dispute',
    component: '@/pages/DisputeHandle/index',
    title: 'handle_dispute',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/handle_dispute_gift',
    component: '@/pages/DisputeHandle/index',
    title: 'handle_dispute',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/handle_dispute_voucher',
    component: '@/pages/DisputeHandle/index',
    title: 'handle_dispute',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/dispute_detail/:id',
    component: '@/pages/DisputeInfo/DisputeLocabonus/index',
    title: 'handle_dispute',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/dispute_gift_detail/:id',
    component: '@/pages/DisputeInfo/DisputeGift/index',
    title: 'handle_dispute',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/dispute_voucher_detail/:id',
    component: '@/pages/DisputeInfo/DisputeVoucher/index',
    title: 'handle_dispute',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/company',
    component: '@/pages/Company/index',
    title: 'company',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/company/:id',
    component: '@/pages/CompanyInfo/index',
    title: 'company',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/version',
    component: '@/pages/Version/index',
    title: 'Version',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/locabonus_release',
    component: '@/pages/LocabonusRelease/index',
    title: 'LocabonusRelease',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/locabonus_transfer',
    component: '@/pages/LocabonusTransfer/index',
    title: 'LocabonusTransfer',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/blacklist_device',
    component: '@/pages/BlacklistDevice/index',
    title: 'BlacklistDevice',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  {
    exact: true,
    path: '/building-template',
    component: '@/pages/BuildingTemplate/index',
    title: 'BuildingTemplate',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-template',
    component: '@/pages/BuildingTemplate/CreateTemplate/index',
    title: 'CreateTemplate',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/building-template/:id',
    component: '@/pages/BuildingTemplate/DetailTemplate/index',
    title: 'DetailTemplate',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  //locabuilding
  {
    exact: true,
    path: '/loca-building',
    component: '@/pages/LocaBuilding/index',
    title: 'LocaBuilding',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/loca-building/:id',
    component: '@/pages/LocaBuilding/DetailBuilding/index',
    title: 'LocaBuilding',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/update-loca-building/:id',
    component: '@/pages/LocaBuilding/UpdateBuilding/index',
    title: 'LocaBuilding',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-building',
    component: '@/pages/LocaBuilding/CreateBuilding/index',
    title: 'CreateLocaBuilding',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  //stall
  {
    exact: true,
    path: '/stall',
    component: '@/pages/StallList/index',
    title: 'StallList',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-stall',
    component: '@/pages/StallList/CreateStall/index',
    title: 'CreateStall',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/stall/:id',
    component: '@/pages/StallList/DetailStall/index',
    title: 'stall',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/update-stall/:id',
    component: '@/pages/StallList/UpdateStall/index',
    title: 'stall',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  //billboard
  {
    exact: true,
    path: '/billboards',
    component: '@/pages/BillBoards/index',
    title: 'BillBoards',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-billboards',
    component: '@/pages/BillBoards/CreateBillBoards/index',
    title: 'CreateBillBoards',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/billboards/:id',
    component: '@/pages/BillBoards/DetailBillBoards/index',
    title: 'DetailBillBoards',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/update-billboards/:id',
    component: '@/pages/BillBoards/UpdateBillBoards/index',
    title: 'UpdateBillBoards',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  //   gift
  {
    exact: true,
    path: '/gift-building',
    component: '@/pages/GiftBuilding/index',
    title: 'Quản lý quà tặng',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-events',
    component: '@/pages/GiftBuilding/index',
    title: 'Quản lý sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-statistics',
    component: '@/pages/GiftBuilding/index',
    title: 'Thống kê nhặt quà',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-events/:id',
    component: '@/pages/GiftBuilding/DetailEvent/index',
    title: 'Quản lý sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-gift-events',
    component: '@/pages/GiftBuilding/CreateEvent/index',
    title: 'Tạo sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-gift-building',
    component: '@/pages/GiftBuilding/CreateGift/index',
    title: 'Quản lý quà tặng',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-building/:id',
    component: '@/pages/GiftBuilding/DetailGift/index',
    title: 'Chi tiết quà tặng',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/list-received-gift/:id',
    component: '@/pages/GiftBuilding/ListReceivedGift/index',
    title: 'ListReceivedGift',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/enterprise_customer_request',
    component: '@/pages/CustomerLocabonusRequest/index',
    title: 'enterprise_customer_request',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/enterprise_customer_request/:id',
    component: '@/pages/CustomerRequestDetail/index',
    title: 'enterprise_customer_request',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher_urbox/*',
    component: '@/pages/VoucherUrbox/index',
    title: 'voucher_urbox',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher_urbox',
    component: '@/pages/VoucherUrbox/index',
    title: 'voucher_urbox',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/released',
    component: '@/pages/Voucher/index',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/history',
    component: '@/pages/Voucher/index',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/bad_reports',
    component: '@/pages/Voucher/index',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher',
    component: '@/pages/Voucher/index',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/statistic_voucher/:id',
    component: '@/pages/Voucher/pages/StatisticVoucher',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/released/:id',
    component: '@/pages/Voucher/pages/ManageTypeVoucher',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/released-partner/:id',
    component: '@/pages/Voucher/pages/ManageTypeVoucherPartner',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/transfer_statistics',
    component: '@/pages/Voucher/index',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/transfer_history/:id',
    component: '@/pages/Voucher/pages/TransferHistory',
    title: 'voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  //liveness
  {
    exact: true,
    path: '/liveness',
    component: '@/pages/Liveness/index',
    title: 'liveness',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/liveness-detail/:id',
    component: '@/pages/Liveness/pages/LivenessDetail',
    title: 'liveness',
    wrappers: ['@/layouts/Wrapper'],
  },
  //transfer voucher web app
  {
    exact: true,
    path: '/transfer_voucher_web',
    component: '@/pages/TransferVoucherWeb/index',
    title: 'transfer_voucher_web',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event',
    component: '@/pages/DiscountEvent/index',
    title: 'discount_event',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event/report',
    component: '@/pages/DiscountEvent/index',
    title: 'discount_event',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create_discount_event',
    component: '@/pages/DiscountEvent/Component/CreateDiscountEvent',
    title: 'discount_event',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event/:id',
    component: '@/pages/DiscountEvent/Component/DetailDiscountEvent',
    title: 'discount_event',
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event/report/:id',
    component: '@/pages/DiscountEvent/Component/DetailStaticVoucherEvent',
    title: 'discount_event',
    wrappers: ['@/layouts/Wrapper'],
  },

  //product
  {
    exact: true,
    path: '/products',
    component: '@/pages/Products/index',
    title: 'product_management',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/products/:id',
    component: '@/pages/Products/Component/DetailProduct',
    title: 'product_management',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  //order
  {
    exact: true,
    path: '/order',
    component: '@/pages/Order',
    title: 'order_management',
    wrappers: ['@/layouts/Wrapper'],
  },
];
export default [
  {
    exact: true,
    path: '/login',
    component: '@/layouts/AuthLayout/index',
    title: 'Login',
    wrappers: ['@/layouts/Wrapper'],
    routes: [
      { exact: true, path: '/login', component: '@/pages/SignIn/index' },
    ],
  },
  {
    exact: false,
    path: '/',
    component: '@/layouts/MainLayout',
    wrappers: ['@/layouts/Wrapper'],
    routes: authRoutes,
  },
];
