import { ENVIRONMENTS } from '@/utils/constant';
import { atom, useRecoilState } from 'recoil';
import { isBrowser, history } from 'umi';

const authCache =
  isBrowser() &&
  window?.localStorage.getItem(ENVIRONMENTS.LOCAL_STORAGE_KEY as string);

const adminCache =
  isBrowser() &&
  window?.localStorage.getItem(ENVIRONMENTS.LOCAL_STORAGE_KEY as string);

interface IAuth {
  token: string | null;
  refresh_token: string | null;
}

let loggedIn = false;

if (authCache) {
  loggedIn = !!JSON.parse(authCache);
}

export const authAtom = atom({
  key: `${ENVIRONMENTS.LOCAL_STORAGE_KEY}_AUTH`,
  default: loggedIn,
});

let initalAdmin = {
  admin_profile: {},
  role: {
    permissions: {
      admin_management_level: '',
      role_group_level: '',
      user_management_level: '',
      kyc_management_level: '',
      gift_management_level: '',
      gift_recive_management_level: '',
      item_management_level: '',
      enterprise_management_level: '',
      report_management_level: '',
      system_setting_level: '',
      total_locabonus_level: '',
      handle_dispute_level: '',
      version_management_level: '',
      locabonus_transfer_management_level: '',
      company_management_level: '',
      statistic_release_locabonus_level: '',
      blacklist_device_management: '',
      withdraw_locabonus: '',
      gift_level: '',
      config_user_receive_gift: '',
      request_customer_management_level: '',
      voucher_urbox_management_level: '',
      release_voucher_management_level: '',
      voucher_type_management_level: '',
    },
  },
};

if (adminCache) {
  initalAdmin = {
    ...initalAdmin,
  };
}

export const adminAtom = atom({
  key: 'admin_info',
  default: initalAdmin,
});

const getAuthLocalStorage = () => {
  try {
    const authData = window?.localStorage.getItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
    );
    let authObj: any = {};
    if (authData) {
      authObj = JSON.parse(authData);
    }

    return authObj;
  } catch (error) {}
};

const getAdminLocalStore = () => {
  try {
    const adminData = window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    );
    let adminObj = {};
    if (adminData) {
      adminObj = JSON.parse(adminData);
    }

    return adminObj;
  } catch (error) {}
};

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const setAuthData = (data: IAuth): void => {
    setAuth(true);
    window?.localStorage.setItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
      JSON.stringify(data),
    );
    // decode and set admin role
    setAdmin({ ...initalAdmin });
  };

  const getAccessToken = () => {
    const authData: any = getAuthLocalStorage();
    return authData?.token;
  };

  const onLogout = () => {
    setAuth(false);
    setAdmin(initalAdmin);
    window.localStorage.clear();
    history.push('/login');
  };
  const onLogin = (data: IAuth, adminData: any) => {
    setAuthData(data);
    window.localStorage.setItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
      JSON.stringify(adminData),
    );
    history.push('/');
  };

  return {
    auth,
    admin,
    setAuthData,
    setAdmin,
    onLogin,
    onLogout,
    getAccessToken,
    getAdminLocalStore,
  };
};
