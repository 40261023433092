import React from 'react';
import ReactEcharts from 'echarts-for-react';
interface IpropsChart {
  data: any[];
}
const TopGiftChart = ({ data }: IpropsChart) => {
  const rawData = data?.map((item) => {
    return [item.limit - item.received_gift, item.received_gift];
  });

  const grid = {
    left: 100,
    right: 100,
    top: 50,
    bottom: 50,
  };
  const series = [
    {
      barWidth: '50%',
      name: 'Chưa nhặt',
      type: 'bar',
      stack: 'total',
      data: rawData?.map((item, i) => item[0]),
    },
    {
      barWidth: '50%',
      name: 'Đã nhặt',
      type: 'bar',
      stack: 'total',
      data: rawData?.map((item, i) => item[1]),
    },
  ];
  const option = {
    title: {
      text: 'Thống kê theo số lượng nhặt quà',
      left: 'center',
    },
    legend: {
      orient: 'horizontal',
      bottom: 0,
    },
    tooltip: {
      trigger: 'item',
    },
    grid,
    yAxis: {
      type: 'value',
      name: 'Số lượng quà',
    },
    xAxis: {
      type: 'category',
      data: data?.map((item) => item?.name),
      axisLabel: { interval: 0, rotate: 0 },
    },
    series,
  };
  return (
    <div>
      <ReactEcharts
        option={option}
        style={{ height: '400px', maxWidth: '1600px' }}
      ></ReactEcharts>
    </div>
  );
};

export default TopGiftChart;
