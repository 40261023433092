import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  UploadFile,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import UploadImage from '@/components/UploadImage';
import {
  VoucherType,
  createTypeVoucher,
  getDetailTypeVoucher,
  updateTypeVoucher,
} from '../service';
import { ENVIRONMENTS } from '@/utils/constant';
import { formatInputNumber } from '@/utils/apis/common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';

import TypeVoucher from './TypeVoucher';
import config from 'config/config';
const CreateTypeVoucher = () => {
  const param: { id: string } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isDVoucher, setIsDVoucher] = useState<boolean>(false);
  const createType = useRequest(createTypeVoucher, {
    manual: true,
    onSuccess: (res) => {
      message.success('Tạo voucher thành công');
      history.push('/voucher/released');
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const updateType = useRequest(updateTypeVoucher, {
    manual: true,
    onSuccess: (res) => {
      message.success('Sửa voucher thành công');
      // setDisabled(true);
      // detailType.run(param.id);
      history.push('/voucher/released');
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const detailType = useRequest(getDetailTypeVoucher, {
    manual: true,
    onSuccess: (res) => {
      form.setFieldsValue({
        ...res,
        pool_percent: Math.floor(res.pool_percent * 100),
      });
      setIsDVoucher(res.is_dvoucher);
      setFileList([
        {
          uid: '-1',
          name: 'image1.png',
          status: 'done',
          url: `${ENVIRONMENTS.BUCKET_NAME + '/' + res?.image}`,
        },
      ]);
    },
    onError(err: any) {
      message.error('Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });
  const onSubmit = (val: any) => {
    const type = form.getFieldValue('type');
    const payload = {
      ...val,
      image: val?.image?.[0]?.response?.path,
      pool_percent: Number(val.pool_percent) / 100 || 1.1,
      is_dvoucher: val.is_dvoucher ?? false,
    };
    if (isDVoucher && !!type) {
      payload.value = type === VoucherType.DVOUCHER ? 20000 : 2000;
      payload.nums_of_piece = 1;
    }
    if (param.id === 'add_voucher') {
      createType.run(payload);
    } else {
      updateType.run(payload, param.id);
    }
  };
  const onCancel = () => {
    if (param.id === 'add_voucher') {
      form.resetFields();
    } else {
      detailType.run(param.id);
      setDisabled(true);
    }
  };
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (param.id === 'add_voucher') {
      form.setFieldsValue({
        pool_percent: 110,
        nums_of_piece: 1,
      });
    } else {
      detailType.run(param.id);
    }
  }, [param]);
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/voucher/released" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {param.id === 'add_voucher' ? 'Tạo loại voucher' : 'Chi tiết voucher'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.formVoucher}>
        <Card className={styles.formVoucherWrapper}>
          {param.id !== 'add_voucher' && (
            <Button
              icon={<EditOutlined />}
              onClick={() => setDisabled(!disabled)}
              title="Sửa"
            ></Button>
          )}
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            disabled={param.id == 'add_voucher' ? false : disabled}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="D-Voucher"
                  name="is_dvoucher"
                  valuePropName="checked"
                >
                  <Switch onChange={(checked) => setIsDVoucher(checked)} />
                </Form.Item>
                <Form.Item
                  label="Tên loại voucher"
                  name="name"
                  rules={[
                    {
                      validator: (_: any, value: string) => {
                        if (!!value) {
                          if (value.length >= 50) {
                            return Promise.reject('Tối đa 50 ký tự');
                          }
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Vui lòng nhập');
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên loại voucher" />
                </Form.Item>
                {isDVoucher ? (
                  <Form.Item
                    label="Giá trị voucher"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn giá trị voucher',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Chọn giá trị Voucher"
                      options={[
                        {
                          label: '20.000 VND',
                          value: VoucherType.DVOUCHER,
                        },
                        {
                          label: '2.000 VND',
                          value: VoucherType.CVOUCHER,
                        },
                      ]}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Giá trị voucher"
                    name="value"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!!value || value === 0) {
                            if (value <= 0) {
                              return Promise.reject('Giá trị phải lớn hơn 0');
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.reject('Vui lòng nhập');
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      controls={false}
                      formatter={formatInputNumber}
                      placeholder="Nhập giá trị voucher"
                      addonAfter="VND"
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Mô tả"
                  name="description"
                  rules={[
                    {
                      validator: (_: any, value: string) => {
                        if (!!value) {
                          if (value.length >= 500) {
                            return Promise.reject('Tối đa 500 ký tự');
                          }
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Vui lòng nhập');
                        }
                      },
                    },
                  ]}
                >
                  <Input.TextArea rows={2} placeholder="Nhập nội dung mô tả" />
                </Form.Item>
                <Form.Item
                  label="Điều kiện áp dụng"
                  name="condition"
                  rules={[
                    {
                      validator: (_: any, value: string) => {
                        if (!!value) {
                          if (value.length >= 2000) {
                            return Promise.reject('Tối đa 2000 ký tự');
                          }
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Vui lòng nhập');
                        }
                      },
                    },
                  ]}
                >
                  {/* <Input.TextArea placeholder="Nhập điều kiện áp dụng" /> */}
                  <CKEditor
                    editor={FullEditor}
                    id="editor"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(e, editor) => {
                      form.setFieldValue('condition', editor.getData());
                    }}
                    data={form.getFieldValue('condition')}
                    disabled={param.id == 'add_voucher' ? false : disabled}
                    style={{ minHeight: 400 }}
                  />
                </Form.Item>
                {!isDVoucher && (
                  <>
                    <Form.Item
                      label="Số mảnh ghép"
                      name="nums_of_piece"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!!value || value === 0) {
                              if (value <= 0) {
                                return Promise.reject('Số mảnh phải lớn hơn 0');
                              } else {
                                if (value % 2 !== 0 && value !== 1) {
                                  return Promise.reject(
                                    'Số mảnh phải là số chẵn hoặc bằng 1',
                                  );
                                }
                                return Promise.resolve();
                              }
                            } else {
                              return Promise.reject('Vui lòng nhập');
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        controls={false}
                        placeholder="Nhập số mảnh ghép"
                        style={{
                          width: '100%',
                        }}
                        addonAfter="mảnh"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tỷ lệ phát hành thêm voucher"
                      name="pool_percent"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!!value || value === 0) {
                              if (value <= 0) {
                                return Promise.reject('Tỉ lệ phải lớn hơn 0');
                              } else {
                                return Promise.resolve();
                              }
                            } else {
                              return Promise.reject('Vui lòng nhập');
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder="Nhập tỷ lệ"
                        style={{
                          width: '100%',
                        }}
                        addonAfter="%"
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item label="Abaha ID" name="abaha_id">
                  <Input placeholder="Nhập Abaha ID" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <UploadImage
                propsUpload={
                  param.id !== 'add_voucher' && {
                    disabled: disabled,
                  }
                }
                fileList={fileList}
                setFileList={setFileList}
                label="Ảnh voucher"
                name="image"
                required={true}
              />
            </Row>

            <Row
              style={{
                padding: '20px 0',
              }}
              justify="center"
            >
              <Button
                type="primary"
                ghost
                className="btn-submit"
                onClick={onCancel}
              >
                Hủy
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                loading={
                  param.id === 'add_voucher'
                    ? createType.loading
                    : updateType.loading
                }
              >
                Lưu
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CreateTypeVoucher;
