import React from 'react';
import styles from '../../index.less';
import { useAntdTable, useRequest } from 'ahooks';
import {
  getListBannerMeet,
  updateBannerMeet,
  updateMeetBannerConfig,
} from '../../service';
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Tooltip,
  Switch,
  Table,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
import { EyeOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const BannerMeet = () => {
  const [form] = Form.useForm();
  const [formRandomBanner] = Form.useForm();
  const updateBanner = useRequest(updateBannerMeet, {
    manual: true,
    onSuccess(res) {
      message.success('Chỉnh sửa banner thành công');
      refresh();
    },
    onError(err: any) {
      message.error(err.data?.message);
    },
  });
  const updateConfig = useRequest(updateMeetBannerConfig, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
    },
    onError(e: any) {
      message.error(e?.data?.message);
    },
  });
  const { tableProps, search, refresh, data, loading } = useAntdTable(
    getListBannerMeet,
    {
      form,
      onSuccess(res) {
        formRandomBanner.setFieldValue('random_banner', res.data.random_banner);
      },
    },
  );

  const { submit } = search;
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Thứ tự',
      dataIndex: 'order',
    },
    {
      title: 'Số lượt hiển thị',
      dataIndex: 'view_count',
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Số lượt click',
      dataIndex: 'tap_count',
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <>
          <Row justify="space-around" align="middle">
            <Tooltip title="Xem">
              <a href={`/detail-banner-meet/${record?.id}`}>
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
            <Tooltip title="Hiển thị">
              <Switch
                defaultChecked={record?.display}
                onChange={(val) =>
                  updateBanner.run(record?.id, {
                    display: val,
                  })
                }
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm title banner"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Button type="primary" href="/create-banner-meet">
        Thêm Banner
      </Button>
    </div>
  );
  return (
    <div className={styles.bannerWrapper}>
      {searchForm}
      <Form
        className={styles.randomDisplay}
        form={formRandomBanner}
        layout="horizontal"
      >
        <Form.Item
          label="Hiển thị ngẫu nhiên"
          name="random_banner"
          valuePropName="checked"
        >
          <Switch onChange={(val) => updateConfig.run(val)} />
        </Form.Item>
      </Form>
      <div>
        <Table
          {...tableProps}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default BannerMeet;
