import LivenessProfile from '@/pages/UsersInfo/Components/LivenessProfile';
import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useParams } from 'umi';
import styles from '../index.less';
import { LeftOutlined } from '@ant-design/icons';
const LivenessDetail = () => {
  const params = useParams<{ id: string }>();
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/liveness" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Chi tiết yêu cầu liveness
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          padding: '0 20px 50px 20px',
        }}
      >
        <LivenessProfile userId={+params.id} />
      </div>
    </div>
  );
};

export default LivenessDetail;
