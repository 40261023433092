import React, { useState } from 'react';
import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Select,
  message,
  Spin,
  Breadcrumb,
  Row,
} from 'antd';
import { Link, history, useParams } from 'umi';

import styles from './index.less';
import {
  ICreateShop,
  getListEnterprise,
  requestUpdateShop,
  useBuilding,
  useGetDetailShop,
  useShop,
} from '../service';
import { useRequest } from 'ahooks';
import { normalizeNumber } from '@/utils/normalize';
import { LeftOutlined } from '@ant-design/icons';

// const { Option } = Select;

export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { building } = useBuilding();
  const { shop, getShop, loadingShop } = useShop();
  const { shopDetail, loadingShopDetail } = useGetDetailShop(param?.id);
  const [listOptionEnterprise, setListOptionEnterprise] = useState<any[]>([]);
  const listEnterprise = useRequest(getListEnterprise, {
    debounceWait: 500,
    onSuccess(res) {
      setListOptionEnterprise(res?.data?.admin_get_list_enterprise?.data);
    },
  });
  React.useEffect(() => {
    form.setFieldsValue(shopDetail);
    getShop(shopDetail?.building_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingShopDetail]);
  const onUpdateShop = useRequest(
    async (data: ICreateShop) => {
      return requestUpdateShop(param?.id, data);
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('Cập nhật gian hàng thành công');
        history.push('/stall');
      },
      onError: (err: any) => {
        // console.log('err', err?.data.message);
        message.error(err?.data.message);
      },
    },
  );
  const onFinish = (data: ICreateShop) => {
    onUpdateShop.run(data);
  };

  const handleSelectEnterprise = (value: any) => {
    const option = listOptionEnterprise.find(
      (item: any) => item.name === value,
    );

    form.setFieldsValue({
      address: option.address,
      representative: option.owner_name,
      phone: option.phone,
      email: option.email,
    });
  };
  const handleSearchEnterprise = (searchValue: string) => {
    setListOptionEnterprise([]);
    listEnterprise.run(searchValue);
  };
  if (loadingShopDetail) {
    return (
      <div className={styles.createBuilding}>
        <Space
          onClick={() => history.push('/stall')}
          className={styles.titlePage}
          size={5}
          align="center"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="left"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
          </svg>
          Cập nhật gian hàng
        </Space>
        <div className={styles.loading}>
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/stall" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Cập nhật gian hàng
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Divider orientation="left">Thông tin chung</Divider>

        <div className={styles.formInfoGeneral}>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={styles.formSearch}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Tên gian hàng"
              rules={[
                { required: true, message: 'Vui lòng nhập tên gian hàng' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="building_id"
              label="Toà nhà"
              className={styles.locationNFT}
            >
              <Select
                placeholder="Lựa chọn toà nhà"
                optionFilterProp="children"
                options={building?.map((building: any) => {
                  return {
                    label: building.name,
                    value: building.id,
                  };
                })}
                onChange={(id: number) => {
                  form.setFieldValue('building_entities_id', null);
                  getShop(id);
                }}
              />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building_id = getFieldValue('building_id');
                const isDisabled = building_id && !loadingShop ? false : true;
                const template = building?.find(
                  (item: any) => item.id === building_id,
                );
                const vr_oid = template?.vr_oid
                  ? template?.vr_oid
                  : shopDetail?.vr_oid;

                return (
                  <>
                    <Form.Item
                      name="building_entities_id"
                      label="Vị trí gian hàng"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (building_id && !value) {
                              return Promise.reject(
                                new Error('Vui lòng chọn vị trí gian hàng'),
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select
                        disabled={isDisabled}
                        placeholder="Lựa chọn vị trí gian hàng"
                        options={shop?.map((shop: any) => {
                          return {
                            value: shop.id,
                            label: shop.name,
                          };
                        })}
                      />
                    </Form.Item>
                    <Form.Item name="vr_oid" label="ID VR tour">
                      <Input />
                    </Form.Item>
                    <div className={styles.preview}>
                      <p>Preview gian hàng</p>
                      <iframe
                        src={`https://nft.locamos.com/${vr_oid}`}
                        title="preview"
                        width="100%"
                        height="600"
                      />
                    </div>
                  </>
                );
              }}
            </Form.Item>

            <Divider orientation="left">Thông tin chủ gian hàng</Divider>
            <Form.Item name="unit_name" label="Tên đơn vị">
              {/* <Input placeholder="tên công ty, cửa hàng, đơn vị,..." /> */}
              <Select
                showSearch
                allowClear
                placeholder="Chọn doanh nghiệp"
                options={
                  listOptionEnterprise.length
                    ? listOptionEnterprise?.map((item: any, i: number) => ({
                        label: item.name,
                        value: item.name,
                        key: `${item?.name}_${i}`,
                      }))
                    : []
                }
                notFoundContent={listEnterprise.loading ? <Spin /> : <>Trống</>}
                onSelect={(data) => handleSelectEnterprise(data)}
                onSearch={handleSearchEnterprise}
                // onPopupScroll={onLoadMoreEnterprise}
              />
            </Form.Item>
            <Form.Item name="address" label="Địa chỉ">
              <Input placeholder="Địa chỉ" />
            </Form.Item>
            <Form.Item name="representative" label="Người đại diện">
              <Input placeholder="Người đại diện" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Số điện thoại"
              normalize={(value, prevValue) =>
                normalizeNumber(value, prevValue)
              }
              rules={[
                {
                  min: 8,
                  message: 'Số điện thoại nhỏ nhất 8 kí tự',
                },
              ]}
            >
              <Input placeholder="Số điện thoại" />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input placeholder="Email" />
            </Form.Item>
            <Space align="center" size={15} className={styles.status}>
              Trạng thái
              <Form.Item name="status" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Space>
            <Row justify="center">
              <Button
                className="btn-submit"
                type="primary"
                ghost
                danger
                onClick={() => history.push('/stall')}
              >
                Huỷ
              </Button>
              <Button className="btn-submit" type="primary" htmlType="submit">
                Lưu
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
