import { checkRoleAdmin } from '@/utils/apis/common';
import { OPTION_GENDER } from '@/utils/constant';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { paternEmail, paternPhone } from '@/utils/patern';
import {
  EditOutlined,
  LockOutlined,
  SwapOutlined,
  UndoOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useToggle } from 'ahooks';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
} from 'antd';
import { useEffect, useState } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import {
  blockBizUser,
  blockUserTransfer,
  cancelMembershipUser,
  resetPinUser,
  updateUserInfo,
} from '../service';
import TransferAccount from './Dialog/TransferAccount';
import { changeUserStatus } from '@/pages/Users/service';
import dayjs from 'dayjs';

const PersonalInfo = ({ infoAccount, getDataUser, orgs, nation }: any) => {
  // console.log(infoAccount);

  const [nationId, setNationId] = useState();
  const [open, setOpen] = useState(false);

  const dataFormat = 'DD/MM/YYYY';
  const params = useParams<{ id: string }>();

  const nation_vn = nation?.get_all_nation?.find((item: any) => {
    return item.name === 'Viet Nam';
  });
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (infoAccount) {
      setNationId(infoAccount?.nationId);
    }
    fillInitialValue();
  }, [infoAccount]);
  const [form] = Form.useForm();

  const [editable, setEditable] = useToggle(false);
  const { t } = useTranslate();

  const updateDataRequest = useRequest(updateUserInfo, {
    manual: true,
    onSuccess(res: any) {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          if (
            res?.errors[0]?.extensions?.response?.message === 'EXISTED_PHONE'
          ) {
            message.error('Số điện thoại đã tồn tại');
          } else if (
            res?.errors[0]?.extensions?.response?.message?.[0] ===
            'auth_phone must be a valid phone number'
          ) {
            message.error('Số điện thoại không đúng định dạng');
          } else {
            message.error('Có lỗi xảy ra');
          }
        }
      } else {
        if (res?.data?.admin_update_user?.message === 'EMAIL ALREADY EXIST') {
          message.error('Email đã tồn tại');
          getDataUser.refresh();
        } else {
          message.success(t('message_success'));
          getDataUser.refresh();
          setEditable.set(false);
        }
      }
    },
    formatResult: (res) => res,
  });
  const blockTransfer = useRequest(blockUserTransfer, {
    manual: true,
    onSuccess(res: any) {
      message.success('Thành công');
      getDataUser.refresh();
      formBlock.resetFields();
    },
  });

  const blockBiz = useRequest(blockBizUser, {
    manual: true,
    onSuccess(res: any) {
      message.success('Thành công');
      getDataUser.refresh();
      formBlockBiz.resetFields();
    },
  });

  const cancelMembership = useRequest(cancelMembershipUser, {
    manual: true,
    onSuccess(res: any) {
      message.success('Thành công');
      getDataUser.refresh();
      formCancelMembership.resetFields();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });

  const resetPin = useRequest(resetPinUser, {
    manual: true,
    onSuccess(res: any) {
      message.success('Thành công');
      getDataUser.refresh();
    },
  });

  const [formBlock] = Form.useForm();
  const handleBlockUser = (userId: number, block: boolean) => {
    const onSubmitBlock = (value: any) => {
      blockTransfer.run(userId, block, value.reason);
    };
    Modal.confirm({
      icon: null,
      title: block
        ? `Bạn có chắc muốn khóa giao dịch chuyển điểm, nhận điểm của user này ?`
        : 'Bạn muốn mở khóa giao dịch của user này ?',
      content: (
        <Form
          layout="vertical"
          form={formBlock}
          onFinish={onSubmitBlock}
          id="formBlock"
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formBlock',
        loading: blockTransfer.loading,
      },
      onOk() {
        if (formBlock.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const [formCancelMembership] = Form.useForm();
  const handleCancelMembership = (userId: number) => {
    const onSubmitCancelMbs = (value: any) => {
      cancelMembership.run(userId, value.reason);
    };
    Modal.confirm({
      icon: null,
      title:
        'Tài khoản hủy membership sẽ không thể mua lại gói membership nữa. Bạn có chắc chắn muốn hủy membership không?',
      content: (
        <Form
          layout="vertical"
          form={formCancelMembership}
          onFinish={onSubmitCancelMbs}
          id="formCancelMembership"
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formCancelMembership',
        loading: cancelMembership.loading,
      },
      onOk() {
        if (formCancelMembership.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const handleResetPin = (userId: number) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn muốn reset mã PIN của tài khoản này?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        resetPin.run(userId);
      },
    });
  };
  const [formBlockBiz] = Form.useForm();
  const handleBlockBizUser = (userId: number, block: boolean) => {
    const onSubmitBlockBiz = (value: any) => {
      blockBiz.run({ userId, block, reason: value.reason });
    };
    Modal.confirm({
      icon: null,
      content: (
        <Form
          layout="vertical"
          form={formBlockBiz}
          onFinish={onSubmitBlockBiz}
          id="formBlockBiz"
        >
          <div>
            {block
              ? `Bạn có chắc chặn yêu cầu biz của user này?`
              : 'Bạn có chắc mở chặn yêu cầu biz của user này?'}
          </div>
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formBlockBiz',
        loading: blockBiz.loading,
      },
      onOk() {
        if (formBlockBiz.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const onSubmit = (value: any) => {
    // const orgsId = orgs.get_all_orgs.find((item: any) => {
    //   return value.organization === item.id;
    // });
    delete value['status'];
    updateDataRequest.run({
      ...value,
      userId: infoAccount.userId,
      orgsId: value.organization,
      nationId: value.nation,
    });
  };

  const changeStatus = useRequest(changeUserStatus, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('có lỗi xảy ra');
        }
      } else {
        message.success(formatMessage({ id: 'message_success' }));
      }
      formChangeStatus.resetFields();
      getDataUser.refresh();
    },
    formatResult: (res) => res,
  });

  const [formChangeStatus] = Form.useForm();

  const handleChangeUserStatus = (idUser: number, status: boolean) => {
    const onSubmitChangeStatus = (value: any) => {
      changeStatus.run(idUser, status, value?.reason);
    };
    Modal.confirm({
      icon: null,
      title: `Bạn có chắc chắn muốn cập nhật trạng thái ${
        status ? 'hoạt động' : 'không hoạt động'
      } cho tài khoản`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      content: (
        <Form
          id="formChangeStatus"
          layout="vertical"
          form={formChangeStatus}
          onFinish={onSubmitChangeStatus}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formChangeStatus',
        loading: changeStatus.loading,
      },

      onCancel() {
        formChangeStatus.resetFields();
      },
      onOk() {
        if (formChangeStatus.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const fillInitialValue = () => {
    const filldata = {
      ...infoAccount,
      membership_expired: dayjs(infoAccount?.membership_expired).format(
        'HH:mm:ss DD/MM/YYYY',
      ),
      email_login: infoAccount?.facebook_id
        ? `Facebook: ${infoAccount?.facebook_id}`
        : infoAccount?.google_id
        ? `Google: ${infoAccount?.google_id}`
        : infoAccount?.apple_id
        ? `Apple: ${infoAccount?.apple_id}`
        : '',
    };
    delete filldata['status'];
    form.setFieldsValue(filldata);
  };

  return (
    <>
      {checkRoleAdmin('user_management_level') === 'FULL' && (
        <Row
          align="top"
          justify="end"
          style={{
            widows: '400px',
          }}
        >
          <Button
            // ghost
            title="Chỉnh sửa"
            type="primary"
            onClick={() => setEditable.set(true)}
            icon={<EditOutlined />}
            className={styles.transferAccount}
          ></Button>

          <Button
            type="primary"
            ghost
            onClick={() => setOpen(true)}
            className={styles.transferAccount}
            icon={<SwapOutlined />}
          >
            Transfer Tài khoản
          </Button>
          {infoAccount?.is_membership && (
            <Button
              type="primary"
              ghost
              danger
              className={styles.transferAccount}
              icon={<LockOutlined />}
              onClick={() => handleCancelMembership(infoAccount?.userId)}
            >
              Hủy membership
            </Button>
          )}
          {!infoAccount?.is_blocked_transfer ? (
            <Button
              danger
              icon={<LockOutlined />}
              onClick={() => handleBlockUser(infoAccount.userId, true)}
              className={styles.transferAccount}
            >
              Khóa giao dịch
            </Button>
          ) : (
            <Button
              danger
              icon={<UnlockOutlined />}
              onClick={() => handleBlockUser(infoAccount.userId, false)}
              className={styles.transferAccount}
            >
              Mở khóa giao dịch
            </Button>
          )}
          <Button
            danger
            icon={<UndoOutlined />}
            onClick={() => handleResetPin(infoAccount.userId)}
            className={styles.transferAccount}
          >
            Reset mã PIN
          </Button>
          <Button
            danger
            icon={
              infoAccount?.warning_biz_count >= 3 ? (
                <UnlockOutlined />
              ) : (
                <LockOutlined />
              )
            }
            onClick={() =>
              handleBlockBizUser(
                infoAccount.userId,
                !(infoAccount?.warning_biz_count >= 3),
              )
            }
            // className={styles.transferAccount}
          >
            {infoAccount?.warning_biz_count >= 3 ? `Mở chặn biz` : 'Chặn biz'}
          </Button>
        </Row>
      )}
      {params.id && !infoAccount ? (
        <Skeleton active />
      ) : (
        <Form
          labelWrap={true}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={onSubmit}
          layout="horizontal"
          className={styles.mainInfoUser}
          autoComplete="off"
        >
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="full_name"
                label={t('fullname')}
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('fullname'),
                //     }),
                //   },
                // ]}
              >
                <Input placeholder={t('fullname')} disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="phone"
                label={t('phone_number')}
                rules={[
                  {
                    pattern: paternPhone,
                    message: 'Vui lòng nhập đúng định dạng số điện thoại',
                  },
                ]}
              >
                <Input placeholder={t('phone_number')} disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label={t('email')}
                rules={[
                  {
                    validator: async (_, value) => {
                      if (value) {
                        if (paternEmail.test(value) || value.length === 0) {
                          return await Promise.resolve();
                        }
                        return await Promise.reject(
                          new Error('Vui lòng nhập đúng định dạng email'),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder={t('email')} disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name={
                  'email_login'
                  // 'google_id' || 'apple_id' || 'facebook_id' || 'twitter_id'
                }
                label={'Email đăng nhập'}
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('email'),
                //     }),
                //   },
                // ]}
              >
                <Input placeholder={'Nhập email đăng nhập'} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="auth_phone"
                label="Số điện thoại đăng nhập"
                rules={[
                  {
                    pattern: paternPhone,
                    message: 'Không hỗ trợ đăng nhập bằng mạng điện thoại này',
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Input
                  placeholder="Nhập số điện thoại đăng nhập"
                  disabled={infoAccount?.auth_phone ? true : !editable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="date_of_birth"
                label={t('date_of_birth')}
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('date_of_birth'),
                //     }),
                //   },
                // ]}
              >
                {/* @ts-ignore */}

                <DatePicker
                  style={{ width: '100%' }}
                  disabled={!editable}
                  format={dataFormat}
                  disabledDate={(current) => {
                    return current.valueOf() > new Date().valueOf();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="gender"
                label={t('general_gender')}
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('general_gender'),
                //     }),
                //   },
                // ]}
              >
                <Select disabled={!editable}>
                  {OPTION_GENDER.map((e, i) => {
                    return (
                      <Select.Option value={e.value} key={i}>
                        {t(e.name)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="nation"
                label="Quốc gia"
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('nation'),
                //     }),
                //   },
                // ]}
              >
                <Select
                  disabled={!editable}
                  onChange={(val) => setNationId(val)}
                >
                  {nation?.get_all_nation?.map((item: any, i: any) => {
                    return (
                      <Select.Option value={item.id} key={i}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {nationId === nation_vn?.id && (
            <Row>
              <Col xs={24} md={12}>
                <Form.Item
                  name="organization"
                  label="Tỉnh/Thành phố"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t('error.require', {
                  //       field: t('organization'),
                  //     }),
                  //   },
                  // ]}
                >
                  <Select disabled={!editable}>
                    {orgs?.get_all_orgs?.map((e: any, i: any) => {
                      return (
                        <Select.Option value={e.id} key={i}>
                          {e.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="address"
                label="Địa chỉ"
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('address'),
                //     }),
                //   },
                // ]}
              >
                <Input placeholder="Địa chỉ" disabled={!editable} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="date_created"
                label="Ngày tham gia"
                // rules={[
                //   {
                //     required: true,
                //     message: t('error.require', {
                //       field: t('date_created'),
                //     }),
                //   },
                // ]}
              >
                <Input placeholder="Ngày tham gia" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                // name="status"
                label={t('status')}
                // valuePropName="checked"
                // initialValue={userInfo.fullName}
              >
                <Switch
                  disabled={!editable}
                  checked={infoAccount?.status}
                  onChange={() =>
                    handleChangeUserStatus(+params.id, !infoAccount?.status)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              {infoAccount?.is_membership && (
                <Form.Item name="membership_expired" label="Membership">
                  <Input disabled={true} />
                </Form.Item>
              )}
            </Col>
          </Row>
          {infoAccount?.checkpoint_reason && (
            <Row>
              <Col xs={24} md={12}>
                <Form.Item
                  name="checkpoint_reason"
                  label="Lý do checkpoint"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t('error.require', {
                  //       field: t('fullname'),
                  //     }),
                  //   },
                  // ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          )}
          {editable && (
            <Row
              align="middle"
              justify="center"
              className={styles.submitButton}
            >
              <Col>
                <Button
                  danger
                  onClick={() => {
                    fillInitialValue();
                    setEditable.set(false);
                  }}
                  className={styles.addButton}
                >
                  {t('general_cancel')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateDataRequest.loading}
                  className={styles.addButton}
                >
                  {t('general_save')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      )}
      <TransferAccount
        open={open}
        setOpen={setOpen}
        infoAccount={infoAccount}
      />
    </>
  );
};

export default PersonalInfo;
