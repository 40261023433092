import {
  Breadcrumb,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import React, { useState } from 'react';
const { RangePicker } = DatePicker;
const { Option } = Select;
import styles from './index.module.less';
import { useIntl, useRequest } from 'umi';
import {
  ENTERPRISE_CUSTOMER_REQUEST_STATUS,
  blankImageUrl,
} from '@/utils/constant';
import { useAntdTable } from 'ahooks';
import { getTableData } from './service';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { StatusCustomerRequest } from '@/utils/enum';
import dayjs from 'dayjs';
import { ApproveCustomerRequest } from '../CustomerRequestDetail/service';
import CustomerRequestDetail from '../CustomerRequestDetail';
const CustomerLocabonusRequest = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const { tableProps, search, loading, refresh } = useAntdTable(getTableData, {
    form,
  });

  const approveRequest = useRequest(ApproveCustomerRequest, {
    manual: true,
    onSuccess(res) {
      message.success('Thành công');
      refresh();
    },
    onError() {
      message.error('Có lỗi xảy ra');
    },
  });

  const [formReject] = Form.useForm();
  const handleApproveRequest = (spending: number, id: number) => {
    return Modal.confirm({
      title: 'Xác nhận duyệt',
      content: 'Bạn có chắc chắn muốn duyệt hóa đơn này ?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        approveRequest.run({
          request_id: id,
          approve: true,
          spending: +spending,
        });
      },
    });
  };

  const handleRejectRequest = (id: number) => {
    const onFinish = (val: any) => {
      approveRequest.run({
        request_id: id,
        approve: false,
        spending: 0,
        reason: val.reason,
      });
    };
    Modal.confirm({
      icon: null,
      title: 'Xác nhận từ chối',
      width: 600,
      content: (
        <Form
          form={formReject}
          id="formReject"
          onFinish={onFinish}
          layout="vertical"
        >
          <p>Bạn có chắc chắn muốn từ chối hóa đơn này ?</p>
          <Form.Item
            name="reason"
            label={formatMessage({ id: 'reason' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'error.require' },
                  {
                    field: formatMessage({ id: 'reason' }),
                  },
                ),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'reason' })} />
          </Form.Item>
        </Form>
      ),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formReject',
      },

      onCancel() {
        formReject.resetFields();
      },
      onOk() {
        if (formReject.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const { submit } = search;

  const handleOpenModal = (id: number) => {
    setIsOpenDetail(true);
    setSelectedId(id);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
    },
    {
      title: 'Thời gian',
      dataIndex: 'request_at',
      align: 'right',
      render: (value) => <>{dayjs(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'Tên doanh nghiệp',
      dataIndex: 'enterprise_name',
    },
    {
      title: 'Tên user',
      dataIndex: 'user_name',
    },
    {
      title: 'Chi tiêu tích điểm',
      align: 'right',
      dataIndex: 'accrual_spending',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Giá trị hóa đơn',
      align: 'right',
      dataIndex: 'spending',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Đánh giá',
      align: 'right',
      dataIndex: 'rating',
    },
    {
      title: 'Ảnh hóa đơn',
      dataIndex: 'bill_path',
      render: (value) => (
        <>
          <Image
            alt="bill"
            src={value}
            style={{
              widows: '50px',
              height: '50px',
            }}
            fallback={blankImageUrl}
          />
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'stastus',
      render: (_: any, record: any) => (
        <>
          {record?.status === 'REFUSE' ? (
            <Tag color="red" icon={<CloseOutlined />}>
              Đã từ chối
            </Tag>
          ) : record?.status === 'APPROVE' ? (
            <Tag color="green" icon={<CheckOutlined />}>
              Đã duyệt
            </Tag>
          ) : (
            <Tag color="warning" icon={<ClockCircleOutlined />}>
              Chờ xử lý
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'custom',
      align: 'center',
      width: '12%',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip
              title={formatMessage({ id: 'general_tooltip_show_infomation' })}
            >
              <EyeOutlined
                className="purple-color"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleOpenModal(record?.request_id)}
              />
            </Tooltip>
            {/* {record.status === StatusCustomerRequest.PENDING && (
              <>
                <Tooltip
                  title={formatMessage({ id: 'general_tooltip_accept' })}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <CheckOutlined
                      className="green-color"
                      onClick={() =>
                        handleApproveRequest(
                          record?.spending,
                          record?.request_id,
                        )
                      }
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={formatMessage({ id: 'general_tooltip_denied' })}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <StopOutlined
                      className="red-color"
                      onClick={() => handleRejectRequest(record?.request_id)}
                    />
                  </div>
                </Tooltip>
              </>
            )} */}
          </div>
        );
      },
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo mã hóa đơn, userId, tên user, id DN, tên DN"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item
          name="status"
          initialValue={ENTERPRISE_CUSTOMER_REQUEST_STATUS[0].value}
          className={styles.searchItem}
        >
          <Select onChange={submit} placeholder="Trạng thái" allowClear>
            {ENTERPRISE_CUSTOMER_REQUEST_STATUS.map((item, i) => (
              // eslint-disable-next-line react/jsx-key
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'enterprise_customer_request' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
            rowKey={(item) => item.request_id}
          />
        )}
      </div>
      {isOpenDetail && (
        <Modal
          title="Chi tiết yêu cầu"
          width="1400px"
          footer={null}
          open={isOpenDetail}
          onCancel={() => {
            setIsOpenDetail(false);
          }}
        >
          <CustomerRequestDetail
            refresh={refresh}
            id={selectedId}
            idArr={tableProps.dataSource?.map((item: any) => item.request_id)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CustomerLocabonusRequest;
