import React, { useState } from 'react';
import {
  Form,
  Input,
  Divider,
  Switch,
  Space,
  Button,
  Select,
  Upload,
  message,
  Row,
  Breadcrumb,
} from 'antd';
import { Link, history } from 'umi';

import styles from './index.less';
import type { UploadProps } from 'antd/es/upload/interface';
import { LeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  ICreateBanner,
  requestCreateBanner,
  useBanner,
  useBuilding,
} from '../service';
import { ENVIRONMENTS } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
import axios from 'axios';

// const { Option } = Select;
export enum ClickAction {
  IFRAME = 1,
  POPUP = 2,
  NO = 3,
}
export default () => {
  const [form] = Form.useForm();
  const { building } = useBuilding();
  const [loadingIMG, setLoadingIMG] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [loading, setLoading] = useState<Boolean>(false);
  const { banner, getBanner, loadingBanner } = useBanner();
  const onCreateBanner = useRequest(
    (payload: ICreateBanner) => requestCreateBanner(payload),
    {
      manual: true,
      onSuccess: () => {
        setLoading(false);
        message.success('thành công');
        history.push('/billboards');
      },
      onError: (err: any) => {
        setLoading(false);
        message.error(err?.data?.message);
      },
    },
  );
  const onFinish = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const data = {
      ...values,
      image: imageUrl,
    };
    onCreateBanner.run(data);
  };

  const onChangeAvatar = useRequest(
    (value: any) => {
      return axios.post(ENVIRONMENTS.API_URL + API_PATH.UPLOAD_DEV, value);
    },
    {
      manual: true,
      onSuccess: (res: any) => {
        setLoadingIMG(false);
        setImageUrl(`${res.data.url}${res.data.path}`);
        // const urlImage = `${res.data.url}${res.data.path}`;
        // form.setFieldValue('image', urlImage);
      },
    },
  );

  const handleChange: UploadProps['onChange'] = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadingIMG(true);
      return;
    }
    if (info.file.status === 'done') {
      const formData = new FormData();
      formData.append('file', info.file.originFileObj);
      onChangeAvatar.run(formData);
    }
  };

  const uploadButton = (
    <div>
      {loadingIMG ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/billboards" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Tạo biển quảng cáo
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Divider orientation="left">Thông tin chung</Divider>
        <div className={styles.formInfoGeneral}>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={styles.formSearch}
            layout="vertical"
            initialValues={{
              action: ClickAction.IFRAME,
              status: true,
            }}
          >
            <Form.Item
              name="name"
              label="Tên biển quảng cáo"
              rules={[
                {
                  required: true,
                  message: 'Tên biển quảng cáo không được để trống',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="building_id"
              label="Toà nhà"
              className={styles.locationNFT}
            >
              <Select
                placeholder="Lựa chọn toà nhà"
                options={building?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                onChange={(data) => getBanner(data)}
              />
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building = getFieldValue('building_id');
                const isDisabled = building && !loadingBanner ? false : true;
                return (
                  <Form.Item
                    name="building_entities_id"
                    label="Vị trí biển quảng cáo"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (building && !value) {
                            return Promise.reject(
                              new Error('Vui lòng chọn vị trí biển quảng cáo'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Search to Select"
                      options={banner?.map((banner: any) => {
                        return {
                          value: banner.id,
                          label: banner.name,
                        };
                      })}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Upload
              name="image"
              listType="picture-card"
              className={styles.uploadAvatar}
              showUploadList={false}
              onChange={handleChange}
              accept=".png,.jpg,.jpeg"
              // onPreview={onPreview}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.building_id !== curValues.building_id
              }
            >
              {({ getFieldValue }) => {
                const building_id = getFieldValue('building_id');
                const template = building?.find(
                  (item: any) => item.id === building_id,
                );
                if (!building_id) return;
                return (
                  <div className={styles.preview}>
                    <p>Preview vị trí biển quảng cáo</p>
                    <iframe
                      src={`https://nft.locamos.com/${template?.vr_oid}`}
                      title="preview"
                      width="100%"
                      height="600"
                    />
                  </div>
                );
              }}
            </Form.Item>
            <Divider orientation="left">Tuỳ chọn tương tác</Divider>
            <Form.Item name="click_action" label="Click action">
              <Select
                options={[
                  {
                    value: ClickAction.IFRAME,
                    label: 'Embed Iframe',
                  },
                  {
                    value: ClickAction.POPUP,
                    label: 'Popup nội dung',
                  },
                  {
                    value: ClickAction.NO,
                    label: 'Không',
                  },
                ]}
              />
            </Form.Item>

            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const action = getFieldValue('click_action');
                if (action === ClickAction.IFRAME) {
                  return (
                    <Form.Item name="content" label="URL hiển thị">
                      <Input placeholder="URL được mở khi ấn vào quảng cáo" />
                    </Form.Item>
                  );
                }
                if (action === ClickAction.POPUP) {
                  return (
                    <Form.Item name="content" label="Nội dung hiển thị">
                      <Input placeholder="Nhập nội dung" />
                    </Form.Item>
                  );
                }
                return '';
              }}
            </Form.Item>
            <Space size={15}>
              <p>Trạng thái</p>
              <Form.Item
                name="status"
                className={styles.status}
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Space>

            <Row justify="center">
              <Button
                className="btn-submit"
                type="primary"
                ghost
                danger
                onClick={() => {
                  history.push('/billboards');
                }}
              >
                Huỷ
              </Button>
              <Button
                className="btn-submit"
                type="primary"
                htmlType="submit"
                loading={onCreateBanner.loading}
              >
                Lưu
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
