import React, { useState } from 'react';
import { Button, Form, InputNumber, Modal, Row, message } from 'antd';
import { useIntl, useParams, useRequest } from 'umi';
import { withdrawUserPoint } from '../../service';
import { formatInputNumber } from '@/utils/apis/common';
export const WithdrawDialog = ({
  totalLB,
  open,
  onClose,
  refresh,
}: {
  totalLB?: number;
  open: boolean;
  onClose: () => void;
  refresh: () => void;
}) => {
  const params = useParams<{ id: string }>();
  const { id } = params;
  const withdraw = useRequest(withdrawUserPoint, {
    manual: false,
    onSuccess: (res) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN')
          message.error('Không có quyền');
        else if (res?.errors[0]?.extensions?.code === 'BAD_USER_INPUT') {
          message.error(res?.errors[0]?.extensions?.response?.message);
        }
      } else {
        if (res?.data.withdraw_user_point) {
          message.success('Thu hồi Locabonus thành công');
        } else {
        }
        refresh();
      }
      onClose();
    },
    formatResult: (res) => res,
  });
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const onFinish = (value: any) => {
    Modal.confirm({
      icon: null,
      content: `${value?.amount} LocaBonus sẽ được chuyển về quỹ DAO và không thể hoàn tác. Bạn có chắc chắn muốn thu hồi LocaBonus?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        loading: withdraw.loading,
      },
      onOk() {
        const payload = { userId: +id, amount: value.amount };
        withdraw.run(payload);
      },
    });
  };

  return (
    <Modal
      title="Xác nhận thu hồi Locabonus"
      open={open}
      footer={null}
      closable
      onCancel={onClose}
    >
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          label="Số Locabonus muốn thu hồi"
          name="amount"
          initialValue={totalLB}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập',
            },
            // {
            //   validator(rule, value) {
            //     if (Number(value) > Number(totalLB)) {
            //       return Promise.reject('Giá trị không hợp lệ');
            //     }
            //     return Promise.resolve();
            //   },
            // },
          ]}
        >
          <InputNumber
            formatter={formatInputNumber}
            controls={false}
            min={0}
            style={{
              width: '200px',
            }}
          />
        </Form.Item>
        <Row justify="center">
          <Button
            type="primary"
            danger
            ghost
            onClick={onClose}
            className="btn-submit"
          >
            Hủy
          </Button>
          <Button type="primary" htmlType="submit" className="btn-submit">
            Xác nhận
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
