import {
  Form,
  Input,
  DatePicker,
  Breadcrumb,
  Image,
  message,
  Table,
  Modal,
  Button,
  Upload,
  UploadFile,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
import styles from './index.less';
import { useIntl, useRequest } from 'umi';
import { useAntdTable } from 'ahooks';
import { deleteBlacklistFace, getDataBlacklist } from './service';
import { ColumnsType } from 'antd/lib/table';
import { ENVIRONMENTS } from '@/utils/constant';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DialogAddFaceBlacklist } from './Components/DialogAddFaceBlacklist';

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
interface DataType {
  id: number;
  image: string;
  created_at: string;
}
const BlacklistFace = () => {
  const [form] = Form.useForm();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const { formatMessage } = useIntl();
  const { tableProps, refresh, search } = useAntdTable(getDataBlacklist, {
    form,
    onSuccess: (res) => {},
  });

  const { submit } = search;

  const deleteFace = useRequest(deleteBlacklistFace, {
    manual: true,
    onSuccess: (res: any) => {
      message.success('Xóa thành công');
      refresh();
    },
    onError(err) {
      message.error('Có lỗi xảy ra');
    },
  });

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo ID User"
            allowClear
            onSearch={submit}
          />
        </Form.Item>

        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={() => setIsOpenAddModal(true)}>
        Thêm ảnh
      </Button>
    </div>
  );

  const handleDeleteFace = (id: number) => {
    return Modal.confirm({
      icon: null,
      title: 'Xác nhận xóa',
      content: 'Bạn muốn xóa ảnh khỏi blacklist khuôn mặt không ?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        deleteFace.run(id);
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (_, record) => (
        <>{dayjs(record.created_at).format('HH:mm:ss DD/MM/YYYY')}</>
      ),
    },
    {
      title: 'ID User',
      align: 'right',
      dataIndex: 'name',
    },
    {
      title: 'Ảnh',
      render: (_, record) => (
        <>
          <Image
            src={record?.image}
            style={{
              width: '50px',
              height: '50px',
            }}
          />
        </>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <>
          <DeleteOutlined
            className="red-color"
            onClick={() => handleDeleteFace(record.id)}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Blacklist khuôn mặt</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
        />
      </div>
      <DialogAddFaceBlacklist
        refresh={refresh}
        isOpen={isOpenAddModal}
        setIsOpen={setIsOpenAddModal}
      />
    </div>
  );
};

export default BlacklistFace;
