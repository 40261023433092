import {
  Form,
  Input,
  Switch,
  Space,
  Tabs,
  Select,
  Spin,
  Image,
  Breadcrumb,
} from 'antd';
import { Link, history, useParams } from 'umi';

import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import Stall from '../Components/Stall';
import BillBoards from '../Components/BillBoards';
import { useGetBuildingTemplate, useGetNFT, requesBuilding } from '../service';
import { useRequest } from 'ahooks';
import { LeftOutlined } from '@ant-design/icons';
export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { buildingTemplate } = useGetBuildingTemplate();
  const { address } = useGetNFT();
  const { data, loading } = useRequest(() => requesBuilding(param.id), {
    onSuccess: (res) => {
      form.setFieldsValue(res);
    },
  });

  if (loading)
    return (
      <div>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>
            <Link to="/loca-building" className={styles.previousLink}>
              <LeftOutlined />
            </Link>
            Chi tiết building
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.loading}>
          <Spin />
        </div>
      </div>
    );
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/loca-building" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Chi tiết building
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin chung" key="1">
            <div className={styles.formInfoGeneral}>
              <Form
                form={form}
                autoComplete="off"
                className={styles.formSearch}
                layout="vertical"
                initialValues={{
                  status: true,
                }}
                disabled
              >
                <Form.Item name="name" label="Tên toà nhà">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address_nft"
                  label="Địa điểm NFT"
                  className={styles.locationNFT}
                >
                  <Select
                    placeholder="Search to Select"
                    options={address?.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.address_nft !== curValues.address_nft
                  }
                >
                  {({ getFieldValue }) => {
                    const value = getFieldValue('address_nft');
                    const addressNFT = address?.find(
                      (item: any) => item.id === Number(value),
                    );
                    const lat = addressNFT ? addressNFT?.lat : data?.lat_nft;
                    const long = addressNFT ? addressNFT?.long : data?.long_nft;
                    const user_name_owner = addressNFT
                      ? addressNFT?.user_name_owner?.username
                      : data?.user_owner_nft;
                    if (!value) return;
                    return (
                      <div className={styles.infoNFT}>
                        <p>lat: {lat}</p>
                        <p>long: {long}</p>
                        <p>Chủ sở hữu: {user_name_owner}</p>
                      </div>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  name="building_template_id"
                  label="Toà nhà"
                  className={styles.locationNFT}
                >
                  <Select
                    placeholder="Search to Select"
                    options={buildingTemplate?.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item name="image" label="Ảnh tòa nhà">
                  <Image
                    src={form.getFieldValue('image')}
                    height={300}
                    width={300}
                  />
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.building_template_id !==
                    curValues.building_template_id
                  }
                >
                  {({ getFieldValue }) => {
                    const building = getFieldValue('building_template_id');
                    const template = buildingTemplate?.find(
                      (item: any) => item.id === Number(building),
                    );
                    if (!building) return;
                    return (
                      <div className={styles.preview}>
                        <p>Preview Toà nhà</p>
                        <iframe
                          src={`https://nft.locamos.com/${template?.vr_oid}`}
                          title="preview"
                          width="100%"
                          height="600"
                        />
                      </div>
                    );
                  }}
                </Form.Item>
                <Space size={15}>
                  <p>Trạng thái</p>
                  <Form.Item
                    name="status"
                    className={styles.status}
                    valuePropName="checked"
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </TabPane>
          <TabPane tab="Gian hàng" key="2">
            <Stall shop={data?.shopLocation} detail={true} />
          </TabPane>
          <TabPane tab="Biển quảng cáo" key="3">
            <BillBoards banner={data?.bannerLocation} detail={true} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
