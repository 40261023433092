import { Tabs } from 'antd';
import React, { useMemo, useState } from 'react';
import BannerApp from './BannerApp';
import BannerMeet from './BannerMeet';
import StatisticBanner from './StatisticBanner';
import styles from '../../index.less';
import { history, useLocation } from 'umi';
const BannerConfig = () => {
  const location = useLocation();
  const renderTab = () => {
    switch (location.pathname) {
      case '/setting/banner':
        return <BannerApp />;
      case '/setting/banner/meet':
        return <BannerMeet />;
      case '/setting/banner/statistic':
        return <StatisticBanner />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane tab="Màn Home" key="/setting/banner"></Tabs.TabPane>
        <Tabs.TabPane tab="Màn Meet" key="/setting/banner/meet"></Tabs.TabPane>
        {/* <Tabs.TabPane
          tab="Thống kê"
          key="/setting/banner/statistic"
        ></Tabs.TabPane> */}
      </Tabs>
      {renderTab()}
    </>
  );
};

export default BannerConfig;
