import MarkerCluster from './MarkerCluster';
import { MarkerType, text } from './type';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import style from './map.module.less';

import LocationMarker from './LocationMarker';
import classNames from 'classnames';
import { toLowerCaseNonAccentBs } from '@/utils/apis/common';

const geocoder = new google.maps.Geocoder();

const listPosition = [];

const MapCenterUpdater = ({ position, zoom }: any): any => {
  const map = useMap();
  useEffect(() => {
    if (position?.lat && position?.long) {
      map.setView(position, zoom);
    }
  }, [map, position, zoom]);
  return null;
};

const MapNftComponent = ({
  lang,
  country,
  listLocation,
  loading,
  setPos,
  mapItemDetail,
}: any) => {
  const mapDefaultCenterPos: number[] = [21.02274, 105.836964];
  const [itemPopup, setItemPopup] = useState(mapItemDetail);
  // const [filters, setFilters] = useState([...listPosition]);
  const [nameAddress, setNameAddress] = useState({
    nameDistrict: '',
    nameProvince: '',
  });

  const [mapType, setMapType] = useState('m');
  const [mapCenterPos, setMapCenterPos] = useState({
    lat: mapDefaultCenterPos[0],
    lng: mapDefaultCenterPos[1],
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(13);
  const [mapSearchString, setMapSearchString] = useState('');
  const [mapSearchPrevString, setMapSearchPrevString] = useState('');
  const [mapSearchResult, setMapSearchResult] = useState([]);
  const [mapSearchResultShow, setMapSearchResultShow] = useState(false);

  const [markerPickerDefaultPos, setMarkerPickerDefaultPos] = useState({
    lat: null,
    lng: null,
    address: '',
  });

  // const [listMarkerInfos] = useState([]);

  // const updateFilter = (index: number) => {
  //   const list = [...filters];
  //   list[index].active = !list[index].active;
  //   setFilters(list);
  // };

  useEffect(() => {
    if (country) {
      setMapCenterPos(country.position);
    }
    if (mapItemDetail) {
      setMapCenterPos({ lat: mapItemDetail.lat, lng: mapItemDetail.long });
    }
  }, [country]);

  function handleMapTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const map_type = e.target.value;
    setMapType(map_type);
  }

  function handleMapSearchStringInput(e: React.ChangeEvent<HTMLInputElement>) {
    setMapSearchString(e.target.value);
    setMapSearchResultShow(false);
  }

  function handleMapSearchEnter(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitSearch();
    }
  }

  function submitSearch() {
    if (mapSearchString !== mapSearchPrevString) {
      geocoder.geocode(
        { address: mapSearchString },
        function (results: any, status) {
          if (status == 'OK') {
            setMapSearchPrevString(mapSearchString);
            setMapSearchResult(results);
            setMapSearchResultShow(true);
          } else {
            setMapSearchResult([]);
          }
        },
      );
    } else {
      setMapSearchResultShow(true);
    }
  }

  function renderMapSearchResults(list: any[]) {
    if (!list || list.length === 0) {
      return null;
    }

    function zoomMapToPos(
      lat: number,
      lng: number,
      address: string,
      item: any,
    ) {
      let nameDistrict;
      let nameProvince;
      if (lat && lng) {
        setMapCenterPos([lat + 0.0012348989, lng] as any);
        setMapZoomLevel(15);
        setMarkerPickerDefaultPos({ lat, lng, address } as any);
        setMapSearchResultShow(false);
      }

      item?.address_components.forEach((add: any) => {
        if (
          add?.types[0] === 'administrative_area_level_2' ||
          add?.types[0] === 'locality'
        ) {
          nameDistrict = toLowerCaseNonAccentBs(add?.long_name);
        }
        if (add?.types[0] === 'administrative_area_level_1') {
          nameProvince = toLowerCaseNonAccentBs(add?.long_name);
        }
      });
      setNameAddress({ nameDistrict, nameProvince } as any);
    }

    const res: any[] = [];
    list.forEach((item: any, index: number) => {
      res.push(
        <div
          key={'mapSearchRes_' + index}
          className="map-search-result-item"
          onClick={() => {
            zoomMapToPos(
              item.geometry.location.lat(),
              item.geometry.location.lng(),
              item.formatted_address,
              item,
            );
          }}
        >
          {item.formatted_address} ({item.geometry.location.lat()},{' '}
          {item.geometry.location.lng()})
        </div>,
      );
    });

    return res;
  }

  return (
    <div id="map">
      <div className={`map-loading-indicator ${loading ? 'show' : ''}`}>
        Loading map data... <i className="far fa-cog fa-spin"></i>
      </div>
      {/* {country && !loading ? (
        <button
          className="btn btn-sm btn-light bg-white btn-select-country-on-maps"
          data-bs-toggle="modal"
          data-bs-target="#selectCountryModal"
        >
          <span
            className="select-country-flag"
            style={{ backgroundImage: 'url("' + country.flag_image + '")' }}
          ></span>
          <span className="select-country-label">{country.display_name}</span>
        </button>
      ) : (
        <></>
      )} */}
      <div className="map-search-box">
        <div className="input-group">
          <div
            className={style.prefixSearch}
            id="button-addon2"
            onClick={submitSearch}
          >
            <img
              src="/static/icons/icon_search.svg"
              width={18}
              height={18}
              alt="icon_search"
            />
          </div>
          <input
            type="text"
            className="form-control form-control-lg bg-white"
            id="mapSearchInput"
            placeholder={text.dashboard.map_search_input_placeholder[lang]}
            onChange={handleMapSearchStringInput}
            onKeyDown={handleMapSearchEnter}
          ></input>
        </div>
        <div
          className={`map-search-results ${mapSearchResultShow ? 'show' : ''}`}
        >
          {renderMapSearchResults(mapSearchResult)}
        </div>
        {/* <div className={style.mapInfo}>
          {filters?.map((item: any, index) => (
            <div
              key={index}
              className={classNames(
                { [style.mapItem]: true },
                { [style.mapItem__active]: item.active },
              )}
              onClick={() => updateFilter(index)}
            >
              <div className={style.icon}>{item.icon}</div>
              <div className={style.nameIcon}>
                <div>{item.name}</div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <div className="map-style-selector">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input d-none"
            type="radio"
            name="mapTypeSelector"
            id="mapTypeStreet"
            value="m"
            defaultChecked={true}
            onChange={handleMapTypeChange}
          ></input>
          <label className="form-check-label" htmlFor="mapTypeStreet">
            <img
              src="/public_assets/images/map-type-street.png"
              alt=""
              className="img-fluid map-style-img"
            />
            <span className="map-style-label">
              {text.dashboard.map_type_street[lang]}
            </span>
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input d-none"
            type="radio"
            name="mapTypeSelector"
            id="mapTypeSatelite"
            value="s,h"
            onChange={handleMapTypeChange}
          ></input>
          <label className="form-check-label" htmlFor="mapTypeSatelite">
            <img
              src="/public_assets/images/map-type-satelite.png"
              alt=""
              className="img-fluid map-style-img"
            ></img>
            <span className="map-style-label text-white">
              {text.dashboard.map_type_satelite[lang]}
            </span>
          </label>
        </div>
      </div>
      <MapContainer
        className="nft-maps"
        center={mapDefaultCenterPos as any}
        zoom={13}
        scrollWheelZoom={true}
        zoomControl={false}
        attributionControl={false}
        preferCanvas={true}
      >
        <TileLayer
          attribution=""
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url='https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?apiKey=Bt771_BaSy5e0qyE7qowpIcNoiJ9lIqgk3zfmkJdso4'
          url={`http://{s}.google.com/vt/lyrs=${mapType}&x={x}&y={y}&z={z}`}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />

        <MarkerCluster
          markers={listLocation}
          // filters={filters}
          mapItemDetail={mapItemDetail}
        />
        <LocationMarker
          defaultPostion={markerPickerDefaultPos}
          setPos={setPos}
          nameAddress={nameAddress}
          setNameAddress={setNameAddress}
          setItemPopup={setItemPopup}
        />
        <MapCenterUpdater position={mapCenterPos} zoom={mapZoomLevel} />
      </MapContainer>
    </div>
  );
};

export default MapNftComponent;
