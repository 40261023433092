import { EyeOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Breadcrumb, Form, Input, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { getTableData } from './service';
import styles from './index.less';
import { useHistory, useIntl } from 'umi';
interface DataType {
  id: number;
  name: string;
  count_user: number;
  users: UserType;
  created_at: string;
  updated_at: string;
}
interface UserType {
  full_name: string;
  email: string;
  phone: string;
}
const Company = () => {
  const [isOpenList, setIsOpenList] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search } = useAntdTable(getTableData, {
    form,
    onSuccess: (res) => {},
  });
  const { submit } = search;
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên công ty',
      dataIndex: 'name',
    },
    {
      title: 'Số thành viên',
      dataIndex: 'count_user',
    },
    {
      title: 'Xem danh sách',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <>
          <EyeOutlined
            onClick={() => {
              history.push('/company/' + record.id);
            }}
          />
        </>
      ),
    },
  ];
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchItem}>
        <Form.Item name="name">
          <Input.Search
            placeholder={'Tìm kiếm tên công ty'}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({
            id: 'navigation_list_company',
          })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table {...tableProps} columns={columns} />
      </div>
    </div>
  );
};

export default Company;
