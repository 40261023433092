import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useIntl, useHistory, useRequest, useParams } from 'umi';
import styles from '../index.less';
import { getCustomerHistory } from '../service';
import { formatNumber } from '@/utils/apis/common';
import { CUSTOMER_BIZ_PER_DAY } from '@/utils/constant';
const { Option } = Select;
interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: string;
}
const EnterpriseCustomer = () => {
  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const { id } = params;
  const { tableProps, search, error, loading } = useAntdTable(
    getCustomerHistory,

    {
      defaultParams: [
        {
          pageSize: 10,
          current: 1,
          enterprise_id: +id,
        },
        {
          fullName: '',
        },
      ],
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { submit } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_customer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'const_column_phone_number',
      dataIndex: 'phone',
      align: 'right',
      key: 'phone',
    },
    {
      title: 'Số lượt quét/ngày',
      dataIndex: 'requestCount',
      align: 'right',
    },
    {
      title: 'const_column_use_service',
      align: 'right',
      dataIndex: 'checkin_count',
      key: 'checkin_count',
      render: (_: any, record: any) => (
        <>{formatNumber(record?.checkin_count)}</>
      ),
    },
    {
      title: 'const_column_gift',
      align: 'right',
      dataIndex: 'get_point_count',
      key: 'get_point_count',
      render: (_: any, record: any) => (
        <>{formatNumber(record?.get_point_count)}</>
      ),
    },
    {
      title: 'const_column_point_target',
      align: 'right',
      dataIndex: 'temporary_spending',
      key: 'get_point_count',
      render: (_: any, record: any) => (
        <>{formatNumber(record?.temporary_spending)}</>
      ),
    },
    {
      title: 'const_column_cumulative_target',
      align: 'right',
      dataIndex: 'accrual_spending',
      key: 'get_point_count',
      render: (_: any, record: any) => (
        <>{formatNumber(record?.accrual_spending)}</>
      ),
    },
    {
      title: 'const_column_loca_received',
      align: 'right',
      dataIndex: 'total_get_point',
      key: 'total_get_point',
      render: (_: number, record: any) => (
        <>{formatNumber(record?.total_get_point)}</>
      ),
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_admin' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="biz_per_day"
          className={styles.searchItem}
          // initialValue={StatusKyc.PENDING}
        >
          <Select onChange={submit} allowClear placeholder="Số lượt quét/ngày">
            {CUSTOMER_BIZ_PER_DAY.map((item) => (
              <Option value={item.value}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {/* <Typography.Text>Tổng Loca Bonus: {numberLoca}</Typography.Text> */}
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'customer_list_go_shop' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
      {/* {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};

export default EnterpriseCustomer;
