import { API_PATH, privateRequest, request } from '@/utils/apis';

export const getListKiotBranches = (searchValue?: string) => {
  return privateRequest(request.get, API_PATH.KIOTVIET_BRANCHES, {
    params: {
      searchValue,
    },
  });
};

export const getListKiotBranchesV2 = (searchValue?: string) => {
  return privateRequest(request.get, API_PATH.KIOTVIET_LOCAMART_BRANCHES, {
    params: {
      name: searchValue,
    },
  });
};
