import React, { useEffect, useState } from 'react';
import styles from '../index.less';
import { Button, Form, Input, Modal, Row, Table } from 'antd';
import { useAntdTable } from 'ahooks';
import { UserGroup, getListGroupUser } from '../service';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  setGroupUser: React.Dispatch<React.SetStateAction<UserGroup[]>>;
  selectedGroup: any[];
}
const SelectGroupUserModal = ({
  isOpen,
  onClose,
  setGroupUser,
  selectedGroup,
}: IProps) => {
  const [formSearch] = Form.useForm();
  const [selectGroupUser, setSelectGroupUser] = useState<UserGroup[]>([]);
  const { tableProps, search } = useAntdTable(getListGroupUser, {
    form: formSearch,
  });
  const onConfirm = () => {};
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={formSearch} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder={'Tìm kiếm them tên nhóm user'}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  useEffect(() => {
    setSelectGroupUser(selectedGroup);
  }, []);
  const rowSelection: TableRowSelection<any> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectGroupUser(
        selectedRows.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      );
    },
    selectedRowKeys: selectGroupUser?.map((item) => item.id),
  };
  const columnsGroup: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nhóm user',
      dataIndex: 'name',
    },
  ];
  return (
    <Modal
      footer={null}
      open={isOpen}
      onCancel={onClose}
      width={600}
      title="Chọn nhóm user"
    >
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          {...tableProps}
          columns={columnsGroup}
          rowKey={(item) => item.id}
        />
      </div>
      <Row justify="center">
        <Button
          type="primary"
          onClick={() => {
            setGroupUser(selectGroupUser);
            onClose();
          }}
          disabled={selectGroupUser.length === 0}
        >
          Xác nhận
        </Button>
      </Row>
    </Modal>
  );
};

export default SelectGroupUserModal;
