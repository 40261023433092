import { useAuth } from '@/utils/hooks/useAuth';
import { API_PATH, request } from '@/utils/apis';
import { useRequest } from 'ahooks';
import { message } from 'antd';
import { fullKeyRole } from '@/utils/constant';

export const useLogin = () => {
  const { onLogin } = useAuth();

  return useRequest(
    async (values) => {
      return request.post(API_PATH.default, {
        data: {
          query: `mutation {
            login_admin(account: { email_or_phone: "${values?.phone_number?.trim()}", password: "${
            values.password
          }" }) {
              message
              data {
                email
                phone
                id
                role {
                  id
                  role_name
                  permissions {
                    id
										${fullKeyRole()}
                  }
                } 
                token
                refresh_token
                admin_profile {
                  id
                  full_name
                  date_of_birth
                  address
                  date_modified
                  date_created
                  admin_id
                  avatar
                }
              }
            }
          }
          `,
        },
      });
    },
    {
      manual: true,
      onSuccess: (result) => {
        if (result?.errors) {
          if (result?.errors[0]?.message === 'Account is not active') {
            message.error('Tài khoản không hoạt động');
          } else message.error('Tài khoản hoặc mật khẩu không chính xác');
        }

        const loginItem = {
          token: result?.data?.login_admin?.data?.token,
          refresh_token: result?.data?.login_admin?.data?.refresh_token,
        };

        const dataAdmin = {
          admin_profile: result?.data?.login_admin?.data?.admin_profile,
          role: result?.data?.login_admin?.data?.role,
        };
        onLogin(loginItem, dataAdmin);
      },
      onError: (err) => {
        message.error(err?.message || 'Login failed');
      },
    },
  );
};
