import { useAntdTable } from 'ahooks';
import { Breadcrumb, Form, message, DatePicker, Table, Skeleton } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import { getTableData } from './service';
import { formatNumber } from '../../utils/apis/common/index';
const { RangePicker } = DatePicker;
interface DataType {
  total_release_token: number;
  release_at: string;
  difference: number;
  current_amount: number;
  total_lb_transfer_to_web: number;
}
const LocabonusRelease = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { tableProps, search, loading, error } = useAntdTable(getTableData, {
    form,
    onError: (error: any) => {
      message.error(
        error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
      );
    },
  });
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form}>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'release_at',
      align: 'right',
    },
    {
      title: 'Tổng LB đã phát hành',
      dataIndex: 'total_release_token',
      align: 'right',
      render: (_, record) => <>{formatNumber(record.total_release_token)}</>,
    },
    {
      title: 'Tổng LB trên hệ thống',
      dataIndex: 'current_amount',
      align: 'right',
      render: (_, record) => <>{formatNumber(record.current_amount)}</>,
    },
    {
      title: 'Tổng LB chuyển sang web',
      dataIndex: 'total_lb_transfer_to_web',
      align: 'right',
      render: (_, record) => (
        <>{formatNumber(record.total_lb_transfer_to_web)}</>
      ),
    },
    {
      title: 'Chênh lệch',
      dataIndex: 'difference',
      align: 'right',
      render: (_, record) => (
        <p
          style={{
            fontWeight: record.difference > 2000000 ? 600 : 'unset',
          }}
          className={record.difference > 2000000 ? 'red-color' : ''}
        >
          {formatNumber(record.difference)}
        </p>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_locabonus_release' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )}
      </div>
    </div>
  );
};

export default LocabonusRelease;
