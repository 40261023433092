import React from 'react';
import styles from '../index.less';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Skeleton,
  Table,
  Tag,
  Row,
  Tooltip,
  Switch,
  message,
} from 'antd';
import { useAntdTable } from 'ahooks';
import { getListStore } from '../service';
import { STATUS_KYC } from '@/utils/constant';
import { useIntl, useRequest } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  updateBusiness,
  updateStatusStore,
} from '@/pages/BusinessInfo/service';
import { convertTimeUnit } from '@/utils/apis/common';
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const ListStore = () => {
  const [form] = Form.useForm();
  const { tableProps, search, loading, error, refresh } = useAntdTable(
    getListStore,
    {
      form,
    },
  );
  const { formatMessage } = useIntl();
  const switchRequest = useRequest(updateStatusStore, {
    manual: true,
    onSuccess: (res) => {
      if (res?.errors) {
        if (res?.errors[0]?.message === 'Forbidden resource') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra!');
        }
        refresh();
      } else {
        message.success('Đã cập nhật trạng thái cửa hàng');
        refresh();
      }
    },
    formatResult: (res) => res,
  });
  const { submit } = search;
  const onSwitchStatus = (id: any, status: any) => {
    switchRequest.run(id);
  };
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ACCEPT':
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case 'PENDING':
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case 'REFUSE':
        return (
          <>
            <Tag color="red" icon={<CloseOutlined />}>
              Từ chối xác thực
            </Tag>
          </>
        );
      case 'NOT_REQUEST':
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
      default:
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
    }
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên, mail, SĐT"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format={dateFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái xác thực"
          >
            {STATUS_KYC?.map((item, i) => (
              <Select.Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên cửa hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'display_phone',
      key: 'display_phone',
    },
    {
      title: 'Người giới thiệu',
      dataIndex: 'ref_username',
      key: 'ref_username',
    },
    {
      title: 'Số lượt quét/ngày',
      dataIndex: 'scan_count',
      key: 'scan_count',
    },
    {
      title: 'Hoạt động',
      dataIndex: ['last_active', 'unit'],
      key: 'last_active',
      render: (value, record) => {
        switch (value) {
          case 'active': {
            return <>Đang hoạt động</>;
          }
          case 'no-information':
            return <>Không rõ</>;
          default:
            return (
              <>
                {record?.last_active?.quantity} {convertTimeUnit(value)} trước
              </>
            );
        }
      },
    },
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'requested_at',
      render: (value, record) => (
        <>{value ? dayjs(value).format('HH:mm DD/MM/YYYY') : ''}</>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'verify_status',
      key: 'verify_status',
      render: (_: any, record: any) => (
        <>{renderStatus(record?.verify_status)}</>
      ),
    },
    {
      title: 'Người duyệt',
      dataIndex: ['verified_by', 'full_name'],
      key: 'verified_by',
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Xem">
              <a href={`/business/store/${record?.id}`}>
                <EyeOutlined className="color-purple" />
              </a>
            </Tooltip>
            <Tooltip title="Trạng thái hoạt động">
              <Switch
                loading={switchRequest.loading}
                defaultChecked={record.status}
                onClick={(e: any) => {
                  onSwitchStatus(record.id, e);
                }}
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            /* @ts-ignore */
            {...tableProps}
            columns={columns}
            locale={{
              emptyText: formatMessage({ id: 'const_column_empty' }),
            }}
            rowKey={'enterprise_id'}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
    </div>
  );
};

export default ListStore;
