export enum StatusKyc {
  NOT_REQUEST = 'NOT_REQUEST',
  PENDING = 'PENDING',
  ACCEPT = 'ACCEPT',
  REFUSE = 'REFUSE',
}

export enum EnterpriseBizPerDay {
  LT_30 = 'LT_30',
  GT_30 = 'GT_30',
}

export enum CustomerBizPerDay {
  LT_10 = 'LT_10',
  GT_10 = 'GT_10',
}

export enum StatusCustomerRequest {
  PENDING = 'PENDING',
  APPROVE = 'APPROVE',
  REFUSE = 'REFUSE',
}

export enum StatusReportVoucher {
  PENDING = 'CHO_XU_LY',
  APPROVE = 'DUYET',
  REFUSE = 'TU_CHOI',
}
export enum TransferVoucherWeb {
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
  REFUSED = 'REFUSED',
  CONFIRMED = 'CONFIRMED',
  CALLBACK_ERROR = 'CALLBACK_ERROR',
}
export enum MembershipPackage {
  SILVER = 'SILVER',
  PLATINUM = 'PLATINUM',
  GOLD = 'GOLD',
  GOLD_TRIAL = 'GOLD_TRIAL',
  RUBY = 'RUBY',
  DIAMOND = 'DIAMOND',
}

export enum LinkAccountStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  USER_CANCELS = 'USER_CANCELS',
  ENTEPRISE_CANCELS = 'ENTEPRISE_CANCELS',
  CONFIRM = 'CONFIRM',
  ADMIN_CANCELS = 'ADMIN_CANCELS',
}

export enum KycApprover {
  ADMIN = 'ADMIN',
  SYSTEM = 'SYSTEM',
}

export enum BusinessType {
  FOOD_DRINK = 'FOOD_DRINK',
  RESTAURANT = 'RESTAURANT',
  HOTEL = 'HOTEL',
  FASHION = 'FASHION',
  GROCERY = 'GROCERY',
  MART = 'MART',
  HEALTHCARE = 'HEALTHCARE',
  HOUSEWARE = 'HOUSEWARE',
}

export enum EnterpriseType {
  NORMAL = 'NORMAL',
  LOCACAFE = 'LOCACAFE',
  LOCAMART = 'LOCAMART',
}

export enum BusinessScale {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export enum AverageInvoice {
  FROM50TO200 = 'FROM50TO200',
  FROM200TO500 = 'FROM200TO500',
  FROM500TO1000 = 'FROM500TO1000',
  FROM1000TO3000 = 'FROM1000TO3000',
  OVER3000 = 'OVER3000',
}

export enum LocaBonusTransferType {
  MEET_TO_EARN = 'MEET_TO_EARN',
  USE_TO_EARN = 'USE_TO_EARN',
  TRANSFER = 'TRANSFER',
  GET = 'GET',
  TRANSFER_TO_WEB = 'TRANSFER_TO_WEB',
  WITHDRAW = 'WITHDRAW',
  USER_FEE = 'USER_FEE',
  BUY_VOUCHER = 'BUY_VOUCHER',
  BUY_EVOUCHER_URBOX = 'BUY_EVOUCHER_URBOX',
  GIFT = 'GIFT',
  CALL_TO_EARN = 'CALL_TO_EARN',
}

export enum StatusLoca {
  MEET_TO_EARN = 'MEET_TO_EARN',
  USE_TO_EARN = 'USE_TO_EARN',
  WITHDRAW = 'WITHDRAW',
}
export enum StatusNofi {
  WAITING = 'WAITING',
  SEND = 'SEND',
  ERROR = 'ERROR',
  SENDING = 'SENDING',
}
export enum ROLE {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum StatusPointGiving {
  GET_BONUS_PENDING = 'GET_BONUS_PENDING',
  GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS',
  GET_BONUS_FAILED = 'GET_BONUS_FAILED',
}

export enum StatusAccount {
  ACTIVE = 'true',
  INACTIVE = 'false',
}
export enum Applicable {
  ALL = 'ALL',
  USER = 'USER',
}
export enum DisplayType {
  ON = 'ON',
  OFF = 'OFF',
}

export enum PaymentType {
  VTC_PAY = 'VTCPay',
  DOMESTIC_BANK = 'DomesticBank',
  INTERNATIONAL_CARD = 'InternationalCard',
}

export enum KycType {
  IDENTIFY_CARD = 'IDENTIFY_CARD',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
}

export enum KycPhotoType {
  FRONT_PHOTO = 'FRONT_PHOTO',
  BACK_PHOTO = 'BACK_PHOTO',
}
export enum MerchantType {
  APP = 'APP',
  WEBSITE = 'WEBSITE',
}
export enum TypeTemplateNoti {
  USER = 'USER',
  TOPIC = 'TOPIC',
}

//locamos

export enum PERMISSIONS {
  NONE = 'NONE',
  READ = 'READ',
  FULL = 'FULL',
  UPDATE = 'UPDATE',
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
}
export enum StatusDespute {
  DISPUTE = 'DISPUTE',
  REFUND = 'REFUND',
  TRANSFER = 'TRANSFER',
}
export enum StatusGiftDispute {
  DISPUTE = 'DISPUTE',
  HANDLE_TRANSFER = 'HANDLE_TRANSFER',
  HANDLE_BACK = 'HANDLE_BACK',
}

export enum AdminConfirmStatus {
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
  NOT_YET_STARTED = 'NOT_YET_STARTED',
  FINISHED = 'FINISHED',
  STARTED = 'STARTED',
}
export enum DiscountType {
  PRODUCT_DISCOUNT = 'PRODUCT_DISCOUNT',
  BILL_DISCOUNT = 'BILL_DISCOUNT',
}
