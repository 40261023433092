import { API_PATH, privateRequest, request } from '@/utils/apis';

interface Result {
  total: number;
  list: any[];
}
export const getTransforDetail = (id: number) => {
  const data = {
    query: `  
mutation {
  get_transfer_request_info(transfer_id: ${id}) {
    transfer_id
    request_user_name
    request_user_email
    request_user_phone
    request_user_code
    amount
    receiver_name
    receiver_code
    receiver_email
    receiver_phone
    transfer_content
    transfer_fee
    expired_at
    transaction_id
    disputed_at
    images
    dispute_handled
    handle_by
    can_handle
  }
}
    `,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getTransferGiftRequest = (id: number) => {
  const data = {
    query: `{
			getTransferGiftRequest(input: { id: ${id} }) {
				requestUserName
				requestUserEmail
				requestUserPhone
				time
				receiverCode
				receiverName
				receiverEmail
				receiverPhone
				giftId
				giftName
				note
				handleBy
				proofImage
				status
				id
				quantity
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const handleDisputeTransferGift = (id: number, handle: boolean) => {
  const data = {
    query: `mutation {
			handleDisputeTransferGift(input: { id: ${id}, handle: ${handle} }) {
				message
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};
