import React, { useEffect } from 'react';
import { Link, formatMessage, useIntl, useParams, useRequest } from 'umi';
import { getTransferGiftRequest, handleDisputeTransferGift } from '../service';
import {
  Breadcrumb,
  Card,
  Form,
  Input,
  Row,
  Typography,
  Image,
  Col,
  Button,
  Skeleton,
  Modal,
  message,
} from 'antd';
import styles from '../index.less';
import { LeftOutlined } from '@ant-design/icons';
import {
  ENVIRONMENTS,
  STATUS_DESPUTE,
  STATUS_GIFT_DISPUTE,
} from '@/utils/constant';
import { StatusGiftDispute } from '@/utils/enum';
const { Title } = Typography;
const DisputeGift = () => {
  const { formatMessage } = useIntl();
  const params: { id: string } = useParams();
  const { id } = params;
  const [form] = Form.useForm();
  const detailDisputeGift = useRequest(getTransferGiftRequest, {
    manual: true,
    onSuccess(res) {
      form.setFieldsValue({
        ...res?.getTransferGiftRequest,
        status: checkNameStatus(res?.getTransferGiftRequest?.status),
      });
    },
  });
  useEffect(() => {
    detailDisputeGift.run(+id);
  }, [id]);

  const checkNameStatus = (record: any) => {
    const status = STATUS_GIFT_DISPUTE.find((item) => item.value === record);
    return status?.name ? status.name : '';
  };

  const handleDisputeGift = useRequest(handleDisputeTransferGift, {
    manual: true,
    onSuccess(res) {
      if (res.errors) {
        message.error('Có lỗi xảy ra');
      } else {
        message.success('Thành công');
        detailDisputeGift.refresh();
      }
    },
  });

  const onDisputeGift = (id: number, handle: boolean) => {
    Modal.confirm({
      icon: null,
      content: `Bạn có chắc chắn muốn ${
        handle ? 'xác nhận chuyển quà' : 'hoàn lại quà'
      } không ?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onOk() {
        handleDisputeGift.run(id, handle);
      },
    });
  };

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/handle_dispute_gift" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          {'Chi tiết tranh chấp quà'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        {detailDisputeGift && !detailDisputeGift.loading ? (
          <Card
            // title={'Thông tin người chuyển'}
            size="small"
            className={styles.primaryCard}
          >
            <Form
              labelWrap={true}
              form={form}
              labelAlign="left"
              wrapperCol={{
                span: 10,
              }}
              labelCol={{
                span: 5,
              }}
            >
              <Title level={5}>Thông tin người chuyển</Title>
              <Form.Item
                className={styles.formItem}
                label="Người chuyển"
                name="requestUserName"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="requestUserEmail"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Số điện thoại"
                name="requestUserPhone"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Title level={5}>Thông tin nguời nhận</Title>
              <Form.Item
                className={styles.formItem}
                label="Người chuyển"
                name="receiverName"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="receiverEmail"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Số điện thoại"
                name="receiverPhone"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Title level={5}>Chi tiết giao dịch</Title>
              <Form.Item
                className={styles.formItem}
                label="Tên quà"
                name="giftName"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Nội dung"
                name="note"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Mã giao dịch"
                name="id"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                label="Trạng thái"
                name="status"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label="Người xử lý tranh chấp"
                name="handleBy"
              >
                <Input
                  readOnly
                  style={{
                    border: 'none',
                  }}
                />
              </Form.Item>
            </Form>
            {detailDisputeGift && !detailDisputeGift.loading ? (
              <>
                <Title level={5}>Ảnh chụp xác minh</Title>
                <Row gutter={[24, 24]}>
                  <Image
                    wrapperClassName={styles.antImage}
                    style={{
                      width: '100px',
                      height: '100px',
                    }}
                    src={
                      ENVIRONMENTS.BUCKET_NAME +
                      '/' +
                      detailDisputeGift.data?.getTransferGiftRequest?.proofImage
                    }
                  />
                </Row>

                {detailDisputeGift.data?.getTransferGiftRequest?.status ===
                  StatusGiftDispute.DISPUTE && (
                  <Row
                    align="middle"
                    justify="center"
                    className={styles.submitButtonGroup}
                  >
                    <Col>
                      <Button
                        danger
                        onClick={() => onDisputeGift(+id, false)}
                        className={styles.addButton}
                      >
                        Hoàn quà
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => onDisputeGift(+id, true)}
                        className={styles.addButton}
                      >
                        Chuyển quà
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <Skeleton active />
            )}
          </Card>
        ) : (
          <Skeleton active />
        )}
      </div>
    </>
  );
};

export default DisputeGift;
