import {
  OPTION_STATUS_ACTIVE,
  STATUS_ACCOUNT,
  STATUS_KYC,
} from '@/utils/constant';
import { StatusAccount } from '@/utils/enum';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LockOutlined,
  UndoOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useHistory, useIntl, useRequest } from 'umi';
import Dialog from './Dialog';
import styles from './index.less';
import {
  changeUserStatus,
  deleteUserData,
  getTableData,
  openCheckpoint,
  restoreUserData,
} from './service';
import dayjs from 'dayjs';
// import { useRequest } from 'umi';

const { Option } = Select;

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const { RangePicker } = DatePicker;

const Users = () => {
  const [openDialog, setOpenDialog] = useToggle(false);
  const [rejectDialog, setRejectDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const { push } = useHistory();
  const [form] = Form.useForm();

  const dataFormat = 'DD/MM/YYYY';
  const { tableProps, search, params, refresh, loading, error } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const handleViewUser = (idUser: number | string) => {
    push('/user-management/' + idUser);
  };
  const handleEditUser = (idUser: number | string) => {
    push('/user-management/' + idUser);
  };

  ////deleteuser
  const deleteRequest = useRequest(deleteUserData, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('có lỗi xảy ra');
        }
      } else {
        message.success(formatMessage({ id: 'message_success' }));
      }
      refresh();
    },
    formatResult: (res) => res,
  });

  const changeStatus = useRequest(changeUserStatus, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('có lỗi xảy ra');
        }
      } else {
        message.success(formatMessage({ id: 'message_success' }));
      }
      formChangeStatus.resetFields();
      refresh();
    },
    formatResult: (res) => res,
  });

  const restoreRequest = useRequest(restoreUserData, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('có lỗi xảy ra');
        }
      } else {
        message.success(formatMessage({ id: 'message_success' }));
      }
      refresh();
    },
    formatResult: (res) => res,
  });

  const changeCheckpoint = useRequest(openCheckpoint, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('có lỗi xảy ra');
        }
        formCheckpoint.resetFields();
      } else {
        message.success(formatMessage({ id: 'message_success' }));
        formCheckpoint.resetFields();
      }
      refresh();
    },
    formatResult: (res) => res,
  });

  const handleAcceptUser = (idUser: number) => {
    Modal.confirm({
      icon: null,
      content: formatMessage({ id: 'message_delete' }),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      onOk() {
        deleteRequest.run(idUser);
      },
    });
  };

  const handleRestoreUser = (idUser: number) => {
    Modal.confirm({
      icon: null,
      content: formatMessage({ id: 'message_restore' }),
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      onOk() {
        restoreRequest.run(idUser);
      },
    });
  };

  const [formChangeStatus] = Form.useForm();

  const handleChangeUserStatus = (idUser: number, status: boolean) => {
    const onSubmitChangeStatus = (value: any) => {
      changeStatus.run(idUser, status, value?.reason);
    };
    Modal.confirm({
      icon: null,
      title: `Bạn có chắc chắn muốn cập nhật trạng thái ${
        status ? 'hoạt động' : 'không hoạt động'
      } cho tài khoản`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      content: (
        <Form
          id="formChangeStatus"
          layout="vertical"
          form={formChangeStatus}
          onFinish={onSubmitChangeStatus}
        >
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formChangeStatus',
        loading: changeStatus.loading,
      },

      onCancel() {
        formChangeStatus.resetFields();
      },
      onOk() {
        if (formChangeStatus.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };
  const [formCheckpoint] = Form.useForm();
  const handleLockUser = (idUser: number, type: string) => {
    const onSubmitCheckpoint = (value: any) => {
      changeCheckpoint.run(idUser, value?.reason);
    };
    Modal.confirm({
      icon: null,
      width: '600px',
      content: (
        <Form
          id="formCheckpoint"
          layout="vertical"
          form={formCheckpoint}
          onFinish={onSubmitCheckpoint}
        >
          <div>
            {formatMessage({
              id: type === 'lock' ? 'message_lock' : 'message_unlock',
            })}
          </div>
          <Form.Item
            label="Nhập lý do"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập lý do',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      ),
      okButtonProps: {
        htmlType: 'submit',
        form: 'formCheckpoint',
        loading: changeCheckpoint.loading,
      },
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      onCancel() {
        formCheckpoint.resetFields();
      },
      onOk() {
        if (formCheckpoint.getFieldValue('reason')) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      },
    });
  };

  const handleRejectUser = (idUser: number | string) => {
    setIdSelected(idUser);
    setRejectDialog.set(true);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'User ID',
      width: 100,
      dataIndex: 'userId',
      key: 'userId',
      align: 'right',
    },
    {
      title: formatMessage({ id: 'const_column_full_name' }),
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: formatMessage({ id: 'const_column_phone_number' }),
      dataIndex: 'phone',
      align: 'right',
      key: 'phone',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.phone ? record.phone : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: formatMessage({ id: 'const_column_email' }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: formatMessage({ id: 'const_column_invited_by' }),
      dataIndex: 'invite_by',
      render: (_, record: any) => (
        <>
          <div>{record?.invite_by?.full_name}</div>
          <div>{record?.invite_by?.email}</div>
          <div>{record?.invite_by?.phone}</div>
        </>
      ),
      key: 'invite_by',
    },
    {
      title: formatMessage({ id: 'const_column_create_date' }),
      dataIndex: 'date_created',
      align: 'right',
      render: (value) => <>{value}</>,
      key: 'date_created',
    },
    {
      title: formatMessage({ id: 'const_column_status' }),
      dataIndex: 'status',
      key: 'status',
      render: (value: any, record: any, index: number) => {
        return (
          <>
            {record.status === true && !record.deleted ? (
              <Tag color="green">{formatMessage({ id: 'status_active' })}</Tag>
            ) : !record.status && !record.deleted ? (
              <Tag color="gray">{formatMessage({ id: 'status_inactive' })}</Tag>
            ) : record.deleted ? (
              <Tag color="red">{formatMessage({ id: 'status_deleted' })}</Tag>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: formatMessage({ id: 'const_column_action' }),
      width: '12%',
      dataIndex: 'custom',
      key: 'custom',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        return (
          <div className={styles.toolTip}>
            <Tooltip
              title={formatMessage({ id: 'general_tooltip_show_infomation' })}
            >
              <a href={'/user-management/' + record?.userId}>
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
            {!record.deleted ? (
              <Tooltip title={formatMessage({ id: 'general_tooltip_delete' })}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAcceptUser(record.userId)}
                >
                  <DeleteOutlined className="red-color" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={formatMessage({ id: 'general_tooltip_restore' })}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRestoreUser(record.userId)}
                >
                  <UndoOutlined className="gray-color" />
                </div>
              </Tooltip>
            )}
            {!record?.is_checkpoint ? (
              <Tooltip title={formatMessage({ id: 'general_tooltip_lock' })}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleLockUser(record.userId, 'lock')}
                >
                  <LockOutlined className="warning-color" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={formatMessage({ id: 'general_tooltip_unlock' })}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleLockUser(record.userId, 'unlock')}
                >
                  <UnlockOutlined className="warning-color" />
                </div>
              </Tooltip>
            )}
            <Tooltip title={formatMessage({ id: 'general_tooltip_active' })}>
              <Switch
                checked={record.status}
                onClick={() =>
                  handleChangeUserStatus(record.userId, !record.status)
                }
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <RangePicker
            format={dataFormat}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item
          name="filterBy"
          // initialValue={OPTION_STATUS_ACTIVE[0].value}
          className={styles.searchItem}
        >
          <Select
            onChange={submit}
            placeholder={formatMessage({ id: 'general_status_active' })}
            allowClear
          >
            {OPTION_STATUS_ACTIVE.map((item, i) => (
              // eslint-disable-next-line react/jsx-key
              <Option value={item.value} key={i}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'user_management_list_user' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          //@ts-ignore
          {...tableProps}
          columns={columns}
          rowKey="userId"
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
        />
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )}
    </>
  );
};

export default Users;
