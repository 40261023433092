import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate: string[];
  },
) => {
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY/MM/DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY/MM/DD')
    : '';
  const data = {
    query: `{
      statistic_release_lb(input: { page_index: ${
        current - 1
      }, page_size: ${pageSize}, date_start: "${fromDate}", date_end: "${toDate}"  }) {
        total
        data {
          id
          release_at
          total_release_token
          current_amount
          difference
          total_lb_transfer_to_web
        }
      }
    }`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const data = res?.data?.statistic_release_lb?.data?.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current - 1) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.statistic_release_lb?.total,
        list: data,
      };
    },
  );
};
