import {
  DownloadOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Button, Form, Input, Row, Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useIntl, useParams, useRequest } from 'umi';
import styles from '../index.less';
import { getUserChildren } from '../service';
import Expanded from './Expanded';
import { ENVIRONMENTS } from '@/utils/constant';

const SystemTree = () => {
  const [tableData, setTableData] = React.useState<any[]>([]);
  const params = useParams<{ id: string }>();
  let F = 1;
  const [form] = Form.useForm();
  const { tableProps, search, refresh, data } = useAntdTable(getUserChildren, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        id: +params.id,
      },
      {
        search_value: '',
      },
    ],
    form,
    onSuccess: (res) => {
      // refactoryData(res?.children, 1);
      // setTableData(res?.children);
    },
  });
  const { submit } = search;

  const { formatMessage } = useIntl();
  const columns: ColumnsType<any> = [
    // {
    //   title: '',
    //   width: 100,
    //   key: 'userId',
    // },
    {
      title: 'Cấp F',
      // dataIndex: 'fLevel',
      key: 'fLevel',
      render: (value: any, record: any, index: number) => {
        return <>F{F}</>;
      },
    },
    {
      title: 'Mã',
      dataIndex: 'id',
      align: 'right',
      key: 'id',
    },
    {
      title: 'Họ tên',
      dataIndex: ['user_profile', 'full_name'],
      key: 'user_profile.full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ngày tham gia',
      dataIndex: 'date_created',
      align: 'right',
      key: 'date_created',
    },
    {
      title: 'Trạng thái meet',
      dataIndex: 'status_meet',
      render: (value) =>
        value ? (
          <Tag color="green">Đã meet</Tag>
        ) : (
          <Tag color="grey">Chưa meet</Tag>
        ),
    },
  ];

  const infoUser = JSON.parse(
    window?.localStorage.getItem(
      ENVIRONMENTS.ADMIN_STORAGE_KEY as string,
    ) as string,
  );

  const adminId = infoUser?.admin_profile?.admin_id;

  const searchForm = (
    <div className={styles.searchContainer}>
      <Row>
        <Form form={form} className={styles.searchForm}>
          <Form.Item name="search_value" className={styles.searchItem}>
            <Input.Search
              placeholder={formatMessage({ id: 'form_search_text' })}
              allowClear
              onSearch={submit}
            />
          </Form.Item>
        </Form>
        <div>
          <span
            style={{
              fontSize: '18px',
            }}
          >
            Giới thiệu thành công:{' '}
            <b className="primary-color">{data?.invited_success}</b>
          </span>
        </div>
      </Row>
      <Button
        icon={<DownloadOutlined />}
        href={
          ENVIRONMENTS.API_URL +
          `/user/export-meet-data/${params.id}/${adminId}`
        }
        danger
      >
        Xuất File lịch sử Meet
      </Button>
    </div>
  );
  return (
    <div className={styles.mainInfoUser}>
      {searchForm}
      <Table
        //@ts-ignore
        columns={columns}
        rowKey={(record) => record.id}
        // locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowRender: (rc: any) => (
            <Expanded id={rc?.id} fLevel={F + 1} />
          ),
        }}
        {...tableProps}
      />
    </div>
  );
};

export default SystemTree;
