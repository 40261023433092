import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import { useIntl } from 'umi';
import styles from '../index.less';

interface Iprops {
  open: boolean;
  setOpen: (b: boolean) => void;
  onConfirm: (email: string) => void;
  loading: boolean;
}
const Dialog: React.FC<Iprops> = ({ open, setOpen, onConfirm, loading }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Modal
        width={600}
        onCancel={() => setOpen(false)}
        open={open}
        footer={null}
        title="Thêm Domain mail blacklist"
      >
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={(value) => {
            onConfirm(value.email);
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Tên domain"
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'error.require' },
                      {
                        field: formatMessage({ id: 'email' }),
                      },
                    ),
                  },
                ]}
              >
                <Input placeholder="Nhập tên domain" />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.addGroupButton}>
            {/* <Button className={styles.addButton}>Thêm mới</Button> */}
            <Button
              danger
              onClick={() => setOpen(false)}
              className={styles.addButton}
            >
              {formatMessage({ id: 'general_cancel' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.addButton}
              loading={loading}
            >
              {formatMessage({ id: 'general_accept' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(Dialog);
