import { Breadcrumb, Space, Spin, Tabs } from 'antd';

import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import StallInfo from './Components/StallInfo';
// import GiftList from './Components/GiftList';
import Report from './Components/Report';
import { Link, history, useParams } from 'umi';
import { useGetDetailShop } from '../service';
import { LeftOutlined } from '@ant-design/icons';
// const { Option } = Select;

export default () => {
  const param: any = useParams();
  const { loadingShopDetail } = useGetDetailShop(param?.id);
  if (loadingShopDetail) {
    return (
      <div className={styles.createBuilding}>
        <Space
          onClick={() => history.push('/stall')}
          className={styles.titlePage}
          size={5}
          align="center"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="left"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
          </svg>
          Chi tiết gian hàng
        </Space>
        <div className={styles.loading}>
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.createBuilding}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/stall" className={styles.previousLink}>
            <LeftOutlined />
          </Link>
          Chi tiết gian hàng
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="primary-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin gian hàng" key="1">
            <StallInfo />
          </TabPane>
          {/* <TabPane tab="Danh sách Quà tặng" key="2">
          <GiftList />
        </TabPane> */}
          <TabPane tab="Báo cáo tương tác" key="3">
            <Report />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
