import React from 'react';
import styles from '../index.less';
import { Button, Form, Input, Modal, Tooltip, message } from 'antd';
import { useIntl, useRequest } from 'umi';
import { useAntdTable, useToggle } from 'ahooks';
import {
  addBlacklistDomain,
  deleteBlacklistDomain,
  getAllBlackListDomain,
} from '../service';
import Table, { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import Dialog from './Dialog';
const BlacklistDomain = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, search, refresh } = useAntdTable(getAllBlackListDomain, {
    form,
  });
  const [isOpenModal, setIsOpenModal] = useToggle(false);
  const deleteDomain = useRequest(deleteBlacklistDomain, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Xóa thất bại');
        }
      } else {
        message.success('Thành công');
      }
      refresh();
    },
  });
  const addDomain = useRequest(addBlacklistDomain, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.errors) {
        if (res?.errors[0]?.extensions?.code === 'FORBIDDEN') {
          message.error('Không có quyền');
        } else {
          message.error('Có lỗi xảy ra');
        }
      } else {
        message.success('Thành công');
        refresh();
      }
      setIsOpenModal.set(false);
    },
    formatResult: (res) => res,
  });
  const handleDeleteBlacklist = (id: number) => {
    Modal.confirm({
      icon: null,
      content: 'Bạn muốn xóa domain này khỏi blacklist ?',
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      okButtonProps: {
        loading: deleteDomain.loading,
      },
      onOk() {
        deleteDomain.run(id);
      },
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên domain blacklist',
      dataIndex: 'email',
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'total',
      align: 'right',
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <>
          <Tooltip title="Xóa">
            <DeleteOutlined
              style={{
                color: 'red',
              }}
              className={styles.btnIconDelete}
              onClick={() => handleDeleteBlacklist(record?.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({
              id: 'form_search_blacklist_domain',
            })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      <Button onClick={() => setIsOpenModal.set(true)}>
        {formatMessage({ id: 'general_add' })}
      </Button>
    </div>
  );

  return (
    <div>
      <div className={styles.tableComponent}>
        {searchForm}
        <Table {...tableProps} columns={columns} scroll={{ x: 1000 }} />
      </div>
      {isOpenModal && (
        <Dialog
          open={isOpenModal}
          setOpen={(open) => {
            setIsOpenModal.set(open);
          }}
          onConfirm={(email) => {
            addDomain.run(email);
          }}
          loading={addDomain.loading}
        />
      )}
    </div>
  );
};

export default BlacklistDomain;
