import { addFaceToBlacklist } from '@/pages/KycInfo/service';
import { API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import Upload, { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import { useIntl, useRequest } from 'umi';

export const DialogAddFaceBlacklist = ({
  isOpen,
  setIsOpen,
  refresh,
}: {
  isOpen: boolean;
  setIsOpen: any;
  refresh: () => void;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const [formAddFace] = Form.useForm();
  const onCancel = () => {
    setIsOpen(false);
    setFileList([]);
    formAddFace.resetFields();
  };
  const { formatMessage } = useIntl();
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const addFaceBlacklist = useRequest(addFaceToBlacklist, {
    manual: true,
    onSuccess: (res) => {
      formAddFace.resetFields();
      message.success('Thêm ảnh vào danh sách blacklist thành công');
      setIsOpen(false);
      setFileList([]);
      refresh();
    },
    onError(err: any) {
      formAddFace.resetFields();
      message.error(err?.data?.message);
      setIsOpen(false);
      setFileList([]);
    },
  });
  const onFinish = (values: any) => {
    const payload = {
      image:
        values.image?.file?.response.url + values.image?.file?.response.path,
      note: values.note,
    };
    addFaceBlacklist.run(payload);
  };
  return (
    <Modal
      title="Thêm ảnh blacklist khuôn mặt"
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={formAddFace}
        id="formAddFace"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          className="uploadItem"
          style={{
            textAlign: 'center',
          }}
          label="Upload ảnh"
          name="image"
          rules={[
            {
              required: fileList.length > 0 ? false : true,
              message: 'Vui lòng chọn ảnh',
            },
            {
              validator: (_, value) => {
                if (value?.fileList?.length === 0) {
                  return Promise.reject('Vui lòng chọn ảnh');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            accept=".png,.jpg,.jpeg"
            action={
              ENVIRONMENTS.API_URL +
              `${ENVIRONMENTS.DEV_ENV ? API_PATH.UPLOAD_DEV : API_PATH.UPLOAD}`
            }
            onPreview={onPreview}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
          >
            {fileList.length < 1 && 'Tải lên'}
          </Upload>
        </Form.Item>

        <Form.Item
          name="note"
          label={formatMessage({ id: 'reason' })}
          rules={[
            {
              required: true,
              message: formatMessage(
                { id: 'error.require' },
                {
                  field: formatMessage({ id: 'reason' }),
                },
              ),
            },
          ]}
        >
          <Input.TextArea
            maxLength={100}
            placeholder={formatMessage({ id: 'reason' })}
            rows={2}
          />
        </Form.Item>
        <Row justify="center">
          <Button
            type="primary"
            ghost
            danger
            onClick={onCancel}
            style={{
              margin: '0 10px',
            }}
          >
            Hủy
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            style={{
              margin: '0 10px',
            }}
            loading={addFaceBlacklist.loading}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
