import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Space,
  Table,
} from 'antd';
import React from 'react';
import styles from '../index.less';
import { Link, useHistory, useLocation, useParams } from 'umi';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import { useAntdTable } from 'ahooks';
import { GetStatisticEnterpriseVoucher } from '../service';
const StatisticVoucher = () => {
  const [form] = Form.useForm();
  const router = useHistory();
  const param: { id: string } = useParams();
  const location: any = useLocation();

  const { tableProps, search, data } = useAntdTable(
    GetStatisticEnterpriseVoucher,
    {
      defaultParams: [
        {
          pageSize: 10,
          current: 1,
          typeId: +param.id,
          //@ts-ignore
          enterpriseId: +location?.query?.enteprise_id,
        },
        {},
      ],
      form,
    },
  );

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'created_at',
    },
    {
      title: 'Người sử dụng',
      dataIndex: 'customer_name',
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'bill_code',
    },
    {
      title: 'Số lượng voucher',
      dataIndex: 'voucher_count',
      align: 'right',
    },
    {
      title: 'Giá trị hóa đơn',
      dataIndex: 'invoice_value',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Giá trị khuyến mại',
      dataIndex: 'discount',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Giá trị hóa đơn sau KM',
      dataIndex: 'invoice_value_after_discount',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Nguồn voucher',
      dataIndex: 'origin',
      render: (_, record: any) => (
        <div className={styles.sourceVoucher}>
          {record?.voucher_origin.map((item: string, i: number) => (
            <div>
              Voucher {i + 1}: {item}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Ảnh hóa đơn',
      dataIndex: 'bill_path',
      render: (value) => (
        <>
          <Image
            alt="bill"
            src={value}
            style={{
              width: '80px',
              height: '120px',
            }}
          />
        </>
      ),
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm ID/tên người sử dụng"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Space
            onClick={() => router.goBack()}
            className={styles.previousLink}
          >
            <LeftOutlined />
            <span>
              Thống kê sử dụng voucher
              {location?.query?.enteprise_id ? (
                <>
                  {' '}
                  tại <b>{`${data?.enterprise_name}`}</b>
                </>
              ) : (
                <>
                  {' '}
                  <b>{data?.voucher_name}</b>
                </>
              )}
            </span>
          </Space>
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

export default StatisticVoucher;
