import { LeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from 'antd';
import React, { useState } from 'react';
import { Link, useHistory, useIntl } from 'umi';
import styles from '../index.less';
// import UploadImage from '@/components/UploadImage';
import { DISCOUNT_TYPE } from '@/utils/constant';
import { useRequest } from 'ahooks';

import { DiscountType } from '@/utils/enum';
import {
  CreateDiscountEventInput,
  createDiscountEvent,
  getCoreVoucher,
  getListApplyEvent,
  getListEnterpriseRegisterVoucher,
} from '../service';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  formatInputNumber,
  validatorPositiveNumber,
} from '@/utils/apis/common';
const CreateDiscountEvent = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  // const [fileList, setFileList] = useState([]);
  const [atOptions, setAtOptions] = useState<any[]>([]);
  const [optionEnterprise, setOptionEnterprise] = useState<any[]>([]);
  const [optionDiscountEvent, setOptionDiscountEvent] = useState<any[]>([]);
  const [discountType, setDiscountType] = useState<DiscountType>(
    DiscountType.BILL_DISCOUNT,
  );
  const [selectedEnterprise, setSelectedEnterprise] = useState<number>(0);
  const [applySameTime, setApplySameTime] = useState(false);
  const requestSearchVoucher = useRequest(getCoreVoucher, {
    debounceWait: 1000,
    onSuccess: (res) => {
      setAtOptions(() =>
        res.data?.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        })),
      );
    },
    onError: (err) => {},
  });

  const searchEnterprise = useRequest(getListEnterpriseRegisterVoucher, {
    debounceWait: 500,
    onSuccess: (res) => {
      if (res) {
        setOptionEnterprise((state) => state.concat(res?.data?.data));
      }
    },
  });

  const searchDiscountEvent = useRequest(getListApplyEvent, {
    manual: true,
    debounceWait: 500,
    onSuccess: (res) => {
      console.log(res);
      setOptionDiscountEvent(res.data);
    },
  });

  const handleSearchEnterprise = (searchValue: string) => {
    setOptionEnterprise([]);
    searchEnterprise.run(searchValue);
  };

  const createEvent = useRequest(createDiscountEvent, {
    manual: true,

    onSuccess: (res) => {
      message.success('Tạo chuơng trình KM thành công');
      history.push('/discount_event');
    },
    onError: (err: any) => {
      if (err?.data?.statusCode === 403) {
        message.error('Không có quyền');
      } else {
        if (err.data?.message) {
          message.error(err.data?.message);
        } else {
          message.error('Có lỗi xảy ra');
        }
      }
    },
  });
  const onFinish = (val: any) => {
    const payload: CreateDiscountEventInput = {
      ...val,
      start_time: moment(val.start_time).format('YYYY-MM-DD'),
      end_time: moment(val.end_time).format('YYYY-MM-DD'),
      // image: val.image?.[0]?.response?.path,
      apply_at_same_time: val.apply_at_same_time ? true : false,
      disable: !val.display,
    };
    if (discountType === DiscountType.BILL_DISCOUNT) {
      payload.limit_discount_percent = 1;
    }
    if (!!payload.apply_at_same_time) {
      payload.apply_same_time_list = val.apply_same_time_list ?? [];
    }
    //@ts-ignore
    delete payload['display'];
    createEvent.run(payload);
  };
  const [form] = Form.useForm();

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/discount_event" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'discount_event' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.wrapper}>
        <Card>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row justify={'space-between'}>
              <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                <Form.Item
                  label="Tên chương trình"
                  name="event_name"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên chương trình" />
                </Form.Item>
                <Form.Item
                  label="Thời gian bắt đầu"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                  ]}
                >
                  <DatePicker
                    format={'DD/MM/YYYY'}
                    style={{
                      width: '100%',
                    }}
                    disabledDate={(currentDate) => {
                      if (!!form.getFieldValue('end_time')) {
                        const end_time = dayjs(form.getFieldValue('end_time'));
                        return (
                          currentDate < dayjs().add(-1, 'day') ||
                          currentDate > end_time
                        );
                      }

                      return currentDate < dayjs().add(-1, 'day');
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Loại khuyến mãi"
                  name="type"
                  initialValue={discountType}
                >
                  <Select
                    onChange={(val) => setDiscountType(val)}
                    placeholder="Chọn loại"
                    options={DISCOUNT_TYPE.map((item) => ({
                      label: item.name,
                      value: item.value,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  label="Số voucher tối đa được áp dụng/1 hoá đơn"
                  name="limit_voucher_per_bill"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                    {
                      validator: validatorPositiveNumber,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số voucher"
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                {/* {discountType === DiscountType.BILL_DISCOUNT && (
                  <Form.Item
                    label="Hạn mức áp dụng voucher / giá trị hoá đơn"
                    name="limit_discount_percent"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập',
                      },
                      {
                        validator: validatorPositiveNumber,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Nhập hạn mức"
                      style={{
                        width: '100%',
                      }}
                      addonAfter="%"
                    />
                  </Form.Item>
                )} */}
                <Form.Item
                  label="Mô tả chương trình"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Nhập mô tả" rows={4} />
                </Form.Item>
                {discountType === DiscountType.PRODUCT_DISCOUNT && (
                  <>
                    <Form.Item
                      name="apply_at_same_time"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={false}
                        onChange={(e) => {
                          setApplySameTime(e.target.checked);
                          if (!!e.target.checked) {
                            searchDiscountEvent.run(selectedEnterprise);
                          }
                        }}
                      >
                        Áp dụng đồng thời với các chương trình khác
                      </Checkbox>
                    </Form.Item>
                    {applySameTime && (
                      <Form.Item name="apply_same_time_list">
                        <Select
                          mode="multiple"
                          placeholder="Chọn chương trình"
                          options={optionDiscountEvent.map((item) => ({
                            label: item.event_name,
                            value: item.id,
                          }))}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Col>
              <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                <Form.Item
                  label="Doanh nghiệp áp dụng"
                  name="enterprise_id"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn doanh nghiệp',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn doanh nghiệp"
                    options={
                      optionEnterprise.length
                        ? optionEnterprise?.map((item: any, i: number) => ({
                            label: item.name,
                            value: item.id,
                            key: `${item?.name}_${i}`,
                          }))
                        : []
                    }
                    onChange={(val) => {
                      searchEnterprise.run();
                      setSelectedEnterprise(val);
                      if (applySameTime) {
                        searchDiscountEvent.run(val);
                      }
                    }}
                    filterOption={false}
                    notFoundContent={
                      searchEnterprise.loading ? <Spin /> : <>Trống</>
                    }
                    onSearch={handleSearchEnterprise}
                    // onPopupScroll={onLoadMoreEnterprise}
                  />
                </Form.Item>
                <Form.Item
                  label="Thời gian kết thúc"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                  ]}
                >
                  <DatePicker
                    format={'DD/MM/YYYY'}
                    style={{
                      width: '100%',
                    }}
                    disabledDate={(currentDate) => {
                      if (!!form.getFieldValue('start_time')) {
                        const start_time = dayjs(
                          form.getFieldValue('start_time'),
                        );
                        return (
                          currentDate < dayjs().add(-1, 'day') ||
                          currentDate < start_time
                        );
                      }
                      return currentDate < dayjs().add(-1, 'day');
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Loại voucher áp dụng"
                  name="voucher_type_id"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn loại voucher',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn voucher"
                    options={atOptions.map((item: any, i: number) => ({
                      ...item,
                      key: `${item?.value}_${i}`,
                    }))}
                    notFoundContent={
                      requestSearchVoucher.loading ? <Spin /> : <>Trống</>
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').includes(input)
                    }
                    // filterOption={false}
                    // onSearch={(searchValue) => handleSearchVoucher(searchValue)}
                  />
                </Form.Item>
                <Form.Item
                  label="Số voucher tối đa được sử dụng trong chương trình"
                  name="limit_voucher_event"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập',
                    },
                    {
                      validator: validatorPositiveNumber,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Nhập số voucher"
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                {discountType === DiscountType.BILL_DISCOUNT && (
                  <Form.Item
                    label="Giá trị hóa đơn tối thiểu để được áp dụng voucher"
                    name="minimum_amount_require"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập',
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Nhập giá trị"
                      formatter={formatInputNumber}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                )}
                {/* <UploadImage
                  name="image"
                  label="Ảnh chương trình"
                  setFileList={setFileList}
                  fileList={fileList}
                  required={true}
                /> */}
                <Form.Item
                  label="Trạng thái hiển thị"
                  name="display"
                  valuePropName="checked"
                >
                  <Switch checked={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="center" className={styles.addGroupButton}>
              <Button
                danger
                ghost
                className="btn-submit"
                onClick={() => history.push('/discount_event')}
              >
                Huỷ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                loading={createEvent.loading}
              >
                Lưu
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CreateDiscountEvent;
