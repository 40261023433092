import React, { useState } from 'react';
import styles from './index.less';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from 'antd';
import { useIntl } from 'umi';
import { useAntdTable, useRequest } from 'ahooks';
import { ORDER_STATUS, getAllEnterprise, getListOrder } from './service';
import { ColumnsType } from 'antd/lib/table';
import DetailOrder from './Component/DetailOrder';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/apis/common';
const OrderManagement = () => {
  const { formatMessage } = useIntl();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const listEnterprise = useRequest(getAllEnterprise, {
    // manual: true,
    onSuccess: (res) => {
      console.log(res);
    },
    debounceWait: 1000,
  });
  const [form] = Form.useForm();
  const { tableProps, search, run } = useAntdTable(getListOrder, {
    form,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        status: ORDER_STATUS.PENDING,
      },
      {},
    ],
  });
  const { submit } = search;
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'id',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: ['user', 'user_profile', 'full_name'],
    },
    {
      title: 'Thời gian đặt đơn',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: ['user', 'user_profile', 'phone'],
    },
    {
      title: 'Địa chỉ khách hàng',
      dataIndex: ['user', 'user_profile', 'address'],
    },
    {
      title: 'Tổng giá trị đơn hàng',
      dataIndex: 'total_price',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Hành Động',
      key: 'action',
      render: (_: any, record: any) => {
        const isExpanded = expandedRowKeys.includes(record.id);
        return (
          <Button type="link" onClick={() => handleExpand(record.id)}>
            {!isExpanded ? (
              <Space>
                <span>Xem chi tiết</span>
                <DownOutlined />
              </Space>
            ) : (
              <Space>
                <span>Ẩn chi tiết</span>
                <UpOutlined />
              </Space>
            )}
          </Button>
        );
      },
    },
  ];

  const handleExpand = (key: string) => {
    setExpandedRowKeys((prevState) =>
      prevState.includes(key)
        ? prevState.filter((k) => k !== key)
        : [...prevState, key],
    );
  };
  const expandedRowRender = (record: any) => (
    <DetailOrder orderCode={record.id} />
  );
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo ID, tên, khách hàng, Mã đơn hàng"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="enterprise_id" className={styles.searchItem}>
          <Select
            placeholder="Nhập ID và tên DN"
            showSearch
            allowClear={true}
            onSearch={(val: string) => listEnterprise.run(val)}
            filterOption={false}
            notFoundContent={
              listEnterprise.loading ? <Spin size="small" /> : 'Không tìm thấy'
            }
            onChange={submit}
          >
            {listEnterprise.data?.data?.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  const [keyStatus, setKeyStatus] = useState();
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'order_management' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        onChange={(key: string) => {
          run(
            {
              pageSize: 10,
              current: 1,
              status: key as ORDER_STATUS,
            },
            {},
          );
        }}
        defaultActiveKey={location.pathname}
        className={styles.tabs}
      >
        <Tabs.TabPane key={ORDER_STATUS.PENDING} tab="Chờ xử lý"></Tabs.TabPane>
        <Tabs.TabPane
          key={ORDER_STATUS.VERIFIED}
          tab="Đã xác nhận"
        ></Tabs.TabPane>
        <Tabs.TabPane key={ORDER_STATUS.DENIED} tab="Từ chối"></Tabs.TabPane>
        <Tabs.TabPane key={ORDER_STATUS.CANCELED} tab="Đã huỷ"></Tabs.TabPane>
      </Tabs>

      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          expandedRowKeys={expandedRowKeys}
          onExpand={(_, record) => handleExpand(record.id)}
          expandedRowRender={expandedRowRender}
          expandIconColumnIndex={-1}
          rowKey="id"
          scroll={{
            x: 1000,
          }}
        />
      </div>
    </div>
  );
};

export default OrderManagement;
