import { useAntdTable } from 'ahooks';
import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Skeleton,
  Table,
  DatePicker,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'umi';
import { LOCABONUS_TRANSFER } from './constant';
import styles from './index.module.less';
import { getUserTransactionHistory } from './service';
const { Option } = Select;
const { RangePicker } = DatePicker;
const LocabonusTransfer = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getUserTransactionHistory,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );
  const { type, changeType, submit, reset } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form
        form={form}
        className={styles.searchForm}
        initialValues={{
          toDate: [moment(new Date()), moment(new Date())],
        }}
        layout="inline"
      >
        <Form.Item name="searchValue" className={styles.searchItem}>
          <Input.Search
            placeholder={'Tìm kiếm theo UserID, ID giao dịch'}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="type" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'general_transfer_type' })}
          >
            {LOCABONUS_TRANSFER.map((item) => (
              <Option value={item.value}>{item.value}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="amount" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'general_amount_locabonus' })}
          >
            <Option value={'less'}>{`< 1000 LocaBonus`}</Option>
            <Option value={'more'}>{`>= 1000 LocaBonus`}</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      align: 'right',
    },
    {
      title: 'ID giao dịch',
      dataIndex: 'transaction_id',
    },
    {
      title: 'UserId',
      dataIndex: 'user_id',
      align: 'right',
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'type',
    },
    {
      title: 'Số lượng LB',
      dataIndex: 'amount',
      align: 'right',
      render: (value: any) => (
        <strong className="primary-color">{value}</strong>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
  ];
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'locabonus_transfer' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
            {...tableProps}
          />
        )}
      </div>
    </>
  );
};

export default LocabonusTransfer;
